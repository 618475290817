import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
// import GoogleAd from '../..//Components/GoogleAd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class JavaOverview extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Overview</title>
                    <meta name="description" content="Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries" />
                    <meta name="keywords" content="tutsfinder, java tutorials tutsfinder, java tutorial, core java tutorial, java programming, tutorials, java for beginners, java for professionals, introduction to java, core, concepts, example, java, language, basic, introduction" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Overview" />
                    <meta property="og:description" content="Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-overview" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                        <br/><br/>
                        <div>
                            <img src="../img/java/core-java-image.jpg" style={{width:`100%`,height:`auto`,border: `groove`}} alt="core-java"/>
                        </div>
                    <br/>
                    <hr/>
                    <p>After reading this tutorial you will understand the concepts of object oriented programming, how object and classes worked together in java and would be able to solve real world problems.</p>
                    <p>If you have already studied about C++ then it will be advantageous for you to learn java.</p>
                    <p>The objective of this tutorial is to introduce the Java Technology to the learners. This tutorial is designed to provide knowledge of Java and to introduce the programming syntax to the learners. After going through this tutorial the learner will be able to make object oriented programs in Java. The tutorial is bundled with a number of programs to make the learning easy and effective.</p>
                    <strong style={{fontSize:`25px`}}>Why Learn Java ?</strong>
                    <p>Most of the students think, to become a developer why we learn java. So here is your answer, we are adding some advantages –</p>
                    <p><strong>Java is Beginner friendly –</strong></p>
                    <p>Java is a high level programming language, so it is understandable to all the users. Its syntax is very easy and readable.</p>
                    <p><strong>Fast and Maintainale –</strong></p>
                    <p>Java is fatser than c and c++ because it uses jit compiler to compile the code and its code is easy to maintain and more readable.</p>
                    <p><strong>Community Support –</strong></p>
                    <p>Java is managed by oracle, the larger programming language needs more community support. So java provides best community support because there are more then 9 million java developers in world. Java releases it updates after every 6 moths.</p>
                    <p><strong>Java is everywhere –</strong></p>
                    <p>Java is used for everything and everywhere. It is used in –</p>
                    <p>-Enterprise applications</p>
                    <p>- Android apps</p>
                    <p>- AI and Machine learning</p>
                    <p>- Big Data</p>
                    <p>- Web and desktop applications </p>
                    <br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Introduction</strong>
                    <p>Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries</p>
                    <br/>
                    <div className="container" id="java-program" style={{width:`80%`, height:`auto`, border: `2px solid black`, background:`#E3CDC2`, color:`black`}}>
                        <p>
                            public class FirstProgram
                            <br/>
                            &#123;
                                <br/>
                                public static void main(String[] args)&#123;
                                <br/>
                                System.out.print(“This is my First Program”);
                                <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;
                                <br/>
                            &#125;
                        </p>
                    </div>
                    <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>What is object oriented programming?</strong>
                    <p>OOP is problem solving technique to develop software systems. It is a technique to think real word entities in terms of objects. These objects have some states and behaviors and provide services to application.</p>
                    <p>Java is high level, interpreted, object oriented, secure, robust, architecture neutral and secure programming language. These are also the features of Java:</p>
                    <br/>
                    <strong style={{fontSize:`20px`}}>Features of Java</strong>
                    <p>
                    <strong>1.	Platform Independent</strong></p>
                    <p>The concept of write-once-run-anywhere is one of the vital features of Java language that makes java as the powerful language. The program written on one platform can run on any other platform.</p>
                    <p>2.	<strong>Simple</strong></p>
                    <p>In java, programs are easy to write and debug because java does not use the pointer explicitly. If programmer already have knowledge of C++ then it will be easier for them. There are various features that makes java as simple language.</p>
                    <p>3.	<strong>Object Oriented</strong></p>
                    <p>To be an Object Oriented language, any language must follow these characteristics-</p>
                    <p><strong>Encapsulation:</strong> It is mechanism to encapsulate or wrap up the data in a single entity it is known as encapsulation.</p>
                    <p><strong>Abstraction:</strong> Abstraction is a process to hide the implementation details from the users and showing only the functionality.</p>
                    <p><strong>Inheritance:</strong> Inheritance is a process in which one class acquires the property of another class.</p>
                    <p><strong>Polymorphism:</strong> As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods.</p>
                    <p><strong>4.	Bottom-up approach</strong></p>
                    <p>In bottom-up approach, small pieces are come together to form a large sub-system. In this, object and classes get together and form a program.</p>
                    <p><strong>5.	Robust</strong></p>
                    <p>Java has strong memory allocation, automatic garbage collection and provides exception handling mechanism that makes java a robust language.</p>
                    <p><strong>6.	Multithreaded</strong></p>
                    <p>Multithreading means a single program have multiple threads and execute independently at same time. Multithreading is a feature of java that have multiple single threads.</p>
                    <p>Thread is a light weight process of a program and these threads executing simultaneously.</p>
                    <p><strong>7.	Secure</strong></p>
                    <p>All the programs in java run under an area known as sand box.</p>
                    <p>The public key encryption system is used by java to allow the java applications to transmit over the internet in the secure encrypted form.
                    Java does not use memory pointer explicitly.</p>
                    <p><strong>8.	Interpreted</strong></p>
                    <p>Interpreter is used to execute instructions that are written in programming language.</p>
                    <p>Java is interpreted language that makes it versatile in nature because interpreter compiles and executes the code simultaneously; it takes only single line of code at a time.</p>
                    <p><strong>9.	WORA(write-once-run-anywhere)</strong></p>
                    <p>When you write a program on one system that it will be able to run on any other system, this thing makes java as portable.</p>
                    <p><strong>10.	JIT compiler</strong></p>
                    <p>Just in time compiler is used by Java that converts the byte code into native code. JIT is a component of Java Virtual Machine. It is responsible for performance of the Java application at run time.</p>

                    <br/><br/>

                    <div className="row next-prev-button-row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="setup-environmental-variables-in-java">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className="side-pincode-check">
                    <span className="quick-search">Quick Search</span>
                    <br/>
                    <span className="check-pincode-here">Check Pincode here</span>
                    <br/>
                    <button className="pincode-click-button"><Link to="/pincode" style={{color: 'white', fontSize: '1.5rem'}}>Click</Link></button>
                </div> */}
            </>
        )
    }
}