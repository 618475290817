import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CommandLineArguments extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Command Line Arguments</title>
                    <meta name="description" content = "Command line argument is used to pass the value to main() function. We can pass the value to our C program when they are executed." />
                    <meta name="keywords" content="Command Line Arguments, Command Line Arguments in c, C Command Line Arguments,  C Command Line Arguments Tutorial, javatpoint , tutsfinder, Command Line Arguments tutsfinder tutorial" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming -Command Line Arguments" />
                    <meta property="og:description" content="Command line argument is used to pass the value to main() function. We can pass the value to our C program when they are executed." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-command-line-argument" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Command Line Argument</h2>
                    <br/>
                    <p>Command line argument is used to pass the value to main() function. We can pass the value to our C program when they are executed.</p>
                    <br/>
                Syntax –
                <br/>
                <pre>
                int main(int argc, char *argv[])<br/>
                &#123;<br/>
                &#125;
                </pre>
                <br/>
                <p>where,</p>
                <p>argc is used to counts the number of arguments in the command line.</p>
                <p>argv is a pointer array which is used to point to the arguments passed.</p>
                <br/>
                <div className="container" id="java-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                #include&lt;stdlib.h&gt;<br/>
                int main(int argc, char *argv[])<br/>
                &#123;<br/>
                    int num1 = atoi(argv[1]);<br/>
                    printf("Number of arguments: %d\n", argc);<br/>
                    printf("The entered number is %d", num1);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <p>To run this program enter this command – <i>ProgramName.exe</i> input</p>
                <p><strong>Ex-</strong></p> <i>program.exe 2</i>
                <br/>
                Output :
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                Number of arguments: 1
                The entered number is 0
                </pre>
                </div>
                <br/><br/>
                Another  Program for Hello
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                int main(int argc, char *argv[])<br/>
                &#123;<br/>
                    printf("Number of arguments: %d\n", argc);<br/>
                    printf("Arguments entered: %s\n", argv[1]);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <p>Run this command -  <i>program.exe hello</i></p>
                <br/>
                Output :
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                Number of arguments: 2<br/>
                Arguments entered: Hello
                </pre>
                </div>
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                #include &lt;string.h&gt;<br/>
                <br/>
                int main() &#123;<br/>
                <br/>
                    char name[100];<br/>
                    char *tuts;<br/>
                    <br/>
                    strcpy(name, "Trishant");<br/>
                    <br/>
                    tuts = malloc( 30 * sizeof(char) );<br/>
                    <br/>
                    if( tuts == NULL ) &#123;<br/>
                        fprintf(stderr, "Error - unable to allocate required memory\n");<br/>
                    &#125; else &#123;<br/>
                        strcpy( tuts, "Elementary way to find.\n");<br/>
                    &#125;<br/>
                    <br/>
                    tuts = realloc( tuts, 100 * sizeof(char) );<br/>
                    <br/>
                    if( tuts == NULL ) &#123;<br/>
                        fprintf(stderr, "Error - unable to allocate required memory\n");<br/>
                    &#125; else &#123;<br/>
                        strcat( tuts, "C Programming tutorials");<br/>
                    &#125;<br/>
                    <br/>
                    printf("Name = %s\n", name );<br/>
                    printf("tuts: %s\n", tuts );<br/>
                    <br/>
                    (/* release memory */)<br/>
                    free(tuts);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/>
                <br/>
                <div className="container" id="java-program">
                <pre>
                Name = Trishant<br/>
                tuts: Elementary way to find.<br/>
                C Programming tutorials
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="memory-management-in-c">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                </div>
            </>
        )
    }
}