import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HowToAddCss extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - How to add CSS</title>
                    <meta name="description" content="In CSS there are three ways to add CSS – inline, internal and external" />
                    <meta name="keywords" content="How to add CSS, How to import CSS, How to implement CSS, inline css, internal css, external css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="In CSS there are three ways to add CSS – inline, internal and external" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>

                    <h2>Types of HTML</h2>
                    <br/><br/>
                    <p>In CSS there are three ways to add CSS – </p>
                    <p>•	Inline CSS</p>
                    <p>•	Internal CSS</p>
                    <p>•	External CSS</p>

                    <p><strong>Inline CSS</strong> – Inline CCS  is used to apply CSS on a single element.</p>
                    <p>To use inline CSS, add the style attribute in that element, that you want to style. </p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt;Inline CSS&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h1 style="text-align:center;color:blue"&gt;Hello World!&lt;/h1&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1 style={{textAlign:`center`, color:`blue`}}>Hello World!</h1>
                    <br/><br/>


                    <p><strong>Internal CSS </strong></p>
                    <p>Internal CSS is used to style a single page .</p>
                    <p>We can implement internal CSS between &lt;head&lt; tags in style attribute.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body &#123;<br/>
                    text-align: center;<br/>
                    font-family:Florence, cursive;<br/>
                    &#125;<br/>
                    <br/>
                    h1 &#123;<br/>
                    color:blue;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h1&gt;Hello World&lt;/h1&gt;<br/>
                    &lt;p&gt;Welcome to TutsFinder.&lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/internal.png" alt="internal.png" width="100%" />
                    <br/><br/>
                    <p><strong>External CSS –</strong></p>
                    <p>In external CSS we have include external style sheet with the help of &lt;link&gt; tag .</p>
                    <p>It is used to style the entire website. An external style sheet is a separate file with.css extension.</p>
                    <p>You have to provide the url of stylesheet in head section with in &lt;link&gt; tag.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}> For Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;head&gt;<br/>
                        &lt;link type=”text/css” href=”url” /&gt;<br/>
                            Or<br/>
                        &lt;link rel="stylesheet" href="url"&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;pre&gt;<br/>
                    /div&gt;<br/>
                    &lt;/div&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    style.css<br/><br/>
                    body &#123;<br/>
                    text-align: center;<br/>
                    font-family:Florence, cursive;<br/>
                    &#125;<br/>
                    h1 &#123;<br/>
                    color:blue;<br/>
                    &#125;
                    </pre>
                    </div>
                    </div>

                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/internal.png" alt="internal.png" width="100%" />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="how-to-use-css">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-comments">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}