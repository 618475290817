import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppInterface extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Interface</title>
                    <meta name="description" content="Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword." />
                    <meta name="keywords" content="C++ Interface,  C++ Interface Tutorial, Interface , tutorials Interface, Interface beginners,Interface professionals, Interface in c++, Interface javatpoint, trishant, tutsfinder, tutorialspoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Interface" />
                    <meta property="og:description" content="Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-interface" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Interface</h2>
                <br/>
                <p>Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword.</p>
                <p>Interfaces cannot be instantiated, but can be implemented.</p>
                <p>The methods we declare in interface, it is necessary to define them in the class in which we are implementing them otherwise compiler gives an error.</p>
                <p>Abstraction can be achieved in C++ by using Abstract class and Interfaces.</p>
                <p><strong>Abstract Class</strong> – In C++, a class is made abstract class by declaring at least one of its function as pure virtual function. In pure virtual function just placing “=0” in its declaration.</p>
                <br/><br/>
                Program to demonstrate abstract claa with on virtual function –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    virtual void run()=0;<br/>
                &#125;;<br/>
                class Bike : Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    void run()<br/>
                    &#123;<br/>
                        cout &lt;&lt; " Bike is running..." &lt;&lt; endl;<br/>
                    &#125;<br/>
                &#125;;<br/>
                class Car : Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    void run()<br/>
                    &#123;<br/>
                        cout &lt;&lt; "Car is running..." &lt;&lt; endl;<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main( ) &#123;<br/>
                    Bike bike;<br/>
                    Car car;<br/>
                    bike.run();<br/>
                    car.run();<br/>
                    return 0;  
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Bike is running...<br/>
                Car is running...
                </pre>
                </div>
                <br/><br/>
                <h4>Rules of Abstract Class</h4>
                <p>1.	Any class that has a pure virtual function is an abstract class.</p>
                <p>2.	We cannot create the instance of abstract class.</p>
                <p>3.	We can create pointer and reference of base abstract class points to the instance of child class</p>
                <p>4.	Abstract class can have constructors.</p>
                <p>5.	If the derived class does not implement the pure virtual function of parent class then the derived class becomes abstract.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-inheritance">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-multithreading">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}