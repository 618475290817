import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class GettingStartedWithPhp extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Getting Started</title>
                    <meta name="description" content = "In order to run php program on your system, you must need WampServer installed on your system to create web applications with Apache, PHP and MySql database." />
                    <meta name="keywords" content="install php,getting started with php, installing php on windows 10, how to install php in windows 7, how to install php ubuntu, how to install php on mac, php installer, php for windows, download php for windows, install and configure php web server mysql, wampser, apache http server" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Getting Started" />
                    <meta property="og:description" content="In order to run php program on your system, you must need WampServer installed on your system to create web applications with Apache, PHP and MySql database." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/getting-started-with-php" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                        <hr/>
                    <strong style={{fontSize:`35px`}}>Getting Started</strong>
                    <br/><br/>
                    <p>In order to run php program on your system, you must need WampServer installed on your system to create web applications with Apache, PHP and MySql database.</p>
                    <h1>Set up PHP on your system </h1>
                    <p>You must -</p>
                    <p>Install a web server</p>
                    <p>Install PHP </p>
                    <p>Install database</p>
                    <p>Follow  these steps to install PHP on your website  <a href="https://php.net/manual/en/install.php" target="_blank" rel="noreferrer">https://php.net/manual/en/install.php</a></p>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-overview">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-syntax-and-comments">Next</Link>
                            </button>
                        </div>
                    </div>

                    </div>
            </>
        )
    }
}