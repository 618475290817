import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptObjects extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Objects</title>
                    <meta name="description" content="Javascript is a object oriented programming language. OOP is problem solving technique to develop software systems. It is a technique to think real word entities in terms of objects. These objects have some states and behaviors and provide services to application." />
                    <meta name="keywords" content="javascript object properties, javascript object methods, javascript objects explained, javascript array of objects, javascript new object, javascript object constructor, object literal javascript, add property to object javascript, javascript methods, javascript list, javascript class, javascript var, type of javascript, javascript undefined" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Objects" />
                    <meta property="og:description" content="Javascript is a object oriented programming language. OOP is problem solving technique to develop software systems. It is a technique to think real word entities in terms of objects. These objects have some states and behaviors and provide services to application." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-objects" />
                </MetaTags>
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                    <FontAwesomeIcon icon="align-left"/>
                                    <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                                </button>
                            </div>
                            </nav>
                        <br/><br/><br/>
                        <h2>JavaScript Objects</h2>
                        <br/><br/>
                        <p>Javascript is a object oriented programming language.</p>
                        <p>OOP is problem solving technique to develop software systems. It is a technique to think real word entities in terms of objects. These objects have some states and behaviors and provide services to application.</p>
                        <p>Features of object oriented programming </p>
                        <h3>Abstraction</h3>
                        <p>Abstraction is a process to hide the implementation details from the users and showing only the functionality. It displays only essential things.</p>
                        <h3>Encapsulation</h3>

                        <p>It is mechanism to encapsulate or wrap up all the data into a single entity it is known as encapsulation.</p>
                        <h3>Polymorphism</h3>


                        <p>As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods.</p>
                        <h3>Inheritance</h3>

                        <p>Inheritance is one of the features of OOP where one class acquires the properties of another class.</p>
                        <p>Inheritance represents IS-A relationship and used for reusability of code.</p>

                        <p>There are different ways to create new objects:</p>
                        <p>•	Using an object literal.</p>
                        <p>•	With new keyword .</p>
                        <br/>
                        <h3>By using literals</h3>

                        <br/><br/>
                        <div style={{backgroundColor:`#D5CFCF`}}>
                        <h5 style={{padding:`10px`}}>Example</h5>
                        <div className="javascript-program">
                        <pre style={{padding:`10px`}}>
                        &lt;!DOCTYPE html&gt;<br/>
                        &lt;head&gt;<br/>
                        &lt;title&gt; JavaScript Objects &lt;/title&gt;<br/>
                        &lt;/head&gt;<br/>
                        &lt;body&gt;<br/>
                        &lt;p&gt; Creating a JavaScript Object by using literals.&lt;/p&gt;<br/>
                        &lt;p id="demo"&gt; &lt;/p&gt;<br/>
                        &lt;script&gt;<br/>
                        var website = &#123;name:"TutsFinder", logo:"Elementary way to find"&#125;;<br/>
                        document.getElementById("demo").innerHTML = website.name + " " + website.logo ;<br/>
                        &lt;/script&gt;<br/>
                        &lt;/body&gt;<br/>
                        &lt;/html&gt;
                        </pre>
                        </div>
                        </div>
                        <br/><br/>
                        Output:
                        <br/><br/>
                        <p>TutsFinder Elementary way to find</p>
                        <br/><br/>

                        <h3>By using new keyword</h3>

                        <br/><br/>
                        <div style={{backgroundColor:`#D5CFCF`}}>
                        <h5 style={{padding:`10px`}}>Example</h5>
                        <div className="javascript-program">
                        <pre style={{padding:`10px`}}>
                        &lt;!DOCTYPE html&gt;<br/>
                        &lt;head&gt;<br/>
                        &lt;title&gt; Using New Keyword &lt;/title&gt;<br/>
                        &lt;/head&gt;<br/>
                        &lt;body&gt;<br/>
                        &lt;p id="demo"&gt; &lt;/p&gt;<br/>
                        &lt;script&gt;<br/>
                        var website = new Object();<br/>
                        website.name = "TutsFinder";<br/>
                        website.logo= "Elementary way to find";<br/>
                        document.getElementById("demo").innerHTML = website.name + " " + website.logo ;<br/>
                        &lt;/script&gt;<br/>
                        &lt;/body&gt;<br/>
                        &lt;/html&gt;
                        </pre>
                        </div>
                        </div>
                        <br/><br/>
                        Output:
                        <br/><br/>
                        TutsFinder Elementary way to find
                        <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-functions">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-forms">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}