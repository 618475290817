import React from 'react';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HrQuesAns1 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Quantitative Abilty - Set 2</title>
                    <meta name="keywords" content="aptitude questions for interview, aptitude question answer tutsfinder, online aptitude questions and answers, 100 aptitude questions and answers, logical reasoning aptitude questions, aptitude questions pdf, mathematics aptitude test questions and answers pdf, aptitude questions on average, aptitude questions meaning, train aptitude questions, job question, pass question, talent question, quantitative aptitude for competitive, quantitative aptitude, rapid quantitative aptitude, how to prepare for quantitative, quantitative aptitude and reas, course in mental ability and" />

                    <meta property="og:title" content="Quantitative Abilty - Set 2" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/quantitative/quantitative-set-2" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Set 2</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>Quantitative Ability Questions</h2>
                    <br/><br/><br/><br/>
                    <img src="../img/quant/set-2/1.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/2.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/3.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/4.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/5.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/6.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/7.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/8.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/9.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/10.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/11.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/12.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/13.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/14.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/15.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/16.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/17.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/18.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/19.JPG" width="100%" alt="quant" />
                    <img src="../img/quant/set-2/20.JPG" width="100%" alt="quant" />
                    <div className="line"></div>
                </div>
            </>
        )
    }
}