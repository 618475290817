import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptFundamentals extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Fundamentals</title>
                    <meta name="description" content="Hello World Program, A program that defines many aspects of javascript program and tells about common terms used in this program." />
                    <meta name="keywords" content="javascript fundamentals tutorial, javascript fundamentals w3schools, javascript fundamentals pdf, javascript fundamentals interview questions, javascript download, javascript basics for beginners, javascript fundamental concepts, javascript fundamentals cheat sheet, javascript" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Fundamentals" />
                    <meta property="og:description" content="Hello World Program, A program that defines many aspects of javascript program and tells about common terms used in this program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-fundamentals" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Fundamentals</h2>
                    <br/><br/>
                    <h3>Hello World Program</h3>
                    <p>A program that defines many aspects of javascript program and tells about common terms used in this program.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Embedding JavaScript &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = "Hello World!";<br/>
                    document.write(x);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/javascript/insert.png" alt="javascript insert" />
                    <br/><br/>
                    <h3>The &lt;script&gt; tag</h3>
                    <p>Javascript program can be inserted in this program with the help of &lt;script&gt; tag inside html program.</p>
                    <h3>The type attribute in &lt;script&gt; tag</h3>
                    &lt;script type=”text/javascript”&gt;
                    <p>This attribute is used to define the language of the script. But there is no need to use this attribute in javascript because the default language of script is javascript.</p>
                    <h3>External scripts</h3>
                    <p>If user have lots of script code to implement in html code then user can write it in external file. </p>
                    &lt;script src="/path/to/script.js"&gt;&lt;/script&gt;

                    <p>"src" attribute is used to describe the path of the script.</p>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-getting-started">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-variables">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}