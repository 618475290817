import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppFileAndStreams extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Files and Streams</title>
                    <meta name="description" content="In this tutorial we will learn how to read and write from a file. In C++ there is a library called fstream that defines some data types – " />
                    <meta name="keywords" content="C++ Files and Streams,  C++ Files and Streams Tutorial, javatpoint, Files and Streams, Files and Streams in c++" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Files and Streams" />
                    <meta property="og:description" content="In this tutorial we will learn how to read and write from a file. In C++ there is a library called fstream that defines some data types – " />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-file-and-streams" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Files and Streams</h2>
                    <br/>
                <p>In this tutorial we will learn how to read and write from a file. In C++ there is a library called fstream that defines some data types – </p>
                <p><strong>Ofstream</strong> – it is used to create files and performs wrting operation on it, it performs outpur operations.</p>
                <p><strong>Ifstream</strong> – it is used for reading from the files, it performs input operations.</p>
                <p><strong>Fstream</strong> – It can perform functions of both ofstream and ifstream which means it can read and write from the files.</p>
                <br/>
                <p>Operations in file – </p>
                <p>•	Creating a file: <i>open()</i></p>
                <p>•	Reading data: <i>read()</i></p>
                <p>•	Writing new data: <i>write()</i></p>
                <p>•	Closing a file: <i>close()</i></p>
                <br/>
                <h3>Create and opening a file – </h3>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                <br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    fstream fs;<br/>
                    fs.open("C:\xyz.txt",ios::out);  // Creating new file<br/>
                    if(!fs)<br/>
                    &#123;<br/>
                        cout&lt;&lt;"File creation failed";<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        cout&lt;&lt;"New file created";<br/>
                        fs.close();<br/>
                    &#125;<br/>
                    <br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                New file created
                </pre>
                </div>
                <br/><br/>
                <h3>Reading from a file –</h3>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main () &#123;<br/>
                    string str;<br/>
                    ifstream filestream("xyz.txt");<br/>
                    if (filestream.is_open())<br/>
                    &#123;<br/>
                    while ( getline (filestream,str) )<br/>
                    &#123;<br/>
                        cout &lt;&lt; str &lt;&lt;endl;<br/>
                    &#125;<br/>
                    filestream.close();<br/>
                    &#125;<br/>
                    else &#123;<br/>
                        cout &lt;&lt; "File opening is fail !"&lt;&lt;endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h3>Writing to a file – </h3>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                <br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    fstream fs;<br/>
                    fs.open("C:\xyz.txt",ios::out);<br/>
                    if(!fs)<br/>
                    &#123;<br/>
                        cout&lt;&lt;"File creation failed";<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        cout&lt;&lt;"New file created successfully";<br/>
                        fs&lt;&lt;"TutsFinder";<br/>
                        fs.close();<br/>
                    &#125;<br/>
                    <br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-multithreading">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-exception-handling">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}