import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpControlStatements extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Control Statements</title>
                    <meta name="description" content = "Control Statements controls the flow of program execution. These are keywords that are used for making a decision. In this, we will discuss about conditional statements and unconditional statements. These statements are used to control the flow of statements." />
                    <meta name="keywords" content="control statements in php, control statements php, php control statements, control statements, php control statements tutsfinder, tutsfinder, tutsfinder.com, php tutorials, free php tutorials" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Control Statements" />
                    <meta property="og:description" content="Control Statements controls the flow of program execution. These are keywords that are used for making a decision. In this, we will discuss about conditional statements and unconditional statements. These statements are used to control the flow of statements." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-control-statements" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Control Statements</strong>
                    <br/><br/>
                    <p>Control Statements controls the flow of program execution.</p>
                    <p>These are keywords that are used for making a decision. In this, we will discuss about conditional statements and unconditional statements. These statements are used to control the flow of statements.</p>
                    <h1>Conditional Statements</h1>
                    <p>Conditional Statements are used when the execution of statements depends on one or more conditions.</p>
                    <h2>Decision making statements:</h2>
                    <p>•	If</p>
                    <p>•	If-else</p>
                    <p>•	if-else-if</p>
                    <p>•	switch case</p>
                    <br/>
                    <p>If statement is execute only when given condition satisfied at least one condition.</p>
                    <p>This is the easiest PHP condition that looks like:</p>
                    <div className="php-syntax-program">
                    <pre>
                    if(condition){<br/>
                    //code<br/>
                    }
                    </pre>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $d = date("D");<br/>
                    if($d == "Fri")&#123;<br/>
                    echo "Have a nice weekend!";<br/>
                    &#125;<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <p>If-else statement can be executed if a specified condition is true otherwise it will execute another block of code.</p>
                    <div className="php-syntax-program">
                    <pre>
                    if(condition)&#123;<br/>
                        (// Code)<br/>
                    &#125; else&#123;<br/>
                        (// Code)<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/><br/>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $d = date("D");<br/>
                    if($d == "Fri")&#123;<br/>
                    echo "Have a nice weekend!";<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    echo "Have a nice day!";<br/>
                    &#125;<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <p>If-else-if condition is used to for multiple conditions.</p>
                    <div className="php-syntax-program">
                    <pre>
                    if(condition1)&#123;<br/>
                        (// Code to be executed if condition1 is true)<br/>
                    &#125; elseif(condition2)&#123;<br/>
                        (// Code to be executed if the condition1 is false and condition2 is true)<br/>
                    &#125; else&#123;<br/>
                        (// Code to be executed if both condition1 and condition2 are false)<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $d = date("D");<br/>
                    if($d == "Fri")&#123;<br/>
                    echo "Have a nice weekend!";<br/>
                    &#125;<br/>
                    elseif($d == "Sun")&#123;<br/>
                    echo "Have a nice Sunday!";<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    echo "Have a nice day!";<br/>
                    &#125;<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <p><strong>Switch statement</strong> – Switch statement is used to select one of many blocks of code to be executed.</p>
                    <div className="php-syntax-program">
                    <pre>
                    switch (n) &#123;<br/>
                    case label1:<br/>
                        code to be executed if n=label1;<br/>
                        break;<br/>
                    case label2:<br/>
                        code to be executed if n=label2;<br/>
                        break;<br/>
                    case label3:<br/>
                        code to be executed if n=label3;<br/>
                        break;<br/>
                        ...<br/>
                    default:<br/>
                        code to be executed if n is different from all labels;<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $favcolor = "red";<br/>
                    <br/>
                    switch ($favcolor) &#123;<br/>
                    case "red":<br/>
                        echo "Your favorite color is red!";<br/>
                        break;<br/>
                    case "blue":<br/>
                        echo "Your favorite color is blue!";<br/>
                        break;<br/>
                    case "green":<br/>
                        echo "Your favorite color is green!";<br/>
                        break;<br/>
                    default:<br/>
                        echo "Your favorite color is neither red, blue, nor green!";<br/>
                    &#125;
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-data-types">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-array">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}