import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpArrays extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Arrays</title>
                    <meta name="description" content = "Arrays enable you to store elements of same data type in the memory, in a sequential order." />
                    <meta name="keywords" content="arrays, php arrays, array, array in php, php array, php array tutsfinder" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Arrays" />
                    <meta property="og:description" content="Arrays enable you to store elements of same data type in the memory, in a sequential order." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-arrays" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Arrays</strong>
                    <br/><br/>
                    <p>Arrays enable you to store elements of same data type in the memory, in a sequential order.</p>
                    <p>• An array is a collection of elements.</p>
                    <p>• All elements contained in an array are of same data type.</p>
                    <p>• This collection forms a complete set.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    var_dump($city);<br/>
                    echo "<br/>";<br/>
                    $city_codes = array( "Pune" =&gt; "411047", "Delhi" =&gt; "110001", "Ghaziabad" =&gt; "201204" );<br/>
                    var_dump($city_codes);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <h1>Types of Arrays in PHP</h1>
                    <p>There are three types of arrays that are:</p>
                    <p>•	Indexed array — Array with a numeric key.</p>
                    <p>•	Associative array — Array where each key has its own specific value.</p>
                    <p>•	Multidimensional array — Array that contains one or more arrays within itself.</p>
                    <br/>
                    <h1>Array();</h1>
                    <p>array(); method is used to create an array.</p>
                    <br/>
                    <h1>Get The Length of an Array - The count() Function</h1>
                    <p>The count() function is used to get the length of an array:</p>
                    <br/><br/>
                    <div style={{fontSize:`35px`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    echo count($city);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>

                    <h1>Sorting of an array</h1>
                    <p>There are some PHP array sorting functions –</p>
                    <p><strong>•	sort()</strong> - sort arrays in ascending order.</p>
                    <p><strong>•	rsort()</strong> - sort arrays in descending order.</p>
                    <p><strong>•	asort()</strong> - sort associative arrays in ascending order, according to the value.</p>
                    <p><strong>•	ksort()</strong> - sort associative arrays in ascending order, according to the key.</p>
                    <p><strong>•	arsort()</strong> - sort associative arrays in descending order, according to the value.</p>
                    <p><strong>•	krsort()</strong> - sort associative arrays in descending order, according to the key.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    <br/>
                    sort($city);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                        <br/><br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-control-statements">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-string">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}