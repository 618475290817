import React from 'react';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Feedback</title>
                    <meta name="keywords" content="tutsfinder feedback form, feedback, tutsfinder feeback" />

                    <meta property="og:title" content="TutsFinder - Feedback" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/feedback" />
                </MetaTags>
                <div className="bradcam_area breadcam_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bradcam_text">
                                    <h3 >Feedback</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-section">
                        <div className="container">
                            <div className="d-none d-sm-block mb-5 pb-4">
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="contact-title">Send your feedback here</h2>
                                </div>
                                <div className="col-lg-8">
                                    <form className="form-contact contact_form" action="feedback.php" method="post" id="contactForm" style={{fontFamily: `Poppins, sans-serif`}}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control valid" name="name" id="name" type="text" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter your name'" placeholder="Enter your name(optional)"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control valid" name="email" id="email" type="email" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter email address'" placeholder="Email(optional)"/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    How easy is it to find information on website?<br/>
                                                    <input type="radio" id="very_easy" name="easy" value="very_easy"/>
                                                    <label for="very_easy">Very easy</label><br/>
                                                    <input type="radio" id="easy" name="easy" value="easy"/>
                                                    <label for="easy">Easy</label><br/>
                                                    <input type="radio" id="average" name="easy" value="average"/>
                                                    <label for="average">Average</label><br/>
                                                    <input type="radio" id="difficult" name="easy" value="difficult"/>
                                                    <label for="difficult">Difficult</label><br/>
                                                    <input type="radio" id="very_difficult" name="easy" value="very_difficult"/>
                                                    <label for="very_difficult">Very Difficult</label><br/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    Is this your first visit to the website ?<br/>
                                                    <input type="radio" id="yes" name="yes/no" value="yes"/>
                                                    <label for="yes">Yes</label><br/>
                                                    <input type="radio" id="no" name="yes/no" value="no"/>
                                                    <label for="no">No</label><br/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter Message'" placeholder="Please provide us additional comments or suggestions "></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <button type="submit" name="submit" className="button button-contactForm boxed-btn">Send</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-3 offset-lg-1">
                                    {/* <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-home"></i></span>
                                        <div className="media-body">
                                            <h3>Buttonwood, California.</h3>
                                            <p>Rosemead, CA 91770</p>
                                        </div>
                                    </div>
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                        <div className="media-body">
                                            <h3>+1 253 565 2365</h3>
                                            <p>Mon to Fri 9am to 6pm</p>
                                        </div>
                                    </div> */}
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><FontAwesomeIcon icon="envelope" style={{fontSize: `30px`}} /></span>
                                        <div className="media-body">
                                            <h3>feedback@tutsfinder.in</h3>
                                            <p>Give us your feedback</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </>
        )
    }
}