import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppNamespace extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Namespace</title>
                    <meta name="description" content = "Namespace is used to create two variables and member functions with same name, it’s main purpose is to prevent ambiguity that may occur when two variable shaving have same name." />
                    <meta name="keywords" content="C++ Namespace,  C++ Namespace Tutorial, Namespace in c++, what is Namespace in c++, Namespace, tutsfinder, tutorialspoint, latest movies, Namespace tutorialspoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Namespace " />
                    <meta property="og:description" content="Namespace is used to create two variables and member functions with same name, it’s main purpose is to prevent ambiguity that may occur when two variable shaving have same name." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-namespace" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Namespaces</h2>
                    <br/>
                    <p>Namespace is used to create two variables and member functions with same name, it’s main purpose is to prevent ambiguity that may occur when two variable shaving have same name.</p>
                <p><strong>Synatx –</strong></p>
                <pre>
                namespace namespace_name<br/>
                &#123;<br/>
                    int x, y; /* code declarations where<br/>
                                x and y are declared in<br/>
                                namespace_name's scope*/<br/>
                &#125;
                </pre>
                <br/>
                <h4>Namespace Example : </h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                    <pre>
                    #include &lt;iostream&gt;<br/>
                    using namespace std;<br/>
                    <br/>
                    namespace Tuts &#123;<br/>
                        void Hello() &#123;<br/>
                            cout &lt;&lt; "Hello World" &lt;&lt; endl;<br/>
                        &#125;<br/>
                    &#125;<br/>
                    namespace Finder  &#123;<br/>
                            void Hello() &#123;<br/>
                                cout &lt;&lt; "Java Tutorials" &lt;&lt; endl;<br/>
                            &#125;<br/>
                    &#125;<br/>
                    int main()<br/>
                    &#123;<br/>
                    Tuts::Hello();<br/>
                    Finder::Hello();<br/>
                    return 0;<br/>
                    &#125;
                    </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                    <pre>
                    Hello World<br/>
                    Java Tutorials
                    </pre>
                </div>
                <br/><br/>
                <h4>Nested namespace in C++</h4>
                <br/>
                <p>Defining a namespace inside another namespace</p>
                <pre>
                namespace namespace_name1<br/>
                &#123;<br/>
                    body of namespace_name1<br/>
                    ... ... ...<br/>
                    namespace namespace_name2<br/>
                    &#123;<br/>
                        body of namespace_name2<br/>
                    &#125;<br/>
                    ... ... ...<br/>
                &#125;
                </pre>
                <br/><br/>
                Program to show nested namespace:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;conio.h&gt;<br/>
                using namespace std;<br/>
                <br/>
                namespace first<br/>
                &#123;<br/>
                    int a;<br/>
                    float b;<br/>
                    namespace second<br/>
                    &#123;<br/>
                        float add(int x, float y)<br/>
                        &#123;<br/>
                            return x+y;<br/>
                        &#125;<br/>
                    &#125;<br/>
                &#125;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    cout &lt;&lt; "Enter a and b: ";<br/>
                    cin &gt;&gt; first::a &gt;&gt; first::b;<br/>
                    cout &lt;&lt; "Sum = " &lt;&lt; first::second::add(first::a,first::b) &lt;&lt; endl;<br/>
                    getch();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Enter a and b: 45<br/>
                23<br/>
                Sum = 68
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-pointers">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-class-and-objects">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}