import React from 'react';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HrQuesAns2 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HR Interview Questions - Set 2</title>
                    <meta name="description" content="There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview." />
                    <meta name="keywords" content="top 10 interview questinos and answers, hr interview questions tutsfinder, interview questions for hr position, hr interview questions and answers for freshers pdf, logical hr interview questions and answers, hr interview questions for freshers with best answers and examples, interview questions and answers for hr position, mba hr interview questions, 100 hr interview questions and answers, entry level hr interview questions" />

                    <meta property="og:title" content="HR Interview Questions - Set 2" />
                    <meta property="og:description" content="There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/hr-interview-questions/hr-interview-questions-set-2" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Interview Questions</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>HR Interview Questions</h2><br/><br/>
                    <h4>There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview.</h4>
                    <br/>
                    <br/>
                    <p className="hr-p-tag"><b>11. What was the most difficult decision you had ever made?</b></p>
                    <p><b>Ans.</b> This is the chance to show you’re up to the task in making good decisions in challenging situations.</p>
                    <p><strong>Example-</strong> In my past company I had to choose between joining a group of employees protesting some issues in the company and staying away from the issue, I choose to be a mediator between employees and my supervisor and I was glad I made that situation because the situation ended well.</p><br/>
                    <p className="hr-p-tag"><b>12. Are you applying to other companies as well?</b></p>
                    <p>
                        <b>Ans.</b> Yes, I have submitted my resume in other companies as well, but I hope my priority and faith will led to land a job in your company.
                    </p><br/>
                    <p className="hr-p-tag"><b>13. Why should we hire you?</b></p>
                    <p><b>Ans.</b> For the position I had applied, I believe I have the perfect combination of skills and experience that is required. Also, my 3 year experience in my past company has given me the platform to enhance my skills more that I needed to deliver for this position.</p><br/>
                    <p className="hr-p-tag"><b>14. Describe your work policy ethics.</b></p>
                    <p><b>Ans.</b> Always gives your best in every work, if not, don’t do it at all.</p><br/>
                    <p className="hr-p-tag"><b>15. Do you have any questions for me?</b></p>
                    <p><b>Ans.</b> I would like to know what the next step for me are. I would also be interested to know more details of this position I am applying for.</p><br/>
                    <p className="hr-p-tag"><b>16. Are you willing to relocate or travel?</b></p>
                    <p><b>Ans.</b> Yes, I am ready to relocate as it’s a great opportunity to work with different people and the environment. Also it will help me to boost my knowledge.</p><br/>
                    <p className="hr-p-tag"><b>17. Explain how you would be an asset to this organization?</b></p>
                    <p><b>Ans.</b> Basically for answering this question mention about your qualities.</p>
                    <p><strong>Example:</strong> I would be an asset to this company because I am go getter, achiever, team player, quick learner, versatile.</p><br/>
                    <p className="hr-p-tag"><b>18. Where do you see yourself five years from now?</b></p>
                    <p><b>Ans.</b> I want to devote the next few years learning new skills, taking up more challenging jobs and putting all my hard work which helps me to grow. A person in me after some years should be more responsible and having a more respected position.</p><br/>
                    <p className="hr-p-tag"><b>19. Describe the workplace which you thinks is the best suited for the one and increases the productivity as well.</b></p>
                    <p><b>Ans.</b> I like workplaces that emphasize both freedom and teamwork. I like collaborating with others and exchanging ideas, but I also want to have flexibility to work. Also I value the absence of restrictions, such as a casual dress code unless I’m meeting with a client or partners.</p><br/>
                    <p className="hr-p-tag"><b>20. How do you propose to compensate for your lack of experience?</b></p>
                    <p><b>Ans.</b> I am a quick learner, even time is there is something new given to me, I tried my best to complete it by doing study more and taking trainings as well.</p><br/>
                    <p className="hr-p-tag"><b>21. What suggestion have you made in your previous job that was implemented?</b></p>
                    <p><b>Ans.</b> I have once suggested that management and staff should have more regular meetings which helps to be more interactive amongst the people and knowing each other better. I was glad that it was implemented too.</p><br/>
                    <p className="hr-p-tag"><b>22. How do you cope with stress?</b></p>
                    <p><b>Ans.</b> I like to take a break for a duration which helps me to change again. I can manage stress well enough and does not decrease my productivity level.</p><br/>
                    <p className="hr-p-tag"><b>23. How did you know about this Position?</b></p>
                    <p><b>Ans.</b> I have go through the roles and responsibilities for this job, so I am fully aware and best suited for this position.</p><br/>
                    <p className="hr-p-tag"><b>24. What will you do if you don’t get this position?</b></p>
                    <p><b>Ans.</b> If I don’t get this job, I will use this experience to reflect my weakness and try my best to improve on them for the future opportunities along the way.</p><br/>
                    <p className="hr-p-tag"><b>25. What is the success for you?</b></p>
                    <p><b>Ans.</b> Ans. Success refers to completion of aim or purpose.</p>
                    <div className="line"></div>
                </div>
            </>
        )
    }
}