import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppStrings extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Strings</title>
                    <meta name="description" content = "Strings are a special case of an array of characters. String is a sequence of characters that are used to store text." />
                    <meta name="keywords" content="Strings, Strings in c++, C++ Strings,  C++ Strings Tutorial, strings methods, concat string in c++, substring in c++" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Strings " />
                    <meta property="og:description" content="Strings are a special case of an array of characters. String is a sequence of characters that are used to store text." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-strings" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Strings</h2>
                    <br/>
                    <p>Strings are a special case of an array of characters. String is a sequence of characters that are used to store text.</p>
                <p>You have already seen some examples of string in the form of string literals that you have been writing into your code:</p>
                <br/>
                Std::cout &lt;&lt; ”Hello World”;
                <br/>
                <p>This is equivalent to using the array declaration:</p>
                <br/>
                char TutsFinder = &#123;‘T’,’u’,’t’,’s’,’ ‘,’F’,’i’,’n’,’d’,’e’,’r’,’\0’&#125;;<br/>
                cout &lt;&lt; TutsFinder &lt;&lt; endl;
                <br/>
                <p>Last character in the array is a null character ‘\0’. This is also called the string terminating character because it tells the compiler that the string has ended.</p>
                <p>Strings are same as array but there is a difference between them that string is terminated with null character.</p>
                <p>Using std::string to initialize, store user input, copy , concatenate and determine the length of the string.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;string&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    string tutsfinder("Hello World");<br/>
                    cout &lt;&lt; tutsfinder &lt;&lt; endl;<br/>
                    <br/>
                    cout &lt;&lt; "Enter String : ";<br/>
                    string s1;<br/>
                    getline(cin,s1);<br/>
                    <br/>
                    cout &lt;&lt; "Enter another String : ";<br/>
                    string s2;<br/>
                    getline(cin,s2);<br/>
                    <br/>
                    cout &lt;&lt; "String after concatenation: ";<br/>
                    string concat=s1+" "+s2;<br/>
                    cout &lt;&lt; concat &lt;&lt; endl;<br/>
                    <br/>
                    cout &lt;&lt;"Copied string : ";<br/>
                    string copy;<br/>
                    copy=concat;<br/>
                    cout &lt;&lt; copy &lt;&lt; endl;<br/>
                    <br/>
                    cout &lt;&lt; "Length of string : " &lt;&lt; concat.length() &lt;&lt; endl;<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Hello World<br/>
                Enter String : c++<br/>
                Enter another String : tutorials<br/>
                String after concatenation: c++ tutorials<br/>
                Copied string : c++ tutorials<br/>
                Length of string : 13
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" >
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-arrays">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-pointers">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}