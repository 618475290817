import React, { useEffect } from "react";
import { Route } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import LeftNavBar from '../Components/LeftNavBar';
import AppConfig from "../App.config";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);

const CoursesLayout = ({ children }) => (
    <>
        <Header/>
          <div style={{display:`flex`}}>
          <LeftNavBar/>
          {children}
          </div>
        <Footer/>
    </>
);

  const CoursesLayoutRoute = ({component: Component, ...rest}) => {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
    return (
      <Route {...rest} render={(props) => (
        <CoursesLayout>
            <Component {...props} />
        </CoursesLayout>
      )} />
    )
  };

export default withRouter(CoursesLayoutRoute);