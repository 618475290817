import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ProgramExecutionInJava extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Program Execution In Java</title>
                    <meta name="description" content="Java Programming language includes Java compiler, Java Virtual Machine and Java class Libraries. JVM is a software implementation that executes the programs." />
                    <meta name="keywords" content="Compilation and Execution of a Java Program, program execution in java, how java program run, proces to run java program" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Program Execution In Java" />
                    <meta property="og:description" content="Java Programming language includes Java compiler, Java Virtual Machine and Java class Libraries. JVM is a software implementation that executes the programs." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/program-execution-in-java" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <p>In this section you will learn about, how does java program work.</p>
                    <hr/>
                    <br/>
                    <strong style={{fontSize:`25px`, textAlign:`center`}}>Program execution in Java</strong>
                    <br/>
                    <br/>
                    <p>Java Programming language includes Java compiler, Java Virtual Machine and Java class Libraries. JVM is a software implementation that executes the programs.</p>
                    <p>Complier converts the java code (high level language) into machine code i.e. Byte code. The JVM interprets this byte-code and runs the programs.
                    </p>
                    <img src="../img/java/program-execution.png" style={{maxWidth:`100%`}} alt="program exceution in java" />
                    <br/><br/>
                    <h4>Steps to execute java program:</h4>
                    <p>•	First, the user has to create a className.java file with any text editor.</p>
                    <p>•	Then, compiled it with this command - <i>javac ClassName.java</i>. The compilation produces as a result a file called ClassName.class</p>
                    <p>•	A program can be executed only after it has been compiled. Command for execution - <i>java ClassName</i>.</p>
                    <br/>
                    <strong style={{fontSize:`20px`}}>Byte Code</strong>
                    <br/><br/>
                    <p>Byte code is an instruction set of the Java Virtual Machine, it is created when user wants to compile their java source code than that code will be converted into byte code.</p>
                    <p>We can also say this portable code or p-code because it is responsible for Java portability and it is executed by virtual machine.</p>
                    <br/>
                    <strong style={{fontSize:`20px`}}>Key Points</strong>
                    <br/>
                    <p>While creating java program, please keep these points in your mind or follow these points:</p>
                    <p><strong>Class Names</strong> − The first letter of any class should be in Upper Case after that we can use Camel case. And program name must be same as our className.</p>
                    <p>Example: <i>class FirstProgram</i></p>
                    <p><strong>Method Names</strong> − The first letter of method name should start with a small letter. And after that the first letter of each word of method name should be in upper case.</p>
                    <p>Example: <i>public void firstMethodNName()</i></p>
                    <p><strong>Case Sensitivity</strong> − Java is case sensitive. In java hello and Hello words have different meaning.</p>
                    <br/>
                    <p>In next segment, you will be able to set-up environment variables </p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="setup-environmental-variables-in-java">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="first-java-program">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}