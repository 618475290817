import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlStructure extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Table </title>
                    <meta name="description" content="This feature of HTML allows the user to arrange the data in rows and columns of cells which is in image, text, links etc." />
                    <meta name="keywords" content="tables in html, Html tables, Html tables Tutorial" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Table" />
                    <meta property="og:description" content="This feature of HTML allows the user to arrange the data in rows and columns of cells which is in image, text, links etc." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-table" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Tables</h2>
                    <br/><br/>
                <p>This feature of HTML allows the user to arrange the data in rows and columns of cells which is in image, text, links etc.</p>
                <p>The HTML tables are created using &lt;table&gt; tag in which the &lt;tr&gt; tag is used tto create rows and &lt;td&gt; tag is used to create data cells. The elements under &lt;td&gt; are regular and left align by default.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; HTML tables &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;table border = "1"&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Row 1, Column 1 &lt;/td&gt;<br/>
                &lt;td&gt; Row1, Column 2 &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Row 2, Column 1 &lt;/td&gt;<br/>
                &lt;td&gt; Row2, Column 2 &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/table.png" alt="table" style={{width:`100%`}} />
                <br/>
                <p>Border attribute is used to set the border of table, if you do not want to put the border you can enter the value as 0.</p>
                <p>Table Header, Body and Footer</p>
                <br/>
                <p>Three elements of table which separates the table:</p>
                <br/>
                <p>•	&lt;thead&gt; - to create separate table header.</p>
                <p>•	&lt;tbody&gt; - to show the main body of table.</p>
                <p>•	&lt;tfoot&gt; - to create separate table footer.</p>
                <p><strong>NOTE:</strong> In a table there will be always one header and one footer, but the body can element may be differ depending upon the content to show the different pages or group of data.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; HTML table &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;table border="1" width="100%" style="text-align:center"&gt;<br/>
                &lt;thead&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td colspan="4"&gt; This is the head of the table&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/thead&gt;<br/>
                &lt;tfoot&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td colspan="4"&gt; This is the foot of the table&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/tfoot&gt;<br/>
                &lt;tbody&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Cell 1&lt;/td&gt;<br/>
                &lt;td&gt;Cell 2&lt;/td&gt;<br/>
                &lt;td&gt;Cell 3&lt;/td&gt;<br/>
                &lt;td&gt;Cell 4&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/tbody&gt;<br/>
                &lt;/table&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/table1.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <h3>Attributes of Table:</h3>
                <h4>1.	Table Heading</h4>
                <p>This attribute is used to define the heading of table.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; HTML Table Header&lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;table border = "2"&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;th&gt; Student Name &lt;/th&gt;<br/>
                &lt;th&gt; Class &lt;/th&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;TrishantShishodia&lt;/td&gt;<br/>
                &lt;td&gt; 10th&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Rashika Garg&lt;/td&gt;<br/>
                &lt;td&gt; 12th&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/table2.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <h4>2.	Cellspacing and Cellpadding</h4>
                <p><strong>Cellpadding</strong>- specifies the space between the cell content and its borders.</p>
                <p><strong>Cellspacing</strong>- specifies the space between the cells.</p>
                Example for Cellpadding:
                <br/>
                <pre>
                th, td &#123;
                padding: 15px;
                &#125;
                Example for Cellspacing:
                Table &#123;
                Border-spacing: 5px;
                &#125;
                </pre>
                <br/>
                <h4>3.	Colspan and Rowspan</h4>
                <p><strong>Colspan</strong>- it is used for making a cell span more than one column.</p>
                Example:
                <br/>
                <pre>
                &lt;table style=“width: 100%”&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;th&gt; Name &lt;/th&gt;<br/>
                &lt;th colspan = "2"&gt; Salary &lt;/th&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Aayush &lt;/td&gt;<br/>
                &lt;td&gt; 100000 &lt;/td&gt;<br/>
                &lt;td&gt; 200000 &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;
                </pre>
                <p><strong>Rowspan</strong>- It is used for making a cell span more than one row.</p>
                Example:
                <br/>
                <pre>
                &lt;table style=“width: 100%”&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;th&gt; Name &lt;/th&gt;<br/>
                &lt;td&gt;Aayush&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;th rowspan = "2"&gt; Salary: &lt;/th&gt;<br/>
                &lt;td&gt; 12344 &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt; 12233444 &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;<br/>
                </pre>
                <h4>4.	Table height and Width</h4>
                <p>It is used to set a table height and width specifying in a terms of pixels or percentage of available screen area.</p>
                Example:
                <br/>
                &lt;table border = “1” width = “400” height = “150”&gt;
                <br/><br/>
                <h5>Nested Tables</h5>
                <p>In HTML you can use one table inside other table, also all the tags inside table data tag &lt;td&gt;.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt;HTML Table&lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;table border="2"&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Nested Table&lt;/td&gt;<br/>
                &lt;td&gt;<br/>
                &lt;table border="1"&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;th&gt;Name&lt;/th&gt;<br/>
                &lt;th&gt;Salary&lt;/th&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Rahul&lt;/td&gt;<br/>
                &lt;td&gt;500&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;tr&gt;<br/>
                &lt;td&gt;Sanket&lt;/td&gt;<br/>
                &lt;td&gt;700&lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;<br/>
                &lt;/td&gt;<br/>
                &lt;/tr&gt;<br/>
                &lt;/table&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/table3.png" alt="table" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-styles">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-lists">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}