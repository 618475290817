import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HowToUseCss extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - How to use CSS</title>
                    <meta name="description" content="Mostly beginners have question in his/her mind that how we can implement CCS and how to use it. So In this, we will learn how CCS can be used in HTML pages to make page attractive." />
                    <meta name="keywords" content="how to use css, use css, implement css, import css, tutsfinder, w3school, how to use css in html, htmlm css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="Mostly beginners have question in his/her mind that how we can implement CCS and how to use it. So In this, we will learn how CCS can be used in HTML pages to make page attractive." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2>How to use CSS</h2>
                    <br/><br/>
                    <p>Mostly beginners have question in his/her mind that how we can implement CCS and how to use it. So In this, we will learn how CCS can be used in HTML pages to make page attractive.</p>
                    <p>In CCS, there is set of rules to that can be followed to use CCS where it consists of a selector, a property and a value, A CSS syntaxt contains a selector and a declaration block .</p>
                    <p>A CSS rule set comprise of a selector and a declaration – </p>
                    <img src="../img/css/syntax.png" style={{width:`100%`}} alt="CSS"/>
                    <br/>

                    <p>To style any element you have to follow this rule –</p>
                    <p><strong>Selector –</strong> Selector is any HTML tag that you want to style.</p>
                    <p><strong>Property –</strong> Property is a kind of attribute, It defines tha additioanl settings of the selector.</p>
                    <p><strong>Value –</strong> Value is associated with property, each property have values. E.g. color has value blue.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>CSS Example that demonstrates how css works – </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    h1&#123;<br/>
                    color:blue;<br/>
                    text-align:center;<br/>
                    &#125;<br/>
                    p &#123;<br/>
                    color: red;<br/>
                    text-align: center;<br/>
                    font-size:40px;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h1&gt;Hello World!&lt;/h1&gt;<br/>
                    &lt;p&gt;Welcome to TutsFinder&lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;

                    </pre>
                    </div>
                    </div>
                    <br/>
                    <br/>
                    Output:
                    <img src="../img/css/use.png" style={{width:`100%`}} alt="CSS"/>
                    <br/>
                    <br/>
                    <p>In above example, we have – </p>
                    <p>•	&lt;p&gt; and &lt;h1&gt; are selectors in CSS.</p>
                    <p>•	Color, text-align and font size are properties which have values like red, blue,center etc.</p>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-introduction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="how-to-add-css">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}