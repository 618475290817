import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlTags extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Tags </title>
                    <meta name="description" content="HTML tags are elements which defines that how web browser will format and display the content enclosed by angular brackets. With the help of tags, a web browser can distinguish between an HTML content and a simple content." />
                    <meta name="keywords" content="html tags tutsfinder, Html tags tutorial, html tags, tags in html, what is tags in html, html tags w3schools, w3schools, bing" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Tags" />
                    <meta property="og:description" content="HTML tags are elements which defines that how web browser will format and display the content enclosed by angular brackets. With the help of tags, a web browser can distinguish between an HTML content and a simple content." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-tags" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span className="tutorial-header-name">Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Tags</h2>
                    <br/><br/>
                <p>HTML tags are elements which defines that how web browser will format and display the content enclosed by angular brackets. With the help of tags, a web browser can distinguish between an HTML content and a simple content.</p>
                <p>Three main components of HTML tags:</p>
                <p>•	Opening tag : &lt;<i>tagname</i>&gt;</p>
                <p>•	Content</p>
                <p>•	Closing tag : &lt;<i>/tagname</i>&gt;</p>
                <br/>
                <p>Each HTML tags have different properties. Tags normally comes in a pairs like &lt;h1&gt; and &lt;/h1&gt;.</p>
                <p><strong>NOTE:</strong> Almost every open tag must be closed, but there is exception also, an example of a tag that does not have to be closed is an empty tag, such as the link break &lt;br&gt;.</p>
                <br/>
                <h5>How to use HTML tag:</h5>
                &lt;tagname&gt; Content &lt;/tagname&gt;
                <br/><br/>
                <h5>Some basic HTML tag examples</h5>
                <ul>
                <li><h1>heading tag</h1></li>
                <li><i>Italic tag</i></li>
                <br/>
                <li><u>underline tag</u></li>
                <br/>
                <li><b>bold tag</b></li>
                </ul>
                <br/>
                <br/>
                <h4>Tag Reference in Alphabetical Order</h4>
                <br/>
                <table className="cont">
                <tr>
                <th>TAG NAME</th>
                <th>Description	</th>
                <th>Function</th>
                </tr>
                <tr>
                <td>&lt;!--…--&gt;</td>
                <td>Allows explanatory comments into our code.</td>
                <td>Basic</td>
                </tr>
                <tr>
                <td>&lt;!DOCTYPE&gt;</td>
                <td>Tell browser version of HTML/XHTML we're using.</td>
                <td>Basic</td>
                </tr>
                <tr>
                <td>&lt;a&gt;</td>
                <td>Define an anchor for links and hyperlinks.</td>
                <td>Links</td>
                </tr>
                <tr>
                <td>&lt;abbr&gt;</td>
                <td>Used to define an abbreviation in our HTML code.</td>
                <td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;acronym&gt;</td>	<td>Used to define an acronym in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;address&gt;</td>	<td>Used to define an address in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;applet&gt;</td>	<td>Defines an embedded Java applet.</td>	<td>Programming</td>
                </tr>
                <tr>
                <td>&lt;area/&gt;</td>	<td>Defines clickable areas on client side image.</td>	<td>Image</td>
                </tr>
                <tr>
                <td>&lt;b&gt;</td>	<td>Used for making text bold.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;base/&gt;</td>	<td>Used to define a base URL for links on a page.</td>	<td>Links</td>
                </tr>
                <tr>
                <td>&lt;basefont/&gt;</td>	<td>Specify font values in HTML document.</td>	<td>Meta</td>
                </tr>
                <tr>
                <td>&lt;bdo&gt;</td>	<td>Defines a birectional override for some text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;big&gt;</td>	<td>Used for defining a large text style.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;blockqoute&gt;</td>	<td>Used to define long quotations in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;body&gt;</td>	<td>Specifies body element of HTML document.</td>	<td>Structure</td>
                </tr>
                <tr>
                <td>&lt;br/&gt;</td>	<td>Used to define a line break in our HTML code.</td>	<td>Basic</td>
                </tr>
                <tr>
                <td>&lt;button&gt;</td>	<td>Defines a clickable button in our HTML code.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;caption&gt;</td>	<td>Specifies a name for the table element in question.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;center&gt;</td>	<td>Used for centering text in HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;cite&gt;</td>	<td>Used to define a citation in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;code&gt;</td>	<td>Used to define text that looks like computer code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;col/&gt;</td>	<td>Used to specify criteria for a table column.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;colgroup&gt;</td>	<td>Used to specify criteria for a group of columns.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;dd&gt;</td>	<td>Define a definition description.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;del&gt;</td>	<td>Used to define editorial deletions in some text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;dfn&gt;</td>	<td>Used to define a definition term in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;dir&gt;</td>	<td>Used to define a directory list.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;div&gt;</td>	<td>Group HTML blocks together for styling with CSS.</td>	<td>Structure</td>
                </tr>
                <tr>
                <td>&lt;dl&gt;</td>	<td>Used to define a definition list in our HTML code.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;dt&gt;</td>	<td>Define a definition term within a definition list</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;em&gt;</td>	<td>Define text to be emphasised in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;fieldset&gt;</td>	<td>Used for grouping form elements together.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;font&gt;</td>	<td>Specify font, font size and font colour.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;form&gt;</td>	<td>Used to define an anchor for links and hyperlinks</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;frame/&gt;</td>	<td>Define subwindow for another document.</td>	<td>Frames</td>
                </tr>
                <tr>
                <td>&lt;frameset&gt;</td>	<td>Frameset to hold multiple documents.</td>	<td>Frames</td>
                </tr>
                <tr>
                <td>&lt;h1&gt;-&lt;h6&gt;</td>	<td>Define the six HTML headings for use in our HTML</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;head&gt;</td>	<td>Specifies head element of HTML document.</td>	<td>Structure</td>
                </tr>
                <tr>
                <td>&lt;hr/&gt;</td>	<td>Used to define a horizontal rule in our HTML code.</td>	<td>Basics</td>
                </tr>
                <tr>
                <td>&lt;html&gt;</td>	<td>Specifies root element of HTML document.</td>	<td>Structure</td>
                </tr>
                <tr>
                <td>&lt;i&gt;</td>	<td>Define italic text in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;iframe&gt;</td>	<td>Define inline window to hold another document.</td>	<td>Inline Frame</td>
                </tr>
                <tr>
                <td>&lt;img/&gt;</td>	<td>Create area on page to hold the referenced image.</td>	<td>Images</td>
                </tr>
                <tr>
                <td>&lt;input/&gt;</td>	<td>Used to define an area of a form for user input.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;ins&gt;</td>	<td>Used to define editorial insertions in our text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;isindex/&gt;</td>	<td>Define single line input control prompt.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;kbdv&gt;</td>	<td>Used to define keyboard text in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;label&gt;</td>	<td>Label for input, select or textarea form element.</td>	<td>Forms </td>
                </tr>
                <tr>
                <td>&lt;legend&gt;</td>	<td>Used for defining a title for a fieldset element.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;li&gt;</td>	<td>Define list item in an ordered or unordered list.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;link/&gt;</td>	<td>Link to external resource such as image/stylesheet.</td>	<td>Import</td>
                </tr>
                <tr>
                <td>&lt;map&gt;</td>	<td>Define client-side image map with clickable areas.</td>	<td>Images</td>
                </tr>
                <tr>
                <td>&lt;menu&gt;</td>	<td>Used for defining a menu list.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;meta/&gt;</td>	<td>Send metadata about data within our HTML file.</td>	<td>Meta</td>
                </tr>
                <tr>
                <td>&lt;noframes&gt;</td>	<td>Content to render if frames unavailable.</td>	<td>Frames</td>
                </tr>
                <tr>
                <td>&lt;noscripts&gt;</td>	<td>Non script based rendering if JavaScript disabled.</td>	<td>Programming</td>
                </tr>
                <tr>
                <td>&lt;object&gt;</td>	<td>Used to define an embedded object in our HTML.</td>	<td>Programming</td>
                </tr>
                <tr>
                <td>&lt;ol&gt;</td>	<td>Used to define an ordered list in our HTML code.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;optgroup&gt;</td>	<td>Define related option groups within select element.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;option&gt;</td>	<td>Define a selectable option within a select element.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;p&gt;</td> <td>Create a paragraph of the enclosed content.</td>	<td>Basic</td>
                </tr>
                <tr>
                <td>&lt;param/&gt;</td>	<td>Define parameters for an embedded object.</td>	<td>Programming</td>
                </tr>
                <tr>
                <td>&lt;pre&gt;</td>	<td>Define preformatted text in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;q&gt;</td>	<td>Define a short inline quotation in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;s&gt;</td>	<td>Define a strike through text style.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;samp&gt;</td>	<td>Define text that looks like sample computer code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;script&gt;</td>	<td>Define scripting language in HTML document.</td>	<td>Programming</td>
                </tr>
                <tr>
                <td>&lt;select&gt; </td>	<td>ed to define a drop-down list for selection.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;small&gt; </td>	<td>Define a small text style.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;span&gt; </td>	<td>Group inline HTML together for styling with CSS.</td>	<td>Structure</td>
                </tr>
                <tr>
                <td>&lt;strike&gt;	</td><td>Used for making strike through text.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;strong&gt;	</td><td>Define strongly emphasised text in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;style&gt;</td>	<td>Style information into our HTML documents.</td>	<td>Style</td>
                </tr>
                <tr>
                <td>&lt;sub&gt;</td>	<td>Used for defining subscript text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;summary&gt;</td>	<td>It defines summary which can be used with &lt;details&gt; tag.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;sup&gt;</td>	<td>Used for defining superscript text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;table&gt;</td>	<td>Used to define a table for displaying tabular data.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;tbody&gt;</td>	<td>Define group body information within a table.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;td&gt;</td>	<td>Used to define a table cell within a table element.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;textarena&gt;</td>	<td>Define a multiple line area for text input.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;tfoot&gt;</td>	<td>Define group footer information within a table.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;thead&gt;</td>	<td>Used to define a heading within a table element.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;time&gt;</td>	<td>It uses to define data/time.</td>	<td>Forms</td>
                </tr>
                <tr>
                <td>&lt;title&gt;</td>	<td>Defines the document title element of a HTML file.</td>	<td>Meta</td>
                </tr>
                <tr>
                <td>&lt;tr&gt;</td>	<td>Used to define a row within a table element.</td>	<td>Tables</td>
                </tr>
                <tr>
                <td>&lt;tt&gt;</td>	<td>Define teletype/monospaced text.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;u&gt;</td>	<td>Underline text in our HTML code.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;ul&gt;</td> <td>Defines an unordered list in our HTML code.</td>	<td>Lists</td>
                </tr>
                <tr>
                <td>&lt;var&gt;</td>	<td>Define text that looks like a computer variable.</td>	<td>Formatting</td>
                </tr>
                <tr>
                <td>&lt;video&gt;</td>	<td>It is used to embed a video content with an HTML document</td>	<td>Images</td>
                </tr>
                <tr>
                <td>&lt;wbr&gt;</td>	<td>It defines a position within text where break line is possible.</td>	<td>Forms</td>
                </tr>
                </table>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" >
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-introduction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6" >
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-structure">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}