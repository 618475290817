import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaRegularExpression extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Regular Expression</title>
                    <meta name="description" content="Regular Expression is an API that is used to defining String patterns that we want to match to strings." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Regular Expression" />
                    <meta property="og:description" content="Regular Expression is an API that is used to defining String patterns that we want to match to strings." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-regular-expression" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                    </nav>

                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Regular	Expression</strong>
                    <br/><br/>
                <p>Regular Expression is an API that is used to defining String patterns that we want to match to strings.</p>
                <p>Java provides java.util.Regex  package for pattern matching .</p>
                <p>We can perform such tasks –</p>
                <p>•	String matching with validation criteria.</p>
                <p>•	Data Extraction</p>
                <br/>
                <p>Package consists of 3 classes Pattern, Matcher and PatternSyntaxException.</p>
                <p><strong>1.	Pattern</strong> – It defines the patterns that you want to search.</p>
                <p><strong>2.	Matcher</strong> – It is used for performing matching operations of pattern(the string you have entered).</p>
                <p><strong>3.	PatternSyntaxException</strong> – It indicates exceptions in Regular Expression Pattern. And  package also consist of 1 Interface..</p>
                <strong>MatchResultInterface</strong> – <p>Hold the results of a successful match of a Pattern against a given String.</p>
                <br/><br/>
                <strong>A simple program of regex.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                import java.util.regex.Pattern;<br/>
                public class RegexExample &#123;<br/>
                public static void main(String args[]) &#123;<br/>
                        System.out.println(Pattern.matches("...s", "tuts"));<br/>
                        System.out.println(Pattern.matches(".i...", "finder"));<br/>
                        System.out.println(Pattern.matches("tuts......", "tutsfinder"));<br/>
                    &#125;<br/>
                &#125;
                    </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <div className="container" id="java-program">
                <pre>
                true
                false
                true
                </pre>
                </div>
                <br/><br/>
                <h2>Matching Symbols</h2>
                <p>These are used to match the characters in the specefied string.</p>
                <br/><br/>
                <div style={{textAlign:`center`}}>
                <img src="../img/java/regular-expression.png" alt="Regular Expression" style={{width:`100%`}} />
                </div>
                <br/><br/>
                <h2>Meta Character</h2>
                <p>Meta characters have predefined meanings. They works as short code.</p>
                <br/><br/>
                <div style={{textAlign:`center`}}>
                <img src="../img/java/meta-character.png" alt="Regular Expression" style={{width:`100%`}} />
                </div>
                <br/><br/>
                <h2>Quantifiers</h2>
                <p>Quantifiers define how many times an element can occur.</p>
                <br/><br/>
                <div style={{textAlign:`center`}}>
                <img src="../img/java/quantifier.png" alt="Regular Expression" style={{maxWidth:`80%`}} />
                </div>
                <br/><br/>
                <strong>In this Program we have used matching symbols, meta characters and quantifiers.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                import java.util.regex.*;<br/>
                class RegexExampleMain&#123;<br/>
                public static void main(String args[])&#123;<br/>
                    System.out.println(Pattern.matches("[6789]{1}[0-9]{9}", "9897654321"));//true<br/>
                    System.out.println(Pattern.matches("[6789][0-9]{9}", "523164987"));//false starts from 5<br/>
                    System.out.println(Pattern.matches("[tuts]?", "t"));//true t comes only one time<br/>
                    System.out.println(Pattern.matches("[tuts]+", "t"));//true  once or more times<br/>
                    System.out.println(Pattern.matches("[tuts]", "s"));//true (only from t, u, s)<br/>
                    System.out.println(Pattern.matches("\\d", "tuts"));//false (\d only used for digits)<br/>
                    System.out.println(Pattern.matches("\\d", "1"));//true<br/>
                    &#125;<br/>
                &#125;
                    </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-exception-handling">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-multithreading">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}