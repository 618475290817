import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptVariables extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Variables</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="javascript variable across functions, javascript variable in string, what are variables used for in javascript programs, javascript variable function, javascript variable types, use javascript variable in html attribute, how to use javascript variable in html, in javascript variables are containers, javascript constants, javascript operators, arrays in javascript ,javascript classes, number, string, boolean data type, object, floatingp arithmetic" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Variables" />
                    <meta property="og:description" content="Variables are used to store data. It tells the system to reserve the necessary space for it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-variables" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Variables</h2>
                    <br/><br/>
                    <p>Variables are used to store data. It tells the system to reserve the necessary space for it.</p>
                    <p>The data stored in the variable can be retrieve, update and delete.</p>
                    <p>In Javascript, variable can be created by using var keyword , whereas = operator is used to assign the value to that variable.</p>
                    <h4>Example – </h4>
                    <p>Var varName = Value; </p>
                    <h4>Rules to define variables in C++</h4>
                    <p>1. A variable contains only alphanumeric characters(a-z , A-Z , 0-9) and underscore(_).</p>
                    <p>2. The first character of a variable should start with alphabet(a-z , A-Z) or underscore (_).</p>
                    <p>3. Variables are also case sensitive.</p>
                    <p>4. Keep variables name in small alphabets.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>JavaScript var keyword</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Embedding JavaScript &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = 7;<br/>
                    var y = 8;<br/>
                    var z = x + y;<br/>
                    document.write(z);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>15</h1>
                    <br/><br/>

                    <h3>Local Variable</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>JavaScript Local Variables</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Local Variables &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; JavaScript local Variable &lt;h1&gt;<br/>
                    &lt;p id="demo"&gt; &lt;/p&gt;<br/>
                    &lt;script&gt;<br/>
                    function Example(a, b) &#123;<br/>
                    return a * b;<br/>
                    &#125;<br/>
                    document.getElementById("demo").innerHTML = Example(7, 8);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> JavaScript local Variable </h1>
                    <br/>
                    <h1>56</h1>
                    <br/><br/>

                    <h3>Global Variable</h3>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>JavaScript Global Variable</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Global Variable &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var data=10;//Global variable<br/>
                    function a()&#123;<br/>
                    document.writeln(data);<br/>
                    &#125;<br/>
                    function b()&#123;<br/>
                    document.writeln(data*5);<br/>
                    &#125;<br/>
                    a();//calling JavaScript function<br/>
                    b();<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>10 50</h1>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-fundamentals">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-data-types">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}