import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class FirstProgramInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - First Program In C</title>
                    <meta name="description" content = "First you should need a text editor and a compiler, there are many compilers in market TDM-GCC, MinGW." />
                    <meta name="keywords" content="First Program In C, c program syntax, hello world program in C, C language program , how to write program in C, beginners program in C, c first program, c hello world program" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - First Program In C" />
                    <meta property="og:description" content="First you should need a text editor and a compiler, there are many compilers in market TDM-GCC, MinGW." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/first-program-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>First Program in C</h2>
                    <br/>
                <p>In this, we are showing you how you can create first C program. It is very simle to run this.</p>
                <p>First you should need a text editor and a compiler, there are many compilers in market TDM-GCC, MinGW.</p>
                <p>Step 1. Open any Text Editor</p>
                <p>Step 2. Place the code in that file and save as hello.c</p>
                <br/>
                <pre>
                #include&lt;stdio.h&gt;<br/>
                void main()&#123;	/*main() function starts*/<br/>
                    printf("Hello Guys");<br/>
                &#125;
                </pre>
                <br/>
                <p>Step 3. Open your command prompt and set your directory path where your program is stored.</p>
                <p>Step 4. Type this command gcc -o hello hello.c and hit enter button.</p>
                <p>After that you will get a .exe file, also you can run this file on any other system.</p>
                <p>Step 5. Type you filename on command prompt and hit enter. After that your program runs smoothly.</p>
                <p>Eg. Hello </p>
                <p>In this program, we have using many new terms, so lets discuss it.</p>
                <p><strong>#include</strong> – It is preprocessor directive, it creates the environment for the program and link it to the header file i.e stdio.h</p>
                <p><strong>Stdio.h</strong> – Standard input output header file. This header file provides the function to the program.</p>
                <p><strong>For Example</strong> – stdio.h is used for printf function.</p>
                <p><strong>Now, What is header files ?</strong></p>
                <p>Header files contain collection of built in functions. Header files contain definition of functions that we are going to use in our programs. We can use header files with preprocessor by linking it.</p>
                <p><strong>main()</strong> – main() function tells the compiler that our program execution starts from here. It can have return type void or int etc.</p>
                <p><strong>printf()</strong> – printf() is used to print the data, this function is provided by stdio.h header file which contains the definition of this function.</p>
                <p><strong>Comments</strong> – We can also add comments in our program . They are ignored by the compiler.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-language-introduction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-keywords-identifiers">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}