import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class VariablesAndConstantsInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Variables And Constants</title>
                    <meta name="description" content = "Variables are identifier that are used to hold the data. Variable is the name of an address."/>
                    <meta name="keywords" content="Variables in C,  Constants in C, Variables, Constants, identifiers, geeksforgeeks, C Variables And Constants,  C Variables And Constants Tutorial, Variables And Constants" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Tutorials - Variables And Constants In C" />
                    <meta property="og:description" content="Variables are identifier that are used to hold the data. Variable is the name of an address." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/VariablesAndConstantsInC" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Variables</h2>
                    <br/>
                    <p>Variables are identifier that are used to hold the data. Variable is the name of an address.</p>
                <p>Variables can be changed, we can change the name of the variable at execution time.</p>
                <p>Keywords are not used as variables because variables are user defined.</p>
                <p>Variables are name, age, rollNo .</p>
                <p>Rules for Variables :</p>
                <p>1.	A variable contains only  alphanumeric characters(a-z , A-Z , 0-9) and underscore(_).</p>
                <p>2.	The first character of a variable should start with alphabet(a-z , A-Z) or underscore (_).</p>
                <p>3.	Variables are also case sensitive. </p>
                <p>4.	Keep variables name in small alphabets</p>
                <br/>
                <h2>Constants</h2>
                <p>Constant variables are the variables that value can not be changed.</p>
                <p>Const keyword is used to make a variable constant.</p>
                <p><strong>For Exmaple</strong>:  const int = 5;</p>
                <p>We will also define a constant with #define directive.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-keywords-identifiers">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-data-types">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}