import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class GettingStarted extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    myFunction1() {
        document.getElementById("demo1").innerHTML = "Elementary way to find.";
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Getting Started</title>
                    <meta name="description" content="How to add Javascript in your program. In order to use javascript, you have to write your code between script tags." />
                    <meta name="keywords" content="javascript tutorial, javascript w3schools, learn javascript step by step, how to learn javascript, javascript for beginners pdf, javascript basics for beginners, javascript download, javascript getting started pluralsight, javascript" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Introduction" />
                    <meta property="og:description" content="How to add Javascript in your program. In order to use javascript, you have to write your code between script tags." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-getting-started" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/><br/>
                    <h2>Getting Started</h2>
                    <br/><br/>
                    <p>How to add Javascript in your program </p>
                    <p>In order to use javascript, you have to write your code between &lt;script&gt; ….. &lt;/script&gt; tags.</p>
                    <p>There are three ways to use javascript in your code</p>
                    <p>1.	Insert javascript code inside HTML element.</p>
                    <p>2.	Embedded the javascript code between script tags.</p>
                    <p>3.	Use external script with .js extension and then implement it with src attribute.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Embedding JavaScript &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = "Hello World!";<br/>
                    document.write(x);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/javascript/insert.png" alt="javascript insert" />
                    <br/><br/>

                    <h3>Script in head section</h3>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                        <pre style={{padding:`10px`}}>
                        &lt;!DOCTYPE html&gt;<br/>
                        &lt;html lang="en&gt;<br/>
                        &lt;head&gt;<br/>
                        &lt;meta charset="UTF-8"&gt;<br/>
                        &lt;title&gt; Javascript in head tag &lt;/title&gt;<br/>
                        &lt;script&gt;<br/>
                        function myFunction() &#123;<br/>
                        document.getElementById("demo").innerHTML = "Elementary way to find.";<br/>
                        &#125;<br/>
                        &lt;/script&gt;<br/>
                        &lt;/head&gt;<br/>
                        &lt;body&gt;<br/>
                        &lt;h1&gt; On click event&lt;/h1&gt;<br/>
                        &lt;p id="demo"&gt;TutsFinder&lt;/p&gt;<br/>
                        &lt;button type="button" onclick="myFunction()"&gt;Click to Change &lt;/button&gt;<br/>
                        &lt;/body&gt;<br/>
                        &lt;/html&gt;
                        </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> On click event</h1>
                    <p id="demo1">TutsFinder</p>
                    <button type="button" onClick={this.myFunction1}>Click to Change </button>
                    <br/><br/>

                    <h3>External script</h3>
                    Script.js
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>script.js</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;script&gt;<br/>
                    function myFunction() &#123;<br/>
                    document.getElementById("demo").innerHTML = "Elementary way to find.";<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    </pre>
                    </div>
                    </div>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>demo.html</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; Embedding JavaScript &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt;On click event&lt;/h1&gt;<br/>
                    &lt;p id="demo"&gt;TutsFinder&lt;/p&gt;<br/>
                    &lt;button type="button" onclick="myFunction()"&gt;Click to Chnage &lt;/button&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> On click event</h1>
                    <p id="demo1">TutsFinder</p>
                    <button type="button" onClick={this.myFunction1}>Click to Change </button>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-introduction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-fundamentals">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}