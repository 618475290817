import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaSwing extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Swing</title>
                    <meta name="description" content="Swings components are not platfotm-specefic, they are written purely in java that’s why they are platform independent." />
                    <meta name="keywords" content="java swing tutorial, what is swing in java, java swing tutsfinder" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Swing" />
                    <meta property="og:description" content="Swings components are not platfotm-specefic, they are written purely in java that’s why they are platform independent." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-swing" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Swing</strong>
                    <br/><br/>
                <p>Swing is used to create windows based applications. Swing is a GUI(Graphical User Interface) toolkit for java.</p>
                <p>Swings components are not platfotm-specefic, they are written purely in java that’s why they are platform independent.</p>
                <p>Swing enables customization in look and feel of components in an application without making changes in the code. Also it supports pluggable look and feel.</p>
                <p>Swing components are loosely coupled because they makes use of MVC(Model/View/Controller) architecture.</p>
                <p><strong>Model</strong> - It is responsible to store and manipulate data.</p>
                <p><strong>View</strong> - It is responsible to represent data that model contains.</p>
                <p><strong>Controller</strong> - It acts as bridge between Model and view.</p>
                <br/>
                <div>
                <img src="../img/java/MVC.png" alt="MVC Architecture" style={{width: `70%`}} />
                </div>
                <br/>
                <br/><br/>
                <strong>Program to show you how swing components works: </strong>
                <br/><br/>
                <div className="container" id="java-program">
                <p>
                import javax.swing.JPanel;<br/>
                import javax.swing.JFrame;<br/>
                import javax.swing.JLabel;<br/>
                import javax.swing.JTextField;<br/>
                import javax.swing.JPasswordField;<br/>
                import javax.swing.JButton;<br/>
                public class SwingExample &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args) &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JFrame frame = new JFrame("Login Form");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;frame.setSize(500, 300);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;frame.setDefaultCloseOperation(JFrame.EXIT_ON_CLOSE);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JPanel panel = new JPanel();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;frame.add(panel);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;placeComponents(panel);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;frame.setVisible(true);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;private static void placeComponents(JPanel panel) &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.setLayout(null);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JLabel userNameLabel = new JLabel("User Name");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;userNameLabel.setBounds(20,30,90,30);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.add(userNameLabel);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JTextField userText = new JTextField(20);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;userText.setBounds(200,30,200,30);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.add(userText);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JLabel passwordLabel = new JLabel("Password");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;passwordLabel.setBounds(20,80,100,30);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.add(passwordLabel);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JPasswordField passwordText = new JPasswordField(20);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;passwordText.setBounds(200,80,200,30);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.add(passwordText);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JButton loginButton = new JButton("login");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;loginButton.setBounds(235, 150, 80, 30);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;panel.add(loginButton);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;
                </p>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <img src="../img/java/swing-output.png" style={{width:`100%`}} alt="" />
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-applet">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </>
        )
    }
}