import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlIframes extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Iframes </title>
                    <meta name="description" content="An HTML Iframe tag iframe is used to define a web page within a web page. It separate a document with a rectangular portion which indicates different document with in a document including scrollbars and borders." />
                    <meta name="keywords" content="frames, iframes in html, Html iframes, iframse, Html iframes Tutorial" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - IFrames" />
                    <meta property="og:description" content="An HTML Iframe tag iframe is used to define a web page within a web page. It separate a document with a rectangular portion which indicates different document with in a document including scrollbars and borders." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-iframes" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>IFrames</h2>
                    <br/><br/>
                <p>An HTML Iframe tag &lt;iframe&gt; is used to define a web page within a web page. It separate a document with a rectangular portion which indicates different document with in a document including scrollbars and borders.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; HTML Iframes &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;p&gt; Content of Document. &lt;/p&gt;<br/>
                &lt;iframe src = "http://tutsfinder.in/index.html" width = "555" height = "200"&gt;<br/>
                &lt;/iframe&gt;<br/>
                &lt;p&gt;Another content of Document &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <iframe src="../index.html" title="index.html">
                </iframe>
                <br/><br/>
                Attributes of Iframe Tag with description:
                <br/><br/>
                <img src="../img/html/iframes.png" alt="Form" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-forms">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-javascript">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}