import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class FileInputOutputInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Tutorials - File Input Output</title>
                    <meta name="description" content = "A file can be used to store data. In C we can use file pointer to perform input and output operations. Different operations that can be perform on files are –" />
                    <meta name="keywords" content="C File Input Output,  C File Input Output Tutorial, File Input Output , File Input Output in C, Input Output, File" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - File Input Output" />
                    <meta property="og:description" content="A file can be used to store data. In C we can use file pointer to perform input and output operations. Different operations that can be perform on files are –" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/file-input-output-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>File Input Output</h2>
                    <br/>
                    <p>A file can be used to store data. In C we can use file pointer to perform input and output operations. Different operations that can be perform on files are –</p>
                <p>1.	Open a file</p>
                <p>2.	Create a file</p>
                <p>3.	Read a file</p>
                <p>4.	Write a file</p>
                <p>5.	Close a file</p>
                <br/>
                <h4>File opening modes in C </h4>
                <br/><br/>
                <div className="tuts-container" >
                    <table className="tuts-table tuts-striped" border="2">
                    <tr>
                        <th>File Mode</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>r</td>
                        <td>to open a file for reading</td>
                    </tr>
                    <tr>
                        <td>w</td>
                        <td>open a file for writting</td>
                    </tr>
                    <tr>
                        <td>a</td>
                        <td>open a file in append mode</td>
                    </tr>
                    <tr>
                        <td>r+</td>
                        <td>open a file for reading and writing and also you can modify the file.</td>
                    </tr>
                    <tr>
                        <td>w+</td>
                        <td>open a file for reading and writing and you can overwrite the data in it.</td>
                    </tr>
                    <tr>
                        <td>a+</td>
                        <td>open a file in append mode for reading and you can’t modify the datat in it.</td>
                    </tr>
                    </table>
                </div>
                <br/><br/>
                <h4>Types of Files –</h4>
                <br/>
                <p>There are two types of file –</p>
                <p>1.	Text Files – These files are simple text files like .txt files. Text files have plain text in easy and understandable format.</p>
                <p>2.	Binary Files – These files store data in binary format in 0 & 1. Mostly .bin files are used to store binary data.</p>
                <br/><br/>
                <h4>Create a File –</h4>
                <p>When you want to use a file, first you have to create it. A file is used to store some data and needs some space in a memory.</p>
                <p><strong>Synatx :</strong></p>
                <p>File *fp;</p>
                <p>Fp = fopen (FileName, mode); </p>
                <p>If the file is not present in the system, then it is created that otherwise it will open the file.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main() &#123;<br/>
                FILE *fp;<br/>
                fp  = fopen ("C://xyz.txt", "w");<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h4>Closing a File</h4>
                <p>is used to closing a already opened file.</p>
                <p>fclose() is used to closing a file.</p>
                <p><strong>Syntax :</strong></p>
                <p>fclose (file_pointer);</p>
                <br/><br/>
                <h3>Reading and Writing a file  </h3>
                <p>fprintf() and fscanf() is used to reading and writing a file.</p>
                <br/>
                <h4>Writing a file</h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    int num;<br/>
                    FILE *fp;<br/>
                    <br/>
                    fptr = fopen("C:\\xyz.txt","w");<br/>
                    <br/>
                    if(fp == NULL)<br/>
                    &#123;<br/>
                        printf("Error occurred ");<br/>
                        exit(1);<br/>
                    &#125;<br/>
                    <br/>
                    printf("Enter num: ");<br/>
                    scanf("%d",&num);<br/>
                    <br/>
                    fprintf(fp,"%d",num);<br/>
                    fclose(fp);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h4>Reading from a file – </h4>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    int num;<br/>
                    FILE *fp;<br/>
                    <br/>
                    if ((fp = fopen("C:\\xyz.txt","r")) == NULL)&#123;<br/>
                        printf("Error occured");<br/>
                        <br/>
                        (// Program exits if the file pointer returns NULL.)<br/>
                        exit(1);<br/>
                    &#125;<br/>
                    <br/>
                    fscanf(fp,"%d", &num);<br/>
                    <br/>
                    printf("Value of n=%d", num);<br/>
                    fclose(fp);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-pointers">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-type-casting">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}