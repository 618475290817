import React from 'react';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import GoogleAd from '../Components/GoogleAd';
export default class WebDevelopment extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Web Development Tutorials</title>
                    <meta name="keywords" content="tutsfinder web development tutorials,  Web Development Tutorials, web developer tutorials, html, css, javascript" />

                    <meta property="og:title" content="TutsFinder - Web Development Tutorials" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/web-development" />
                </MetaTags>
                <GoogleAd/>
                <div className="popular_program_area section__padding" style={{paddingTop:`0px`, paddingBottom:`0px`}}>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Web Development</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent" style={{paddingTop:`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/html/html-introduction"><img src="img/html.png" alt="HTML" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>HTML </h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/css/css-introduction"><img src="img/css.png" alt="CSS" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>CSS</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/javascript/javascript-introduction"><img src="img/Javascript.png" alt="Javascript" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>Javascript</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}