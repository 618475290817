import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptArrays extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Arrays</title>
                    <meta name="description" content="An array is a group of elements forming a complete building block. If you want use array in your program then first declare variable and initialize the value to that variable." />
                    <meta name="keywords" content="javascript multidimensional array, javascript associative array, javascript array of objects, javascript array functions, javascript array append, javascript array push, javascript array indexof, javascript array find index" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Arrays" />
                    <meta property="og:description" content="An array is a group of elements forming a complete building block. If you want use array in your program then first declare variable and initialize the value to that variable." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-arrays" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/><br/>
                    <h2>JavaScript Arrays</h2>
                    <br/><br/>
                    <p>An array is a group of elements forming a complete building block.</p>
                    <p>The following are characteristics of an array –</p>
                    <p>• An array is a collection of elements.</p>
                    <p>• All elements contained in an array are of same data type.</p>
                    <p>• This collection forms a complete set.</p>
                    <br/><br/>
                    <h3>Array Declaration and Initialization</h3>
                    <p>If you want use array in your program then first declare variable and initialize the value to that variable.</p>
                    <p><strong>Syntax:</strong> dataType array_name[]={ };</p>
                    <p><strong>Example:</strong> int myarray [] = &#123; 7,8,4,21 &#125;;</p>
                    <p>There are 3 ways to construct array in JavaScript:</p>
                    <p>•	Using an Array literal.</p>
                    <p>•	By using keyword new.</p>
                    <p>•	By passing arguments in Array constructor.</p>
                    <br/><br/>
                    <h3>By using literals</h3>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt;  JavaScript Array using literals &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var Colors=["Red","Blue","Green","Yellow"];<br/>
                    for (i=0;i&lt;Colors.length;i++)&#123;<br/>
                    document.write(Colors[i] + "&lt;br/&gt;");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    Red<br/>
                    Blue<br/>
                    Green<br/>
                    Yellow
                    <br/><br/>

                    <h3>By using new keyword</h3>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; JavaScript Array New Keyword &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var i;<br/>
                    var Colors = new Array();<br/>
                    Colors[0] = "Red";<br/>
                    Colors[1] = "Blue";<br/>
                    Colors[2] = "Greeen";<br/>
                    Colors[3] = "Yellow";<br/>
                    for (i=0;i&lt;Colors.length;i++)&#123;<br/>
                    document.write(Colors[i] + "&lt;br&gt;");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    Red<br/>
                    Blue<br/>
                    Green<br/>
                    Yellow
                    <br/><br/><br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-events">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-strings">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}