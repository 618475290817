import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CKeywordsAndIdentifiers extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Keywords and Identifiers</title>
                    <meta name="description" content = "Keywords are the reserved words that have special meaning in C language. We cannot use keywords as identifiers. There are 32 keywords in C language" />
                    <meta name="keywords" content="Keywords and Identifiers, Keywords, Identifiers, C Keywords and Identifiers,  C Keywords and Identifiers Tutorial, if, else, if else, goto, switch , case, null, void, int, char, float , enum, sizeOf, return, volatile, unsigned, signed" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Keywords and Identifiers " />
                    <meta property="og:description" content="Keywords are the reserved words that have special meaning in C language. We cannot use keywords as identifiers. There are 32 keywords in C language" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-keywords-identifiers" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Keywords</h2>
                    <br/>
                    <p>Keywords are the reserved words that have special meaning in C language. We cannot use keywords as identifiers. There are 32 keywords in C language</p>
                <br/><br/>
                <img src="../img/c/keywords.png" style={{width:`100%`, height:`auto`}} alt="C Keywords"/>
                <br/><br/>
                <p><strong>auto, signed, const, extern, register, unsigned</strong> – These key words are used to define a variable.</p>
                <p><strong>break</strong> – It is a loop control statement which is used to terminate the loop.</p>
                <p><strong>int, float, char, double, long</strong> – These are data types used in to declaring the type variables.</p>
                <p><strong>if, else, switch, case, default</strong> -  These are conditional statements used for decision controls.</p>
                <p><strong>continue</strong> – It is a statement used inside the loop, when continue statement run inside the loop, control jumping to the beginning of the loop for next iteration.</p>
                <p><strong>for, while, do</strong> – These are loops used to repeat the block of code.</p>
                <p><strong>enum</strong> – or enumeration is a user define data type used to define the variables of enum type</p>
                <p><strong>goto</strong> -  goto statement is used to jump anywhere from one statement to another in functions.</p>
                <p><strong>return</strong> – used to returning a value</p>
                <p><strong>sizeOf</strong> – it is used to compute the size of its operand.</p>
                <p><strong>struct, typedef</strong> - both are data types and used to create a structure.</p>
                <p><strong>void</strong> – void is return type that indicates that function does not return a value.</p>
                <p><strong>union</strong> - It allows the use to store different type of data in same memory location.</p>
                <p><strong>volatile</strong> – It tells the browser that the value of volatile variable can be change any time.</p>
                <br/>
                <h2>Identifiers</h2>
                <br/>
                <p>Identifiers are user defined which can be any variable, function and structure etc.</p>
                <p>Identifier must be unique.</p>
                <p><strong>Eg.</strong> tutsfinder, tutorials etc.</p>
                <p>Rules to create identifier  :</p>
                <p>1.	An Identifier contains only  alphanumeric characters(a-z , A-Z , 0-9) and underscore(_).</p>
                <p>2.	The first character of an identifier should start with alphabet(a-z , A-Z) or underscore (_).</p>
                <p>3.	Identifiers are also case sensitive. For example finder and Finder are two different identifiers.</p>
                <p>4.	Keywords are not allowed to be used as Identifiers.</p>
                <p>5.	No special characters, such as semicolon, period, whitespaces, slash or comma are allowed to be used as Identifier.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="first-program-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-variables-constants">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}