import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Display</title>
                    <meta name="description" content="Display propery is used to control layout. It specefies how components or box should place on the web page. The default value for most of the property is block." />
                    <meta name="keywords" content="css display, display in css, display, display css property, inline display, flex display, block display, display property, css tutorials, display tutorials" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Display" />
                    <meta property="og:description" content="Display propery is used to control layout. It specefies how components or box should place on the web page. The default value for most of the property is block." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-Display" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Display</h2>
                    <br/><br/>

                    <p>Display propery is used to control layout. It specefies how components or box should place on the web page. The default value for most of the property is block.</p>
                    <table className="cont" style={{border: `1px solid`}}>
                    <thead>
                    <tr>
                    <th>Value</th>
                    <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>inline</td>
                    <td>used to displays an element as an inline element.</td>
                    </tr>
                    <tr>
                    <td>block</td>
                    <td> used to displays an element as a block element </td>
                    </tr>
                    <tr>
                    <td>contents</td>
                    <td>used to disappear the container.</td>
                    </tr>
                    <tr>
                    <td>flex</td>
                    <td>used to display an element as a block-level flex container.</td>
                    </tr>
                    <tr>
                    <td>grid</td>
                    <td>used to display an element as a block-level grid container.</td>
                    </tr>
                    <tr>
                    <td>inline-block</td>
                    <td>used to display an element as a inline-level block container.</td>
                    </tr>
                    <tr>
                    <td>inline-flex</td>
                    <td>used to display an element as a inline-level flex container.</td>
                    </tr>
                    <tr>
                    <td>inline-grid</td>
                    <td>used to display an element as a inline-level grid container.</td>
                    </tr>
                    <tr>
                    <td>inline-table</td>
                    <td>used to display an inline-level table</td>
                    </tr>
                    <tr>
                    <td>list-item</td>
                    <td>used to display all the element in &lt;li&gt; element.</td>
                    </tr>
                    <tr>
                    <td>run-in</td>
                    <td>used to display an element inline or block level, depending on context.</td>
                    </tr>
                    <tr>
                    <td>table</td>
                    <td>used to set the behavior as &lt;table&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-caption</td>
                    <td>used to set the behavior as &lt;caption&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-column-group</td>
                    <td>used to set the behavior as &lt;column&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-header-group</td>
                    <td> element behave like a &lt;thead&gt; element</td>
                    </tr>
                    <tr>
                    <td>table-footer-group</td>
                    <td>used to set the behavior as &lt;header&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-row-group</td>
                    <td>used to set the behavior as &lt;row&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-cell</td>
                    <td>used to set the behavior as &lt;td&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-column</td>
                    <td>used to set the behavior as &lt;col&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>table-row</td>
                    <td>used to set the behavior as &lt;tr&gt; for all element.</td>
                    </tr>
                    <tr>
                    <td>none</td>
                    <td>used to remove the element.</td>
                    </tr>
                    <tr>
                    <td>initial</td>
                    <td>used to set the default value.</td>
                    </tr>
                    <tr>
                    <td>inherit</td>
                    <td>used to inherit the property from it’s parents elements.</td>
                    </tr>
                    </tbody>
                    </table>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example of Inherit property</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body &#123;<br/>
                    display: inline;<br/>
                    &#125;<br/>
                    <br/>
                    p &#123;<br/>
                    display: inherit;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;p&gt;We are using inherit property to show how it works,&lt;/p&gt;<br/>
                    <br/>
                    &lt;p&gt; And see there is no spcae between these two paragraphs.&lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/display.png" alt="display" style={{width:`100%`}} />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-padding">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-opacity">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}