import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppMultithreading extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Multithreading</title>
                    <meta name="description" content = "Multithreading means a single program have multiple threads and execute independently at same time. Multithreading is a feature of C++ that have multiple single threads." />
                    <meta name="keywords" content="C++ Multithreading,  C++ Multithreading Tutorial, Multithreading in c++, Multithreading, tutsfinder, javatpoint, Multithreading javatpoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Multithreading " />
                    <meta property="og:description" content="Multithreading means a single program have multiple threads and execute independently at same time. Multithreading is a feature of C++ that have multiple single threads." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-multithreading" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><br/>
                <h2>Multithreading</h2>
                    <br/>
                <p>Multithreading means a single program have multiple threads and execute independently at same time. Multithreading is a feature of C++ that have multiple single threads.</p>
                <p>Thread is a light weight process of a program and these threads executing simultaneously.</p>
                <strong>Example:</strong>
                <br/>
                <p>•	We can print a document in MS-Word at the same time checking the spelling mistakes in the document.</p>
                <p>•	We can open multiple tabs in browser.</p>
                <strong>Important terms in Multithreading –</strong>
                <br/><br/>
                <p><strong>Program:</strong> A set of instruction store in hard disk is called a program.</p>
                <p><strong>Process:</strong>  A program in RAM is called process.</p>
                <p><strong>Thread:</strong> Thread is a process under the control of CPU.</p>
                <br/>
                <h2>Thread Life Cycle – </h2>
                <p>Thread goes through these stages in its life cycle.</p>
                <p>•	New</p>
                <p>•	Runnable</p>
                <p>•	Running</p>
                <p>•	Non- runnable</p>
                <p>•	Terminated
                </p>
                <br/>
                <div style={{textAlign:`center`}}>
                <img src="../img/java/thread-life-cycle.png" alt="Threda Life Cycle" />
                </div>
                <br/><br/>
                <p>•	In new state, thread instance has been created but start() is not yet invoked.</p>
                <p>•	When start() is invoked thread is in runnable state.</p>
                <p>•	After call the run() method thread is in running state.</p>
                <p>•	Waiting thread is non-runnable state , in this state the thread is alive but not eligible to run.</p>
                <p>•	In dead state, thread is not alive.Once the run method is completed then it will destroy.</p>
                <br/>
                <strong>Multiprogramming :</strong>
                <p>When more than one process are queued in RAM for their execution the concept is known as multiprogramming.</p>
                <p><strong>For Example:</strong>  There are many students in queue for interview and only one student is interviewed is allow at a time, the process that is going on will be multiprogramming.</p>
                <strong>Multitasking: </strong>
                <p>When more than one process are under execution than the concept is known as multitasking.</p>
                <p><strong>For Example</strong>: At a same I am using my worpad and as well as playing songs.</p>
                <strong>Multiprocessing :</strong>
                <p>When more than one processors are used in same system to solve single or multiple task than the concept is known as multiprocessing.</p>
                <br/><br/>
                Program to demonstrate abstract claa with on virtual function –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    virtual void run()=0;<br/>
                &#125;;<br/>
                class Bike : Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    void run()<br/>
                    &#123;<br/>
                        cout &lt;&lt; " Bike is running..." &lt;&lt; endl;<br/>
                    &#125;<br/>
                &#125;;<br/>
                class Car : Vehicle<br/>
                &#123;<br/>
                    public:<br/>
                    void run()<br/>
                    &#123;<br/>
                        cout &lt;&lt; "Car is running..." &lt;&lt; endl;<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main( ) &#123;<br/>
                    Bike bike;<br/>
                    Car car;<br/>
                    bike.run();<br/>
                    car.run();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Bike is running...<br/>
                Car is running...
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-interface">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-file-and-streams">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}