import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppVariables extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Variables</title>
                    <meta name="description" content = "Variables are used to store data, it tell the compiler the nature of the data and compiler reserves the necessary space for it." />
                    <meta name="keywords" content="Variables, C++ Variables,  C++ Variables Tutorial, Variables in c++, c++ Variables tutsfinder, udemy, coursera" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Variables " />
                    <meta property="og:description" content="Variables are used to store data, it tell the compiler the nature of the data and compiler reserves the necessary space for it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-loops" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Variables</h2>
                    <br/>
                    <p>Variables are used to store data, it tell the compiler the nature of the data and compiler reserves the necessary space for it.</p>
                <br/>
                <p>Rules to define variables in C++</p>
                <p>1. A variable contains only alphanumeric characters(a-z , A-Z , 0-9) and underscore(_).</p>
                <p>2. The first character of a variable should start with alphabet(a-z , A-Z) or underscore (_).</p>
                <p>3. Variables are also case sensitive. </p>
                <p>4. Keep variables name in small alphabets</p>
                <br/>
                <p><strong>Syntax</strong> – type variable_name;</p>
                <p><strong>For Example</strong> – int x ,float y</p>
                <br/>
                <p>Following example will help you to understand what variables are.</p>
                <p>Assume you are writing a program to addition of two numbers. The user has to enter some input for that operands, user needs some memory area to store that two input values, therefore we named that two variables a and b and store in another variable sum so these memory names is known as variables.</p>
                <br/>
                <h4>Program to show addition of two numbers where we will take 3 variables, let’s see how it works</h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main() &#123;<br/>
                    int a,b,sum;<br/>
                    cout &lt;&lt; " Enter first number ";<br/>
                    cin &gt;&gt; a;<br/>
                    cout &lt;&lt; " Enter second number ";<br/>
                    cin &gt;&gt; b;<br/>
                    sum=a+b;<br/>
                    cout &lt;&lt; " Sum of a & b is " &lt;&lt; sum;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h4>Output :</h4>
                <br/>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                Enter first number10<br/>
                Enter second number5<br/>
                Sum of a & b is 15
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" >
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-first-program">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6" >
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-data-types">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}