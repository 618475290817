import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PseudoClasses extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Pseudo Classes</title>
                    <meta name="description" content="Pseudo classes are CCS classes which are used with elements to define the state." />
                    <meta name="keywords" content="pseudo classes, css pseudo classes, pseudo classes in css, pseudo classes tutsfinder, pseudo, flipkart, " />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Pseudo Classes" />
                    <meta property="og:description" content="Pseudo classes are CCS classes which are used with elements to define the state." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-pseudo-classes" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Pseudo Classes</h2>
                    <br/><br/>

                    <p>Pseudo classes are CCS classes which are used with elements to define the state.</p>
                    <p><strong>For Example</strong> – mouse-hover, click to get focus, active links.</p>
                    Syntax
                    <br/><br/>
                    <pre>
                    selector:pseudo-class &#123;
                    property: value;
                    &#125;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    div &#123;<br/>
                    width: 200px;<br/>
                    height: 200px;<br/>
                    color: white;<br/>
                    background: green;<br/>
                    &#125;<br/>
                    div.box:hover&#123;<br/>
                    color: black;<br/>
                    background: white;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;div className="box"&gt; TutsFinder - Elementary way to find &lt;/div&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>

                    <div className="box"> TutsFinder - Elementary way to find </div>
                    <br/><br/>
                    <table className="cont">
                    <tbody><tr>
                        <th style={{width:`20%`}}>Selector</th>
                        <th style={{width:`20%`}}>Example</th>
                        <th>Example description</th>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_active.asp">:active</a></td>
                        <td className="notranslate">a:active</td>
                        <td>Selects the active link</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_checked.asp">:checked</a></td>
                        <td className="notranslate">input:checked</td>
                        <td>Selects every checked &lt;input&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_disabled.asp">:disabled</a></td>
                        <td className="notranslate">input:disabled</td>
                        <td>Selects every disabled &lt;input&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_empty.asp">:empty</a></td>
                        <td className="notranslate">p:empty</td>
                        <td>Selects every &lt;p&gt; element that has no children</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_enabled.asp">:enabled</a></td>
                        <td className="notranslate">input:enabled</td>
                        <td>Selects every enabled &lt;input&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_firstchild.asp">:first-child</a></td>
                        <td className="notranslate">p:first-child</td>
                        <td>Selects every &lt;p&gt; elements that is the first child of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_first-of-type.asp">:first-of-type</a></td>
                        <td className="notranslate">p:first-of-type</td>
                        <td>Selects every &lt;p&gt; element that is the first &lt;p&gt; element of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_focus.asp">:focus</a></td>
                        <td className="notranslate">input:focus</td>
                        <td>Selects the &lt;input&gt; element that has focus</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_hover.asp">:hover</a></td>
                        <td className="notranslate">a:hover</td>
                        <td>Selects links on mouse over</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_in-range.asp">:in-range</a></td>
                        <td className="notranslate">input:in-range</td>
                        <td>Selects &lt;input&gt; elements with a value within a specified range</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_invalid.asp">:invalid</a></td>
                        <td className="notranslate">input:invalid</td>
                        <td>Selects all &lt;input&gt; elements with an invalid value</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_lang.asp">:lang(<i>language</i>)</a></td>
                        <td className="notranslate">p:lang(it)</td>
                        <td>Selects every &lt;p&gt; element with a lang attribute value starting with "it"</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_last-child.asp">:last-child</a></td>
                        <td className="notranslate">p:last-child</td>
                        <td>Selects every &lt;p&gt; elements that is the last child of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_last-of-type.asp">:last-of-type</a></td>
                        <td className="notranslate">p:last-of-type</td>
                        <td>Selects every &lt;p&gt; element that is the last &lt;p&gt; element of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_link.asp">:link</a></td>
                        <td className="notranslate">a:link</td>
                        <td>Selects all unvisited links</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_not.asp">:not(selector)</a></td>
                        <td className="notranslate">:not(p)</td>
                        <td>Selects every element that is not a &lt;p&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_nth-child.asp">:nth-child(n)</a></td>
                        <td className="notranslate">p:nth-child(2)</td>
                        <td>Selects every &lt;p&gt; element that is the second child of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_nth-last-child.asp">:nth-last-child(n)</a></td>
                        <td className="notranslate">p:nth-last-child(2)</td>
                        <td>Selects every &lt;p&gt; element that is the second child of its parent, counting from the last child</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_nth-last-of-type.asp">:nth-last-of-type(n)</a></td>
                        <td className="notranslate">p:nth-last-of-type(2)</td>
                        <td>Selects every &lt;p&gt; element that is the second &lt;p&gt; element of its parent, counting from the last child</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_nth-of-type.asp">:nth-of-type(n)</a></td>
                        <td className="notranslate">p:nth-of-type(2)</td>
                        <td>Selects every &lt;p&gt; element that is the second &lt;p&gt; element of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_only-of-type.asp">:only-of-type</a></td>
                        <td className="notranslate">p:only-of-type</td>
                        <td>Selects every &lt;p&gt; element that is the only &lt;p&gt; element of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_only-child.asp">:only-child</a></td>
                        <td className="notranslate">p:only-child</td>
                        <td>Selects every &lt;p&gt; element that is the only child of its parent</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_optional.asp">:optional</a></td>
                        <td className="notranslate">input:optional</td>
                        <td>Selects &lt;input&gt; elements with no "required" attribute</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_out-of-range.asp">:out-of-range</a></td>
                        <td className="notranslate">input:out-of-range</td>
                        <td>Selects &lt;input&gt; elements with a value outside a specified range</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_read-only.asp">:read-only</a></td>
                        <td className="notranslate">input:read-only</td>
                        <td>Selects &lt;input&gt; elements with a "readonly" attribute specified</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_read-write.asp">:read-write</a></td>
                        <td className="notranslate">input:read-write</td>
                        <td>Selects &lt;input&gt; elements with no "readonly" attribute</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_required.asp">:required</a></td>
                        <td className="notranslate">input:required</td>
                        <td>Selects &lt;input&gt; elements with a "required" attribute specified</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_root.asp">:root</a></td>
                        <td className="notranslate">root</td>
                        <td>Selects the document's root element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_target.asp">:target</a></td>
                        <td className="notranslate">#news:target</td>
                        <td>Selects the current active #news element (clicked on a URL containing that anchor name)</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_valid.asp">:valid</a></td>
                        <td className="notranslate">input:valid</td>
                        <td>Selects all &lt;input&gt; elements with a valid value</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_visited.asp">:visited</a></td>
                        <td className="notranslate">a:visited</td>
                        <td>Selects all visited links</td>
                    </tr>
                    </tbody>
                    </table>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-shadow-property">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-pseudo-elements">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}