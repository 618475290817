import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppIntroduction extends React.Component {
    componentDidMount() {
        $(document).ready(function () {
            window.scrollTo(0, 0);
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Introduction</title>
                    <meta name="description" content = "These tutorials starts with the very basics of C++. An existing knowledge of C language would be an advantage but it is not prerequisite. All that is needed is a desire to learn this language and curiosity to understand how C++ works. So let’s get stared –" />
                    <meta name="keywords" content="C++ tutorial,  C++ Tutorials, tutsfinder, c++ tutorial tutsfinder , tutorials, c++ beginners, c++ professionals, introduction to C++, c++ concepts, c++ example, C++, c++ language, c++ basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Introduction" />
                    <meta property="og:description" content="These tutorials starts with the very basics of C++. An existing knowledge of C language would be an advantage but it is not prerequisite. All that is needed is a desire to learn this language and curiosity to understand how C++ works. So let’s get stared –" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-introduction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/c++/c++.jpg" alt="C++" style={{width:`100%`, height:`auto`, border: `groove`}} />
                    </div>
                    <br/>
                    <p>These tutorials starts with the very basics of C++. An existing knowledge of C language would be an advantage but it is not prerequisite. All that is needed is a desire to learn this language and curiosity to understand how C++ works. So let’s get stared –</p>
                <h2>Introduction</h2>
                <br/>
                <p>C ++ is considered as an intermediate-level programming language, which means that it allows high level programming of applications as well as low level of libraries that works to close o the hardware. </p>
                <p>C++ is general purpose mid level language developed by Bjarne Stroustroup at Bell Labs in 1979.</p>
                <p>C++ is a successor of C Language.</p>
                <p>C++ is object oriented language and it implements concepts such as inheritance, abstraction , encapsulation, polymorphism.</p>
                <p>In 1998, C++ first standard version was sanctioned by ISO committee in ISO/IEC.</p>
                <p>Applications that are using C++ -</p>
                <p>1.	Operating Systems</p>
                <p>2.	Device drivers</p>
                <p>3.	Office applications</p>
                <p>4.	Web servers</p>
                <p>5.	Cloud based applications</p>
                <p>6.	Search engines</p>
                <p>7.	Network programming language</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-environment-setup">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}