import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class BrowserObjectModel extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Browser Object Model</title>
                    <meta name="description" content="Browser Object Model specifies how objects interact with browser. Window is the global object of Javascript in the browser." />
                    <meta name="keywords" content="browser object model in javascript pdf, browser object in javascript, window object in javascript, browser object model in javascript ppt, navigator object in javascript, browser object model vs document object model, javascript dom, location object in javascript, document object model, javascript, jquery, html5" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Introduction" />
                    <meta property="og:description" content="Browser Object Model specifies how objects interact with browser. Window is the global object of Javascript in the browser." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-browser-object-model" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/><br/>
                    <h2>Browser Object Model</h2>
                    <br/><br/>
                    <p>Browser Object Model specifies how objects interact with browser.</p>
                    <br/>
                    <h3>Objects of browser(Browser Object Models)</h3>
                    <h4>Window</h4>
                    <p>Window is the global object of Javascript in the browser.</p>
                    <p>The variables and methods are declared globally are the members of window object.</p>
                    <h4>Properties :</h4>
                    <p>•	window.open() </p>
                    <p>•	window.close() </p>
                    <p>•	window.moveTo() </p>
                    <p>•	window.resizeTo()</p>
                    <p>•	window.innerHeight </p>
                    <p>•	window.innerWidth</p>
                    <br/>

                    <h4>Screen</h4>
                    <p>Screen object is used to get the information of current screen.</p>
                    <p>window.screen object is used to access the current screen.</p>

                    <h4>Properties:</h4>
                    <p>•	screen.width</p>
                    <p>•	screen.height</p>
                    <p>•	screen.availWidth</p>
                    <p>•	screen.availHeight</p>
                    <p>•	screen.colorDepth</p>
                    <p>•	screen.pixelDepth</p>

                    <h4>Location</h4>
                    <p>Location objects represents the location(URL)  of a document and it is used to access the location of the document.</p>
                    <p>window.location is used to get the location of the page.</p>
                    <h4>Properties:</h4>
                    <p>•	window.location.href </p>
                    <p>•	window.location.hostname </p>
                    <p>•	window.location.pathname </p>
                    <p>•	window.location.protocol </p>
                    <p>•	window.location.assign()</p>

                    <h4>Navigator </h4>
                    <p>Navigator object is used to get the information about the browser and its capabilities.</p>
                    <p><strong>window.navigator.userAgent</strong> – This property is used to identifies the browser.</p>
                    <h4>Properties:</h4>
                    <p>activeVrDisplays</p>
                    <p>appCodeName</p>
                    <p>appName</p>
                    <p>appVersion</p>
                    <p>battery</p>
                    <p>buildId</p>
                    <p>connection</p>
                    <p>cookieEnabled</p>
                    <p>credentials</p>
                    <p>deviceMemory</p>
                    <p>doNotTrack</p>
                    <p>geolocation</p>
                    <p>getVRDisplays()</p>
                    <p>getUserMedia()</p>
                    <p>hardwareConcurrency()</p>
                    <p>javaEnabled()</p>
                    <p>language()</p>
                    <p>languages()</p>
                    <p>locks()</p>
                    <p>mediaCapabilities()</p>
                    <p>mediaDevices()</p>
                    <p>maxTouchPoints()</p>
                    <p>mimeTypes()</p>
                    <p>onLine()</p>
                    <p>oscpu()</p>
                    <p>permissions()</p>
                    <p>platform()</p>
                    <p>plugins()</p>
                    <p>product()</p>
                    <p>productSub()</p>
                    <p>registerProtocolHandler()</p>
                    <p>requestMediaKeySystemAccess()</p>
                    <p>sendBeacon()</p>
                    <p>serviceWorker()</p>
                    <p>share()</p>
                    <p>storage()</p>
                    <p>userAgent()</p>
                    <p>vendor()</p>
                    <p>vendorSub()</p>
                    <p>vibrate()</p>
                    <p>webdriver()</p>
                    <p><strong>Note:</strong> use window.Navigator suffix before all the properties.</p>
                    <br/>
                    <h4>History</h4>
                    <p>Javascript history object is used to access the browser session history.</p>
                    <p>Javascript History object have 3 methods that are used in to navigating between pages.</p>
                    <p>•	 back()</p>
                    <p>•	 forward()</p>
                    <p>•	 go()</p>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-dom">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-jquery">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}