import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlAnchorTags extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Anchor tag</title>
                    <meta name="description" content="Anchor tag is used to create a link between web pages, files, location, or any URL." />
                    <meta name="keywords" content="anchor tag, Html anchor tag, Html Anchor tag Tutorial, website , tutorials, beginners, professionals, introduction to Html, concepts, example, Html, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Anchor tag" />
                    <meta property="og:description" content="Anchor tag is used to create a link between web pages, files, location, or any URL." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-anchor-tag" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Anchor Tag</h2>
                    <br/><br/>
                <p>Anchor tag is a tag which defines a hyperlink that links one page to another page.</p>
                <p>Anchor tag is used to create a link between web pages, files, location, or any URL.</p>
                <p>The most important attribute of anchor (a) tag is href which is used to link destination page or URL.</p>
                <p>For using the target attribute of anchor tag it is must to have href attribute.</p>
                <br/>
                <h3>Description of href attribute</h3>
                <p>The href attribute is used to define the address of the file to be linked, basically it points out to the destination page.</p>
                <br/>
                Syntax:
                <br/>
                &lt;a href = “. . . . . . . . . “&gt; Text to be Linked &lt;/a&gt;
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt;&lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;p&gt; Click on &lt;a href= http://www.tutsfinder.in/ target=”_blank”&gt; this-
                Link&lt;/a&gt; to go to home page of tutfinder. &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-comments">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-links">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}