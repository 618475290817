import React from 'react';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HrQuesAns1 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HR Interview Questions - Set 1</title>
                    <meta name="description" content="There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview." />
                    <meta name="keywords" content="top 10 interview questinos and answers, hr interview questions tutsfinder, interview questions for hr position, hr interview questions and answers for freshers pdf, logical hr interview questions and answers, hr interview questions for freshers with best answers and examples, interview questions and answers for hr position, mba hr interview questions, 100 hr interview questions and answers, entry level hr interview questions" />

                    <meta property="og:title" content="HR Interview Questions - Set 1" />
                    <meta property="og:description" content="There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/hr-interview-questions/hr-interview-questions-set-1" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Interview Questions</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>HR Interview Questions</h2><br/><br/>
                    <h4>There are some most common HR Interview Questions which will be asked everywhere so that's why we brought this content for you so that you can prepare for you interview.</h4>
                    <br/>
                    <br/>
                    <p className="hr-p-tag"><b>1. Tell me about yourself ?</b></p>
                    <p><b>Ans.</b> For experienced people:
                    I am working as a technical project manager, having more than five years of experience at XYZ Company. I am a type of person that survives in a swift environment. It would be a great pleasure for me to discuss how i can be such an asset to your company.</p>
                    <p><strong>For Fresher’s:</strong></p>
                    <p>Good Morning Sir/Mam,</p>
                    <p>My name is XYZ. Basically i am from Noida, and currently residing in Pune. I have completed my Graduation (tell them about your specialization e.g. BCA/B.tech) from XYZ college. My short term goal is to work in a reputed company. My long term goal is to be the most responsible and efficient employee of an organization.</p><br/>
                    {/* <!--<p className="hr-p-tag"><b><b></p>
                    <p>
                        <b>Ans.</b>
                    </p>--> */}
                    <p className="hr-p-tag"><b>2. Why do you want to work for this company?</b></p>
                    <p><b>Ans. </b>I would be proud to work for a reputed company like yours.I have accomplish web research and believe that the company's XYZ products and its future projections are very impressive and promising. The role i am going to get in your company fits my skills or background	 perfectly and can be utilized, respectively.</p><br/>
                    <p className="hr-p-tag"><b>3. What are your strengths?</b></p>
                    <p><b>Ans. </b>One of the qualities that describe me is my positive attitude. It actually helps me to keep myself motivated even in the most challenging situations. I also get along quite well with people and pick up new ideas quickly leading to hassle free teamwork.
                    <p>Basically your answer should implies all the good qualities you are having in a short statement. Qualities like positivity, self-motivation, quick learner, team player etc.</p>
                    </p><br/>
                    <p className="hr-p-tag"><b>4. What are your weaknesses?</b></p>
                    <p><b>Ans.</b> This questions is basically to cross verify you but don’t ever tell about your weakness part which will impact the role you are going to hire for. The best route you can take is to propound a weakness not related to the job or role as well as give information as what you done to overcome it.</p><br/>
                    <p className="hr-p-tag"><b>5. What are your salary expectations?</b></p>
                    <p><b>Ans.</b> Salary is not an issue for me, learning and gaining experience is my top most priority. Also a reputed company like yours will provide the best best offer in the industry.</p><br/>
                    <p className="hr-p-tag"><b>6. What are your achievements in life?</b></p>
                    <p><b>Ans.</b> <strong>For fresher’s:</strong></p>
                    <p>Mention about the achievements which you had acquired in your graduation (recent one).</p>
                    <p><strong>Example: </strong>My greatest achievement so far in my career is getting awarded for topper of college having 9.2 SGPA.</p><br/>
                    <p className="hr-p-tag"><b>7. What is the reason for leaving your last job?</b></p>
                    <p><b>Ans.</b> I left my last job because I feel I want to do more, to get hold a greater opportunity and gain more knowledge in my area, also enhancing my skills.</p><br/>
                    <p className="hr-p-tag"><b>8. What is your ideology towards work?</b></p>
                    <p><b>Ans.</b> I believe when it comes to work, every piece of work regardless of size has to be done in a given time and in the right manner.</p><br/>
                    <p className="hr-p-tag"><b>9. What is your way to do leadership or describe your management style?</b></p>
                    <p><b>Ans.</b> Managing people is a skill one cans implement by listening, explaining expectations and working with your colleagues in a flexible manner. Promptness is also a key point for management.</p><br/>
                    <p className="hr-p-tag"><b>10. How would you be an asset to this company?</b></p>
                    <p><b>Ans.</b> My skills are best fitted for this position. As an employee, I handle pressure with ease and can work with minimal supervision.</p>
                    <div className="line"></div>
                </div>
            </>
        )
    }
}