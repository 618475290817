import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class jQuery extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    animate(){
        document.getElementById('blackDiv').style.position= 'relative';
        document.getElementById('blackDiv').style.left = '300px';
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - jQuery</title>
                    <meta name="description" content="jQuery  is a javascript library. jQuery is used to create impressive and interactive animations." />
                    <meta name="keywords" content="jquery javatpoint, jquery w3schools, jquery tutorial, jquery download, jquery cdn, jquery interview questions, jquery examples, jquery vs javascript" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - jQuuery" />
                    <meta property="og:description" content="jQuery  is a javascript library. jQuery is used to create impressive and interactive animations." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-jquery" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/><br/>
                    <h2>jQuery</h2>
                        <br/><br/>
                    <p>jQuery  is a javascript library.</p>
                    <p>jQuery is used to create impressive and interactive animations.</p>
                    <p><strong>You can implement jQuery in 2 ways –</strong></p>
                    <p>1.	You can download direct jQuery library from jquery.com</p>
                    <p>2.	And, you can implement CDN</p>
                    <p><strong>Synatx </strong>– We can use $ symbol before any selector.</p>

                    $(selector).action()

                    <p>Selector can be any id, class and element in the document.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"&gt;&lt;/script&gt;<br/>
                    &lt;script&gt;<br/>
                    $(document).ready(function()&#123;<br/>
                    $("button").click(function()&#123;<br/>
                        $("div").animate(&#123;right: '300px'&#125;);<br/>
                    &#125;);<br/>
                    &#125;);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;button&gt;Start Animation&lt;/button&gt;<br/>
                    <br/>
                    &lt;p&gt;Click on button to see animate function.&lt;/p&gt;<br/>
                    <br/>
                    &lt;div style="background:black;height:100px;width:100px;"&gt;&lt;/div&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>

                    <button onClick={this.animate}>Start Animation</button>

                    <p>Click on button to see animate function.</p>

                    <div id="blackDiv" style={{background:`black`, height:`100px`, width:`100px`}}></div>
                    <br/><br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-bom">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-json">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}