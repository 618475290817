import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlMultimedia extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Multimedia </title>
                    <meta name="description" content="The easiest way to add video or sound to you web page is to include the special HTML tag called &lt;embed&gt;." />
                    <meta name="keywords" content="media, multimedia, html media, html audio, html video, html mredia, Html multimedia, geeks, geeksforgeeks, javatpoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Multimedia" />
                    <meta property="og:description" content="The easiest way to add video or sound to you web page is to include the special HTML tag called &lt;embed&gt;." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-multimedia" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Multimedia</h2>
                    <br/><br/>
                <p>HTML multimedia element is used when user wants to add music or video into a webpage.</p>
                <p>The easiest way to add video or sound to you web page is to include the special HTML tag called &lt;embed&gt;.</p>
                <p>&lt;embed&gt; tag allows the browser to include the controls for the multimedia automatically provided browser supports &lt;embed&gt; tag and given media type.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;title&gt; HTML embed tag &lt;/title&gt;<br/>
                &lt;body&gt;<br/>
                &lt;embed src = "../img/logo.png" width = “200” height = “100” &gt;<br/>
                &lt;noembed&gt;&lt;img src = “yourimage.src” alt = “Alternative media” &gt;&lt;/noembed&gt;<br/>
                &lt;/embed&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <embed src = "../img/logo.png" width = "200" height = "100" />
                <noembed><img src = "logo.src" alt = "Alternative media" /></noembed>
                <br/><br/>
                <p><strong>Note:</strong> &lt;noembed&gt; tag is used for the browsers which don’t recognize the &lt;embed&gt; tag.</p>
                <br/>
                <h4>The &lt;embed&gt; tag Attributes</h4>
                <br/><br/>
                <img src="../img/html/multimedia.png" alt="multimedia" style={{width:`100%`}} />
                <br/><br/>
                <h4>Supported Video Types:</h4>
                <br/>
                <p><strong>•	.swf files</strong> − are the file types created by Macromedia's Flash program.</p>
                <p><strong>•	.wmv files</strong> − are Microsoft's Window's Media Video file types.</p>
                <p><strong>•	.mov files</strong> − are Apple's Quick Time Movie format.</p>
                <p><strong>•	.mpeg files</strong> − are movie files created by the Moving Pictures Expert Group.</p>
                <br/><br/>
                <h4>Background Audio</h4>
                <br/>
                <p>For playing a soundtrack in the background of your webpage &lt;bgsound&lgt; tag is used.</p>
                <p>This tag is only supported by Internet Explorer.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; HTML embed tag &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;bgsoundsrc = “/html/yourlife.mid”&gt;<br/>
                &lt;noembed&gt;&lt;img src =”yourimage.gif”&gt;&lt;/noembed&gt;<br/>
                &lt;/bgsound&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-svg">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </>
        )
    }
}