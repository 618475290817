import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlLists extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Lists </title>
                    <meta name="description" content="HTML lists allow users to group a set of related items in a lists." />
                    <meta name="keywords" content="Html lists, Html lists Tutorial, lists in html, html lists w3schools, html lists tutsfinder" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Lists" />
                    <meta property="og:description" content="HTML lists allow users to group a set of related items in a lists." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-lists" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Lists</h2>
                    <br/><br/>
                <p>HTML lists allow users to group a set of related items in a lists.</p>
                <p>Lists may contain one or more elements.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h2&gt;An Unordered HTML List&lt;/h2&gt;<br/>
                &lt;ul&gt;<br/>
                &lt;li&gt;Books&lt;/li&gt;<br/>
                &lt;li&gt;Student&lt;/li&gt;<br/>
                &lt;li&gt;School&lt;/li&gt;<br/>
                &lt;/ul&gt;<br/>
                &lt;h2&gt;An Ordered HTML List&lt;/h2&gt;<br/>
                &lt;ol&gt;<br/>
                &lt;li&gt;Books&lt;/li&gt;<br/>
                &lt;li&gt;Student&lt;/li&gt;<br/>
                &lt;li&gt;School&lt;/li&gt;<br/>
                &lt;/ol&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/list.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p>There are three different types of HTML lists:</p>
                <p><strong>1.	Ordered list or numbered list (ol)</strong> - ordered list is defined by &lt;ol&gt; tag.</p>
                <p>Each list item starts with the &lt;li&gt; tag.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;An ordered HTML list&lt;/h1&gt;<br/>
                &lt;ol&gt;<br/>
                &lt;li&gt;Pasta&lt;/li&gt;<br/>
                &lt;li&gt;Pizza&lt;/li&gt;<br/>
                &lt;li&gt;Burger&lt;/li&gt;<br/>
                &lt;/ol&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/list1.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p><strong>2.	Unordered lists or Bulleted list (ul)</strong> – unordered list are marked with bullets, and also known as Bulleted list. It starts with &lt;ul&gt; tag and each item starts with the &lt;li&gt; tag.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h2&gt;An unordered HTML list&lt;/h2&gt;<br/>
                &lt;li&gt;<br/>
                &lt;li&gt;Pasta&lt;/li&gt;<br/>
                &lt;li&gt;Pizza&lt;/li&gt;<br/>
                &lt;li&gt;Burger&lt;/li&gt;<br/>
                &lt;/ul&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/list2.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p><strong>3.	Description List or Definition List (dl)</strong> – this type of list is supported by HTML and XML where records are listed like in a dictionary or encyclopedia.</p>
                <p>It consists of mainly three tags:</p>
                <p>•	&lt;dl&gt; - defines the start of the list.</p>
                <p>•	&lt;dt&gt; - defines the term(name).</p>
                <p>•	&lt;dd&gt; - describes each term.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;A Description List&lt;/h1&gt;<br/>
                &lt;dl&gt;<br/>
                &lt;dt&gt;Starter&lt;/dt&gt;<br/>
                &lt;dd&gt;- Soup&lt;/dd&gt;<br/>
                &lt;dt&gt;Main Course&lt;/dt&gt;<br/>
                &lt;dd&gt;- Dal Makhni&lt;/dd&gt;<br/>
                &lt;/dl&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list3.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <h3>HTML Ordered Lists</h3>
                <br/>
                <p>HTML ordered lists or Numbered lists shows an element in numbered format.</p>
                <p>The HTML &lt;olgt; tag is used to create ordered list. It can be used when user want to present the data in numerical or alphabetical order format, or any format where an order is emphasized.</p>
                <br/>
                <p>Different types of Ordered Lists:</p>
                <p>•	Numeric Number (1,2,3)</p>
                <p>•	Small Roman Number (i, ii, iii)</p>
                <p>•	Capital Roman Number(I II III)</p>
                <p>•	Capital Alphabet (A, B, C)</p>
                <p>•	Small Alphabet (a, b, c)</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h2&gt;An ordered HTML list&lt;/h2&gt;<br/>
                &lt;ol&gt;<br/>
                &lt;li&gt;Pasta&lt;/li&gt;<br/>
                &lt;li&gt;Pizza&lt;/li&gt;<br/>
                &lt;li&gt;Burger&lt;/li&gt;<br/>
                &lt;/ol&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list4.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p><strong>Ordered HTML list</strong> – The Type Attribute</p>
                <p>Below table illustrating the type attribute of the &lt;ol&gt; tag:</p>
                <br/><br/>
                <img src="../img/html/ol.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p><strong>Note:</strong> By default an ordered list will start counting from 1 , if you want to start counting from a specified number you can use the start attribute.</p>
                <br/>
                Syntax for using Start Attribute:
                <br/>
                &lt;ol start="50"&gt;
                <br/><br/><br/>
                <h5>Nested HTML Lists</h5>
                <p>Nested list is a list which contain list inside lists.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h2&gt;A Nested List&lt;/h2&gt;<br/>
                &lt;p&gt;List can be nested (lists inside lists):&lt;/p&gt;<br/>
                &lt;ol&gt;<br/>
                &lt;li&gt;Coffee&lt;/li&gt;<br/>
                &lt;li&gt;Tea&lt;/li&gt;<br/>
                &lt;ol&gt;<br/>
                &lt;li&gt;Black tea&lt;/li&gt;<br/>
                &lt;li&gt;Green tea&lt;/li&gt;<br/>
                &lt;/ol&gt;<br/>
                &lt;/li&gt;<br/>
                &lt;li&gt;Milk&lt;/li&gt;<br/>
                &lt;/ol&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list5.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <h4>HTML Unordered Lists</h4>
                <p>HTML Unordered list or bulleted lists shows the element in bulleted format.</p>
                <p>The HTML &lt;ul&gt; tag is used to create unordered list, each list items starts with the &lt;li&gt; tag.</p>
                <p>By default lists items will be marked with bullets (small black circles).</p>
                <br/>
                <p>Different types of bulleted list:</p>
                <p>•	circle</p>
                <p>•	disc</p>
                <p>•	square</p>
                <p>•	none</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;An unordered HTML list&lt;/h1&gt;<br/>
                &lt;ul&gt;<br/>
                &lt;li&gt;Pasta&lt;/li&gt;<br/>
                &lt;li&gt;Pizza&lt;/li&gt;<br/>
                &lt;li&gt;Burger&lt;/li&gt;<br/>
                &lt;/ul&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list6.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p>Below table illustrating the Attributes of &lt;ul&gt; tag:</p>
                <br/><br/>
                <img src="../img/html/ul.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <p>The type attribute is not supported in HTML5, instead of using type you can use CSS property of list-style-type.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example showing the CSS property for &lt;ul&gt; tag:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h2&gt;Unordered List with CSS property&lt;/h2&gt;<br/>
                &lt;ul style="list-style-type:square;"&gt;<br/>
                &lt;li&gt;Coffee&lt;/li&gt;<br/>
                &lt;li&gt;Tea&lt;/li&gt;<br/>
                &lt;li&gt;Milk&lt;/li&gt;<br/>
                &lt;/ul&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list7.png" alt="table" style={{width:`100%`}} />
                <br/><br/>
                <h4>HTML Description Lists</h4>
                <p>HTML description list or definition list shows the elements in the type of dictionary or encyclopedia.</p>
                <p>Three tags are used to define description lists:</p>
                <p>1.	&lt;dl&gt; tag defines the description list.</p>
                <p>2.	&lt;dt&gt; tag defines the data term.</p>
                <p>3.	&lt;dd&gt; tag defines data definition.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;A Description List&lt;/h1&gt;<br/>
                &lt;dl&gt;<br/>
                &lt;dt&gt;Starter&lt;/dt&gt;<br/>
                &lt;dd&gt;- Soup&lt;/dd&gt;<br/>
                &lt;dt&gt;Main Course&lt;/dt&gt;<br/>
                &lt;dd&gt;- Dal Makhni&lt;/dd&gt;<br/>
                &lt;/dl&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Outout:
                <br/><br/>
                <img src="../img/html/list8.png" alt="table" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-tables">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-marquees">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}