import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Introduction</title>
                    <meta name="description" content="Javascript is a object oriented client side scripting language to make web pages  interactive and dynamic." />
                    <meta name="keywords" content="javascript tutorial tutsfinder, javascript tutorial pdf, javascript javatpoint, javascript tutorial free, javascript tutorial geeksforgeeks, best javascript tutorial youtube, best javascript tutorial, javascript tutorial youtube, javascript tutorialspoint, javascript"/>
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Introduction" />
                    <meta property="og:description" content="Javascript is a object oriented client side scripting language to make web pages  interactive and dynamic." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-introduction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/javascript/Javascript.png" alt="javascript" style={{width:`100%`, height:`auto`, border: `groove`}} />
                    </div>
                    <br/>
                    <h2>Introduction</h2>
                    <br/><br/>
                    <p>Javascript is a object oriented client side scripting language to make web pages  interactive and dynamic.</p>
                    <p>Javascript supports all features of C++ or java. But javascript is not same as java, both arre different languages.</p>
                    <p>JavaScript was originally developed as LiveScript by the Netscape in 1990s. Later It renamed to JavaScript in 1995, and then became an ECMA standard in 1997.</p>
                    <br/><br/>
                    <h3>Javascript frameworks – </h3>
                    <p>AngularJS</p>
                    <p>React </p>
                    <p>VueJS</p>
                    <p>Ember.js</p>
                    <p>Meteor</p>
                    <p>Mithril</p>
                    <p>Node.js</p>
                    <p>Polymer Aurelia</p>
                    <p>Backbone.js</p>
                    <br/><br/>
                    <h3>Advantages of Javascript – </h3>
                    <p><strong>Easy to learn</strong> – Javascript is easy to learn, It’s syntax is easy to understand and Javascript have predefined methods that helps in implementation of  Javascript.</p>
                    <p><strong>Client side language</strong> – Javascript code runs on client server instead of web server, so it saves bandwidth and reduces the load on web server.</p>
                    <p><strong>Intercative web pages</strong> – It provides various functionalities that make web pages interactive. </p>
                    <p>For example – Event call when mouse hover on any element or keyboard key pressed.</p>
                    <p><strong>Javascript is fast</strong> – Because javacript is client side language, so request not sent to the servers that takes time to reach to web server and get back to user with result.</p>
                    <p><strong>Platform independent</strong> – It is platform independent, when you write code on another machine then it will be able to run on another machine.</p>
                    <br/><br/>
                    <h3>Javascript Applications </h3>
                    <p>•	Client-side validation</p>
                    <p>•	Dynamic drop-down menus</p>
                    <p>•	Displaying data and time</p>
                    <p>•	Displaying pop-up windows and dialog boxes (like alert box, confirm box and prompt box).</p>
                    <p>•	Adding or removing elements from the webpage</p>
                    <br/><br/>
                    <h3>Javscript Example –</h3>
                    <br/><br/>
                    Simple example to show javascript syntax.
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;script&gt;<br/>
                    document.write("Hello World");<br/>
                    &lt;/script&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <p>In next chapter we will learn how user can implement javascript in program.</p>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-getting-started">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}