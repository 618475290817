import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class OperatorsInC extends React.Component {
    componentDidMount() {
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Operators</title>
                    <meta name="description" content = "An operator is a symbol that is used to perform some mathematical and logical operations on operands." />
                    <meta name="keywords" content="C Operators,  C Operators Tutorial, Operators , Operators in C, arithmetic operators, bitwise operators, assignment operators, logical operators, introduction to C, concepts, example, C, language, basic, introduction" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Operators" />
                    <meta property="og:description" content="An operator is a symbol that is used to perform some mathematical and logical operations on operands." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-operators" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Operators</h2>
                <br/><br/><br/>
                <p>An operator is a symbol that is used to perform some mathematical and logical operations on operands.</p>
                <p>C language provides following type of operators – </p>
                <p>1.	Assignment Operators</p>
                <p>2.	Arithmetic Operators</p>
                <p>3.	Unary Operators</p>
                <p>4.	Equality and relational Operators</p>
                <p>5.	Logical Operators</p>
                <p>6.	Bitwise Operator</p>
                <p><strong>1.	Assignment Operator</strong> – It is the mot common operator almost used with all the programming languages. It is represented with “=” symbol which is used to assign a valueto a variable lying to the left side of the assignament operator.</p>
                Syntax :
                <p>variable = expression;</p>
                <p><strong>For Example</strong> : int counter=1;</p>
                <p>String=”Trish” ;</p>
                <p>boolean b=true;</p>
                <p><strong>2.	Arithmetic Operators</strong> – These operators are used to perform some mathematical operations like addition, subtraction, multiplication, division, and modulo (or remainder).</p>
                <br/>
                <table border="2" align="center">
                <tbody>
                <tr>
                <th>&nbsp;Symbol</th>
                <th>&nbsp;Operator</th>
                <th>&nbsp;Example</th>
                </tr>
                <tr>
                <td>&nbsp;+</td>
                <td>&nbsp;Addition</td>
                <td>&nbsp;x=a+b;;</td>
                </tr>
                <tr>
                <td>&nbsp;-</td>
                <td>&nbsp;Subtraction</td>
                <td>&nbsp;x=a-b;</td>
                </tr>
                <tr>
                <td>&nbsp;*</td>
                <td>&nbsp;Multiply</td>
                <td>&nbsp;x=a*b;</td>
                </tr>
                <tr>
                <td>&nbsp;/</td>
                <td>&nbsp;Division</td>
                <td>&nbsp;x=a*b;</td>
                </tr>
                <tr>
                <td>&nbsp;%</td>
                <td>&nbsp;Remainder</td>
                <td>&nbsp;x=a%b</td>
                </tr>
                </tbody>
                </table>
                <br/>
                <p><strong>3.	Unary Operators</strong> – Unary Operators require only one operand to perform different operations such as increasing/decreasing or manu operations.</p>
                <p><strong>For Example</strong> - int x=1;</p>
                <p>int y=5;</p>
                <p>X=(+y);</p>
                <br/>
                <table border="2" align="center">
                <tbody>
                <tr>
                <th>&nbsp;Symbol</th>
                <th>&nbsp;Operator</th>
                <th>&nbsp;Description</th>
                </tr>
                <tr>
                <td>&nbsp;+</td>
                <td>&nbsp;Unary plus Operator</td>
                <td>&nbsp;indicates positive value</td>
                </tr>
                <tr>
                <td>&nbsp;-</td>
                <td>&nbsp;Unary minus Operator</td>
                <td>&nbsp;negates an expression</td>
                </tr>
                <tr>
                <td>&nbsp;++</td>
                <td>&nbsp;Decrement Operator</td>
                <td>&nbsp;decrease a value by 1</td>
                </tr>
                <tr>
                <td>&nbsp;--</td>
                <td>&nbsp;Decremeny Operator</td>
                <td>&nbsp;decrease a value by 1</td>
                </tr>
                <tr>
                <td>&nbsp;!</td>
                <td>&nbsp;Logical Complement </td>
                <td>&nbsp;inverts a boolean value</td>
                </tr>
                </tbody>
                </table>
                <br/>
                <p><strong>4.	Relational Operators</strong> – These operators are used to compare the results of two expression or operands whether an operand is equal, not equal, greater than, less than to another operand.</p>
                <br/>
                <table border="2" align="center">
                <tbody>
                <tr>
                <th>&nbsp;Symbol </th>
                <th>&nbsp;Operator</th>
                <th>&nbsp;Operation</th>
                </tr>
                <tr>
                <td>&nbsp;==</td>
                <td>&nbsp;equal to</td>
                <td>&nbsp;a == b</td>
                </tr>
                <tr>
                <td>&nbsp;!=</td>
                <td>&nbsp;not equal to</td>
                <td>&nbsp;a != b</td>
                </tr>
                <tr>
                <td>&nbsp;&lt;</td>
                <td>&nbsp;gretaer than</td>
                <td>&nbsp;a &gt; b</td>
                </tr>
                <tr>
                <td>&nbsp;&lt;</td>
                <td>&nbsp;smaller than</td>
                <td>&nbsp;a &lt; b</td>
                </tr>
                <tr>
                <td>&nbsp;&gt;=</td>
                <td>&nbsp;greater than or equal to</td>
                <td>&nbsp;a&gt;=b</td>
                </tr>
                <tr>
                <td>&nbsp;&lt;=</td>
                <td>&nbsp;smaller than or equal to</td>
                <td>&nbsp;a&lt;=b</td>
                </tr>
                </tbody>
                </table>
                <br/>
                <p><strong>5.	Conditional Operator</strong> – Return a true or false value based on the state of the variables.</p>
                <p><strong>6.	Bitwise and shift operator</strong> - Operators are used to manipulate the contents of variables at a bit level according to binary format.</p>
                <p>There are different type of bitwise operators – </p>
                <br/>
                <table border="2" align="center">
                <tbody>
                <tr>
                <th>&nbsp;Symbol</th>
                <th>&nbsp;Oerator</th>
                <th>&nbsp;Example</th>
                </tr>
                <tr>
                <td>&nbsp;~</td>
                <td>&nbsp;Unary bitwise complement</td>
                <td>&nbsp;~a</td>
                </tr>
                <tr>
                <td>&nbsp;&</td>
                <td>&nbsp;Bitwise AND</td>
                <td>&nbsp;a & b</td>
                </tr>
                <tr>
                <td>&nbsp;|</td>
                <td>&nbsp;Bitwise inclusive OR</td>
                <td>&nbsp;a | b</td>
                </tr>
                <tr>
                <td>&nbsp;^</td>
                <td>&nbsp;Bitwise exclusive OR</td>
                <td>&nbsp;a ^ b</td>
                </tr>
                <tr>
                <td>&nbsp;&lt;&lt;</td>
                <td>&nbsp;Signed left shift</td>
                <td>&nbsp;a &lt;&lt; b</td>
                </tr>
                <tr>
                <td>&nbsp;&gt;&gt;</td>
                <td>&nbsp;Signed right shift</td>
                <td>&nbsp;a &gt;&gt; b</td>
                </tr>
                <tr>
                <td>&nbsp;&gt;&gt;&gt;</td>
                <td>&nbsp;Unsigne right shift</td>
                <td>&nbsp;a &gt;&gt;&gt; b</td>
                </tr>
                </tbody>
                </table>
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-data-types">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}