import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaInterface extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Interface</title>
                    <meta name="description" content="Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword." />
                    <meta name="keywords" content="java tutorial, core java tutorial, Java Interface, java InputOutput, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Interface" />
                    <meta property="og:description" content="Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-interface" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Interface</strong>
                    <br/><br/>
                <p>Through Interface mechanism we can achieve abstraction.</p>
                <p>Interface contains only undefined methods i.e. abstract methods(methods without body). A class can implement interface through implements keyword.</p>
                <p>Interfaces cannot be instantiated, but can be implemented. </p>
                <p>The methods we declare in interface, it is necessary to define them in the class in which we are implementing them otherwise compiler gives an error.</p>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                        <br/>
                        <h4>Syntax:</h4>
                        Interface abc<br/>
                        &#123;<br/>
                            (// only methods)<br/>
                        &#125;<br/>
                        Class xyz implements abc<br/>
                        &#123;<br/>
                            (//methods with body)<br/>
                        &#125;<br/>
                        <br/>
                    </pre>
                </div>
                <br/><br/>
                <p>Multiple Inheritance is not achieved in java, to overcome this problem Interface mechanism is introduced.</p>
                <br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                interface Vehicle&#123;<br/>
                    void car();<br/>
                    void bike();<br/>
                &#125;<br/>
                class InterfaceDemo implements Vehicle&#123;<br/>
                    public void car()&#123;<br/>
                        System.out.println("Car is running...");<br/>
                    public void bike()&#123;<br/>
                        System.out.println("Bike is running..");<br/>
                    &#125;<br/>
                    public static void main(String[] args)<br/>
                    &#123;<br/>
                        Vehicle obj=new InterfaceDemo();<br/>
                        obj.car();<br/>
                        obj.bike();<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                Car is running...
                Bike is running..
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-string">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-inner-class">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}