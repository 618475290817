import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Introduction</title>
                    <meta name="description" content="CSS is used to style your HTML page that are displayed on browser. It is the presentation of the HTML page." />
                    <meta name="keywords" content="css tutorial, css tutorials, tutorials, beginners, professionals, introduction to css, concepts of css, example, tutsfinder, basic, introduction of css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="CSS is used to style your HTML page that are displayed on browser. It is the presentation of the HTML page." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-introduction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/css/css.png" style={{width:`100%`, height:`auto`, border: `groove`}} alt="CSS" />
                    </div>
                    <br/>
                <h2>Introduction</h2>
                    <br/><br/>
                <p>CSS stands for Cascading Style Sheets.</p>
                <p>CSS descbries that how your HTML pages looks like ?</p>
                <p>CSS is used to style your HTML page that are displayed on browser. It is the presentation of the HTML page.</p>
                <p>CSS was introduced in 1996 by the World Wide Web Consortium (W3C).</p>
                <br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>CSS Example that demonstrates how css works – </h5>
                <div className="css-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;style&gt;<br/>
                body&#123;<br/>
                text-align:center;<br/>
                &#125;<br/>
                h1&#123;<br/>
                color:white;<br/>
                background-color:green;<br/>
                padding:10px;<br/>
                &#125;<br/>
                p&#123;<br/>
                color:blue;<br/>
                font-size:20px;<br/>
                &#125;<br/>
                &lt;/style&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;Write Your First CSS Example&lt;/h1&gt;<br/>
                &lt;p&gt;Hello World.&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/>
                Output
                <br/><br/>
                <img src="../img/css/first.png" alt="CSS first program" width="100%" / >
                <br/>
                <br/>
                In above example we have used many CSS properties in internal CSS, we will go through with it in next chapter.
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="how-to-use-css">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
