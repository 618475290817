import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class TwoDTransform extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - 2D Transform</title>
                    <meta name="description" content="CSS Transform property is used to transform the element where we will able to  move, rotate scale and skew elements." />
                    <meta name="keywords" content="2D Transform, css 2D Transform property Tutorials, css 2D Transform, CSS Transform property, Transform property, amazon, aws, goolge, cloud, tutorials, " />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - 2D Transform" />
                    <meta property="og:description" content="CSS Transform property is used to transform the element where we will able to  move, rotate scale and skew elements." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/>
                    <h2>2D Transform</h2>
                    <br/><br/>
                    <p>CSS Transform property is used to transform the element where we will able to  move, rotate scale and skew elements.</p>
                    <p>There are two type of CSS transform property –</p>
                    <p>1.	2D Transform</p>
                    <p>2.	3D Transform</p>

                    <p>CSS 2D Transform is used to  translate, rotate, scale and skew the elements.</p>
                    <h4>Methods of 2D Transform </h4>
                    <p><strong>•	translate()</strong> – It is used to transform an element position along X- axis and Y-axis.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    #translate &#123;<br/>
                    width: 250px;<br/>
                    height: 70px;<br/>
                    background-color: yellow;<br/>
                    border: 1px solid black;<br/>
                    -ms-transform: translate(50px,30px); /* IE 9 */<br/>
                    transform: translate(50px,30px); /* Standard syntax */<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;div id="translate"&gt;<br/>
                    translate() Method<br/>
                    &lt;br&gt;<br/>
                    TutsFinder - Elementary to find<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/2D-Translate.png" alt="2DTranslate" />
                    <br/><br/>
                    <p><strong>•	rotate()</strong> – It is used to rotate the element according to angle.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    div#rotate &#123;<br/>
                    width: 250px;<br/>
                    height: 70px;<br/>
                    background-color: yellow;<br/>
                    border: 1px solid black;<br/>
                    -ms-transform: rotate(20deg); /* IE 9 */<br/>
                    transform: rotate(20deg); /* Standard syntax */<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;div id="rotate"&gt;<br/>
                    rotate() Method<br/>
                    &lt;br&gt;<br/>
                    TutsFinder - Elementary to find<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/2D-Rotate.png" alt="2DTranslate" />
                    <br/><br/>
                    <p><strong>•	scale()</strong> - It is used to increases and decreases the size of an element according to width and height.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    div &#123;<br/>
                    margin: 120px;<br/>
                    width: 250px;<br/>
                    height: 70px;<br/>
                    background-color: yellow;<br/>
                    border: 1px solid black;<br/>
                    -ms-transform: scale(2,3); /* IE 9 */<br/>
                    transform: scale(2,3); /* Standard syntax */<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; Scale() mehod&lt;/h2&gt;<br/>
                    &lt;br&gt;&lt;br&gt;<br/>
                    &lt;div&gt;<br/>
                    scale() Method<br/>
                    &lt;br&gt;<br/>
                    TutsFinder - Elementary to find<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/2D-Scale.png" alt="2DTranslate" />
                    <br/><br/>
                    <p><strong>•	scaleX()</strong> – It increases or decreases the width of the element.</p>
                    <p>For scaleX(), we just need to add only X-axis parameter.</p>
                    Example
                    <pre>
                    -ms-transform: scaleX(2); /* IE 9 */
                    transform: scaleX(2); /* Standard syntax */
                    </pre>
                    <p><strong>•	scaleY()</strong> – It increases or decreases the height of the element.</p>
                    <p>For scaleY(), we just need to add only Y-axis parameter.</p>

                    <pre>
                    -ms-transform: scaleX(2); /* IE 9 */
                    transform: scaleX(2); /* Standard syntax */
                    </pre>
                    <p><strong>•	skew()</strong> – It is used to skew the elements along X-axis and Y-axis.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    div &#123;<br/>
                    width: 250px;<br/>
                    height: 70px;<br/>
                    background-color: yellow;<br/>
                    border: 1px solid black;<br/>
                    <br/>
                    -ms-transform: skew(20deg,10deg); /* IE 9 */<br/>
                    transform: skew(20deg,10deg); /* Standard syntax */<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt;Skew() mehod &lt;/h2&gt;<br/>
                    &lt;br&gt;&lt;br&gt;<br/>
                    &lt;div&gt;<br/>
                    skew() Method<br/>
                    &lt;br&gt;<br/>
                    TutsFinder - Elementary to find<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/2D-Skew.png" alt="2D Skew" />
                    <br/><br/>
                    <p><strong>•	skewX()</strong> – It skews the element along X-axis. </p>
                    <p>We just need to add only one parameter.</p>
                    transform: skewX(10deg);
                    <p><strong>•	skewY()</strong> – It skews the element along Y-axis.</p>
                    <p>We just need to add only one parameter.</p>
                    transform: skewY(10deg);
                    <p><strong>•	matrix()</strong> – All the methods of 2D Transform comes under matrix().</p>
                    <p>Syntax -</p>
                    matrix(scaleX(),skewY(),skewX(),scaleY(),translateX(),translateY())
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    <br/>
                    div &#123;<br/>
                    width: 250px;<br/>
                    height: 70px;<br/>
                    background-color: yellow;<br/>
                    border: 1px solid black;<br/>
                    -ms-transform: matrix(1, -0.3, 0, 1, 0, 0); /* IE 9 */<br/>
                    transform: matrix(1, -0.5, 0, 1, 0, 10); /* Standard syntax */<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; Matrix() method &lt;/h2&gt;<br/>
                    &lt;br&gt;&lt;br&gt;<br/>
                    &lt;div&gt;<br/>
                    Matrix() method<br/>
                    &lt;br&gt;<br/>
                    TutsFinder - Elementary to find<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/2D-Matrix.png" alt="2D Matrix" />
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-flexbox">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-3d-transform">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}