import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaCollection extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Collection</title>
                    <meta name="description" content="In java, all dynamically allocated data structures(such as ArrayList, Vector, LinkedList, Stack, Queue,HashSet) are unites in a integrated architecture called as Java collection framework.Java collection." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Collection" />
                    <meta property="og:description" content="Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-collection" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Collection</strong>
                    <br/><br/>
                <p>In java, all dynamically allocated data structures(such as ArrayList, Vector, LinkedList, Stack,  Queue,HashSet) are unites in a integrated architecture called as Java collection framework.</p>
                <p>Java collection, a name define itself is a collection of interfaces and classes that are used in to manipulate the group of objects.</p>
                <p>Java collection interface provides a interface to store, update, delete the data.</p>
                <p>To use collection framework we have to import java.util.* package.</p>
                <p>Java collection framework package contains- </p>
                <br/>
                <h3>1.	Interfaces-</h3><br/>
                <h4>•	Iterable</h4> <p>Iterbale is the root interface of Java collection API. It is used to travers the list and modify the elements .</p>
                <p>The Collection interface extends the iterable interface and List , Queue , Set extends the Collection interface so therefore all the sub types of collection implements iterable.</p>
                <p> An object of a class that implements the Iterable interface can be used with the Java for-each loop.</p>
                <pre className="pre-tag-program">
                Iterable itr = List.Iterator();<br/>
                while(itr.hasNext()))<br/>
                &#123;<br/>
                    System.out.println(itr.next());<br/>
                &#125;
                </pre>
                <h4>•	Collection</h4> <p>Collection interface defines several interface, methods and classes.</p>
                <pre>
                Interfaces - List, Queue, Set, Deque, SortedSet.<br/>
                Methods - public int size(), public void clear(), public Iterator iterator().<br/>
                Classes - ArrayList, LinkedList, Vector, Stack, PrirorityQueue, ArrayDeque, HashSet, LinkedHashSet, TreeSet etc.
                </pre>
                <br/>
                <h4>•	List</h4><p>It contains list type data structure in which data can be store in order collection and also they can contain duplicate values. </p>
                <h5>Syntax</h5><p>List &lt;data-type&gt; name-of-list=new ArrayList&lt;data-type&gt;();</p>
                <h4>•	Queue</h4><p>It is based on first-come-first-serve.It can contain order list of elements.</p>
                <p>Classes which implements the Queue interface are PriorityQueue, Deque, and ArrayDeque.</p>
                <h5>Syntax</h5><p>Queue &lt;data-type&gt; name-of-list=new PriorityQueue&lt;data-type&gt;();</p>
                <h4>•	Set</h4><p>Set interface stores data in inordered set of elements and does not contain duplicate items.</p>
                <p>Classes which implements Set interface are HashSet, LinkedHashSet, and TreeSet.</p>
                <h5>Syntax</h5><p>Set &lt;data-type&gt; name-of-list=new HashSet&lt;data-type&gt;();</p>
                <h4>•	Deque</h4><p>Deque is double ended queue where can be add and remove from both the ends of queue. Deque extends Queue interface </p>
                <h5>Syntax</h5><p>Deque &lt;data-type&gt; name-of-list=new Deque&lt;data-type&gt;();</p>
                <h4>•	SortedSet</h4><p>It is an ordered version of Set interface where elements are arranged in increasing order.</p>
                <h5>Syntax</h5><p>SortedSet &lt;data-type&gt; name-of-list=new TreeSet&lt;data-type&gt;();</p>
                <br/>
                <h3>2.	Class –</h3><br/>
                <h4>•	ArrayList</h4><p>Implements dynamic array that can store duplicate elements of different data types. It maintains insertion order and elements that are stored can be accessed randomly. </p>
                <h4>•	LinkedList</h4><p>In linked list elements are not stored in contiguous location. It uses doubly linked list to stor data.</p>
                <p> Maintains insertion order and store duplicate items.</p>
                <h4>•	Vector</h4><p> It is same as ArrayList but it is synchronized. Implements dynamic array to store the elements. </p>
                <h4>•	Stack</h4><p> It is based on last-in-first-out. It is a subclass of vector where stack have same methods of vector as well as its own. </p>
                <h4>•	PrirorityQueue</h4><p>A PriorityQueue is used when the objects are supposed to be processed based on the priority of elements. Queue follows first-in-first-out.</p>
                <h4>•	ArrayDeque</h4><p>In ArrayDeque, we can add and remove the data from both the ends. This class implements the Deque interface.</p>
                <h4>•	HashSet</h4><p>It stores unique elements and does not maintain insertion order, it uses a hash table for storage.</p>
                <h4>•	LinkedHashSet</h4><p>It provides the facilties of both hash table and linked list data structure. It contains unique elements and maintains insertion order.</p>
                <h4>•	TreeSet</h4><p>TreeSet stores unique elements in ascending order and it is not thread safe. It stores data in tree format storage.</p>
                <br/><br/>
                <strong>Program in which we can store all types of data. This will give us an overview how we can implements all data structures.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                import java.util.*;<br/>
                class Collection&#123;<br/>
                    public static void main(String[] args)<br/>
                    &#123;<br/>
                        ArrayList&lt;String&gt; al=new ArrayList&lt;String&gt;();<br/>
                        al.add("Trish");<br/>
                        al.add("Rash");<br/>
                        Iterator&lt;String&gt; itr1=al.iterator();<br/>
                        while(itr1.hasNext())&#123;<br/>
                            System.out.println(itr1.next());<br/>
                        &#125;<br/>
                        LinkedList&lt;String&gt; li=new LinkedList&lt;String&gt;();<br/>
                        li.add("Vinay");<br/>
                        li.add("Vikul");<br/>
                        Iterator&lt;String&gt; itr2=li.iterator();<br/>
                        while(itr2.hasNext())&#123;<br/>
                            System.out.println(itr2.next());<br/>
                        &#125;<br/>
                        <br/>
                        Vector&lt;String&gt; vt=new Vector&lt;String&gt;();<br/>
                        vt.add("Vishal");<br/>
                        vt.add("Akash");<br/>
                        Iterator&lt;String&gt; itr3=vt.iterator();<br/>
                        while(itr3.hasNext())&#123;<br/>
                            System.out.println(itr3.next());<br/>
                        &#125;<br/>
                        Stack&lt;String&gt; st = new Stack&lt;String&gt;();<br/>
                        st.push("Deepak");<br/>
                        st.push("Mahesh");<br/>
                        Iterator&lt;String&gt; itr4=st.iterator();<br/>
                        while(itr4.hasNext())&#123;<br/>
                            System.out.println(itr4.next());<br/>
                        &#125;<br/>
                        PriorityQueue&lt;String&gt; qe=new PriorityQueue&lt;String&gt;();<br/>
                        qe.add("Suman");<br/>
                        qe.add("Swapnil");<br/>
                        Iterator&lt;String&gt; itr5=qe.iterator();<br/>
                        while(itr5.hasNext())&#123;<br/>
                            System.out.println(itr5.next());<br/>
                        &#125;<br/>
                        Deque&lt;String&gt; de = new ArrayDeque&lt;String&gt;();<br/>
                        de.add("Pinky");<br/>
                        de.add("Choti");<br/>
                        Iterator&lt;String&gt; itr6=de.iterator();<br/>
                        while(itr6.hasNext())&#123;<br/>
                            System.out.println(itr6.next());<br/>
                        &#125;<br/>
                        HashSet&lt;String&gt; hs=new HashSet&lt;String&gt;();<br/>
                        hs.add("Shubham");<br/>
                        hs.add("Prashant")<br/>
                        Iterator&lt;String&gt; itr7=hs.iterator();<br/>
                        while(itr7.hasNext())&#123;<br/>
                            System.out.println(itr7.next());<br/>
                        &#125;<br/>
                        LinkedHashSet&lt;String&gt; lhs=new LinkedHashSet&lt;String&gt;();<br/>
                        lhs.add("Piyush");<br/>
                        lhs.add("Rohit");<br/>
                        Iterator&lt;String&gt; itr8=lhs.iterator();<br/>
                        while(itr8.hasNext())&#123;<br/>
                            System.out.println(itr8.next());<br/>
                        &#125;<br/>
                        TreeSet&lt;String&gt; ts=new TreeSet&lt;String&gt;();<br/>
                        ts.add("Ajju");<br/>
                        ts.add("Laddoo");<br/>
                        Iterator&lt;String&gt; itr9=ts.iterator();<br/>
                        while(itr9.hasNext())&#123;<br/>
                            System.out.println(itr9.next());<br/>
                        &#125;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-input-output">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-jdbc">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}