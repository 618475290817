import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppEncapsulation extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Encapsulation </title>
                    <meta name="description" content="It is mechanism to encapsulate or wrap up all the data members and member functions into a single entity that is class .It is used to prevent data from directly access. It is on eof the important feature of ObjectOriented that is helps in to data hiding." />
                    <meta name="keywords" content="encapsulation, encapsulation in c++, C++ encapsulation,  C++ encapsulation Tutorial, facebook, google, c++ encapsulation javatpoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Encapsulation " />
                    <meta property="og:description" content="It is mechanism to encapsulate or wrap up all the data members and member functions into a single entity that is class .It is used to prevent data from directly access. It is on eof the important feature of ObjectOriented that is helps in to data hiding." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-encapsulation" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Encapsulation</h2>
                    <br/>
                <p>It is mechanism to encapsulate or wrap up all the data members and member functions into a single entity that is class .It is used to prevent data from directly access. It is on eof the important feature of ObjectOriented that is helps in to data hiding.</p>
                <p>Encapsulation can be achieved in two ways:</p>
                <p>1. By using setter and getter methods.</p>
                <p>2. Making all variables as private.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                class Car &#123;<br/>
                    public:<br/>
                        double printInfo(void) &#123;<br/>
                        cout &lt;&lt; ”Brand is BMW”;<br/>
                    cout &lt;&lt; ”Model is X5”;<br/>
                        &#125;<br/>
                    private:<br/>
                        int price;<br/>
                &#125;;
                </pre>
                </div>
                <br/><br/>
                <p>The variable price is set to private. This means that this can be accessed only by other members of the Car class, and not by any other part of your program. This is one way to achieve encapsulation in C++.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Exp<br/>
                &#123;<br/>
                    private:<br/>
                    int x;<br/>
                    public:<br/>
                    Exp(int a)<br/>
                    &#123;<br/>
                        x =a;<br/>
                    &#125;<br/>
                    int display()<br/>
                    &#123;<br/>
                        return x;<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main()<br/>
                &#123;<br/>
                    Exp a(8);<br/>
                    cout &lt;&lt; "Number is: " &lt;&lt; a.display();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Number is: 8
                </pre>
                </div>
                <br/><br/>
                <p>In the above program, we show how encapsulation works. We have a private member x which cannot be accessed from the main function. The only way to access it is by creating a object of class Exp.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Multiplication &#123;<br/>
                        (// constructor)<br/>
                        public:<br/>
                        Multiplication(int i=1) &#123;<br/>
                        Ans = i;<br/>
                        &#125;<br/>
                        void mul(int num) &#123;<br/>
                        Ans *= num;<br/>
                        &#125;<br/>
                        int getMult() &#123;<br/>
                        return Ans;<br/>
                        &#125;;<br/>
                    private:<br/>
                        (// hidden data from outside world)<br/>
                        int Ans;<br/>
                &#125;;<br/>
                int main() &#123;<br/>
                    Multiplication obj;<br/>
                    obj.mul(5);<br/>
                    obj.mul(2);<br/>
                    obj.mul(3);<br/>
                    cout &lt;&lt; "Ans is : " &lt;&lt; obj.getMult() &lt;&lt; endl;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Ans is : 30
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-abstraction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-polymorphism">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}