import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Fonts</title>
                    <meta name="description" content="CSS font property is used to style the text or control the looks of the text such by ussing this property you can change font color, size." />
                    <meta name="keywords" content="fonts, css fonts, fonts in fonts, cool fonts, sans-serif, font-size, font size, font color, font family, popping fonts, bold font, fonts tutsfinder, w3schools" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Fonts" />
                    <meta property="og:description" content="CSS font property is used to style the text or control the looks of the text such by ussing this property you can change font color, size." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-fonts" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/>
                    <h2>CSS Fonts</h2>
                    <br/><br/>

                    <p>CSS font property is used to style the text or control the looks of the text such by ussing this property you can change font color, size.</p>

                    <p>Some font properties are –</p>

                    <p>1.	font-size</p>
                    <p>2.	font-color</p>
                    <p>3.	font-family</p>
                    <p>4.	font-style</p>
                    <br/>
                    <p><strong>1.	font-color property</strong> – This property is used to color the text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    h1 &#123; color: red; &#125;   // used with color name<br/>
                    h2 &#123; color:  #008000; &#125;   //write only color code<br/>
                    p &#123; color:rgb(0, 255, 255); &#125;   //We can use color rgb fromat<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; TutsFinder &lt;/h1&gt;<br/>
                    &lt;h2&gt; TutsFinder &lt;/h2&gt;<br/>
                    &lt;p&gt; TutsFinder &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/font-color.jpg" alt="Background" />
                    <br/><br/>
                    <p><strong>2.	font-size property</strong> – This property is used to specify the size of the text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; Font Size property &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p style="font-size:200%;"&gt;   set on 100%. &lt;/p&gt;<br/>
                    &lt;p style="font-size:20px;"&gt;   size is 20 pixels.  &lt;/p&gt;<br/>
                    &lt;p style="font-size:xx-small;"&gt;  extremely small.&lt;/p&gt;<br/>
                    &lt;p style="font-size:x-small;"&gt;  extra small&lt;/p&gt;<br/>
                    &lt;p style="font-size:small;"&gt;  small&lt;/p&gt;<br/>
                    &lt;p style="font-size:medium;"&gt;  medium. &lt;/p&gt;<br/>
                    &lt;p style="font-size:large;"&gt;  large. &lt;/p&gt;<br/>
                    &lt;p style="font-size:x-large;"&gt;  extra large. &lt;/p&gt;<br/>
                    &lt;p style="font-size:xx-large;"&gt; extremely large. &lt;/p&gt;<br/>
                    &lt;p style="font-size:smaller;"&gt;  smaller. &lt;/p&gt;<br/>
                    &lt;p style="font-size:larger;"&gt;  larger. &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/font-size.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>3.	font-family property</strong> – a CSS font family property is used to specify a list of prioritized fonts and generic family names.</p>
                    <p>It can be divided in two types – </p>
                    <p>Generic family</p>
                    <p>Font family</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    <br/>
                    .sansserif &#123;<br/>
                    font-family: Arial, Helvetica, sans-serif;<br/>
                    &#125;<br/>
                    <br/>
                    .monospace &#123;<br/>
                    font-family: "Lucida Console", Courier, monospace;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h1&gt;CSS font-family &lt;/h1&gt;<br/>
                    &lt;p className="sansserif"&gt; This is a paragraph, shown in the Arial font. &lt;/p&gt;<br/>
                    &lt;p className="monospace"&gt; This is a paragraph, shown in the Lucida Console font. &lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/font-family.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>4.	font-style property</strong> – This property is used to specify the text that it will be italic, oblique or normal.</p>
                    <p><strong>normal</strong> - The text is shown normally</p>
                    <p><strong>italic</strong> - The text is shown in italics</p>
                    <p><strong>oblique</strong> - The text is "leaning" (oblique is very similar to italic, but less supported).</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    p.normal &#123;<br/>
                    font-style: normal;<br/>
                    &#125;<br/>
                    <br/>
                    p.italic &#123;<br/>
                    font-style: italic;<br/>
                    &#125;<br/>
                    <br/>
                    p.oblique &#123;<br/>
                    font-style: oblique;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;p className="normal"&gt; normal style. &lt;/p&gt;<br/>
                    &lt;p className="italic"&gt; italic style. &lt;/p&gt;<br/>
                    &lt;p className="oblique"&gt; oblique style. &lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/font-style.jpg" alt="Background" width="100%" />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-background">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-text">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}