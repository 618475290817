import React from 'react';
import {Link} from "react-router-dom";
// import GoogleAd from '../Components/GoogleAd';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <div className="popular_program_area section__padding" style={{paddingTop:`0px`,paddingBottom:`0px`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Popular Tutorials</h3>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content" id="nav-tabContent" style={{paddingTop:`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <img src="img/java.jfif" alt="java"/>
                                            </div>
                                            <div className="program__content" style={{paddingTop:`10px`}}>
                                                <span></span>
                                                <h4>Core Java</h4>
                                                <p></p>
                                                <Link to="/java/java-overview" className="boxed-btn5">Learn more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <img src="img/html.png" alt="html"/>
                                            </div>
                                            <div className="program__content" style={{paddingTop:`10px`}}>
                                                <span></span>
                                                <h4>HTML</h4>
                                                <p></p>
                                                <Link to="/html/html-introduction" className="boxed-btn5">Learn more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <img src="img/c++.jpg" alt="c++"/>
                                            </div>
                                            <div className="program__content" style={{paddingTop:`10px`}}>
                                                <span></span>
                                                <h4>C++</h4>
                                                <p></p>
                                                <Link to="/c++/cpp-introduction" className="boxed-btn5">Learn more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <img src="img/php-logo.jpg" alt="php"/>
                                            </div>
                                            <div className="program__content" style={{paddingTop:`10px`}}>
                                                <span></span>
                                                <h4>PHP</h4>
                                                <p></p>
                                                <Link to="/php/php-overview" className="boxed-btn5">Learn more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="course_all_btn text-center">
                                        <Link to="/courses" className="boxed-btn4">View All course</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/>
                        <div className="row pincode-container-homepage">
                            <div className="col-md-6" style={{textAlign: 'center'}}>
                                <img src="img/pincode-image.jpg" alt="pincode"/>
                            </div>
                            <div className="col-md-6" style={{textAlign: 'center', margin: 'auto'}}>
                                <span className="to-check-pincode">To check Pincode</span>
                                <br/><br/>
                                <Link to="/pincode" className="boxed-btn5">Click here!</Link>
                                <br/><br/>
                            </div>
                        </div>
                            <div className="latest_coures_area">
                                <div className="latest_coures_inner">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="coures_info">
                                                    <div className="section_title white_text">
                                                        <h3>Reasoning</h3>
                                                        <p></p>
                                                    </div>
                                                    <div className="coures_wrap d-flex">
                                                        <div className="single_wrap" style={{marginRight:`10px`}}>
                                                            <div className="icon">
                                                                <i className="fa fa-quora"></i>
                                                            </div>
                                                            <h4>Quantitative <br/> Ability </h4>
                                                            <p></p>
                                                            <Link to="/quantitative/quantitative-set-1" className="boxed-btn5">Learn more</Link>
                                                        </div>
                                                        <div className="single_wrap">
                                                            <div className="icon">
                                                                <i className="fa fa-cogs"></i>
                                                            </div>
                                                            <h4>Logical <br/>
                                                                Ability</h4>
                                                            <p></p>
                                                            <a href="/" className="boxed-btn5">Learn more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </>
        )
    }
}
