import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlElements extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Elements </title>
                    <meta name="description" content="HTML elements are the main component of HTML file, which consists of the opening tag " />
                    <meta name="keywords" content="Html elements tutorial, html elements, elements in html, what is element, html element ttsfinder, html element w3school" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Elements" />
                    <meta property="og:description" content="HTML elements are the main component of HTML file, which consists of the opening tag " />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-elements" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Elements</h2>
                    <br/><br/>
                <p>HTML elements are the main component of HTML file, which consists of the opening tag <tag>, closing tag  </tag> and the content between the tags.</p>
                <p>When a tag is opened, content is introduced and the tag is closed, we have an element.</p>
                <br/>
                Example:
                <br/><br/>
                <table className="cont">
                    <tr>
                    <td>OPENING TAG	</td>
                    <td>ELEMENT CONTENT	</td>
                    <td>CLOSING TAG</td>
                    </tr>
                    <tr>
                    <td> &lt;p&gt; </td>
                    <td>Write your paragraph here.</td>
                    <td>&lt;/p&gt;</td>
                    </tr>
                    <tr>
                    <td> &lt;h2&gt; </td>
                    <td>Write your heading here.</td>
                    <td>&lt;/h2&gt;</td>
                    </tr>
                    <tr>
                    <td>
                    &lt;br&gt;</td>
                    <td></td>
                    <td></td>
                    </tr>
                </table>
                <br/><br/>
                <p>HTML elements having no content and closing tag are called empty elements or self closing elements.</p>
                <br/>
                <h5>Empty Element:</h5>
                <br/>
                <p>All the elements in HTML do not require start tag and end tag, some elemets does not have content and closing tag which are known as empty elements or void elements.</p>
                <p>They are also known as unpaired tag.</p>
                <p>Example for empty element:</p>
                <p>&lt;br&gt; : represents a line break</p>
                <p>&lt;ol&gt; : used to make ordered list</p>
                <br/>
                <h5>Nested HTML Elements</h5>
                <br/>
                <p>HTML elements can be nested which means an element can contain another element.</p>
                <p>They are made up of tags, attributes, and content or other elements.</p>
                <br/>
                <p><strong>NOTE:</strong> In nested HTML elements number of elements can be there except empty elements.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example for Nested elements:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt; My first heading &lt;/h1&gt;<br/>
                &lt;p&gt; My first paragraph &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h5>Elucidation of example:</h5>
                <br/>
                <p>The &lt;/html&gt; element defines the whole html file.</p>
                <p>Inside the &lt;/html&gt; element is the &lt;/body&gt; element.</p>
                <p>The body element defines the main content of html file which is displayed on browser.</p>
                <p>Every element is having opening and closing tag.</p>
                <p>Inside the body element there are two more elements named &lt;h1&gt; and &lt;p&gt;.</p>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/program.png" alt="program" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-structure">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-attributes">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}