import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class TypeCastingInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Type Casting</title>
                    <meta name="description" content = "String is a sequence of characters. Strings are used to store text."/>
                    <meta name="keywords" content="C Type Casting,  C Type Casting Tutorial, Type Casting , Type Casting in C, Implicit Type Casting, Explicit Type Casting" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Tutorials - Type Casting" />
                    <meta property="og:description" content="String is a sequence of characters. Strings are used to store text." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-type-casting" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Type Casting</h2>
                    <br/>
                    <p>Convert one data type into another data type is known as type casting or data conversion.</p>
                <p>Two types of typecasting in C –</p>
                <p>1.	Implicit typecasting</p>
                <p>2.	Explicit typecasting</p>
                <br/>
                <p><strong>1.	Implicit typecasting</strong> – Implicit typecasting done automatically by the compiler without any user intervention.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                include&lt;stdio.h&gt;<br/>
                void main()<br/>
                &#123;<br/>
                    int a = 10;<br/>
                    char b = 'x';  //ASCII value of x is 120<br/>
                    <br/>
                    a = a + b;<br/>
                    <br/>
                    float sum = x + 1;<br/>
                    <br/>
                    printf("Addition : %f",sum);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <p><strong>2.	Explicit typecasting</strong> – It is a user defined typecasting, according to their needs user typecast there variables.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                void main()<br/>
                &#123;<br/>
                    float a = 7.8;<br/>
                    <br/>
                    int b = (int)a + 1;<br/>
                    <br/>
                    printf("Value of b is %d\n",b);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/><br/>
                Value of b is 8
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="file-input-output-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-enumeration">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}