import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppDataTypes extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Data Types</title>
                    <meta name="description" content="Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta name="keywords" content="C++ data types,  C++ data types Tutorial, data types in c++, data types, c++ data types tutorialspoint, byju's" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Data Types" />
                    <meta property="og:description" content="Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-data-types" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2>Data Types</h2>
                        <br/>
                    <p>Data type defines the type of variable that which type of variable you are using in you program.</p>
                <p>C language have pre defined set of data types to handle different type of data.</p>
                <p><strong>Eg.</strong> int a;</p>
                <p><strong>Primitive Data type</strong> - Primitive data types are fundamentals data types. There are 8 primitive data types, they are predefined by the language.</p>
                <p>1.	Byte</p>
                <p>2.	Short</p>
                <p>3.	Int</p>
                <p>4.	Long</p>
                <p>5.	Float</p>
                <p>6.	Double</p>
                <p>7.	Boolean</p>
                <p>8.	Char</p>
                <br/>
                <p><strong>Derived Data type</strong> - The data types that are derived from primitive data types are known as derived data types.</p>
                <p>1.	Array</p>
                <p>2.	Pointer</p>
                <br/>
                <p><strong>Enumeration</strong> - Enum is a user defined data type that is used to store constant values that makes a program readable and simple.</p>
                <br/>
                <p><strong>Structure</strong> - It is a user define data type which is used to grouping simple data types.</p>
                <br/>
                <br/>
                <div className="tuts-container">
                <table className="tuts-table-all" align="center" style={{width: `622px`, height: `347px`}} border="2" cellPadding="1">
                <tbody>
                <tr className="tuts-red" style={{height: `20px`}}>
                <th style={{height: `20px`, width: `130.4px`}}>&nbsp;Data Type</th>
                <th style={{height: `20px`, width: `143.2px`}}>&nbsp;Size</th>
                <th style={{height: `20px`, width: `346.4px`}}>&nbsp;Range</th>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;char</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;1 byte</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;-128 to 127 or 0 to 255</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;unsigned char</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;1 byte</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;0 to 255</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;signed char</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;1 byte</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;-128 to 127</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;int</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;2 or 4 bytes</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;-32,768 to 32,767 or -2,147,483,648 to 2,147,483,647</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;unsigned int</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;2 or 4 bytes</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;0 to 65,535 or 0 to 4,294,967,295</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;short</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;2 bytes</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;-32,768 to 32,767</td>
                </tr>
                <tr style={{height: `20.2px`}}>
                <td style={{height: `20.2px`, width: `130.4px`}}>&nbsp;unsigned short</td>
                <td style={{height: `20.2px`, width: `143.2px`}}>&nbsp;2 bytes</td>
                <td style={{height: `20.2px`, width: `346.4px`}}>&nbsp;0 to 65,535</td>
                </tr>
                <tr style={{height: `20px`}}>
                <td style={{height: `20px`, width: `130.4px`}}>&nbsp;long</td>
                <td style={{height: `20px`, width: `143.2px`}}>&nbsp;8 bytes</td>
                <td style={{height: `20px`, width: `346.4px`}}>&nbsp;-9223372036854775808 to 9223372036854775807</td>
                </tr>
                <tr style={{height: `35px`}}>
                <td style={{height: `35px`, width: `130.4px`}}>&nbsp;unsigned long</td>
                <td style={{height: `35px`, width: `143.2px`}}>&nbsp;8 bytes</td>
                <td style={{height: `35px`, width: `346.4px`}}>&nbsp;0 to 18446744073709551615</td>
                </tr>
                <tr>
                <td style={{width: `76.8px`}}>&nbsp;float</td>
                <td style={{width: `51.2px`}}>&nbsp;4 byte</td>
                <td style={{width: `158.4px`}}>&nbsp;1.2E-38 to 3.4E+38</td>
                </tr>
                <tr>
                <td style={{width: `76.8px`}}>&nbsp;double</td>
                <td style={{width: `51.2px`}}>&nbsp;8 byte</td>
                <td style={{width: `158.4px`}}>&nbsp;2.3E-308 to 1.7E+308</td>
                </tr>
                <tr>
                <td style={{width: `76.8px`}}>&nbsp;long double</td>
                <td style={{width: `51.2px`}}>&nbsp;10 byte</td>
                <td style={{width: `158.4px`}}>&nbsp;3.4E-4932 to 1.1E+4932</td>
                </tr>
                </tbody>
                </table>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-variables">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}