import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaAbstractionEncapsulation extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Abstraction and Encapsulation</title>
                    <meta name="description" content="For example: Like ATM Machine, we can see only functionality but don’t know internal details that how it works." />
                    <meta name="keywords" content="java abstraction, java abstraction encapsulation, encapsulation, Abstraction in Java tutorials, beginners, Encapsulation in Java, example, java, abstraction, encapsulation basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Abstraction and Encapsulation" />
                    <meta property="og:description" content="For example: Like ATM Machine, we can see only functionality but don’t know internal details that how it works." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/Java/java-abstraction-encapsulation" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Abstraction</strong>
                    <p>Abstraction is a process to hide the implementation details from the users and showing only the functionality. It displays only essential things.</p>
                <p><strong>For example:</strong> Like ATM Machine, we can see only functionality but don’t know internal details that how it works.</p>
                <p><strong>Advantages:</strong>
                <br/>•	Increases reusability of code by minimizes code redundancy.
                <br/>•	Separates program into code and implementation.
                <br/>•	Increases code readability.
                </p>
                <br/>
                <div className="container" id="java-program">
                <br/>
                <p>
                abstract class Vehicle&#123;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//declare abstract class<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;String model;<br/>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public Vehicle(String model)&#123;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//abstract class with constructor<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Vehicle constructor called");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;this.model=model;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125; <br/>
                class Car extends Vehicle&#123;<br/>
                    public Car(String model)&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;super(model);<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Bike constructor called");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String toString()&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;return "Car model is "+super.model;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;<br/>
                public class Abstraction&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vehicle obj = new Car("BMW");<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(obj);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;	<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;</p>
                <br/>
                </div>
                <br/>
                <strong>Output: </strong>
                <div className="container" id="java-program">
                <p>Vehicle constructor called<br/>
                Bike constructor called<br/>
                Car model is BMW<br/>
                </p>
                </div>
                <br/><br/>
                <p>In java abstraction is achieved in two ways- </p>
                <p>•	By using abstract class</p>
                <p>•	Interface</p>
                <br/>
                <strong style={{fontSize:`25px`}}>Abstract Class</strong>
                <br/>
                <p>Abstract class is declared with abstract keyword– it can contain both abstract and non-abstract methods.</p>
                <p>Abstract methods are methods that can contain methods without body i.e. undefined methods.</p>
                <p>Non-abstract methods are methods that contain methods with body i.e. defined methods.</p>
                <p>Abstract class is used to achieve abstraction.</p>
                <p>Abstract class cannot be instantiated but can be sub classed. </p>
                <p>A simple program to show how abstraction class works in java.</p>
                <div className="container" id="java-program">
                <pre style={{fontSize: `17px`, fontWeight: `400`, lineHeight: `28px`, marginBottom: `13px`, fontFamily: `Poppins, sans-serif`}}>
                {`
                abstract class Vehicle{	        //class with abstract keyword
                    abstract void run();		//abstract method
                    void bike(){				//non-abstract method
                    System.out.println("Bike is running");
                    }
                }
                class Car extends Vehicle{
                    void run(){
                    System.out.println("Car is running");
                    }
                }
                class AbstractClass{
                    public static void main(String[] args){
                        Vehicle obj = new Car();
                        obj.run();
                        obj.bike();
                    }
                }
                `}
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                Car is running
                Bike is running
                </pre>
                </div>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Encapsulation</strong>
                    <br/>
                <p>It is mechanism to encapsulate or wrap up all the data into a single entity it is known as encapsulation.</p>
                <p>Encapsulation can be achieved in two ways:</p>
                <p>1.	By using setter and getter methods.</p>
                <p>2.	Making all variables as private.</p>
                <p><strong>For Example:</strong> Here ATM Machine is a class. It contains both data i.e. money and operations i.e. withdraw money, balance enquiry and they are integrated in a single entity called ATM. This is called Encapsulation.
                </p>
                <div className="container" id="java-program">
                <p>
                class Car&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;private String carColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;private String carModel;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String getColor()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return carColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String getModel()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	return carModel;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public void setColor(String newColor)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	carColor=newColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public void setModel(String newModel)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;carModel=newModel;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;<br/>
                <br/>
                    class Encapsulation&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Car obj = new Car();<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.setModel("BMW");<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.setColor("Black");<br/>
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(obj.getColor()+" "+obj.getModel()+" looks awesome.");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;	<br/>
                &#125;<br/>
                </p>
                </div>
                <br/>
                <strong>Output: </strong>
                <div className="container" id="java-program">
                <p>
                Black BMW looks awesome.
                </p>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-class-and-object">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-inheritance">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}