import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptEvents extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    mouseoverevent(){
        alert("This is TutsFinder");
    }
    clicked() {
        alert('You have clicked a button!');
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Events</title>
                    <meta name="description" content="An event is something that happens. There are various events in HTML that are performed by the user in browser such as onClick, onMouseOver, keydown, keyup." />
                    <meta name="keywords" content="javascript events example, event handlers javascript, javascript events list with examples pdf, javascript event listener, html events, javascript events mdn, event handling in javascript pdf, javascript event listener list" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Events" />
                    <meta property="og:description" content="An event is something that happens. There are various events in HTML that are performed by the user in browser such as onClick, onMouseOver, keydown, keyup." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-events" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Events</h2>
                        <br/><br/>
                    <p>An event is something that happens. There are various events in HTML that are performed by the user in browser such as onClick, onMouseOver, keydown, keyup.</p>
                    <p>Some examples of HTML events:</p>
                    <p>•	An HTML web page has finished loading</p>
                    <p>•	On mouse hover input field changed</p>
                    <p>•	Clickable Button</p>
                    Syntax:
                    <br/>
                    onClick Event &nbsp;&nbsp;
                    <button type="button" onClick={this.clicked}> Click Me </button>
                    <br/>
                    Mouseover event &nbsp;&nbsp; <span onMouseOver={this.mouseoverevent}> Keep cursor over me</span>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt;Focus Event&lt;title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h2&gt; Enter something here &lt;/h2&gt;<br/>
                    &lt;input type="text" id="input1" onFocus="focusevent()"/&gt;<br/>
                    &lt;script&gt;<br/>
                        function focusevent()<br/>
                        &#123;<br/>
                            document.getElementById("input1").style.background=" aqua";<br/>
                        &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>Value of x is greater than 10 </h1>
                    <br/><br/>

                    <h3>• if statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    &#123;
                    (//if condition is true then only code to be executed)
                    &#125;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = 100;<br/>
                    if(x &gt; 10)&#123;<br/>
                    document.write("Value of x is greater than 10");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>Value of x is greater than 10 </h1>
                    <br/><br/>

                    <h3>• if-else statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    &#123;
                    (//code to be executed if condition is true;)
                    &#125;
                    else &#123;
                    (//code to be executed if condition is false;)
                    &#125;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if-else statement&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a=20;<br/>
                    if(a%2==0)&#123;<br/>
                    document.write("Even number");<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    document.write("Odd number");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> Even number</h1>
                    <br/><br/>

                    <h3>• if-else-if statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    &#123;
                    (//code to be executed if condition is true;)
                    &#125;
                    elseif (condition) &#123;
                    (//code to be executed if condition is true;)
                    &#125; else &#123;
                    (//code to be executed if all conditions are false;)
                    &#125;
                    </pre>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if-else-if statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a=7;<br/>
                    if(a==8)&#123;<br/>
                    document.write("Number is equal to 8");<br/>
                    &#125;<br/>
                    else if(a==15)&#123;<br/>
                    document.write("Number is equal to 15");<br/>
                    &#125;<br/>
                    else if(a==7)&#123;<br/>
                    document.write("Number is equal to 7");<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    document.write("Number is not equal to 8, 15 or 7");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>Number is equal to 7  </h1>
                    <br/><br/>

                    <h3>switch statement</h3>
                    Syntax:
                    <pre>
                    Switch case
                    switch(expression)
                    &#123;
                    case value1:
                    (//code to be executed)
                    break;
                    case value2:
                    (//code to be executed)
                    break;
                    ......
                    default:
                    (//code to be executed if all cases are not matched;)
                    &#125;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript Switch Statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var favcolor='red';<br/>
                    var result;<br/>
                    switch (favcolor) &#123;<br/>
                    case "red":<br/>
                    result="Your favorite color is red!";<br/>
                    break;<br/>
                    case "blue":<br/>
                    result="Your favorite color is blue!";<br/>
                    break;<br/>
                    case "green":<br/>
                    result="Your favorite color is green!";<br/>
                    break;<br/>
                    default:<br/>
                    result="Your favorite color is neither red, blue, nor green!";<br/>
                    &#125;<br/>
                    document.write(result);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> Your favorite color is red!</h1>
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-data-types">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-events">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}