import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaClassAndObject extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Class and Object</title>
                    <meta name="description" content="In java , Class is a type that defines the implementation of a particular kind of object." />
                    <meta name="keywords" content="java object, java object And class, class, object or Java tutorials, beginners, Java object and class tutorials, example, java, abstraction, encapsulation basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Class and Object" />
                    <meta property="og:description" content="In java , Class is a type that defines the implementation of a particular kind of object." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-class-and-object" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Class</strong>
                    <p>In java , Class is a type that defines the implementation of a particular kind of object.</p>
                <p>A class is a blue print of the object. A class describes all the attributes and methods of object.</p>
                <p>Class keyword is used to declare the new class.</p>
                <p>
                    <i className="fa fa-circle" aria-hidden="true"></i>  Data(variables) and methods defined within the class.<br/>
                    <i className="fa fa-circle" aria-hidden="true"></i>  Variables and methods are called members of class.<br/>
                    <i className="fa fa-circle" aria-hidden="true"></i>  Data are data members or instance variables of the class.<br/>
                    <i className="fa fa-circle" aria-hidden="true"></i>  Methods are called member functions of the class.
                </p>
                <br/>
                <div className="container" id="java-program">
                <br/>
                <p> class ClassName&#123;
                &nbsp;&nbsp;&nbsp;&nbsp;//code
                &#125;</p>
                <br/>
                </div>
                <br/>
                <br/><hr/>
                    <strong style={{fontSize:`35px`}}>Object</strong>
                <p>Object is an instance of class. It is basic unit of system. An object is an real world entity that has some attributes, behavior and identity. Attributes and behavior of an object are defined by the class definition.</p>
                <p><i>new</i> keyword is used to create the object of the class.</p>
                <p>Objects have states and behaviours -</p>
                <p>•	State defines the characteristic of the object e.g. Car brand is BMW, color Black etc.</p>
                <p>    •	Behaviour defines the functionality of the object e.g. gear change, sun roof open etc.</p>
                <p><strong>Example: </strong> In this example we have created a Car class which has two method carRun() and gearChange(). We have creating a object of Car class and print it.</p>
                <div className="container" id="java-program">
                <p>
                class Car<br/>
                &#123;
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;void carRun()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Car is runing");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;void gearChange()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Car gear change");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Car obj=new Car();<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.carRun();<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.gearChange();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;
                </p>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-constructor">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-abstraction-encapsulation">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}