import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssText extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Text</title>
                    <meta name="description" content="These are text formatting properties that are used in to anipulate text" />
                    <meta name="keywords" content="text, css text, text in css, css text tutsfinder, text formatting tags, text size, text color, text position, w3schools" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Text" />
                    <meta property="og:description" content="These are text formatting properties that are used in to anipulate text" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/>
                    <h2>CSS Text</h2>
                    <br/><br/>

                    <p>In this tutorial we will learn how we can change our text style such as color, size, direction and many more.</p>
                    <p>These are text formatting properties that are used in to anipulate text- </p>
                    <p>Color – Color propert is used to set the color of the text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;p style = "color:blue;"&gt;<br/>
                            Text is in Blue.<br/>
                        &lt;/p&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-color.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>Alignment</strong> -  Alignment property is used to alignn tha text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;p style = "color:red;text-align:center"&gt; Text in red. &lt;/p&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-alignment.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>Letter spacing</strong> – letter spancing property is used to specify the text between the characters of the documents.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;p style = "text-indent:20x;letter-spacing:3px"&gt; Text in red. &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-letter-spacing.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>Direction</strong> – Direction property is used to in which direction text should be.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;p style = "direction:rtl;"&gt; Right to left &lt;/p&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-direction.jpg" alt="Background" width="100%" />
                    <br/><br/>

                    <p><strong>Transformation</strong> – It is used to transform the text in uppercase, lowercase or capitalize.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;style&gt;<br/>
                    p.uppercase &#123;<br/>
                    text-transform: uppercase;<br/>
                    &#125;<br/>
                    <br/>
                    p.lowercase &#123;<br/>
                    text-transform: lowercase;<br/>
                    &#125;<br/>
                    <br/>
                    p.capitalize &#123;<br/>
                    text-transform: capitalize;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;p className="uppercase"&gt; This is some text. &lt;/p&gt;<br/>
                    &lt;p className="lowercase"&gt; This is some text. &lt;/p&gt;<br/>
                    &lt;p className="capitalize"&gt; This is some text. &lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-transform.jpg" alt="Background" width="100%" />
                    <br/><br/>
                    <p><strong>Shadow</strong> – This property is used to set shadow around a text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                        &lt;p style = "text-shadow:4px 4px 8px grey;"&gt; This text will have a  blue shadow. &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-shadow.jpg" alt="Background" width="100%" />
                    <br/><br/>
                    <p><strong>Text Decoration</strong> – Text decoration property is used to set decoration to the text to underline, overline, and strikethrough text.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;/body&gt;
                        &lt;p style = "text-decoration:underline;"&gt; Underlined text &lt;/p&gt;<br/>
                        <br/>
                        &lt;p style = "text-decoration:line-through;"&gt; Striked text &lt;/p&gt;<br/>
                        <br/>
                        &lt;p style = "text-decoration:overline;"&gt; Over line text &lt;/p&gt;<br/>
                        <br/>
                        &lt;p style = "text-decoration:blink;"&gt; Blinking effect &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-decoration.jpg" alt="Background" width="100%" />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-fonts">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-links">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}