import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Arrays</title>
                    <meta name="description" content="Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, tutorials, Array, professionals, java array, core, concepts, example, java, language, basic, introduction" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Arrays" />
                    <meta property="og:description" content="Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-array" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <hr/>
                <strong style={{fontSize:`25px`}}>Arrays in Java</strong>
                <br/><br/>
                <p>Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values.</p>
                <p>Array uses static memory allocation. To access element from array you must have to mention its index value corresponding to the position of the element. We can easily access data in array.</p>
                <p>The index of array always starts from 0.</p>
                <p>But there is a disadvantage in array that we can store only fixed amount of elements. We cannot change size of an array at runtime. To overcome this problem, there is concept of Java Collections. Later in this tutorial we will study that.</p>
                <p>There are two types of arrays –</p>
                <p>1.	One Dimensional</p>
                <p>2.	Multi Dimensional</p>
                <h3>Array Declaration and Initialization</h3>
                <p>If you want use array in your program then first declare variable and initialize the value to that variable.</p>
                <p><strong>Syntax:</strong> dataType array_name[]=&#123;&#125;;</p>
                <p><strong>Example:</strong> int myarray [] = &#123; 7,8,4,21 &#125;;</p>
                <br/>
                <strong>Array First Program</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
{`
class ArrayExample{
    public static void main(String args[]){
        int array[] = new int[5];
        for (int i=0 ; i&lt;5 ; i++){
            array[i] = i+1;
        }
        for(int i=0 ; i&lt;5 ;i++){
            System.out.println("array["+i+"] = "+array[i]);
        }
    }
}
`}
                    </pre>
                </div>
                <br/><br/>
                <strong>Output: </strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    {`
array[0] = 1
array[1] = 2
array[2] = 3
array[3] = 4
array[4] = 5
                    `}
                    </pre>
                </div>
                <br/><br/>
                <strong>Array Program to find the length of an array.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    {`
class ArrayLength
{
    public static void main(String[] args)
    {
        int arr[]={12,2,5,8,4,6,32};
        System.out.println("Length of an array is: "+arr.length);
    }
}
                    `}
                    </pre>
                </div>
                <br/><br/>
                <strong>Output: </strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                        <br/>
                        Length of an array is: 7
                    </pre>
                </div>
                <br/>
                <br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-association">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-string">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}