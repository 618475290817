import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JDKJREJVM extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - JDK JRE JVM</title>
                    <meta name="description" content="JVM and JRE are parts of Java JDK. JDK contains JRE (and JRE contains JVM) and other libraries and development tools (such as javac and java)." />
                    <meta name="keywords" content="java tutorial, core java tutorial, Java String, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials -JDK JRE JVM" />
                    <meta property="og:description" content="JVM and JRE are parts of Java JDK. JDK contains JRE (and JRE contains JVM) and other libraries and development tools (such as javac and java)." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/jdk-jre-jvm" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>JDK, JRE, JVM</strong>
                    <br/>
                    <br/>
                    <h3>JDK</h3>
                    <p>It is an acronym of Java Development Kit.  JDK provides development tools to develop java applications and it also provides runtime environment. It contains all the tools and libraries that help in to execute, debug and run the program.
                        </p>
                        <p>JDK is an implementation; there are distinct installers for Windows, Linux and Mac that’s why Java is platform dependent. </p>
                        <p>JVM and JRE are parts of Java JDK.
                JDK contains JRE (and JRE contains JVM) and other libraries and development tools (such as javac and java).
                </p>
                    <img src="../img/java/jdk.png" alt="jdk" style={{maxWidth:`100%`}} />
                    <br/><br/>
                    <h3>JRE</h3>
                <p>JRE is an acronym of Java Runtime Environment. JRE provides libraries, Java Virtual Machine and other components to run applets and applications. JRE is the implementation of JVM.</p>
                <p>JRE provides a runtime environment in which java program can be executed. JRE contains libraries and other files that help in execution of program</p>
                    <img src="../img/java/jre.png" alt="jre" style={{maxWidth:`100%`}} />
                    <br/><br/>
                    <h3>JVM</h3>
                <p>JVM provides a runtime environment in which our byte code can be executed. It is responsible to convert byte code into machine language.</p>
                <p>JVM is a virtual machine that enables computers to run java programs. It is not physically exist that’s why it is known as abstract machine.</p>
                <p>JVM is also responsible to perform these tasks:
                <br/>•	Loads code
                <br/>•	Verifies code
                <br/>•	Executes code
                <br/>•	Provides runtime environment
                </p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="first-java-program">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-keywords">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}