import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlCanvas extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Canvas</title>
                    <meta name="description" content="Anchor tag is used to create a link between web pages, files, location, or any URL." />
                    <meta name="keywords" content="canvas in tml, canvas tutsfinder, canvas, Html canvas, Html canvas Tutorial, tutorialspoint, programiz" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Canvas" />
                    <meta property="og:description" content="Anchor tag is used to create a link between web pages, files, location, or any URL." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-canvas" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Canvas</h2>
                    <br/><br/>
                <p>HTML Canvas element is the part of graphics which is used to draw a graphics on a web page.</p>
                <p>The graphics which can be created usings canvas are like a red rectangle, a gradient rectangle, a multicolor rectangle, multicolor text etc.</p>
                <p>HTML Canvas is used to draw graphics using Javascript.</p>
                <p>The &lt;canvas&gt; tags works only as a container for graphics, for drawing the graphics javascript is used.</p>
                <p>Canvas has several methods for drawing paths, boxes, circles, text etc.</p>
                <br/>
                Syntax:
                <br/>
                <pre>
                &lt;canvas id = “myCanvas” width =”100” height= “200”&gt;&lt;/canvas&gt;
                </pre>
                <p>By default a canvas has no border and no content, if you want to add you can use style attribute.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples for HTML Canvas:<br/><br/>Draw a circle:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;canvas id="demo" width="200" height="100" style="border:1px solid #d3d3d3;"&gt;&lt;/canvas&gt;<br/>
                &lt;script&gt;<br/>
                var x = document.getElementById("demo");<br/>
                var ctx = x.getContext("2d");<br/>
                ctx.beginPath();<br/>
                ctx.arc(100,50,45,0,2*Math.PI);<br/>
                ctx.stroke();<br/>
                &lt;/script&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/canvas.png" alt="canvas" style={{width:`100%`}} />
                <br/><br/>
                Draw Linear Gradient
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples: </h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;canvas id="demo" width="200" height="130" style="border:1px solid #d3d3d3;"&gt;&lt;/canvas&gt;<br/>
                &lt;script&gt;<br/>
                var x = document.getElementById("demo");<br/>
                var ctx = x.getContext("2d");<br/>
                var gradient = ctx.createLinearGradient(0,0,200,0);<br/>
                gradient.addColorStop(0,"green");<br/>
                gradient.addColorStop(1,"white");<br/>
                ctx.fillStyle = gradient;<br/>
                ctx.fillRect(20,20,150,80);<br/>
                &lt;/script&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/gradient.png" alt="canvas" style={{width:`100%`}} />
                <br/><br/>
                <h4>Stroke Text:</h4>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples: </h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;canvas id="demo" width="200" height="100" style="border:1px solid #d3d3d3;"&gt;&lt;/canvas&gt;<br/>
                &lt;script&gt;<br/>
                var x = document.getElementById("demo");<br/>
                var ctx = x.getContext("2d");<br/>
                ctx.font = "30px Arial";<br/>
                ctx.strokeText("Stroke Text",10,50);<br/>
                &lt;/script&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/stroke.png" alt="canvas" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-javascript">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-svg">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}