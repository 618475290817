import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlAttributes extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Attributes</title>
                    <meta name="description" content="An attribute is used to define the characteristics of an HTML element and is always placed inside the opening tag containing two parts a name and a value." />
                    <meta name="keywords" content="what is html attributes , attributes in html, Html attributes, Html attribute Tutorial, html attributes w3schools, udemy " />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Attributes" />
                    <meta property="og:description" content="An attribute is used to define the characteristics of an HTML element and is always placed inside the opening tag containing two parts a name and a value." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-attributes" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Attributes</h2>
                    <br/><br/>
                <p>We have studied tags in previous chapters, likewise html elements can also have attributes which are extra bits of information. Tags comes in simpler way like &lt;p&gt;, &lt;h1&gt;, &lt;title&gt;.</p>
                <p>An attribute is used to define the characteristics of an HTML element and is always placed inside the opening tag containing two parts a name and a value.</p>
                <p>•	The name is the property you want to set.</p>
                <p>•	The value is what you want the value of property to be set and it should be always put within qoutations.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>HTML example for Attribute:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Align Attribute Example &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p align= “left”&gt; This is left aligned &lt;/p&gt;<br/>
                &lt;p align= “center”&gt; This is center aligned &lt;/p&gt;<br/>
                &lt;p align= “right”&gt; This is right aligned &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/attributes.png" alt="Attributes" style={{width:`100%`}} />
                <br/><br/>
                <h4>Description for HTML example:</h4>
                <br/>
                <p>In the above example the paragraph &lt;p&gt; element carries an attribute whose name is align, which is used to indicate the alignment of paragraph on the page.</p>
                <p>For the value in above example shows three possible values of align attribute i.e. left, center and right.</p>
                <p>Attribute names and Attribute values are case sensitive. However, we at Tutsfinder recommend lowercase attribute names.</p>
                <h4>Some main Attributes of HTML</h4>
                <br/>
                <h5>HTML Title Attribute</h5>
                <br/>
                <p>The title attribute is used as text tooltip in most of the browsers. It display its text when user move the cursor over a link or any text. You can use it with any text or link to show the description about that link or text.</p> 
                Example:
                <br/>
                <pre>
                &lt;p title= “I’m a toottip”&gt;
                This is a Paragraph.
                &lt;/p&gt;
                </pre>
                <br/>
                <h5>HTML Style Attribute</h5>
                <br/>
                <p>The style attribute allows you to specify Cascading Style Sheet (CSS) rule within the element.</p>
                Example:
                <br/>
                <pre>
                &lt;p style= “color:orange&gt;
                This is a Paragraph.
                &lt;/p&gt;
                </pre>
                <h5>HTML alt Attribute</h5>
                <p>The alt attribute specifies and alternative text to be used, if an image cannot be displayed.</p>
                <p>The value of the alt attribute can be read by screen readers.</p>
                Example:
                <br/>
                &lt;img src =“img_scenery.jpg” alt=”House with a lobby”&gt;
                <br/><br/>
                <h5>HTML class Attribute</h5>
                <p>The class attribute is used to associate an element with a style sheet, and specifies the class of element, it is basically used In CSS.</p>
                Example:
                <br/>
                <pre>
                className= “className1 className2 className3”
                </pre>
                <br/>
                <h5>Below table consisting of some other attributes that are commonly used.</h5>
                <br/><br/>
                <table className="cont">
                <tr>
                <th>Attribute</th>	<th>Options</th>	<th>Function</th>
                </tr>
                <tr>
                <td>align</td>	<td>right, left, center</td>	<td>Horizontally aligns tags</td>
                </tr>
                <tr>
                <td>valign</td>	<td>top, middle, bottom</td>	<td>Vertically aligns tags within an HTML element.</td>
                </tr>
                <tr>
                <td>bgcolor</td>	<td>numeric, hexidecimal, RGB values</td>	<td>Places a background color behind an element</td>
                </tr>
                <tr>
                <td>background</td>	<td>URL</td>	<td>Places a background image behind an element</td>
                </tr>
                <tr>
                <td>id</td>	<td>User Defined</td>	<td>Names an element for use with Cascading Style Sheets.</td>
                </tr>
                <tr>
                <td>width</td>	<td>Numeric Value</td>	<td>Specifies the width of tables, images, or table cells.</td>
                </tr>
                <tr>
                <td>height</td>	<td>Numeric Value</td>	<td>Specifies the height of tables, images, or table cells.</td>
                </tr>
                </table>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-elements">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-text-format">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}