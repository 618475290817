import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppArrays extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Arrays</title>
                    <meta name="description" content="An array is a group of elements forming a complete building block." />
                    <meta name="keywords" content="arrays, C++ arrays,  C++ arrays Tutorial, tutorialspoint arrays, C++ arrays Tutorialspoint, c++ arrays tutsfinder" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Arrays" />
                    <meta property="og:description" content="An array is a group of elements forming a complete building block." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-arrays" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <h2>Arrays</h2>
                    <br/>
                    <p>An array is a group of elements forming a complete building block.</p>
                <p>The following are characteristics of an array –</p>
                <p>•	An array is a collection of elements.</p>
                <p>•	All elements contained in an array are of same data type.</p>
                <p>•	This collection forms a complete set.</p>
                <p>In C++, arrays enable you to store data elements of a type in the memory, in a sequential order.</p>
                <br/>
                <h3>Array Declaration and Initialization</h3>
                <p>If you want use array in your program then first declare variable and initialize the value to that variable.</p>
                <p><strong>Syntax:</strong> dataType array_name[]={ };</p>
                <p><strong>Example:</strong> int myarray [] = &#123; 7,8,4,21&#125;;</p>
                <br/>
                <h3>Need for Arrays –</h3>
                <p>Imagine that you are writing a program to display the 5 different types of integers. Declaration would look like this – </p>
                <pre>
                int a=0;
                int b=0;
                int c=0;
                int d=0;
                int e=0;
                </pre>
                <p>If your user wants this program to store and display 100 integers value, now first you have to declare 100 integers using the preceding system. This would be very time consuming. So there in concept that is array.</p>
                <p><strong>Declaring an array of 5 integers – </strong></p>
                <p>Int arr[5]={0};</p>
                Program to declaring an array of integers and accessing its elements
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    int arr[5]=&#123;12,45,18,7,8&#125;;<br/>
                    cout &lt;&lt; "First element at index 0: " &lt;&lt; arr[0] &lt;&lt; endl;<br/>
                    cout &lt;&lt; "Second element at index 1: " &lt;&lt; arr[1] &lt;&lt; endl;<br/>
                    cout &lt;&lt; "Third element at index 2: " &lt;&lt; arr[2] &lt;&lt; endl;<br/>
                    cout &lt;&lt; "Fourth element at index 3: " &lt;&lt; arr[3] &lt;&lt; endl;<br/>
                    cout &lt;&lt; "Fifth element at index 4: " &lt;&lt; arr[4] &lt;&lt; endl;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Accessing elements by traversing –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    int arr[5]=&#123;12,45,18,7,8&#125;;<br/>
                    cout &lt;&lt; "Elements of an array" &lt;&lt; endl;<br/>
                    for(int i=0;i&lt;5;i++)<br/>
                    cout &lt;&lt; arr[i]&lt;&lt;"\n";<br/>
                &#125;
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" >
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-functions">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6" >
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-strings">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}