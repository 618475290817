import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PreProcessors extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Pre Processors</title>
                    <meta name="description" content = "Pre-processors provides the functionality to program to integrate header files."/>
                    <meta name="keywords" content="Pre Processors directives, directives, C Pre Processors,  C Pre Processors Tutorial, Pre Processors , #include, #def, #undef, #ifdef" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Pre Processors" />
                    <meta property="og:description" content="An operator is a symbol that is used to perform some mathematical and logical operations on operands." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-pre-processors" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Pre Processors</h2>
                <br/>
                <p>Pre-processors provides the functionality to program to integrate header files.</p>
                <p>It is macro processor that is used automatically by the compiler.</p>
                <p>All preprocessor commands start with # symbol.</p>
                <br/>
                <h4>Directives</h4>
                <br/>
                <p>Directives specifies how its input should process by the compiler.</p>
                <p>Pre-processors directives are the line in our program that starts with # symbol.</p>
                <p><strong>Eg.</strong> #include, #define.</p>
                <br/>
                <h3>Important pre-processor directives</h3>
                <br/>
                <p><strong>#define</strong> – It allows the definition of macros. It is used to give a contant value to the program.</p>
                <p>Before use a macro, first you have to explicitly define the #define directive with name of the macro.</p>
                <p><strong>Eg.</strong> #define MAX 50</p>
                <br/>
                <p><strong>#include</strong> – This directive is used to include the header file(system and user header files).</p>
                <br/>
                <p>It can be define in 3 ways – </p>
                <p>1.	#include &lt;file&gt;	</p>
                <p>2.	#include “file”</p>
                <p>3.	#include anything else </p>
                <p><strong>#ifndef</strong> – One header file include anothers. It returns true if this macro is not defined.</p>
                <br/>
                Syntax:
                <br/>
                <p>#ifndef macro_definition</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                <br/>
                #define TutsFinder 1<br/>
                #ifndef TutsFinder<br/>
                #define TutsFinder 2<br/>
                #endif<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    printf(" %d ", TutsFinder);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/>
                1
                <br/><br/>
                <p>In above program, we have used ifndef before TustFinder 2, that’s why its print the vale TutsFinder 2.</p>
                <p>If we remove TutsFinder 1, than it’s output will be TutsFinder 2.</p>
                <p><strong>#undef</strong> -It is used to tell the pre-processor to remove all definitions from the macro.</p>
                <p>Once a  macro is undefined, again it is define only by #ifdef directive.</p>
                <br/>
                Syntax:
                <br/>
                <p>#undef macro_definition</p>
                <br/>
                Example :
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                <br/>
                #define TutsFinder 7<br/>
                <br/>
                #undef TutsFinder<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    #ifdef TutsFinder<br/>
                    printf("%d \n", TutsFinder);<br/>
                    #endif<br/>
                    <br/>
                    printf("Program for #undef ");<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Program for #undef
                </pre>
                </div>
                <br/><br/>
                In above program, we have undefined the define method so it skips that function and print printf function.
                <p><strong>#ifdef</strong> – The pre-processors returns true if the specified macro is defined.</p>
                <br/>
                Syntax :
                <br/>
                <p>#ifdef macro_definition</p>
                <br/>
                Example –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                <br/>
                #define TutsFinder 7<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    #ifdef TutsFinder<br/>
                    printf("%d\n", TutsFinder);<br/>
                    #endif<br/>
                    <br/>
                    printf("Program for #ifdef\n");<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                7<br/>
                Program for #ifdef
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-enumeration">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="error-handling-in-c">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}