import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpEchoPrint extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Echo Print</title>
                    <meta name="description" content = "Echo and print both statements are used to output the string, they both are same." />
                    <meta name="keywords" content="php echo print, echo print, echo, echo in php, php echo, echo print tutsfinder, echo print php, messenger, google" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Echo Print" />
                    <meta property="og:description" content="Echo and print both statements are used to output the string, they both are same." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-echo-print" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <p>In this tutorial we will learn about php and echo statements to display the output.</p>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP echo/print</strong>
                    <br/><br/>
                    <p>Echo and print both statements are used to output the string, they both are same.</p>
                    <p>The only difference between them is – print has return value of 1 while echo does not have any return value. Echo can take multiple parameters while print can take only 1 parameter.</p>

                    <h1>PHP echo statement</h1>
                    <p>PHP echo statement can output one or more strings. It can be used with echo() or without parenthesis echo.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example - Display String</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo "Hello World!";<br/>
                    echo "TutsFinder";<br/>
                    echo "Elementary way to find";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example- Display HTML code</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo "&lt;h1&gt;Hello World!&lt;/h1&gt;";<br/>
                    echo "&lt;h2&gt;TutsFinder&lt;/h2&gt;";<br/>
                    echo "&lt;p&gt;Elementary way to find&lt;/p&gt;";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example – Display variables</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Defining variables)<br/>
                    $txt = "Hello World!";<br/>
                    $num = 12345678;<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    (// Displaying variables)<br/>
                    echo $txt; echo "<br/>";<br/>
                    echo $num; echo "<br/>";<br/>
                    echo $city[2];<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><hr/><br/>

                    <h1>PHP print statement</h1>
                    <p>An alternative to echo is print statement which is also used to output the strings.</p>
                    <p>We can also use print statement with or without parenthesis: print or print().</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example - Display String</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Displaying string of text)<br/>
                    print "Hello World!";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example - Display HTML code</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Displaying HTML code)<br/>
                    print "&lt;h1&gt;TutsFinder&lt;/h1&gt;";<br/>
                    print "&lt;h2 style='color: red;'&gt;Elementary way to find.&lt;/h2&gt;";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example - Display Variables</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Defining variables)<br/>
                    $txt = "Hello World!";<br/>
                    $num = 12345678;<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    (// Displaying variables)<br/>
                    print $txt; print "<br/>";<br/>
                    print $num; print "<br/>";<br/>
                    print $city[2];<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-variables-and-constants">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-data-types">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}