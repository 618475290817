import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Flexbox</title>
                    <meta name="description" content="CSS Flexbox allows you to make flexible layout that can allign vertically and horizontally using rows and columns." />
                    <meta name="keywords" content="flexbox, css flexbox, flexbox in css, css flexbox tutsfinder, css flexbox w3school, w3school" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Flexbox" />
                    <meta property="og:description" content="CSS Flexbox allows you to make flexible layout that can allign vertically and horizontally using rows and columns." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-flexbox" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Flexbox</h2>
                    <br/><br/>

                    <p>CSS Flexbox allows you to make flexible layout that can allign vertically and horizontally using rows and columns.</p>
                    <h4>Flexbox properties –</h4>
                    <p><strong>flex-wrap property</strong> – Wrap property specefies that elements are appear in single line or multiple line.</p>
                    flex-wrap: nowrap | wrap | wrap-reverse;
                    <br/>
                    <p><strong>flex-direction property</strong> - It specefies the direction of the flex means in which direction container is show.</p>
                    flex-direction: row | row-reverse | column | column-reverse;
                    <br/>
                    <p><strong>flex-flow property</strong> – It is used to set flex-wrap and flex-direction property both together.</p>
                    flex-flow: &lt;‘flex-direction’&gt; || &lt;‘flex-wrap’&gt;
                    <br/>
                    <p><strong>flex-grow property</strong> – It defines that how much flex is stretch when there is available space.</p>
                    flex-grow: 1,2,3……;
                    <br/>
                    <p><strong>align-items property</strong> – It defines the alignment of items according to cross-axis.</p>
                    align-items: flex-start | flex-end | center | baseline | stretch;
                    <br/>
                    <p><strong>align-content</strong> – It is used to align the multiple flex lines. It will only work when flex-wrap is available.</p>
                    align-content: flex-start | flex-end | center | space-between | space-around | stretch;
                    <br/>
                    <p><strong>justify-content property</strong> – It is used to align items according to main axis with in a container.</p>
                    justify-content: flex-start | flex-end | center | space-between | space-around;
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-transition">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-2d-transform">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}