import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptDataTypes extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Data types</title>
                    <meta name="description" content="Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta name="keywords" content="non primitive data types in javascript, types of javascript, javascript object, javascript data structures, types of javascript frameworks, data types in javascript tutorialspoint, javascript variables, javascript type keyword, string, boolean data type, integer, array data structure, object, floatingp arithmetic" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Data Types" />
                    <meta property="og:description" content="Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-data-types" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Data Types</h2>
                    <br/><br/>
                    <p>Data type defines the type of variable that which type of variable you are using in you program.</p>
                    <h3>Types of DataTypes</h3>
                    <p>There are two types of Data Types in JavaScript:</p>
                    <p>1.	Primitive data type</p>
                    <p>2.	Non-Primitive data type</p>
                    <p>Javascript is a dnynamic language so there is no need to specify the type of the variable. Just simply use var keyword to specify the data type.</p>
                    Syntax
                    <p>var a = 10; //Numeric values</p>
                    <p>var b = "Trish"; //string</p>
                    <p><strong>String</strong> -	represents sequence of characters e.g. "hello"</p>
                    <p><strong>Number</strong> -	represents numeric values e.g. 100</p>
                    <p><strong>Boolean</strong> -	represents boolean value either true or false</p>
                    <p><strong>Undefined</strong> -	represents undefined value</p>
                    <p><strong>Null</strong> -	represents null i.e. no value at all</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript Numeric DataType&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a = 10;<br/>
                    document.write(a);<br/>
                    document.write( " Trish ");<br/>
                    document.write(78.123456789E+1234);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> 10 Trish Infinity</h1>
                    <br/><br/>
                    Boolean data types

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript Boolean Datatype&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a = true;<br/>
                    var b = false;<br/>
                    document.write(a);<br/>
                    document.write(b);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    truefalse
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-variables">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-control-flow">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}