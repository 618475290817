import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlSVG extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - SVG </title>
                    <meta name="description" content="SVG is also a category of HTML graphics which defines vector based graphics in XML format." />
                    <meta name="keywords" content="html scalar vector graphics, scalar vector graphics, Html svg, Html svg Tutorial, website , tutorials, beginners, professionals, introduction to Html, concepts, example, Html, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - SVG" />
                    <meta property="og:description" content="SVG is also a category of HTML graphics which defines vector based graphics in XML format." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-svg" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML SVG</h2>
                    <br/><br/>
                <p>SVG is also a category of HTML graphics which defines vector based graphics in XML format.</p>
                <p>SVG stands for Scalable Vector Graphics. SVG is used to define graphics for the web.</p>
                <br/>
                <h4>The HTML &lt;svg&gt; Element</h4>
                <p>It is a container for SVG graphics.</p>
                <p>SVG has several methods  for drawing paths, boxes, circles, text, and graphic images.</p>
                <br/>
                Syntax:
                <br/>
                &lt;svg width =”100” height = “100”&gt;&lt;/svg&gt;
                <br/>
                <h4>SVG Rectangle</h4>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;svg width="500" height="200"&gt;<br/>
                &lt;rect width="500" height="200"
                    style="fill:rgb(0,255,255);stroke-width:10;stroke:rgb(0,0,0)" /&gt;<br/>
                &lt;/svg&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <svg width="500" height="200">
                <rect width="500" height="200"
                    style={{fill:`rgb(0,255,255)`, strokeWidth:`10`, stroke:`rgb(0,0,0)`}} />
                </svg>
                <br/><br/>
                <h4>SVG Rounded Rectangle</h4>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;svg width="500" height="200"&gt;<br/>
                &lt;rect x="50" y="20" rx="20" ry="20" width="150" height="150"
                style="fill:purple;stroke:black;stroke-width:5;opacity:0.5" /&gt;<br/>
                &lt;/svg&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <svg width="500" height="200">
                <rect x="50" y="20" rx="20" ry="20" width="150" height="150"
                style={{fill:`purple`, stroke:`black`, strokeWidth:`5`, opacity:`0.5`}} />
                </svg>
                <br/><br/>
                <h4>SVG Star</h4>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Examples for HTML Canvas:<br/><br/>Draw a circle:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;svg width="500" height="200"&gt;<br/>
                &lt;polygon points="100,10 40,198 190,78 10,78 160,198"
                style="fill:yellow;stroke:purple;stroke-width:5;fill-rule:evenodd;" /&gt;<br/>
                &lt;/svg&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <svg width="500" height="200">
                <polygon points="100,10 40,198 190,78 10,78 160,198"
                style={{fill:`yellow`, stroke:`purple`, strokeWidth:`5`, fillRule:`evenodd`}} />
                </svg>
                <br/><br/>
                <p>As we study two types of Graphic element in HTML SVG and Canvas, let us now study about the difference between both.</p>
                <p>SVG is a language for describing 2D graphics in XML.</p>
                <p>Canvas draws 2D graphics, on the fly (with a JavaScript).</p>
                <p>SVG is XML based, which means that every element is available within the SVG DOM. You can attach JavaScript event handlers for an element.</p>
                <p>In SVG, each drawn shape is remembered as an object. If attributes of an SVG object are changed, the browser can automatically re-render the shape.</p>
                <p>Canvas is rendered pixel by pixel. In canvas, once the graphic is drawn, it is forgotten by the browser. If its position should be changed, the entire scene needs to be redrawn, including any objects that might have been covered by the graphic.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-canvas">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-multimedia">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}