import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Introduction</title>
                    <meta name="description" content="Links are hyperlinks, with this we can style our links such as link color, hover effect." />
                    <meta name="keywords" content="css link style examples, css underline, free css links, css link color, css remove underline from link, css hover, html link color, link html" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="Links are hyperlinks, with this we can style our links such as link color, hover effect." />
                    <meta property="og:site_name" content="https://www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-links" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Links</h2>
                    <br/><br/>

                    <p>Links are hyperlinks, with this we can style our links such as link color, hover effect.</p>
                    <p>There are four link states:</p>
                    <p>•	a:link - unvisited link</p>
                    <p>•	a:visited – visited link</p>
                    <p>•	a:hover - a link when the user mouses over it</p>
                    <p>•	a:active - a link the moment it is clicked</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    (/* unvisited link */)<br/>
                    a:link &#123;<br/>
                    color: red;<br/>
                    &#125;<br/>
                    <br/>
                    (/* visited link */)<br/>
                    a:visited &#123;<br/>
                    color: green;<br/>
                    &#125;<br/>
                    <br/>
                    (/* mouse over link */)<br/>
                    a:hover &#123;<br/>
                    color: yellow;<br/>
                    &#125;<br/>
                    <br/>
                    (/* selected link */)<br/>
                    a:active &#123;<br/>
                    color: blue;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;a href="#"&gt; Click &lt;/a&gt;<br/>
                    &lt;p&gt; Hello World &lt;p&gt;<br/>
                    &lt;p&gt; TutsFinder &lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <a href="https://tutsfinder.com" id="demoLink"> Click </a>
                    <p> Hello World </p>
                    <p> TutsFinder </p>
                    <br/><br/>
                    <p>In above example, we have color property to change the color of the link likewise you can use any property with links such as background-color, text-decoration.</p>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-text">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-table">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}