import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpForm extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Form</title>
                    <meta name="description" content = "Form is used to collect some information from the user in the form of document. Form is a kind of document that containing some fields that can be filled by the user." />
                    <meta name="keywords" content="form, form in php, php form, form php, php form tutsfinder, php tutorial, reactjs, tutorials, learn php, insta, instagram" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Form" />
                    <meta property="og:description" content="Form is used to collect some information from the user in the form of document. Form is a kind of document that containing some fields that can be filled by the user." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-form" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Forms</strong>
                    <br/><br/>
                    <h2>What is form?</h2>
                    <p>Form is used to collect some information from the user in the form of document.</p>
                    <p>Form is a kind of document that containing some fields that can be filled by the user.</p>

                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>A simple HTML form is here:</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;form action="action.php" method="post"&gt;<br/>
                    Name: &lt;input type="text" name="name"&gt;&lt;br&gt;<br/>
                    Password: &lt;input type="password" name="password"&gt;&lt;br&gt;<br/>
                    &lt;input type="submit"&gt;<br/>
                    &lt;/form&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <p>Here, it is simple HTML form that is used to post data with two input fields and one submit button.</p>
                    And PHP file for above HTML code should be look like this:
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>A simple HTML form is here:</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    Welcome &lt;?php<br/>
                    echo $_POST["name"]; ?&gt;<br/>
                    &lt;br&gt;<br/>
                    You have Successfully loged in<br/>
                    ?&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>The output will be:</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    Welcome email@email.com<br/>
                    You have successfully loged in
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <p>Now, we will create a PHP mail function that will directly send the data from a script.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>The output will be:</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>

                    &lt;?php<br/>
                    <br/>
                    if(isset($_POST['submit']))&#123;<br/>
                        $message = $_POST['message'];<br/>
                        $name = $_POST['name'];<br/>
                        $email = $_POST['email'];<br/>
                        $subject = $_POST['subject'];<br/>
                        <br/>
                        $mailTo = "info@tutsfinder.in";<br/>
                        $subject = 'Email from Contact Us';<br/>
                        <br/>
                        $message="Subject: ".$subject."\n"."Name: ".$name."\n"."Email: ".$email."\n"."\n".$message;<br/>
                        $headers="From: ".$email;<br/>
                        <br/>
                        if(mail($mailTo, $subject, $message, $headers))&#123;<br/>
                        <br/>
                        echo '<script>';<br/>
                        echo'alert("Your have Succesfully submitted you request! ");';<br/>
                        echo 'window.location = "http://tutsfinder.in/contact-us.html";';<br/>
                        echo '</script>';<br/>
                        &#125;<br/>
                        else&#123;<br/>
                            echo "Something went wrong!";<br/>
                            &#125;<br/>
                    &#125;<br/>
                    <br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h2>In PHP there are two main methods GET and POST that are used in to send and receive data.</h2>
                    <h1>PHP GET Form</h1>
                    <h2>GET Request</h2>
                    <p>Contains data that will send by using GET request.</p>
                    <br/>
                    <h2>An example to receive data from GET request.</h2>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Form.html</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>

                    &lt;form action="action.php" method="get"&gt;<br/>
                    Name: &lt;input type="text" name="name"/&gt;<br/>
                    &lt;input type="submit" value="visit"/&gt;<br/>
                    &lt;/form&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Action.php</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $name=$_GET["name"];//receiving name field value in $name variable<br/>
                    echo "Welcome, $name";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>

                    <h1>Post Request</h1>
                    <p>Contains data that will send by using POST request.</p>
                    <h2>An example to post data from POST request.</h2>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Form.html</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>

                    &lt;form action="action.php" method="post"&gt;<br/>
                    &lt;table&gt;<br/>
                    &lt;tr&gt;&lt;td&gt;Name:&lt;/td&gt;&lt;td&gt; &lt;input type="text" name="name"/&gt;&lt;/td&gt;&lt;/tr&gt;<br/>
                    &lt;tr&gt;&lt;td&gt;Password:&lt;/td&gt;&lt;td&gt; <input type="password" name="password"/>&gt;&lt;/td&gt;&lt;/tr&gt;<br/>
                    &lt;tr&gt;&lt;td colspan="2"&gt;&lt;input type="submit" value="login"/&gt;&lt;/td&gt;&lt;/tr&gt;<br/>
                    &lt;/table&gt;<br/>
                    &lt;/form&gt;
                    </pre>
                    </div>
                    </div>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Action.php</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $name=$_POST["name"];//receiving name field value in $name variable<br/>
                    $password=$_POST["password"];//receiving password field value in $password variable<br/>
                    <br/>
                    echo "Welcome: $name, your password is: $password";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>

                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-functions">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-date-time">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}