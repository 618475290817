import React from 'react';
import {Link} from 'react-router-dom';
export default class LeftNavBar extends React.Component {
    render() {
        const path = window.location.pathname;
        let contentListJava =  [
            {'contentName': 'Overview', 'contentUrl' : 'java-overview'},
            {'contentName': 'Setup Environment Variables', 'contentUrl' : 'setup-environmental-variables-in-java'},
            {'contentName': 'Program Execution', 'contentUrl' : 'program-execution-in-java'},
            {'contentName': 'First Java Program', 'contentUrl' : 'first-java-program'},
            {'contentName': 'JDK, JRE JVM', 'contentUrl' : 'jdk-jre-jvm'},
            {'contentName': 'Java Keywords', 'contentUrl' : 'java-keywords'},
            {'contentName': 'Java Constructor', 'contentUrl' : 'java-constructor'},
            {'contentName': 'Class and Object', 'contentUrl' : 'java-class-and-object'},
            {'contentName': 'Abstraction & Encapsulation', 'contentUrl' : 'java-abstraction-encapsulation'},
            {'contentName': 'Inheritance', 'contentUrl' : 'java-inheritance'},
            {'contentName': 'Polymorphism', 'contentUrl' : 'java-polymorphism'},
            {'contentName': 'Association', 'contentUrl' : 'java-association'},
            {'contentName': 'Arrays', 'contentUrl' : 'java-array'},
            {'contentName': 'Strings', 'contentUrl' : 'java-string'},
            {'contentName': 'Interface', 'contentUrl' : 'java-interface'},
            {'contentName': 'Inner Class', 'contentUrl' : 'java-inner-class'},
            {'contentName': 'Exception Handling', 'contentUrl' : 'java-exception-handling'},
            {'contentName': 'Regular Expression', 'contentUrl' : 'java-regular-expression'},
            {'contentName': 'Java Multithreading', 'contentUrl' : 'java-multithreading'},
            {'contentName': 'Java Networking', 'contentUrl' : 'java-networking'},
            {'contentName': 'Java Input/Output', 'contentUrl' : 'java-input-output'},
            {'contentName': 'Java Collection', 'contentUrl' : 'java-collection'},
            {'contentName': 'Java JDBC', 'contentUrl' : 'java-jdbc'},
            {'contentName': 'Java Awt', 'contentUrl' : 'java-awt'},
            {'contentName': 'Java Applet', 'contentUrl' : 'java-applet'},
            {'contentName': 'Java Swing', 'contentUrl' : 'java-swing'}
        ];
        let contentListHtml =  [
            {'contentName': 'Introduction', 'contentUrl' : 'html-introduction'},
            {'contentName': 'HTML Tags', 'contentUrl' : 'html-tags'},
            {'contentName': 'HTML Structure', 'contentUrl' : 'html-structure'},
            {'contentName': 'HTML Elements', 'contentUrl' : 'html-elements'},
            {'contentName': 'HTML Attributes', 'contentUrl' : 'html-attributes'},
            {'contentName': 'HTML Text Formatting Tags', 'contentUrl' : 'html-text-format'},
            {'contentName': 'HTML Meta Tag', 'contentUrl' : 'html-meta-tag'},
            {'contentName': 'HTML Comments', 'contentUrl' : 'html-comments'},
            {'contentName': 'HTML Anchor Tags', 'contentUrl' : 'html-anchor-tag'},
            {'contentName': 'HTML Links', 'contentUrl' : 'html-links'},
            {'contentName': 'HTML Styles', 'contentUrl' : 'html-styles'},
            {'contentName': 'HTML Tables', 'contentUrl' : 'html-tables'},
            {'contentName': 'HTML Lists', 'contentUrl' : 'html-lists'},
            {'contentName': 'HTML Marquees', 'contentUrl' : 'html-marquees'},
            {'contentName': 'HTML Forms', 'contentUrl' : 'html-forms'},
            {'contentName': 'IFrames', 'contentUrl' : 'html-iframes'},
            {'contentName': 'HTML Javascript', 'contentUrl' : 'html-javascript'},
            {'contentName': 'HTML Canvas', 'contentUrl' : 'html-canvas'},
            {'contentName': 'HTML SVG', 'contentUrl' : 'html-svg'},
            {'contentName': 'HTML Multimedia', 'contentUrl' : 'html-multimedia'}
        ];
        let contentListCpp =  [
            {'contentName': 'Introduction', 'contentUrl' : 'cpp-introduction'},
            {'contentName': 'Environment Setup', 'contentUrl' : 'cpp-environment-setup'},
            {'contentName': 'First program in C++', 'contentUrl' : 'cpp-first-program'},
            {'contentName': 'Variables', 'contentUrl' : 'cpp-variables'},
            {'contentName': 'Data Types', 'contentUrl' : 'cpp-data-types'},
            {'contentName': 'Control Statements', 'contentUrl' : 'cpp-control-statements'},
            {'contentName': 'Loops', 'contentUrl' : 'cpp-loops'},
            {'contentName': 'Storage Classes', 'contentUrl' : 'cpp-storage-classes'},
            {'contentName': 'Modifiers', 'contentUrl' : 'cpp-modifiers'},
            {'contentName': 'Functions', 'contentUrl' : 'cpp-functions'},
            {'contentName': 'Arrays', 'contentUrl' : 'cpp-arrays'},
            {'contentName': 'Strings', 'contentUrl' : 'cpp-strings'},
            {'contentName': 'Pointers', 'contentUrl' : 'cpp-pointers'},
            {'contentName': 'Namespace', 'contentUrl' : 'cpp-namespaces'},
            {'contentName': 'Class and Objects', 'contentUrl' : 'cpp-class-and-objects'},
            {'contentName': 'Abstraction', 'contentUrl' : 'cpp-abstraction'},
            {'contentName': 'Encapsulation', 'contentUrl' : 'cpp-encapsulation'},
            {'contentName': 'Polymorphism', 'contentUrl' : 'cpp-polymorphism'},
            {'contentName': 'Inheritance', 'contentUrl' : 'cpp-inheritance'},
            {'contentName': 'Interface', 'contentUrl' : 'cpp-interface'},
            {'contentName': 'Multithreading', 'contentUrl' : 'cpp-multithreading'},
            {'contentName': 'File and Streams', 'contentUrl' : 'cpp-file-and-streams'},
            {'contentName': 'Exception Handling', 'contentUrl' : 'cpp-exception-handling'}
        ];
        let contentListC =  [
            {'contentName': 'Introduction', 'contentUrl' : 'c-language-introduction'},
            {'contentName': 'First C Program', 'contentUrl' : 'first-program-in-c'},
            {'contentName': 'Keywords & Identifiers', 'contentUrl' : 'c-keywords-identifiers'},
            {'contentName': 'Variables & Constants', 'contentUrl' : 'c-variables-constants'},
            {'contentName': 'Data Types', 'contentUrl' : 'c-data-types'},
            {'contentName': 'Operators', 'contentUrl' : 'c-operators'},
            {'contentName': 'Control Statements', 'contentUrl' : 'c-control-statements'},
            {'contentName': 'Loops', 'contentUrl' : 'loops-in-c'},
            {'contentName': 'Functions', 'contentUrl' : 'functions-in-c'},
            {'contentName': 'Array', 'contentUrl' : 'c-array'},
            {'contentName': 'Strings', 'contentUrl' : 'strings-in-c'},
            {'contentName': 'Pointers', 'contentUrl' : 'c-pointers'},
            {'contentName': 'File Input Output', 'contentUrl' : 'file-input-output-in-c'},
            {'contentName': 'Type Casting', 'contentUrl' : 'c-type-casting'},
            {'contentName': 'Enumeration', 'contentUrl' : 'c-enumeration'},
            {'contentName': 'Pre - Processors', 'contentUrl' : 'c-pre-processors'},
            {'contentName': 'Error Handling', 'contentUrl' : 'error-handling-in-c'},
            {'contentName': 'Memory Management', 'contentUrl' : 'memory-management-in-c'},
            {'contentName': 'Command Line Arguments', 'contentUrl' : 'c-command-line-argument'},
        ];
        let contentListCSS =  [
            {'contentName': 'Introduction', 'contentUrl' : 'css-introduction'},
            {'contentName': 'How to use CSS', 'contentUrl' : 'how-to-use-css'},
            {'contentName': 'How to add CSS', 'contentUrl' : 'how-to-add-css'},
            {'contentName': 'CSS Comments', 'contentUrl' : 'css-comments'},
            {'contentName': 'CSS Units', 'contentUrl' : 'css-units'},
            {'contentName': 'CSS Background', 'contentUrl' : 'css-background'},
            {'contentName': 'CSS Fonts', 'contentUrl' : 'css-fonts'},
            {'contentName': 'CSS Text', 'contentUrl' : 'css-text'},
            {'contentName': 'CSS Links', 'contentUrl' : 'css-links'},
            {'contentName': 'Tables', 'contentUrl' : 'css-table'},
            {'contentName': 'Lists', 'contentUrl' : 'css-lists'},
            {'contentName': 'Border', 'contentUrl' : 'css-border'},
            {'contentName': 'CSS Margin', 'contentUrl' : 'css-margin'},
            {'contentName': 'CSS Position', 'contentUrl' : 'css-position'},
            {'contentName': 'CSS Padding', 'contentUrl' : 'css-padding'},
            {'contentName': 'CSS Display', 'contentUrl' : 'css-display'},
            {'contentName': 'Opacity', 'contentUrl' : 'css-opacity'},
            {'contentName': 'Icons', 'contentUrl' : 'css-icons'},
            {'contentName': 'Z-Index', 'contentUrl' : 'css-z-index'},
            {'contentName': 'Shadow Property', 'contentUrl' : 'css-shadow-property'},
            {'contentName': 'Pseudo Classes', 'contentUrl' : 'css-pseudo-classes'},
            {'contentName': 'Pseudo Elements', 'contentUrl' : 'css-pseudo-elements'},
            {'contentName': 'CSS Animation', 'contentUrl' : 'css-animation'},
            {'contentName': 'CSS Gradient', 'contentUrl' : 'css-gradient'},
            {'contentName': 'CSS transition', 'contentUrl' : 'css-transition'},
            {'contentName': 'CSS Flexbox', 'contentUrl' : 'css-flexbox'},
            {'contentName': '2D Transform', 'contentUrl' : 'css-2d-transform'},
            {'contentName': '3D Transform', 'contentUrl' : 'css-3d-transform'},
        ];
        let contentListJavascript =  [
            {'contentName': 'Introduction', 'contentUrl' : 'javascript-introduction'},
            {'contentName': 'Getting Started', 'contentUrl' : 'javascript-getting-started'},
            {'contentName': 'Fundamentals', 'contentUrl' : 'javascript-fundamentals'},
            {'contentName': 'Variables', 'contentUrl' : 'javascript-variables'},
            {'contentName': 'Data Types', 'contentUrl' : 'javascript-data-types'},
            {'contentName': 'Control Flow', 'contentUrl' : 'javascript-control-flow'},
            {'contentName': 'JavaScript Events', 'contentUrl' : 'javascript-events'},
            {'contentName': 'JavaScript Arrays', 'contentUrl' : 'javascript-arrays'},
            {'contentName': 'JavaScript Strings', 'contentUrl' : 'javascript-strings'},
            {'contentName': 'JavaScript Functions', 'contentUrl' : 'javascript-functions'},
            {'contentName': 'JavaScript Objects', 'contentUrl' : 'javascript-objects'},
            {'contentName': 'JavaScript Forms', 'contentUrl' : 'javascript-forms'},
            {'contentName': 'Data Object Model(DOM)', 'contentUrl' : 'javascript-document-object-model'},
            {'contentName': 'Browser Object Model(BOM)', 'contentUrl' : 'javascript-browser-object-model'},
            {'contentName': 'jQuery', 'contentUrl' : 'javascript-jquery'},
            {'contentName': 'JSON', 'contentUrl' : 'javascript-json'},
            {'contentName': 'AJAX', 'contentUrl' : 'javascript-ajax'},
            {'contentName': 'Error Handling', 'contentUrl' : 'javascript-error-handling'},
            {'contentName': 'Regular Expression', 'contentUrl' : 'javascript-regular-expression'}
        ];
        let contentListPhp =  [
            {'contentName': 'Overview', 'contentUrl' : 'php-overview'},
            {'contentName': 'Getting Started With Php', 'contentUrl' : 'getting-started-with-php'},
            {'contentName': 'PHP Syntax and Comments', 'contentUrl' : 'php-syntax-and-comments'},
            {'contentName': 'Variables and Constants', 'contentUrl' : 'php-variables-and-constants'},
            {'contentName': 'PHP echo print', 'contentUrl' : 'php-echo-print'},
            {'contentName': 'Data Types', 'contentUrl' : 'php-data-types'},
            {'contentName': 'Control Statements', 'contentUrl' : 'php-control-statements'},
            {'contentName': 'PHP Arrays', 'contentUrl' : 'php-arrays'},
            {'contentName': 'PHP Strings', 'contentUrl' : 'php-strings'},
            {'contentName': 'PHP Functions', 'contentUrl' : 'php-functions'},
            {'contentName': 'PHP Form Handling', 'contentUrl' : 'php-form-handling'},
            {'contentName': 'PHP Form Validation', 'contentUrl' : 'php-form-validation'},
            {'contentName': 'PHP Form Required', 'contentUrl' : 'php-form-required'},
            {'contentName': 'Data and Time', 'contentUrl' : 'php-date-and-time'},
            {'contentName': 'PHP include', 'contentUrl' : 'php-include'},
            {'contentName': 'PHP File Handling', 'contentUrl' : 'php-file-handling'},
            {'contentName': 'PHP File Upload', 'contentUrl' : 'php-file-upload'},
            {'contentName': 'Class and Objects', 'contentUrl' : 'php-class-and-objects'},
            {'contentName': 'Session and Cookies', 'contentUrl' : 'php-session-and-cookies'},
            {'contentName': 'PHP Filters', 'contentUrl' : 'php-filters'},
            {'contentName': 'PHP JSON', 'contentUrl' : 'php-json'},
            {'contentName': 'PHP Exceptions', 'contentUrl' : 'php-exceptions'},
            {'contentName': 'Regular Expression', 'contentUrl' : 'php-regular-expression'},
            {'contentName': 'PHP MySQL Introduction', 'contentUrl' : 'php-mysql-introduction'},
            {'contentName': 'PHP MySQL Connect', 'contentUrl' : 'php-mysql-connect'},
            {'contentName': 'PHP MySQL Create Database', 'contentUrl' : 'php-mysql-create-database'},
            {'contentName': 'PHP MySQL Create Table', 'contentUrl' : 'php-mysql-create-table'},
            {'contentName': 'PHP MySQL Insert', 'contentUrl' : 'php-mysql-insert'},
            {'contentName': 'PHP MySQL Update', 'contentUrl' : 'php-mysql-update'},
            {'contentName': 'PHP MySQL Delete', 'contentUrl' : 'php-mysql-delete'},
            {'contentName': 'PHP MySQL CRUD Operations', 'contentUrl' : 'php-mysql-crud-operations'},
            {'contentName': 'PHP MySQL AJAX Search', 'contentUrl' : 'php-ajax-search'},
            {'contentName': 'PHP MySQL Login System', 'contentUrl' : 'php-mysql-login-system'}
        ];
        let contentListHr =  [
            {'contentName': 'Set 1', 'contentUrl' : 'hr-interview-questions-set-1'},
            {'contentName': 'Set 2', 'contentUrl' : 'hr-interview-questions-set-2'},
        ];
        let contentListQuantitative =  [
            {'contentName': 'Set 1', 'contentUrl' : 'quantitative-set-1'},
            {'contentName': 'Set 2', 'contentUrl' : 'quantitative-set-2'},
            {'contentName': 'Set 3', 'contentUrl' : 'quantitative-set-3'},
            {'contentName': 'Chapter Wise', 'contentUrl' : '/aptitude/simplification'},
        ];
        let contentListQuantitativeTopics =  [
            {'contentName': 'Simplification', 'contentUrl' : 'simplification'},
            {'contentName': 'Speed, Time & Distance', 'contentUrl' : 'speed-time-distance'},
            {'contentName': 'Profit & Loss', 'contentUrl' : 'profit-and-loss'},
            {'contentName': 'Simple Interest & Compound Interest', 'contentUrl' : 'si-and-ci'},
            {'contentName': 'Permutation & Combination', 'contentUrl' : 'permutation-and-combination'},
            {'contentName': 'Average', 'contentUrl' : 'average'},
        ];
        if(path.includes("/java/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/java/core-java.jpg" style={{width:`100%`,height:`auto`,border: `groove`}} alt="core-java"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>Java Tutorial</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListJava.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/java/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/html/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/html/html-logo.png" style={{width:`100%`,height:`auto`,border: `groove`}} alt="core-java"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>HTML</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListHtml.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/html/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/c++/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/c++/c++-logo.jpg" style={{width:`100%`,height:`auto`,border: `groove`}} alt="core-java"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>C++ Programming</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListCpp.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/c++/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/c/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/c/c-logo.jpg" style={{width:`100%`,height:`auto`,border: `groove`}} alt="core-java"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>C Programming</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListC.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/c/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/css/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/css/css-logo.png" style={{width:`100%`,height:`auto`,border: `groove`}} alt="CSS"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>CSS</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListCSS.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/css/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/javascript/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/javascript/Javascript-logo.png" style={{width:`100%`,height:`auto`,border: `groove`}} alt="CSS"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>CSS</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListJavascript.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/javascript/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/hr-interview-questions/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/hr.PNG" style={{width:`100%`,height:`auto`,border: `groove`}} alt="HR Interview Questions"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>HR Interview Questions</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListHr.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/hr-interview-questions/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/quantitative/") === true){
            return (
                    <nav id="sidebar">
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>Quantitative Ability</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListQuantitative.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/quantitative/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/aptitude/") === true){
            return (
                    <nav id="sidebar">
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>Quantitative Ability</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListQuantitativeTopics.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/aptitude/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
        if(path.includes("/php/") === true){
            return (
                    <nav id="sidebar">
                        <div>
                            <img src="../img/php/php-icon.jpg" style={{width:`100%`,height:`auto`,border: `groove`}} alt="Php"/>
                        </div>
                        <div className="sidebar-header">
                            <h3 style={{textAlign:`center`,color:`white`}}>PHP Tutorials</h3>
                        </div>
                        <ul className="lisst-unstyled components" style={{textAlign:`center`}}>
                            {contentListPhp.map((content, index) => {
                                return (
                                    <li key={index} style={{fontSize: '1rem', lineHeight: '1.5'}}
                                    className={
                                        (path === "/php/"+content.contentUrl ? "active" : "")
                                    }
                                    >
                                    <Link to={content.contentUrl}>{content.contentName}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
            )
        }
    }
}