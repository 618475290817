import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptForms extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    password(){
        var firstpassword=document.form_password.password.value;
        var secondpassword=document.form_password.password2.value;
        if(firstpassword === secondpassword){
            alert("password correct!");
        }
        else{
            alert("password must be same!");
        }
    }
    validateForm() {
        var x = document.forms["myForm"]["email"].value;
        var atpos = x.indexOf("@");
        var dotpos = x.lastIndexOf(".");
        if (atpos < 1 || dotpos === x.length) {
        alert("Enter valid e-mail address");
        } else {
            alert("Valid e-mail address");
        }
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Forms</title>
                    <meta name="description" content="Javascript form validation checks the validity or accuracy of something. In form validation, there are many validations that can be apply on form elements." />
                    <meta name="keywords" content="javascript forms example, html forms, javascript form submit, javascript create form and submit, javascript form template, student registration form in html with javascript validation, form action javascript, javascript form validation example" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Forms" />
                    <meta property="og:description" content="Javascript form validation checks the validity or accuracy of something. In form validation, there are many validations that can be apply on form elements." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-forms" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/><br/>
                    <h2>JavaScript Forms</h2>
                    <br/><br/>
                    <p>Javascript form validation checks the validity or accuracy of something.</p>
                    <p>In form validation, there are many validations that can be apply on form elements.</p>

                    <p><strong>Required attribute</strong>  - It is used to make field must filled out before submit.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;form action="/" method="post"&gt;<br/>
                    &lt;input type="text" name="fname" required&gt;<br/>
                    &lt;input type="submit" value="Submit"&gt;<br/>
                    &lt;/form&gt;<br/>
                    <br/>
                    &lt;p&gt; Display error when you hit on submit without fill the field&lt;/p&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <form action="/" method="post">
                        <input type="text" name="fname" required />
                        <input type="submit" value="Submit" />
                    </form>
                    <p> Display error when you hit on submit without fill the field</p>
                    <br/><br/>

                    <h3>Javascript password validation</h3>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; Password Validation&lt;/title&gt;<br/>
                    &lt;script&gt;<br/>
                    function matchpass()&#123;<br/>
                    var firstpassword=document.form_password.password.value;<br/>
                    var secondpassword=document.form_password.password2.value;<br/>
                    if(firstpassword==secondpassword)&#123;<br/>
                    return true;<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    alert("password must be same!");<br/>
                    return false;<br/>
                    &#125;<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;form name="form_password" action="../index.php" onsubmit="return matchpass()"&gt;<br/>
                    Password:&lt;input type="password" name="password" /&gt; &lt;br/&gt;<br/>
                    Re-enter Password:&lt;input type="password" name="password2"/&gt; &lt;br/&gt;<br/>
                    &lt;input type="submit" value="Submit"&gt;<br/>
                    &lt;/form&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <script>
                    {/* function matchpass()&#123;
                    var firstpassword=document.form_password.password.value;
                    var secondpassword=document.form_password.password2.value;
                    if(firstpassword==secondpassword)&#123;
                    return true;
                    &#125;
                    else&#123;
                    alert("password must be same!");
                    return false;
                    &#125;
                    &#125; */}
                    </script>
                    <form name="form_password" action="../index.php" onsubmit="return matchpass()">
                    Password:<input type="password" name="password" /> <br/>
                    Re-enter Password:<input type="password" name="password2"/> <br/>
                    <input type="button" value="Submit" onClick={this.password} />
                    </form>
                    <br/><br/>

                    <h3>Javascript Email validation</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; Email Validation&lt;/title&gt;<br/>
                    &lt;script&gt;<br/>
                    function validateForm() &#123;<br/>
                    var x = document.forms["myForm"]["email"].value;<br/>
                    var atpos = x.indexOf("@");<br/>
                    var dotpos = x.lastIndexOf(".");<br/>
                    if (atpos &lt; 1 || dotpos &gt; atpos+2 || dotpos+2 &gt;=x.length) &#123;<br/>
                    alert("Enter valid e-mail address");<br/>
                    return false;<br/>
                    &#125;<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;form name="myForm" action="../index.php" onsubmit="return validateForm();" method="post"&gt;<br/>
                    Email: &lt;input type="text" name="email"&gt;<br/>
                    &lt;input type="submit" value="Submit"&gt;<br/>
                    &lt;/form&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <form name="myForm" action="/" onsubmit="return validateEmail()" method="post">
                    Email: <input type="text" name="email" />
                    <input type="button" value="Submit" onClick={this.validateForm} />
                    </form>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-objects">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-dom">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}