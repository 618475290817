import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JSON extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - JSON</title>
                    <meta name="description" content="Json provides a simple data exchange format between browser and server. JSON is language independent." />
                    <meta name="keywords" content="javascript json object, javascript json array, javascript json to string, javascript json parse, javascript create json, javascript read json file, get value from json object in javascript, json format, commas values, represent state transfer, bson, geojson, resource description framework, doc, javascript, xml, jquery, python, hypertext transfer protocol, php" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - JSON" />
                    <meta property="og:description" content="Json provides a simple data exchange format between browser and server. JSON is language independent." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-json" />
                </MetaTags>
            <div id="content">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn  btn-info">
                            <FontAwesomeIcon icon="align-left"/>
                            <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                        </button>
                    </div>
                </nav>
            <br/><br/><br/>
            <h2>JSON</h2>
            <br/><br/>
            <p>Javascript object notation</p>
            <p>Json provides a simple data exchange format between browser and server.</p>
            <p>JSON is language independent.</p>
            <br/>
            <h4>Rules to write JSON data:</h4>
            <p>•	Data is in name/value pairs</p>
            <p>•	Data is separated by comma</p>
            <p>•	Curly brackets hold objects</p>
            <p>•	Square bracket holds arrays</p>
            <br/>
            <h4>Example :</h4>
            <br/>
            <pre>
            &#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"employes": [<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id":"01",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name":"Trish",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"city" : "Pune"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,<br/>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id":"02",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name":"sha",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"city" : "Delhi"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &#125;

            </pre>
            <br/><br/>
            <div style={{backgroundColor:`#D5CFCF`}}>
            <h5 style={{padding:`10px`}}>Example</h5>
            <div className="javascript-program">
            <pre style={{padding:`10px`}}>
            &lt;!DOCTYPE html&gt;<br/>
            &lt;head&gt;<br/>
                &lt;title&gt;Get JSON data&lt;/title&gt;<br/>
                <br/>
                    &lt;script language = "javascript" &gt;<br/>
                    <br/>
                    var object1 = &#123; "language" : "Java", "author"  : "herbert schildt" &#125;;<br/>
                    document.write("JSON with JavaScript example");<br/>
                    document.write("&lt;br&gt;");<br/>
                    document.write("Language = " + object1.language+"");<br/>
                    document.write("Author = " + object1.author+");<br/>
                    <br/>
                    var object2 = &#123; "language" : "C++", "author"  : "E-Balagurusamy" &#125;;<br/>
                    document.write("&lt;br&gt;");<br/>
                    document.write("Language = " + object2.language+"");<br/>
                    document.write("Author = " + object2.author+"");<br/>
                    <br/>
                    document.write("");<br/>
                    document.write(object2.language + " programming language can be studied " + "from book written by " + object2.author);<br/>
                    document.write("");<br/>
                    <br/>
                    &lt;/script&gt;<br/>
                    <br/>
                &lt;/head&gt;<br/>
                <br/>
                &lt;body&gt;<br/>
                &lt;/body&gt;
                <br/>
            &lt;/html&gt;
            </pre>
            </div>
            </div>
            <br/><br/>
            Output:
            <br/><br/>
            <br/><br/>
            <h3>Json.parse()</h3>
            <p>It is used send data to the server, first it converts the data in json format and then send it to the server.</p>
            <h3>Json.stringify()</h3>
            <p>It is used to get data from the server, it converts the javascript object into strings.</p>
                <br/>
                <br/>
                <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-jquery">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-ajax">Next</Link>
                        </button>
                    </div>
                </div>
            </div>
          </>
        )
    }
}