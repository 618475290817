import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Border</title>
                    <meta name="description" content="This property is used to style the border around the element. It is a shorthand property where we can define multiple properties in one declaration." />
                    <meta name="keywords" content="border, css border, border in css, css border tutsfinder, border css, solid border, border none css, dashed border, dotted border, border design" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="This property is used to style the border around the element. It is a shorthand property where we can define multiple properties in one declaration." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Border</h2>
                    <br/><br/>

                    <p>This property is used to style the border around the element. It is a shorthand property where we can define multiple properties in one declaration.</p>
                    <br/>
                    <h4>border-style</h4>
                    <br/>
                    <p>It defines the type of border. There are various kind of border –</p>
                    <ul>
                    <li>•	solid</li>
                    <li>•	none (default)</li>
                    <li>•	hidden</li>
                    <li>•	dashed</li>
                    <li>•	dotted</li>
                    <li>•	Double</li>
                    <li>•	groove</li>
                    <li>•	Ridge</li>
                    <li>•	Inset</li>
                    <li>•	outset</li>
                    </ul>
                    <br/>
                    <h4>border-width</h4>
                    <br/>
                    <p>It is used to define the width of the border or thickness of the broder.</p>
                    <p><strong>For example</strong> : border-width: 2px;</p>
                    <p>2px is used for length and length should be in px, em, rem, vh and vw units.</p>
                    <br/>
                    <h4>border-color </h4>
                    <br/>
                    <p>It is used to set the color for border</p>
                    <p><strong>For example</strong> : border-color: red;</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    p.dotted &#123;border-style: dotted;&#125;<br/>
                    p.dashed &#123;border-style: dashed;color:red;&#125;<br/>
                    p.solid &#123;border-style: solid;border-width:5px;&#125;<br/>
                    p.double &#123;border-style: double;&#125;<br/>
                    p.groove &#123;border-style: groove;&#125;<br/>
                    p.ridge &#123;border-style: ridge;&#125;<br/>
                    p.inset &#123;border-style: inset;&#125;<br/>
                    p.outset &#123;border-style: outset;&#125;<br/>
                    p.none &#123;border-style: none;&#125;<br/>
                    p.hidden &#123;border-style: hidden;&#125;<br/>
                    p.mix &#123;border-style: dotted dashed solid double;&#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    &lt;p className="dotted"&gt; Dotted Border &lt;/p&gt;<br/>
                    &lt;p className="dashed"&gt; Dashed Border with red color &lt;/p&gt;<br/>
                    &lt;p className="solid"&gt; Solid Border with 5px width &lt;/p&gt;<br/>
                    &lt;p className="double"&gt; Double Border &lt;/p&gt;<br/>
                    &lt;p className="groove"&gt; Groove Border &lt;/p&gt;<br/>
                    &lt;p className="ridge"&gt; Ridge Border &lt;/p&gt;<br/>
                    &lt;p className="inset"&gt; Inset Border &lt;/p&gt;<br/>
                    &lt;p className="outset"&gt; Outset Border &lt;/p&gt;<br/>
                    &lt;p className="none"&gt; None Border &lt;/p&gt;<br/>
                    &lt;p className="hidden"&gt; Hidden Border &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <img src="../img/css/border.png" alt="css border" style={{width: `100%`}} />
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="next-prev-button" className="btn float-left btn-success btn-lg"><Link to="css-table">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-prev-button" className="btn float-right btn-success btn-lg"><Link to="css-margin">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}