import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaJDBC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                  <title>Java Tutorials - JDBC</title>
                    <meta name="description" content="Java JDBC is an API that enables java applications to connect with relational databases like MySql." />
                    <meta name="keywords" content="java jdbc tutorial, Java JDBC, java database connectivity, javatpoint, jdbc, google" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - JDBC" />
                    <meta property="og:description" content="Java JDBC is an API that enables java applications to connect with relational databases like MySql." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-jdbc" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Database Connectivity</strong>
                    <br/><br/>
                <p>Java JDBC is an API that enables java applications to connect with relational databases like MySql. </p>
                <p>It can access data of relational database where you update, detete , fetch , store the data from database.</p>
                <br/>
                <h4>JDBC Drivers</h4><br/>
                <p><strong>1.	JDBC-ODBC Bridge Driver</strong> -JDBC drivers coverts the interface calls to ODBC calls, then ODBC bridge calls ODBC driver of the respective database. But this driver have some problems to overcome this thin driver is introduce. </p>
                <p><strong>2.	Native API Driver</strong> - Native API Driver part is replaced by ODBC part, thi driver converts the jdbc method calls into native method calls</p>
                <p><strong>3.	Network Protocol Driver(Middleware Driver)</strong> - In this, first java method calls send to middleware. With the help of middleware then connects to the database on behalf of JDBC Driver.</p>
                <p><strong>4.	Database Protocol Driver(Pure java driver) or thin driver</strong> - Thin driver is most used driver. It directly coverts the JDBC methods calls to the database.</p>
                <br/>
                <h4>Steps to set-up JDBC Connection</h4><br/>
                <p>1.	Import JDBC packages </p>
                <p>2.	Load JDBC drivers</p>
                <p>3.	Open a connection to the database</p>
                <p>4.	Create statement</p>
                <p>5.	Execute Query</p>
                <p>6.	 Get resultSet and process it</p>
                <p>7.	Close connection
                </p>
                <br/><br/>
                <strong>Program to create a new file</strong>
                <p>In this program, we are creating a new file, If same name of file is already exist then it will give us error otherwise it will successfully created.</p>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                import java.sql.*;<br/>
                public class JDBCExample &#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args) &#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Statement stmt=null;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Connection con=null;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;try&#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Class.forName("com.mysql.jdbc.Driver");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Connecting to database...");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;con = DriverManager.getConnection("jdbc:mysql://localhost:3306/project", "root", "root");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Creating statement...");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;stmt = con.createStatement();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ResultSet rs = stmt.executeQuery("select * from manufacturer");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while(rs.next())&#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int id  = rs.getInt("ManufacturerID");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;String name = rs.getString("ManufacturerName");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;String address = rs.getString("Address");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;String city = rs.getString("City");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;String mobile = rs.getString("Mobile");<br/>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.print("ID: " + id);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.print(", Name: " + name);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.print(", Address: " + address);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(", City: " + city);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(", Mobile: " + mobile);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rs.close();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;stmt.close();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;con.close();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&#125;catch(SQLException se)&#123;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("se");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                ID: 6123, Name: abcde, Address: Lohgaon-47, City: Pune, Mobile: 123456789
                </pre>
                </div>
                <br/><br/>

                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-collection">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-awt">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}