import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssPosition extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Position</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="position, css position, position in css, css position tutsfinder, positin relative, position absolute, positin static, udemy, coursera" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Position</h2>
                    <br/><br/>

                    <p>This property is used to set position of an element (static, relative, absolute, fixed, or sticky).</p>
                    <br/>
                    <h2>Static</h2>
                    <p>Every element has static property by default, It is not positioned in any special way.</p>
                    <pre>
                    Position&#123;
                        Position:static;
                    &#125;
                    </pre>
                    <br/>
                    <h2>Relative</h2>
                    <p>It is same as static unless you add some extra properties to it.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h2 style={{padding:`10px`}}>Example</h2>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;div style = "position:relative; left:100px; top:10px"&gt;<br/>
                            Relative Position<br/>
                        &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/relative.png"alt="Css relative position" />
                    <br/><br/>
                    <h2>Fixed</h2>
                    <p>It always stay at same place even you scroll your browser window.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h2 style={{padding:`10px`}}>Example</h2>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;div id="demo" style="position:fixed; bottom:0; right:0;background-color:#17a2b8;color:white"&gt;<br/>
                            Fixed Position.<br/>
                        &lt;/div&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = new Date();<br/>
                    document.getElementById("demo").innerHTML = x;<br/>
                    &lt;/script&gt;<br/>
                    <p>See in bottom right corner.</p><br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>

                    <div id="demo" style={{position:`fixed`, bottom:`0`,right:`0`, backgroundColor:`#17a2b8`, color:`white`}}>
                            Fixed Position.
                        </div>
                    <script>
                    var x = new Date();
                    document.getElementById("demo").innerHTML = x;
                    </script>
                    <span></span>
                    <h2>Absolute</h2>
                    <p>It positioned the element at the specefied location relative to top left corner of the screen.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h2 style={{padding:`10px`}}>Example</h2>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    div.relative &#123;<br/>
                    position: relative;<br/>
                    width: 500px;<br/>
                    height: 300px;<br/>
                    border: 2px solid black;<br/>
                    &#125;<br/>
                    <br/>
                    div.absolute &#123;<br/>
                    position: absolute;<br/>
                    bottom: 0;<br/>
                    right: 0;<br/>
                    width: 200px;<br/>
                    height: 100px;<br/>
                    border: 2px solid black;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;div className="relative"&gt;Div with relative position&lt;/div&gt;<br/>
                    &lt;div className="absolute"&gt;Div with absolute position&lt;/div&gt;<br/>
                    &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/absolute.png" alt="Absolute" />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-margin">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-padding">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}