import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlStructure extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Text Formattings Tags</title>
                    <meta name="description" content="As a word suggest Formatting is a way to present your text in a more attractive and enhanced way." />
                    <meta name="keywords" content="Html tutorial, Html comments Tutorial, website , tutorials, beginners, professionals, introduction to Html, concepts, example, Html, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Text Formattings Tags" />
                    <meta property="og:description" content="As a word suggest Formatting is a way to present your text in a more attractive and enhanced way." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-text-format" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Text Formatting Tags</h2>
                    <br/><br/>
                <p>As a word suggest Formatting is a way to present your text in a more attractive and enhanced way.</p>
                <p>Formatting can be done in many ways like bold, italic, underlined.</p>
                <h4>Bold Text</h4>
                <p>&lt;p&gt;Anything that appears within &lt;b&gt;../b&gt;  element is displayed in bold.&lt;/p&gt;</p>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;b&gt; bold &lt;/b&gt; typeface. &lt;/p&gt;</p>
                <h4>Underlined Text</h4>
                <p>Anything that appears within &lt;u&gt;..&lt;/u&gt; elements is displayed with underline.</p>
                Example:
                <p>&lt;p&gt;The following word uses an &lt;u&gt; underlined &lt;/u&gt; typeface. &lt;/p&gt;</p>
                <h4>Italic Text</h4>
                <p>Anything that appears within &lt;i&gt;..&lt;/i&gt; elements is displayed in italicized.</p>
                Example:
                <p>&lt;p&gt;The following word uses an &lt;i&gt; italicized &lt;/i&gt; typeface. &lt;/p&gt;</p>
                <h4>Small Text</h4>
                <p>The content displayed in &lt;small&gt;..&lt;/small&gt; elements shows the size of font one smaller than the rest of the text.</p>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;small&gt; small &lt;/small&gt;  typeface. &lt;/p&gt;</p>
                <h4>Large Text</h4>
                <p>The content displayed in &lt;large&gt;..&lt;/large&gt; elements shows the size of font one larger than the rest of the text.</p>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;large&gt; large &lt;/large&gt; typeface. &lt;/p&gt;</p>
                <h4>Superscript Text</h4>
                <p>The content of a &lt;sup&gt;..&lt;/sup&gt; element is written in a superscript, the font size is same it just shows the text half a character’s size above the other characters.</p>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;sup&gt; superscript &lt;/sup&gt; typeface. &lt;/p&gt;</p>
                <h4>Subscript Text</h4>
                <p>The content of a <sub>..</sub> element is written in a subscript, the font size is same it just shows the text half a character’s size beneath the other characters.</p>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;sub&gt; subscript &lt;/sub&gt; typeface. &lt;/p&gt;</p>
                <h4>Monospaced Font</h4>
                <p>It is used when you want to write your text having same width for each character.</p>
                Syntax:
                &lt;tt&gt;.. &lt;/tt&gt;
                <br/>
                Example:
                <p>&lt;p&gt;The following word uses a &lt;tt&gt;monospaced font &lt;/tt&gt; typeface. &lt;/p&gt;</p>
                <h4>Grouping Content</h4>
                <p>The &lt;div&gt; and &lt;span&gt; elements allow you to group together several elements to create sections or subsections of a page.</p>
                <h5>Below table consisting of some other formatting elements that are commonly used.</h5>
                <br/><br/>
                <img src="../img/html/text-formatting.png" alt="Text Formatting" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-attributes">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-meta-tag">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}