import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CSSbackground extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Background</title>
                    <meta name="description" content="CSS background property is used to style the background of the page. It is a shorthand property which means you can write multiple CSS properties in one." />
                    <meta name="keywords" content="background, css background, background css, background in css, background-color, background color, background-image, background image, w3schools" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Background" />
                    <meta property="og:description" content="CSS background property is used to style the background of the page. It is a shorthand property which means you can write multiple CSS properties in one." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-background" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2>CSS Background</h2>
                    <br/><br/>
                    <p>CSS background property is used to style the background of the page.</p>
                    <p>It is a shorthand property which means you can write multiple CSS properties in one.</p>
                    <p><strong>For Example</strong>  </p>
                    <div className="css-program">
                    <pre>
                    body &#123;<br/>
                    background:<br/>
                        top center<br/>
                        no-repeat<br/>
                        fixed<br/>
                        padding-box<br/>
                        content-box<br/>
                        red;<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/>
                    <p>There are 5 CSSbackground properties that we are going to discuss in this tutorials – </p>
                    <p>1.	Background-color</p>
                    <p>2.	Background-image</p>
                    <p>3.	Background-repeat</p>
                    <p>4.	Background-attachment</p>
                    <p>5.	Background-process</p>
                    <br/>
                    <h4>CSS background-color property </h4>
                    <p>It is used to color the background of HTML elements or pages.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    h1&#123;<br/>
                        background-color: blue;<br/>
                    &#125;<br/>
                    p&#123;<br/>
                        background-color:green;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h2&gt;Background Color&lt;/h2&gt;<br/>
                    &lt;p&gt;Background Color&lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/background-color.JPG"  alt="Background Color" width="100%" />
                    <br/><br/>
                    <h4>CSS background-image property</h4>
                    <p>It is used to set image in background of an element</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body&#123;<br/>
                        background-image: url(“xyz.jpg”);<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h2&gt;TutsFinder&lt;/h2&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/background-image.JPG" alt="Background" width="100%" />
                    <br/><br/>

                    <h4>CSS background-repeat property</h4>
                    <p>It is used to repeat background image vertically or horizontally.</p>
                    <p>We can repeat the background image in variuos ways such as repeat-x, repeat-y, no-repeat.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body &#123;<br/>
                    background-image: url("xyz.jpg");<br/>
                    background-repeat: repeat-x;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt;Hello World&lt;/h1&gt;<br/>
                    &lt;p&gt;Background image repeated horizontally&lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/background-repeat.JPG" alt="Background" width="100%" />
                    <br/><br/>
                    <h4>CSS background-attachement property</h4>
                    <p>This property is used to whether the background image is fixed or scroll </p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body  &#123;<br/>
                    background: white url('xyz.jpg');<br/>
                    background-repeat: no-repeat;<br/>
                    background-attachment: fixed;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt; This is a fixed background-image. &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <h4>CSS background-position property</h4>
                    <p>This property is used to set the position of the image. For Example – If you want to display you image in center or left, right. </p>
                    <p>By default, background image is placed on the top leftof the HTML page.</p>
                    <p><strong>Positions:</strong></p> 
                    <p>1.	center</p>
                    <p>2.	top</p>
                    <p>3.	bottom</p>
                    <p>4.	left</p>
                    <p>5.	right</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    body  &#123;<br/>
                    background: white url('xyz.jpg');<br/>
                    background-repeat: no-repeat;<br/>
                    background-attachment: fixed;<br/>
                    background-position: center;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt;This is a fixed background-image.&lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-units">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-fonts">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}