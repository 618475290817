import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Animation</title>
                    <meta name="description" content="CSS Animation is used to animate the properties such as color, background. But each animation define with @keyframes after that it will be called with elements." />
                    <meta name="keywords" content="CSS Animation, CSS Animation tutorial, animation, animation in css, animation tutsfinder, w3schools, css animation w3schools, what is animation in css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Animation" />
                    <meta property="og:description" content="CSS Animation is used to animate the properties such as color, background. But each animation define with @keyframes after that it will be called with elements." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-animation" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Animation</h2>
                    <br/><br/>

                    <p>It is used to animate the properties such as color, background.</p>
                    <p>But each animation define with @keyframes after that it will be called with elements.</p>
                    <p>Animation property is shorthand property.</p>
                    <br/>
                    <h4>CSS Syntax</h4>

                    animation: name duration timing-function delay iteration-count direction fill-mode play-state;
                    <br/><br/>

                    <h4>Properties value –</h4>
                    <p>•	animation-name</p>
                    <p>•	animation-duration</p>
                    <p>•	animation-timing-function</p>
                    <p>•	animation-delay</p>
                    <p>•	animation-direction</p>
                    <p>•	animation-iteration-count</p>
                    <p>•	animation-fill-mode</p>
                    <p>•	animation-play-state</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;title&gt;Animation property&lt;/title&gt;<br/>
                        &lt;style&gt;<br/>
                        h1 &#123;<br/>
                        animation: bounce 2s;<br/>
                        &#125;<br/>
                        @keyframes bounce &#123;<br/>
                    0% &#123;<br/>
                        transform: scale(0.1);<br/>
                        opacity: 0;<br/>
                    &#125;<br/>
                    60% &#123;<br/>
                        transform: scale(1.2);<br/>
                        opacity: 1;<br/>
                    &#125;<br/>
                    100% &#123;<br/>
                        transform: scale(1);<br/>
                    &#125;<br/>
                    &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;TutsFinder&lt;/h1&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1 style={{animation: `bounce 2s`}}>TutsFinder</h1>
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-pseudo-elements">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-gradient">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}