import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PseudoElements extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Pseudo Elements</title>
                    <meta name="description" content="Pseudo elements are used to style the spcefic part of the element." />
                    <meta name="keywords" content="pseudo elements, css pseudo elements, pseudo elements in css, pseudo elements tutsfinder, tutsfinder, w3schools, pseudo elements tutorials, tutorials" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Pseudo Elements" />
                    <meta property="og:description" content="Pseudo elements are used to style the spcefic part of the element." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-pseudo-elements" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Pseudo Elements</h2>
                    <br/><br/>

                    <p>Pseudo elements are used to style the spcefic part of the element.</p>
                    Syntax:
                    <br/><br/>
                    <pre>
                    selector::pseudo-element&#123;
                        property:value;
                    &#125;
                    </pre>
                    <p>:: is used to style before and after of the element. </p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;title&gt; pseudo after element &lt;title&gt;<br/>
                        &lt;style&gt;<br/>
                        h1::first-letter &#123;<br/>
                            color: red;<br/>
                        &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt; TutsFinder &lt;/h1&gt;<br/>
                        &lt;p&gt; Elementary way to find. &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/pseudo-element.png" alt="pseudo element" style={{width:`60%`}} />
                    <br/><br/>

                    <table className="cont">
                    <tbody><tr>
                        <th style={{width:`20%`}}>Selector</th>
                        <th style={{width:`20%`}}>Example</th>
                        <th>Example description</th>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_after.asp">::after</a></td>
                        <td className="notranslate">p::after</td>
                        <td>Insert something after the content of each &lt;p&gt; element</td>
                        </tr>
                    <tr>
                        <td><a href="/cssref/sel_before.asp">::before</a></td>
                        <td className="notranslate">p::before</td>
                        <td>Insert something before the content of each &lt;p&gt; element</td>
                        </tr>
                    <tr>
                        <td><a href="/cssref/sel_firstletter.asp">::first-letter</a></td>
                        <td className="notranslate">p::first-letter</td>
                        <td>Selects the first letter of each &lt;p&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_firstline.asp">::first-line</a></td>
                        <td className="notranslate">p::first-line</td>
                        <td>Selects the first line of each &lt;p&gt; element</td>
                    </tr>
                    <tr>
                        <td><a href="/cssref/sel_selection.asp">::selection</a></td>
                        <td className="notranslate">p::selection</td>
                        <td>Selects the portion of an element that is selected by a user</td>
                    </tr>
                    </tbody>
                    </table>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-pseudo-classes">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-animation">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}