import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaPolymorphism extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Polymorphism</title>
                    <meta name="description" content="As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Polymorphism" />
                    <meta property="og:description" content="As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-polymorphism" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Polymorphism</strong>
                    <br/><br/>
                    <p>As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods.</p>
                <p><strong>For example:</strong> method calculate_area will definitely work for circle, square and triangle object.</p>
                <br/>
                <strong>Method overloading</strong>
                <p>In Method overloading, functions have same name but different no. of arguments, return type of the parameters.</p>
                <p>•	It can be implemented in both parent and child class.</p>
                <p>•	It is also known as compile time polymorphism.</p>
                <br/>
                <strong>Method Overriding</strong>
                <p>In method overriding, redefining inherit methods in subclasses. In this, declaration should be identical, code may be different.  It is like having another version of the same product.</p>
                <p>•	It can be used to add more functionality to a method.</p>
                <p>•	It is also called as run time polymorphism.</p>
                <p>•	In overriding, a method has same name, same no. of arguments and same data type.</p>
                <br/>
                <div className="container" id="java-program">
                <br/>
                <p>
                abstract class Vehicle&#123;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//declare abstract class<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;String model;<br/>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public Vehicle(String model)&#123;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//abstract class with constructor<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Vehicle constructor called");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;this.model=model;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125; <br/>
                class Car extends Vehicle&#123;<br/>
                    public Car(String model)&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;super(model);<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Bike constructor called");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String toString()&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;return "Car model is "+super.model;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;<br/>
                public class Abstraction&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vehicle obj = new Car("BMW");<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(obj);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;	<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;</p>
                <br/>
                </div>
                <br/>
                <strong>Output: </strong>
                <div className="container" id="java-program">
                <p>Vehicle constructor called<br/>
                Bike constructor called<br/>
                Car model is BMW<br/>
                </p>
                </div>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Encapsulation</strong>
                    <br/>
                <p>It is mechanism to encapsulate or wrap up all the data in a single entity it is known as encapsulation.</p>
                <p>Encapsulation can be achieved in two ways:</p>
                <p>1.	By using setter and getter methods.</p>
                <p>2.	Making all variables as private.</p>
                <p><strong>For Example:</strong> Here ATM Machine is a class. It contains both data i.e. money and operations i.e. withdraw money, balance enquiry and they are integrated in a single entity called ATM. This is called Encapsulation.
                </p>
                <div className="container" id="java-program">
                <p>
                class Car&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;private String carColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;private String carModel;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String getColor()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return carColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public String getModel()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	return carModel;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public void setColor(String newColor)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	carColor=newColor;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public void setModel(String newModel)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;carModel=newModel;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                &#125;<br/>
                <br/>
                    class Encapsulation&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Car obj = new Car();<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.setModel("BMW");<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obj.setColor("Black");<br/>
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println(obj.getColor()+" "+obj.getModel()+" looks awesome.");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;	<br/>
                &#125;<br/>
                </p>
                </div>
                <br/>
                <strong>Output: </strong>
                <div className="container" id="java-program">
                <p>
                Black BMW looks awesome.
                </p>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-inheritance">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-association">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}