import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssOpacity extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Opacity</title>
                    <meta name="description" content="Opacity is used to specify the transparency of an element where lower value has more transparent element." />
                    <meta name="keywords" content="css opacity function, background image opacity css, css opacity background color, css opacity transition, css opacity not working, css opacity foreground, text opacity css, css transparent background color"/>
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Opacity" />
                    <meta property="og:description" content="Opacity is used to specify the transparency of an element where lower value has more transparent element." />
                    <meta property="og:site_name" content="https://www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-opacity" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Opacity</h2>
                    <br/><br/>

                    <p>Opacity is used to specify the transparency of an element where lower value has more transparent element.</p>
                    <p>The opacity is lying on scale between 0 to 1.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show how opacity works on images with 0.1 opacity</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;body/&gt;<br/>
                            &lt;img src="pic.png"  style="opacity:0.1; width:100%"&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/opa-0.1.png" alt="display" style={{width:`100%`}} />
                    <br/><br/>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show how opacity works on images with 0.5 opacity</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;body/&gt;<br/>
                            &lt;img src="pic.png"  style="opacity:0.5; width:100%"&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/opa-0.5.png" alt="display" style={{width:`100%`}} />
                    <br/><br/>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show how opacity works on images with  1 opacity</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;body/&gt;<br/>
                            &lt;img src="pic.png"  style="opacity:1; width:100%"&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/opa-1.png" alt="display" style={{width:`100%`}} />

                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-display">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-icons">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}