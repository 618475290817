import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ControlStatementsInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Control Statements</title>
                    <meta name="description" content = "These are keywords that are used for making a decision. In this, we will discuss about conditional statements, Switch statement. These statements are used to control the flow of statements." />
                    <meta name="keywords" content="C Control Statements tutorial,  C Control Statements, Control Statements, Control Statements in c language, if statement in c, if else statement in c , if, if else, tutorialspoint, google, instagram" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Control Statements" />
                    <meta property="og:description" content="These are keywords that are used for making a decision. In this, we will discuss about conditional statements, Switch statement. These statements are used to control the flow of statements." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-control-statements" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Control Statements</h2>
                    <br/>
                <p>These are keywords that are used for making a decision. In this, we will discuss about conditional statements, Switch statement. These statements are used to control the flow of statements.</p>
                <h4>Conditional Statements</h4>
                <p>Conditional Statements are used when the execution of statements depends on one or more conditions.</p>
                Decision making statements :
                <p>•	If</p>
                <p>•	If-else</p>
                <p>•	if-else-if</p>
                <p>Example of if statement –</p>
                <br/>
                <div className="container" id="c-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main() &#123;<br/>
                    int num;<br/>
                    <br/>
                    printf("Enter any number : ");<br/>
                    scanf("%d", &num);<br/>
                    <br/>
                        if (number &lt; 0) &#123;<br/>
                        printf("Numeber is %d ", num);<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <h4>Syntax of if-else statement -</h4>
                <pre>
                if(condition)<br/>
                &#123;<br/>
                (//statement if condition is true)<br/>
                &#125;<br/>
                else<br/>
                &#123;<br/>
                (//statement if condition is false)<br/>
                &#125;
                </pre>
                <br/>
                <strong>Program to show how to use if else statement</strong>
                <br/>
                <div className="container" id="c-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main() &#123;<br/>
                    int num;<br/>
                    printf("Enter any number ");<br/>
                    scanf("%d", &num);<br/>
                        if  (number%2 == 0) &#123;<br/>
                        printf("%d is an even integer.",num);<br/>
                    &#125;<br/>
                    else &#123;<br/>
                        printf("%d is an odd integer.",num);<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <div className="container" id="c-program">
                <pre>
                Enter an integer: 8<br/>
                8 is an even integer.
                </pre>
                </div>
                <h4>Program of if else if statement –</h4>
                <h4>Program to decide discount percentage for selling a tv.</h4>
                <br/>
                <div className="container" id="c-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                void main()<br/>
                &#123;<br/>
                    int size;int disc;<br/>
                    printf("Enter size ho TV : ");<br/>
                    scanf("%d",&size);<br/>
                    if(size&lt;21)<br/>
                    &#123;<br/>
                        printf("You will get discount of 5%");<br/>
                    &#125;<br/>
                    else if(size &gt; 21 || size &lt; 32)<br/>
                    &#123;<br/>
                        printf("You will get discount of 10%;");<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        printf("You will get discount of 20%");<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h2>Switch Statement</h2>
                <p>Switch statement can have a number of possible execution paths. A switch works with byte, short ,char and int primitive data types.</p>
                <p>The following program SwitchExample shows how switch statement works in C.</p>
                <br/><br/>
                <div className="container" id="c-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    int month;<br/>
                    <br/>
                    printf("Which month you have to print : ");<br/>
                    scanf("%d", &month);<br/>
                    <br/>
                    switch(month)<br/>
                    &#123;<br/>
                        case 1:<br/>
                            printf("January");<br/>
                            break;<br/>
                        case 2:<br/>
                            printf("February");<br/>
                            break;<br/>
                        case 3:<br/>
                            printf("March");<br/>
                            break;<br/>
                        case 4:<br/>
                            printf("April");<br/>
                            break;<br/>
                        case 5:<br/>
                            printf("May");<br/>
                            break;<br/>
                        case 6:<br/>
                            printf("June");<br/>
                            break;<br/>
                        case 7:<br/>
                            printf("July");<br/>
                            break;<br/>
                        case 8:<br/>
                            printf("August");<br/>
                            break;<br/>
                        case 9:<br/>
                            printf("September");<br/>
                            break;<br/>
                        case 10:<br/>
                            printf("October");<br/>
                            break;<br/>
                        case 11:<br/>
                            printf("Novembee");<br/>
                            break;<br/>
                        case 12:<br/>
                            printf("December");<br/>
                            break;<br/>
                        default:<br/>
                            printf("Invalid input! Please enter month between 1-12");<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-operators">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="loops-in-c">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}