import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Introduction </title>
                    <meta name="description" content="HTML is a phrase which stands for Hyper text Markup Language which is used for creating web pages and web applications." />
                    <meta name="keywords" content="Html tutorial, Html Tutorial, introduction to Html, html introduction tutsfinder, what is html" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Introduction" />
                    <meta property="og:description" content="HTML is a phrase which stands for Hyper text Markup Language which is used for creating web pages and web applications." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-introduction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/html/html.png" style={{width:`100%`,height:`auto`,border:`groove`}} alt=""/>
                    </div>
                    <br/>
                    <br/><br/>
                    <h2>Introduction</h2>
                    <br/><br/>
                    <h3>What is HTML?</h3>
                    <br/>
                    <p>HTML is a phrase which stands for Hyper text Markup Language which is used for creating web pages and web applications.</p>
                    <p>As a word suggests hyper text means Text within Text. Whenever you click on any link and it redirects you to a new web page, means you have clicked on a hypertext.</p>
                    <p>Markup Language is a computer language that is used to apply layout and formatting conventions to a text document. Markup language makes text more interactive and dynamic. It can turn text into images, tables, links etc.</p>
                    <p>A web page is a document which is commonly written in HTML and translated by a web browser. A web page can be identified by entering a URL. Web pages are of two types static and dynamic, but with the help of HTML we can create only static pages.</p>
                    <p>HTML elements are represented by tags and each tag contains different content.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                        <h5 style={{padding:`10px`}}>A Simple HTML Example: </h5>
                        <div style={{backgroundColor:`#F3F0F0`, margin:`10px 10px`, padding:`10px`}}>
                            <pre style={{padding:`10px`}}>
                                &lt;!DOCTYPE html&gt;<br/>
                                &lt;html&gt;<br/>
                                &lt;head&gt;<br/>
                                &lt;title&gt;Title of page &lt;/title&gt;<br/>
                                &lt;head&gt;<br/>
                                &lt;body&gt;<br/>
                                &lt;h1&gt; Write your First Heading &lt;/h1&gt;<br/>
                                &lt;p&gt; Write your First paragraph &lt;/p&gt;<br/>
                                &lt;/body&gt;<br/>
                                &lt;/html&gt;
                            </pre>
                        </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/html/first.png" alt="first" style={{width:`100%`}} />
                    <br/><br/>
                    <h4>Elucidation of HTML example:</h4>
                    <p><strong>&lt;!DOCTYPE&gt;</strong> : it defines the document type, also instruct the browser about version of HTML.</p>
                    <p><strong>&lt;html&gt;</strong> : this elements is a root element which works as a container for the html page, it contains each and everything which is defined in a html page except &lt;!DOCTYPE&gt;.</p>
                    <p><strong>&lt;head&gt;</strong> : this element is the first element under the &lt;html&gt; element which describes the meta information of page. Always it should be closed before body tag opens.</p>
                    <p><strong>&lt;body&gt;</strong> : text which is displayed to the end user is defined under the body tag, it contains the main content of the HTML document.</p>
                    <p><strong>&lt;h1&gt;</strong> : text define under this shows the first heading of the page.</p>
                    <p><strong>&lt;p&gt;</strong> : text define under &lt;p&gt; element shows the paragraph of the page.</p>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button"className="btn float-right btn-success btn-lg"><Link to="html-tags">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}