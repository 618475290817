import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpVariablesAndConstants extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Variables and Constants</title>
                    <meta name="description" content = "" />
                    <meta name="keywords" content="variables and constants, php variables and constants, whatsapp, google ,variables and constants in php , php variables,  php constants" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Variables and Constants" />
                    <meta property="og:description" content="" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-variables-and-constants" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Variables</strong>
                    <br/><br/>
                    <p>Variables are used to store data, it tells the compiler about the nature of the data.</p>
                    <p>In this chapter, we will learn about how we can store information in a variable in PHP.</p>
                    <p>•	In PHP, a variable starts with $ sign, followed by the name of the variable.</p>
                    <p>•	In PHP, there is no need to declare the type of variable, PHP automatically converts it into to a correct data types based on its value.</p>
                    <p>•	Use assignment operator = to assign the value to the variable.</p>
                    <p><strong>Syntax</strong> – $var_name = value;</p>
                    <br/>
                    <h2>Rules to define variables-</h2>
                    <br/>
                    <p>1. A variable contains only alphanumeric characters(a-z , A-Z , 0-9) and underscore(_).</p>
                    <p>2. The first character of a variable should start with alphabet(a-z , A-Z) or underscore (_).</p>
                    <p>3. Variables are also case sensitive.</p>
                    <p>4. Keep variables name in small alphabets.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Declaring variables)<br/>
                    $txt = "Hello World!";<br/>
                    $number = 10;<br/>
                    (// Displaying variables value)<br/>
                    echo $txt; // Output: Hello World!<br/>
                    echo $number; // Output: 10<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><hr/><br/>
                    <strong style={{fontSize:`35px`}}>Constants</strong>
                    <br/>
                    <p>Constant variables are the variables that value cannot be changed.</p>
                    <p>Const keyword is used to make a variable constant.</p>
                    <p>Define() function is used to defined constant variables, where two arguments are required that are – name of the constant and its value.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    define("MINSIZE", 50);<br/>
                    <br/>
                    echo MINSIZE;<br/>
                    echo constant("MINSIZE");<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <p>To define constant variables same rules must be follow as variables which means a valid constant first word should start with a underscore (_) or alphabet.</p>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-syntax">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-echo-print">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}