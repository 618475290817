import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaAwt extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - AWT</title>
                    <meta name="description" content="Java AWT is known as Abstract window Toolkit is an API used in to develop windows based applications." />
                    <meta name="keywords" content="wahat is awt, java abstract window toolkit tutorials, java abstract window toolkit tutsfinder, java Awt, awt, Java tutorials, beginners, Awt Java, example, java, basic," />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - AWT" />
                    <meta property="og:description" content="Java AWT is known as Abstract window Toolkit is an API used in to develop windows based applications." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/Java/java-awt" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Abstract Window Toolkit</strong>
                    <br/><br/>
                    <p>Java AWT is known as Abstract window Toolkit is an API used in to develop windows based applications.</p>
                    <p>Awt components are platform dependent because they are displayed according to the view or screen of the operating system.</p>
                    <p>AWT is heavyweight because its components are using resources of operating system.</p>
                    <p>Java.awt package provides some classes:</p>
                    <p>1.	TextField</p>
                    <p>2.	Button</p>
                    <p>3.	Label </p>
                    <p>4.	Chekbox</p>
                    <br/>
                    <img className="center" src="../img/java/awt.png" alt="awt" style={{width:`80%`, textAlign:`-moz-center`}} />
                    <br/>
                    <h4>Container</h4>
                    <p>Container is the component of awt that contains other components like button, textfield, label.</p>
                    <p>Types of Containers :</p>
                    <p><strong>Window</strong> – Window is container but it does not have borders and menu. If you want to create window, you can use frame and dialog.</p>
                    <p><strong>Frame</strong> - The Frame is the container that can contain the title bar and menu bar. It can have other components like button, text field, etc.</p>
                    <p><strong>Panel</strong>  - The Panel is the container that doesn’t contain the title bar and menu bar. It can have other components like button, text field, etc.</p>
                    <p><strong>Dialog </strong>- Dialog is the container having border and title. We cannot create an instance of the Dialog class without an associated instance of the respective Frame class.
                    </p>
                    <br/><br/>
                    <strong>Program to create a button by extends Frame class</strong>
                    <p> </p>
                    <br/><br/>
                    <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    import java.awt.*;<br/>
                    import java.awt.Event.*;<br/>
                    public class AwtExample extends Frame<br/>
                    &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;AwtExample()&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Button b=new Button("Click");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.setBounds(50,100,50,50);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;add(b);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setSize(300,150);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setTitle("Program by extends Frame");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setLayout(new FlowLayout());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setVisible(true);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String[] args)&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AwtExample ae = new AwtExample();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/><br/>
                    Output :
                    <br/><br/>
                    <img src="../img/java/awt-output.png" alt="awt" style={{textAlign:`-moz-center`}} />
                    <br/><br/>
                    <strong>Program to create TextField by creating instance of Frame class.</strong>
                    <p>In this program, we are copy the already existing file into another file, it it does not exist it will shoe an exception otherwise it will create successfully.</p>
                    <br/><br/>
                    <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    import java.awt.*;<br/>
                    public class AwtExample2 &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;AwtExample2()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Frame fr = new Frame();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Label lb = new Label("User Name");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fr.add(lb);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TextField t = new TextField();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fr.add(t);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fr.setSize(300, 150);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fr.setLayout(new FlowLayout());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fr.setVisible(true);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main(String args[])<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AwtExample2 ae = new AwtExample2();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
                    &#125;
                    </pre>
                    </div>
                    <br/><br/>
                    <strong>Output:</strong>
                    <br/><br/>
                    <img src="../img/java/awt-example2.png" alt="awt" style={{textAlign:`-moz-center`}} />
                    <br/><br/>

                    <br/>
                    <br/>

                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-jdbc">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-applet">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}