import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlMetaTag extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Meta Tag </title>
                    <meta name="description" content="The Meta tag means Information about data. It provides information about HTML document. These tags are basically used to add name/value pairs to describe properties of HTML document." />
                    <meta name="keywords" content="meta tag in html, meta tag, Html meta tag, Html meta tag Tutorial, website , tutorials, beginners, professionals, introduction to Html, concepts, example, Html, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Meta Tag" />
                    <meta property="og:description" content="The Meta tag means Information about data. It provides information about HTML document. These tags are basically used to add name/value pairs to describe properties of HTML document." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-meta-tag" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Meta Tag</h2>
                    <br/><br/>
                <p>The Meta tag means Information about data. It provides information about HTML document. These tags are basically used to add name/value pairs to describe properties of HTML document.</p>
                <p><strong>For example:</strong> expiry date, author name, list of keywords.</p>
                <p>This tag is an empty element which means it is only having opening tag and there is no closing tag defined in it. It carries information within its attributes.</p>
                <p>A HTML document can contain number of meta tag depending on the information.</p>
                <p>Meta tags are always go inside the &lt;head&gt; element.</p>
                <br/>
                <h5>Adding Meta Tags to your Documents</h5>
                <p>•	Name Attribute: This attribute is used to define the name of the property.</p>
                <p>•	http-equiv attribute: This attribute is used to get http response message header.</p>
                <p>•	Scheme attribute: This attribute is used to specify a scheme to interpret the property’s value.</p>
                <p>•	Content attribute: This attribute is used to specify properties value.</p>
                <br/>
                <h4>Specifying keywords</h4>
                <p>The meta data is used to specify the important keyword related to the HTML document.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Example for meta tag &lt;/title&gt;<br/>
                &lt;meta name = “keywords” content = “meta Tags, Metadata”/&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p&gt; Hello TutsFinder! &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Document Description</h4>
                <p>The meta data is used in HTML document to provide short description about the document.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Example for meta tag &lt;/title&gt;<br/>
                &lt;meta name = “keywords” content = “Meta Tags, Metadata”/&gt;<br/>
                &lt;meta name = “description”<br/>
                Content = “TutsFinder is a portal where you can find every tutorial.”/&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p&gt; Hello TutsFinder! &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Document Revision Date</h4>
                <br/>
                <p>The meta tag is used to give the information about last updated document.</p>
                <p>This information is used by various browser when refresh web page.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Example for meta tag &lt;/title&gt;<br/>
                &lt;meta name = “keywords” content = “Meta Tags, Metadata”/&gt;<br/>
                &lt;meta name = “description”<br/>
                Content = “Description about Meta tags”/&gt;<br/>
                &lt;meta name = “revised detail”<br/>
                Content= “Last updated time”/&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p&gt; Hello TutsFinder! &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Document Refreshing</h4>
                <p>This meta tag is used to specify a duration after which the web page will keep refreshing automatically after the given duration. Below example shows to refresh the page after 10 sec:</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Example for meta tag &lt;/title&gt;<br/>
                &lt;meta name = “keywords” content = “Meta Tags, Metadata”/&gt;<br/>
                &lt;meta name = “description”<br/>
                Content = “Description about Meta tags”/&gt;<br/>
                &lt;meta name = “revised about”<br/>
                Content= “TutsFinder”/&gt;<br/>
                &lt;meta http-equiv = “refresh” content = “10”/&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p&gt; Hello TutsFinder! &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Setting Author Name</h4>
                <p>The name of the author can be set in web page through HTML document using meta tag.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Example for meta tag &lt;/title&gt;<br/>
                &lt;meta name = “keywords” content = “Meta Tags, Metadata”/&gt;<br/>
                &lt;meta name = “description”<br/>
                Content = “Description about Meta tags”/&gt;<br/>
                &lt;meta name = “author” content = “Trishant Kumar” /&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;p&gt; Hello TutsFinder! &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-text-format">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-comments">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}