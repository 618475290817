import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ErrorHandlingInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Error Handling</title>
                    <meta name="description" content = "C language does not support error handling directly. There is a header file error.h in C that defines various error code." />
                    <meta name="keywords" content="C Error Handling,  C Error Handling Tutorial, Error Handling , Error Handling in C, Error Handling tutorialspoint, tutorialspoint" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - sError Handling" />
                    <meta property="og:description" content="C language does not support error handling directly. There is a header file error.h in C that defines various error code." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/error-handling-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Error Handling</h2>
                    <br/>
                    <p>C language does not support error handling directly. There is a header file error.h in C that defines various error code.</p>
                <p>Most of the C function calls return -1 or NULL in case of any error and set an error code errno.</p>
                <br/>
                <h3>Errno</h3>
                <br/>
                <p>It is a global function that is used to determine which type of error was encountered while function execution.</p>
                <br/>
                <h4>List of errno</h4>
                <br/>
                <p>1.	Operation not permitted</p>
                <p>2.	No such file or directory</p>
                <p>3.	No such process</p>
                <p>4.	Interrupted system call</p>
                <p>5.	I/O error</p>
                <p>6.	No such device or address</p>
                <p>7.	Argument list too long</p>
                <p>8.	Exec format error</p>
                <p>9.	Bad file number</p>
                <p>10.	No child processes</p>
                <p>11.	Try again</p>
                <p>12.	Out of memory</p>
                <p>13.	Permission denied</p>
                <br/>
                <br/>
                <h4>Functions in errno </h4>
                <br/>
                <p>There are two functions that are used to display the error message associated with errno –</p>
                <p><strong>1.	Perror()</strong> – This function returns the string with textual representation with the current errno  value.</p>
                <p><strong>2.	Strerror()</strong> - It is defined in string.h header file. This function returns a pointer to textual representation of the current errno value.</p>
                <br/>
                Program to show how error handling works –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;errno.h&gt;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                <br/>
                    FILE * fp;<br/>
                    <br/>
                    (/* opening a file which does<br/>
                     not exist.*/)<br/>
                    fp = fopen("xyz.txt", "r");<br/>
                    <br/>
                    printf(" Value of errno is : %d\n ", errno);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <div className="container" id="cpp-program">
                <pre>
                Value of errno is 2.
                </pre>
                </div>
                <br/>
                <p>In above program, it returns 2 because 2 means – no such file or directory.</p>
                <br/><br/>
                Program#2
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                <br/>
                void main() &#123;<br/>
                <br/>
                    int a = 20;<br/>
                    int b = 0;<br/>
                    int quotient;<br/>
                    <br/>
                    if( b == 0)&#123;<br/>
                        fprintf(stderr, "Division by zero! Is not allowed\n");<br/>
                        exit(-1);<br/>
                    &#125;<br/>
                    quotient = a/b;<br/>
                    fprintf(stderr, "Value of quotient is : %d\n", quotient );<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-pre-processors">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="memory-management-in-c">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}