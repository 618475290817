import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppPolymorphism extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Polymorphism</title>
                    <meta name="description" content = "As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods." />
                    <meta name="keywords" content="Polymorphism, PolymorphismC++ tutorial,  C++ Polymorphism Tutorial, Polymorphism tutsfinder, Polymorphism tutorialspoint, tutorialspoint, Polymorphism in c++, what is Polymorphism, what is Polymorphism in c++" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Polymorphism " />
                    <meta property="og:description" content="As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-polymorphism" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Polymorphism</h2>
                    <br/>
                <p>As the name suggest one name many forms, Polymorphism is the way of providing the different functionality by the functions having the same name based on the signatures of the methods.</p>
                <p><strong>For example</strong>: method calculate_area will definitely work for circle, square and triangle object.</p>
                <p>There are two type of polymorphism in C++</p>
                <p>1.	Compile time polymorphism</p>
                <p>2.	Run time polymorphism</p>
                <h3>Method overloading</h3>
                <p>In Method overloading, functions have same name but different no. of arguments, return type of the parameters.</p>
                <p>• It can be implemented in both parent and child class.</p>
                <p>• It is also known as compile time polymorphism.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                void area(int);<br/>
                void area(int,float);<br/>
                <br/>
                int main() &#123;<br/>
                <br/>
                    int a = 5;<br/>
                    float b = 10.5;<br/>
                    <br/>
                    area(a);<br/>
                    area(a,b);<br/>
                    <br/>
                    return 0;<br/>
                &#125;<br/>
                <br/>
                void area(int var) &#123;<br/>
                    cout &lt;&lt; "Area of square : " &lt;&lt; var*var &lt;&lt; endl;<br/>
                &#125;<br/>
                <br/>
                void area(int var1,float var2) &#123;<br/>
                    cout &lt;&lt; "Area of rectangle  : " &lt;&lt; var1*var2 &lt;&lt; endl;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Area of square : 25<br/>
                Area of rectangle  : 52.5
                </pre>
                </div>
                <br/><br/>

                <h3>Method Overriding</h3>
                <br/>
                <p>In method overriding, redefining inherit methods in subclasses. In this, declaration should be identical, code may be different. It is like having another version of the same product.</p>
                <p>• It can be used to add more functionality to a method.</p>
                <p>• It is also called as run time polymorphism.</p>
                <p>• In overriding, a method has same name, same no. of arguments and same data type.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class BaseClass &#123;<br/>
                public:<br/>
                    void display()&#123;<br/>
                        cout &lt;&lt; "Parent Class";<br/>
                    &#125;<br/>
                &#125;;<br/>
                class DerivedClass: public BaseClass&#123;<br/>
                public:<br/>
                    void display() &#123;<br/>
                        cout &lt;&lt;"Child Class";<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main() &#123;<br/>
                    DerivedClass obj = DerivedClass();<br/>
                    obj.display();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Child Class
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-encapsulation">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-inheritance">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}