import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Table extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Table</title>
                    <meta name="description" content="To style a table we can use many CSS properties like border, border-collapse, width, height, text-align, padding, background-color." />
                    <meta name="keywords" content="css table, table in css, table, table style, table with border, style table, css table codepen, html table, css table column, css table template, stylish css tables, css table justify, css table examples, html table style, responsive table, javascript table, bootstrap table, div and span, header, meta element, blockquote element, inline frame" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Table" />
                    <meta property="og:description" content="To style a table we can use many CSS properties like border, border-collapse, width, height, text-align, padding, background-color." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-table" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/>
                    <h2>Tables</h2>
                    <br/><br/>

                    <p>To style a table we can use many CSS properties like border, border-collapse, width, height, text-align, padding, background-color.</p>
                    <p>We have used following proprties for style a table – </p>
                    <p>•	The border-collapse specifies the space between table rows and columns, we can collapse it or separate it.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    table &#123;<br/>
                    border-collapse: collapse;<br/>
                    &#125;<br/>
                    <br/>
                    table, td, th &#123;<br/>
                    border: 1px solid black;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h2&gt;Borders collapse property:&lt;/h2&gt;<br/>
                    <br/>
                    &lt;table&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;th&gt;Firstname&lt;/th&gt;<br/>
                        &lt;th&gt;Lastname&lt;/th&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Trishant&lt;/td&gt;<br/>
                        &lt;td&gt;Shishodia&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Stark&lt;/td&gt;<br/>
                        &lt;td&gt;Trish&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;/table&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/border-collapse.jpg" alt="css border-collapse" />
                    <br/><br/>

                    <p>•	The border-spacing specifies the width between the cells.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    table &#123;<br/>
                    border-collapse: separate;<br/>
                    border-spacing: 10px;<br/>
                    &#125;<br/>
                    <br/>
                    table, td, th &#123;<br/>
                    border: 1px solid black;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h2&gt;Borders spacing property:&lt;/h2&gt;<br/>
                    <br/>
                    &lt;table&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;th&gt;Firstname&lt;/th&gt;<br/>
                        &lt;th&gt;Lastname&lt;/th&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Trishant&lt;/td&gt;<br/>
                        &lt;td&gt;Shishodia&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Stark&lt;/td&gt;<br/>
                        &lt;td&gt;Trish&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;/table&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/border-spacing.jpg" alt="css border spacing" />
                    <br/><br/>
                    <p>•	The caption-side captions are used &lt;caption&gt; element. It is use to placed the caption in the table at any side (top, left, bottom, right) </p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    table &#123;<br/>
                    border-collapse: collapse;<br/>
                    caption-side: bottom;<br/>
                    &#125;<br/>
                    <br/>
                    table, td, th &#123;<br/>
                    border: 1px solid black;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h2&gt;Borders caption property:&lt;/h2&gt;<br/>
                    <br/>
                    &lt;table&gt;<br/>
                    &lt;caption&gt;Table 1.1 Customers&lt;/caption&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;th&gt;Firstname&lt;/th&gt;<br/>
                        &lt;th&gt;Lastname&lt;/th&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Trishant&lt;/td&gt;<br/>
                        &lt;td&gt;Shishodia&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;tr&gt;<br/>
                        &lt;td&gt;Stark&lt;/td&gt;<br/>
                        &lt;td&gt;Trish&lt;/td&gt;<br/>
                    &lt;/tr&gt;<br/>
                    &lt;/table&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/border-spacing.jpg" alt="css border spacing" />
                    <br/><br/>
                    <p>•	The empty-cells specifies whether the border should be shown if a cell is empty.</p>
                    <br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-links">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-lists">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}