import ReactGA from 'react-ga';

import './assets/CSS/animate.css';
import './assets/CSS/bootstrap.min.css';
import './assets/CSS/flaticon.css';
import './assets/CSS/font-awesome.min.css';
import './assets/CSS/gijgo.css';
import './assets/CSS/magnific-popup.css';
import './assets/CSS/main.css';
import './assets/CSS/nice-select.css';
import './assets/CSS/normalize.min.css';
import './assets/CSS/owl.carousel.min.css';
import './assets/CSS/slicknav.css';
import './assets/CSS/style.css';
import './assets/CSS/style.map';
import './assets/CSS/theme-default.css';
import './assets/CSS/themify-icons.css';

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import MainLayout from './Layouts/MainLayout'
import CoursesLayout from './Layouts/CoursesLayout'
import Homepage from './Pages/Homepage'
import Courses from './Pages/Courses'
import ContactUs from './Pages/ContactUs'
import Feedback from './Pages/Feedback'
import Programming from './Pages/Programming'
import WebDevelopment from './Pages/WebDevelopment'
import PinCode from './Pages/PinCode'

// Java Tutorials - Start
import JavaOverview from './Pages/Java/JavaOverview'
import SetupEnvironmentalVariables from './Pages/Java/SetupEnvironmentalVariables'
import ProgramExecutionInJava from './Pages/Java/ProgramExecutionInJava'
import FirstJavaProgram from './Pages/Java/FirstJavaProgram'
import JDKJREJVM from './Pages/Java/JDKJREJVM'
import JavaKeywords from './Pages/Java/JavaKeywords'
import JavaConstructor from './Pages/Java/JavaConstructor'
import JavaClassAndObject from './Pages/Java/JavaClassAndObject'
import JavaAbstractionEncapsulation from './Pages/Java/JavaAbstractionEncapsulation'
import JavaInheritance from './Pages/Java/JavaInheritance'
import JavaPolymorphism from './Pages/Java/JavaPolymorphism'
import JavaAssociation from './Pages/Java/JavaAssociation'
import JavaArray from './Pages/Java/JavaArray'
import JavaStrings from './Pages/Java/JavaStrings'
import JavaInterface from './Pages/Java/JavaInterface'
import JavaInnerClass from './Pages/Java/JavaInnerClass'
import JavaExceptionHandling from './Pages/Java/JavaExceptionHandling'
import JavaInputOutput from './Pages/Java/JavaInputOutput'
import JavaAwt from './Pages/Java/JavaAwt'
import JavaApplet from './Pages/Java/JavaApplet'
import JavaCollection from './Pages/Java/JavaCollection'
import JavaJDBC from './Pages/Java/JavaJDBC'
import JavaMultithreading from './Pages/Java/JavaMultithreading'
import JavaNetworking from './Pages/Java/JavaNetworking'
import JavaRegularExpression from './Pages/Java/JavaRegularExpression'
import JavaSwing from './Pages/Java/JavaSwing'
// Java Tutorials - End

// HTML Tutorials - Start
import HtmlIntroduction from './Pages/Html/HtmlIntroduction'
import HtmlMarquees from './Pages/Html/HtmlMarquees'
import HtmlTags from './Pages/Html/HtmlTags'
import HtmlForm from './Pages/Html/HtmlForm'
import HtmlAnchorTags from './Pages/Html/HtmlAnchorTags'
import HtmlAttributes from './Pages/Html/HtmlAttributes'
import HtmlCanvas from './Pages/Html/HtmlCanvas'
import HtmlComments from './Pages/Html/HtmlComments'
import HtmlElements from './Pages/Html/HtmlElements'
import HtmlIframes from './Pages/Html/HtmlIframes'
import HtmlJavascript from './Pages/Html/HtmlJavascript'
import HtmlLinks from './Pages/Html/HtmlLinks'
import HtmlLists from './Pages/Html/HtmlLists'
import HtmlStructure from './Pages/Html/HtmlStructure'
import HtmlMetaTag from './Pages/Html/HtmlMetaTag'
import HtmlStyles from './Pages/Html/HtmlStyles'
import HtmlTextFormattingTags from './Pages/Html/HtmlTextFormattingTags'
import HtmlSVG from './Pages/Html/HtmlSVG'
import HtmlMultimedia from './Pages/Html/HtmlMultimedia'
import HtmlTable from './Pages/Html/HtmlTable'
// HTML Tutorials - End

//C++ Tutorials Start
import CppIntroduction from './Pages/C++/CppIntroduction'
import Abstraction from './Pages/C++/CppAbstraction'
import Arrays from './Pages/C++/CppArrays'
import ClassAndObject from './Pages/C++/CppClassAndObject'
import ControlStatements from './Pages/C++/CppControlStatements'
import CppDataTypes from './Pages/C++/CppDataTypes'
import Functions from './Pages/C++/CppFunctions'
import CppFirstProgram from './Pages/C++/CppFirstProgram'
import Encapsulation from './Pages/C++/CppEncapsulation'
import CppEnvironmentSetup from './Pages/C++/CppEnvironmentSetup'
import ExceptionHandling from './Pages/C++/CppExceptionHandling'
import FileAndStream from './Pages/C++/CppFileAndStream'
import CppVariables from './Pages/C++/CppVariables'
import Interface from './Pages/C++/CppInterface'
import Loops from './Pages/C++/CppLoops'
import Modifiers from './Pages/C++/CppModifiers'
import Pointers from './Pages/C++/CppPointers'
import Multithreading from './Pages/C++/CppMultithreading'
import Namespace from './Pages/C++/CppNamespace'
import StorageClasses from './Pages/C++/CppStorageClasses'
import Strings from './Pages/C++/CppStrings'
import Polymorphism from './Pages/C++/CppPolymorphism'
import Inheritance from './Pages/C++/CppInheritance'
//C++ Tutorials End

//C Tutorials Start
import CIntroduction from './Pages/C/CIntroduction'
import FirstProgramInC from './Pages/C/FirstProgramInC'
import CKeywordsAndIdentifiers from './Pages/C/CKeywordsAndIdentifiers'
import CArrays from './Pages/C/CArrays'
import CommandLineArgumentsInC from './Pages/C/CommandLineArgumentsInC'
import CEnumeration from './Pages/C/CEnumeration'
import ControlStatementsInC from './Pages/C/ControlStatementsInC'
import DataTypesInC from './Pages/C/DataTypesInC'
import VariablesAndConstantsInC from './Pages/C/VariablesAndConstantsInC';
import TypeCastingInC from './Pages/C/TypsCastingInC';
import PreProcessors from './Pages/C/PreProcessors'
import CPointers from './Pages/C/CPointers';
import OperatorsInC from './Pages/C/OperatorsInC';
import ErrorHandlingInC from './Pages/C/ErrorHandlingInC';
import FileInputOutputInC from './Pages/C/FileInputOutputInC'
import StringsInC from './Pages/C/StringsInC'
import MemoryManagementInC from './Pages/C/MemoryManagementInC'
import LoopsInC from './Pages/C/LoopsInC'
import CFunctions from './Pages/C/CFunctions'
//C Tutorials End

//CSS Tutorials Start
import CssIntroduction from './Pages/Css/CssIntroduction'
import HowToUseCss from './Pages/Css/HowToUseCss'
import HowToAddCss from './Pages/Css/HowToAddCss'
import CssBackground from './Pages/Css/CssBackground'
import CssComments from './Pages/Css/CssComments'
import CssUnits from './Pages/Css/CssUnits'
import CssFonts from './Pages/Css/CssFonts'
import Table from './Pages/Css/Table'
import CssText from './Pages/Css/CssText'
import CssLinks from './Pages/Css/CssLinks'
import CssLists from './Pages/Css/CssLists'
import CssBorder from './Pages/Css/CssBorder'
import Opacity from './Pages/Css/Opacity'
import CssMargin from './Pages/Css/CssMargin'
import CssTransition from './Pages/Css/CssTransition'
import CssShadowProperty from './Pages/Css/CssShadowProperty'
import ZIndex from './Pages/Css/ZIndex'
import PseudoClasses from './Pages/Css/PseudoClasses'
import PseudoElements from './Pages/Css/PseudoElements'
import CssPosition from './Pages/Css/CssPosition'
import CssFlexbox from './Pages/Css/CssFlexbox'
import CssDisplay from './Pages/Css/CssDisplay'
import CssGradient from './Pages/Css/CssGradient'
import ThreeDTransform from './Pages/Css/ThreeDTransform'
import TwoDTransform from './Pages/Css/TwoDTransform'
import CssPadding from './Pages/Css/CssPadding'
import Icons from './Pages/Css/Icons'
import CssAnimation from './Pages/Css/CssAnimation'
//CSS Tutorials End

//JavaScript Tutorials Start
import JavascriptIntroduction from './Pages/JavaScript/JavascriptIntroduction';
import JavascriptDataTypes from './Pages/JavaScript/JavascriptDataTypes';
import JavascriptControlFlow from './Pages/JavaScript/JavascriptControlFlow';
import BrowserObjectModel from './Pages/JavaScript/BrowserObjectModel';
import JavascriptArrays from './Pages/JavaScript/JavascriptArrays';
import AJAX from './Pages/JavaScript/AJAX';
import JavascriptFunctions from './Pages/JavaScript/JavascriptFunctions';
import JavascriptForms from './Pages/JavaScript/JavascriptForms';
import JavascriptEvents from './Pages/JavaScript/JavascriptEvents';
import JavascriptErrorHandling from './Pages/JavaScript/JavascriptErrorHandling';
import DataObjectModel from './Pages/JavaScript/DataObjectModel';
import JavascriptFundamentals from './Pages/JavaScript/JavascriptFundamentals';
import JSON from './Pages/JavaScript/JSON';
import jQuery from './Pages/JavaScript/jQuery';
import GettingStarted from './Pages/JavaScript/GettingStarted';
import JavascriptRegularExpression from './Pages/JavaScript/JavascriptRegularExpression';
import JavascriptObjects from './Pages/JavaScript/JavascriptObjects';
import JavascriptVariables from './Pages/JavaScript/JavascriptVariables';
import JavascriptStrings from './Pages/JavaScript/JavascriptStrings';
//JavaScript Tutorials End

//PHP Tutorials Start
import PhpOverview from './Pages/Php/PhpOverview';
import GettingStartedWithPhp from './Pages/Php/GettingStartedWithPhp';
import PhpSyntaxAndComments from './Pages/Php/PhpSyntaxAndComments';
import PhpVariablesAndConstants from './Pages/Php/PhpVariablesAndConstants';
import PhpEchoPrint from './Pages/Php/PhpEchoPrint';
import PhpDataTypes from './Pages/Php/PhpDataTypes';
import PhpControlStatements from './Pages/Php/PhpControlStatements';
import PhpArrays from './Pages/Php/PhpArrays';
import PhpStrings from './Pages/Php/PhpStrings';
import PhpInclude from './Pages/Php/PhpInclude';
import PhpFileHandling from './Pages/Php/PhpFileHandling';
import PhpDateAndTime from './Pages/Php/PhpDateAndTime';
import PhpFunctions from './Pages/Php/PhpFunctions';
import PhpForm from './Pages/Php/PhpForm';

//PHP Tutorials End

import HrQuesAns1 from './Pages/hr-interview-questions/HrQuesAns1';
import HrQuesAns2 from './Pages/hr-interview-questions/HrQuesAns2';

import QuantSet1 from './Pages/Quantitative/QuantSet1';
import QuantSet2 from './Pages/Quantitative/QuantSet2';
import QuantSet3 from './Pages/Quantitative/QuantSet3';

import SiAndCi from './Pages/Quantitative/SiAndCi';
import Simplification1 from './Pages/Quantitative/Simplification1';
import Average from './Pages/Quantitative/Average';
import PnC from './Pages/Quantitative/PnC';
import ProfitAndLoss from './Pages/Quantitative/ProfitAndLoss';
import SpeedTimeDistance from './Pages/Quantitative/SpeedTimeDistance';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHome, faEnvelope, faAlignLeft } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faHome, faEnvelope, faAlignLeft)

ReactGA.initialize('G-B60N1FF5TB');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
    render() {
        return (
            <>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/homepage" />
                    </Route>
                    <MainLayout path="/homepage" component={Homepage} />
                    <MainLayout path="/courses" component={Courses} />
                    <MainLayout path="/contact-us" component={ContactUs} />
                    <MainLayout path="/feedback" component={Feedback} />
                    <MainLayout path="/programming" component={Programming} />
                    <MainLayout path="/web-development" component={WebDevelopment} />
                    <MainLayout path="/pincode" component={PinCode} />

                    <CoursesLayout path="/java/java-overview" component={JavaOverview} />
                    <CoursesLayout path="/java/setup-environmental-variables-in-java" component={SetupEnvironmentalVariables} />
                    <CoursesLayout path="/java/program-execution-in-java" component={ProgramExecutionInJava} />
                    <CoursesLayout path="/java/first-java-program" component={FirstJavaProgram}/>
                    <CoursesLayout path="/java/jdk-jre-jvm" component={JDKJREJVM}/>
                    <CoursesLayout path="/java/java-keywords" component={JavaKeywords}/>
                    <CoursesLayout path="/java/java-constructor" component={JavaConstructor}/>
                    <CoursesLayout path="/java/java-class-and-object" component={JavaClassAndObject}/>
                    <CoursesLayout path="/java/java-abstraction-encapsulation" component={JavaAbstractionEncapsulation}/>
                    <CoursesLayout path="/java/java-inheritance" component={JavaInheritance}/>
                    <CoursesLayout path="/java/java-polymorphism" component={JavaPolymorphism}/>
                    <CoursesLayout path="/java/java-association" component={JavaAssociation}/>
                    <CoursesLayout path="/java/java-array" component={JavaArray}/>
                    <CoursesLayout path="/java/java-string" component={JavaStrings}/>
                    <CoursesLayout path="/java/java-interface" component={JavaInterface}/>
                    <CoursesLayout path="/java/java-inner-class" component={JavaInnerClass}/>
                    <CoursesLayout path="/java/java-exception-handling" component={JavaExceptionHandling}/>
                    <CoursesLayout path="/java/java-input-output" component={JavaInputOutput}/>
                    <CoursesLayout path="/java/java-awt" component={JavaAwt}/>
                    <CoursesLayout path="/java/java-collection" component={JavaCollection}/>
                    <CoursesLayout path="/java/java-networking" component={JavaNetworking}/>
                    <CoursesLayout path="/java/java-multithreading" component={JavaMultithreading}/>
                    <CoursesLayout path="/java/java-jdbc" component={JavaJDBC}/>
                    <CoursesLayout path="/java/java-regular-expression" component={JavaRegularExpression}/>
                    <CoursesLayout path="/java/java-swing" component={JavaSwing}/>
                    <CoursesLayout path="/java/java-applet" component={JavaApplet}/>

                    <CoursesLayout path="/html/html-introduction" component={HtmlIntroduction}/>
                    <CoursesLayout path="/html/html-marquees" component={HtmlMarquees}/>
                    <CoursesLayout path="/html/html-tags" component={HtmlTags}/>
                    <CoursesLayout path="/html/html-forms" component={HtmlForm}/>
                    <CoursesLayout path="/html/html-anchor-tag" component={HtmlAnchorTags}/>
                    <CoursesLayout path="/html/html-attributes" component={HtmlAttributes}/>
                    <CoursesLayout path="/html/html-canvas" component={HtmlCanvas}/>
                    <CoursesLayout path="/html/html-comments" component={HtmlComments}/>
                    <CoursesLayout path="/html/html-elements" component={HtmlElements}/>
                    <CoursesLayout path="/html/html-Iframes" component={HtmlIframes}/>
                    <CoursesLayout path="/html/html-javascript" component={HtmlJavascript}/>
                    <CoursesLayout path="/html/html-links" component={HtmlLinks}/>
                    <CoursesLayout path="/html/html-lists" component={HtmlLists}/>
                    <CoursesLayout path="/html/html-structure" component={HtmlStructure}/>
                    <CoursesLayout path="/html/html-meta-tag" component={HtmlMetaTag}/>
                    <CoursesLayout path="/html/html-styles" component={HtmlStyles}/>
                    <CoursesLayout path="/html/html-text-format" component={HtmlTextFormattingTags}/>
                    <CoursesLayout path="/html/html-svg" component={HtmlSVG}/>
                    <CoursesLayout path="/html/html-multimedia" component={HtmlMultimedia}/>
                    <CoursesLayout path="/html/html-tables" component={HtmlTable}/>

                    <CoursesLayout path="/c++/cpp-introduction" component={CppIntroduction}/>
                    <CoursesLayout path="/c++/cpp-abstraction" component={Abstraction}/>
                    <CoursesLayout path="/c++/cpp-arrays" component={Arrays}/>
                    <CoursesLayout path="/c++/cpp-class-and-objects" component={ClassAndObject}/>
                    <CoursesLayout path="/c++/cpp-control-statements" component={ControlStatements}/>
                    <CoursesLayout path="/c++/cpp-functions" component={Functions}/>
                    <CoursesLayout path="/c++/cpp-first-program" component={CppFirstProgram}/>
                    <CoursesLayout path="/c++/cpp-encapsulation" component={Encapsulation}/>
                    <CoursesLayout path="/c++/cpp-environment-setup" component={CppEnvironmentSetup}/>
                    <CoursesLayout path="/c++/cpp-exception-handling" component={ExceptionHandling}/>
                    <CoursesLayout path="/c++/cpp-data-types" component={CppDataTypes}/>
                    <CoursesLayout path="/c++/cpp-file-and-streams" component={FileAndStream}/>
                    <CoursesLayout path="/c++/cpp-variables" component={CppVariables}/>
                    <CoursesLayout path="/c++/cpp-interface" component={Interface}/>
                    <CoursesLayout path="/c++/cpp-loops" component={Loops}/>
                    <CoursesLayout path="/c++/cpp-modifiers" component={Modifiers}/>
                    <CoursesLayout path="/c++/cpp-pointers" component={Pointers}/>
                    <CoursesLayout path="/c++/cpp-multithreading" component={Multithreading}/>
                    <CoursesLayout path="/c++/cpp-namespaces" component={Namespace}/>
                    <CoursesLayout path="/c++/cpp-storage-classes" component={StorageClasses}/>
                    <CoursesLayout path="/c++/cpp-strings" component={Strings}/>
                    <CoursesLayout path="/c++/cpp-polymorphism" component={Polymorphism}/>
                    <CoursesLayout path="/c++/cpp-inheritance" component={Inheritance}/>

                    <CoursesLayout path="/c/c-language-introduction" component={CIntroduction}/>
                    <CoursesLayout path="/c/first-program-in-c" component={FirstProgramInC}/>
                    <CoursesLayout path="/c/c-keywords-identifiers" component={CKeywordsAndIdentifiers}/>
                    <CoursesLayout path="/c/c-array" component={CArrays}/>
                    <CoursesLayout path="/c/c-command-line-argument" component={CommandLineArgumentsInC}/>
                    <CoursesLayout path="/c/c-enumeration" component={CEnumeration}/>
                    <CoursesLayout path="/c/c-data-types" component={DataTypesInC}/>
                    <CoursesLayout path="/c/c-control-statements" component={ControlStatementsInC}/>
                    <CoursesLayout path="/c/c-variables-constants" component={VariablesAndConstantsInC}/>
                    <CoursesLayout path="/c/c-type-casting" component={TypeCastingInC}/>
                    <CoursesLayout path="/c/c-pre-processors" component={PreProcessors}/>
                    <CoursesLayout path="/c/c-pointers" component={CPointers}/>
                    <CoursesLayout path="/c/c-operators" component={OperatorsInC}/>
                    <CoursesLayout path="/c/error-handling-in-c" component={ErrorHandlingInC}/>
                    <CoursesLayout path="/c/file-input-output-in-c" component={FileInputOutputInC}/>
                    <CoursesLayout path="/c/strings-in-c" component={StringsInC}/>
                    <CoursesLayout path="/c/memory-management-in-c" component={MemoryManagementInC}/>
                    <CoursesLayout path="/c/loops-in-c" component={LoopsInC}/>
                    <CoursesLayout path="/c/functions-in-c" component={CFunctions}/>

                    <CoursesLayout path="/css/css-introduction" component={CssIntroduction}/>
                    <CoursesLayout path="/css/how-to-use-css" component={HowToUseCss}/>
                    <CoursesLayout path="/css/how-to-add-css" component={HowToAddCss}/>
                    <CoursesLayout path="/css/css-comments" component={CssComments}/>
                    <CoursesLayout path="/css/css-units" component={CssUnits}/>
                    <CoursesLayout path="/css/css-background" component={CssBackground}/>
                    <CoursesLayout path="/css/css-fonts" component={CssFonts}/>
                    <CoursesLayout path="/css/css-text" component={CssText}/>
                    <CoursesLayout path="/css/css-links" component={CssLinks}/>
                    <CoursesLayout path="/css/css-table" component={Table}/>
                    <CoursesLayout path="/css/css-lists" component={CssLists}/>
                    <CoursesLayout path="/css/css-border" component={CssBorder}/>
                    <CoursesLayout path="/css/css-opacity" component={Opacity}/>
                    <CoursesLayout path="/css/css-margin" component={CssMargin}/>
                    <CoursesLayout path="/css/css-shadow-property" component={CssShadowProperty}/>
                    <CoursesLayout path="/css/css-z-index" component={ZIndex}/>
                    <CoursesLayout path="/css/css-transition" component={CssTransition}/>
                    <CoursesLayout path="/css/css-pseudo-elements" component={PseudoElements}/>
                    <CoursesLayout path="/css/css-pseudo-classes" component={PseudoClasses}/>
                    <CoursesLayout path="/css/css-position" component={CssPosition}/>
                    <CoursesLayout path="/css/css-fonts" component={CssFonts}/>
                    <CoursesLayout path="/css/css-flexbox" component={CssFlexbox}/>
                    <CoursesLayout path="/css/css-display" component={CssDisplay}/>
                    <CoursesLayout path="/css/css-gradient" component={CssGradient}/>
                    <CoursesLayout path="/css/css-3d-transform" component={ThreeDTransform}/>
                    <CoursesLayout path="/css/css-2d-transform" component={TwoDTransform}/>
                    <CoursesLayout path="/css/css-padding" component={CssPadding}/>
                    <CoursesLayout path="/css/css-icons" component={Icons}/>
                    <CoursesLayout path="/css/css-animation" component={CssAnimation}/>

                    <CoursesLayout path="/javascript/javascript-introduction" component={JavascriptIntroduction}/>
                    <CoursesLayout path="/javascript/javascript-data-types" component={JavascriptDataTypes}/>
                    <CoursesLayout path="/javascript/javascript-control-flow" component={JavascriptControlFlow}/>
                    <CoursesLayout path="/javascript/javascript-browser-object-model" component={BrowserObjectModel}/>
                    <CoursesLayout path="/javascript/javascript-arrays" component={JavascriptArrays}/>
                    <CoursesLayout path="/javascript/javascript-ajax" component={AJAX}/>
                    <CoursesLayout path="/javascript/javascript-functions" component={JavascriptFunctions}/>
                    <CoursesLayout path="/javascript/javascript-forms" component={JavascriptForms}/>
                    <CoursesLayout path="/javascript/javascript-events" component={JavascriptEvents}/>
                    <CoursesLayout path="/javascript/javascript-error-handling" component={JavascriptErrorHandling}/>
                    <CoursesLayout path="/javascript/javascript-document-object-model" component={DataObjectModel}/>
                    <CoursesLayout path="/javascript/javascript-fundamentals" component={JavascriptFundamentals}/>
                    <CoursesLayout path="/javascript/javascript-json" component={JSON}/>
                    <CoursesLayout path="/javascript/javascript-jquery" component={jQuery}/>
                    <CoursesLayout path="/javascript/javascript-getting-started" component={GettingStarted}/>
                    <CoursesLayout path="/javascript/javascript-regular-expression" component={JavascriptRegularExpression}/>
                    <CoursesLayout path="/javascript/javascript-objects" component={JavascriptObjects}/>
                    <CoursesLayout path="/javascript/javascript-variables" component={JavascriptVariables}/>
                    <CoursesLayout path="/javascript/javascript-strings" component={JavascriptStrings}/>

                    <CoursesLayout path="/hr-interview-questions/hr-interview-questions-set-1" component={HrQuesAns1}></CoursesLayout>
                    <CoursesLayout path="/hr-interview-questions/hr-interview-questions-set-2" component={HrQuesAns2}></CoursesLayout>

                    <CoursesLayout path="/quantitative/quantitative-set-1" component={QuantSet1}/>
                    <CoursesLayout path="/quantitative/quantitative-set-2" component={QuantSet2}/>
                    <CoursesLayout path="/quantitative/quantitative-set-3" component={QuantSet3}/>

                    <CoursesLayout path="/aptitude/si-and-ci" component={SiAndCi}/>
                    <CoursesLayout path="/aptitude/simplification" component={Simplification1}/>
                    <CoursesLayout path="/aptitude/average" component={Average}/>
                    <CoursesLayout path="/aptitude/permutation-and-combination" component={PnC}/>
                    <CoursesLayout path="/aptitude/profit-and-loss" component={ProfitAndLoss}/>
                    <CoursesLayout path="/aptitude/speed-time-distance" component={SpeedTimeDistance}/>

                    <CoursesLayout path="/php/php-overview" component={PhpOverview}/>
                    <CoursesLayout path="/php/getting-started-with-php" component={GettingStartedWithPhp}/>
                    <CoursesLayout path="/php/php-syntax-and-comments" component={PhpSyntaxAndComments}/>
                    <CoursesLayout path="/php/php-variables-and-constants" component={PhpVariablesAndConstants}/>
                    <CoursesLayout path="/php/php-echo-print" component={PhpEchoPrint}/>
                    <CoursesLayout path="/php/php-data-types" component={PhpDataTypes}/>
                    <CoursesLayout path="/php/php-control-statements" component={PhpControlStatements}/>
                    <CoursesLayout path="/php/php-arrays" component={PhpArrays}/>
                    <CoursesLayout path="/php/php-strings" component={PhpStrings}/>
                    <CoursesLayout path="/php/php-overview" component={PhpOverview}/>
                    <CoursesLayout path="/php/php-include" component={PhpInclude}/>
                    <CoursesLayout path="/php/php-file-handling" component={PhpFileHandling}/>
                    <CoursesLayout path="/php/php-date-and-time" component={PhpDateAndTime}/>
                    <CoursesLayout path="/php/php-form" component={PhpForm}/>
                    <CoursesLayout path="/php/php-functions" component={PhpFunctions}/>
                    <CoursesLayout path="/php/php-variable-and-constants" component={PhpVariablesAndConstants}/>
                </Switch>
            </Router>
            </>
        );
    }
}

export default App;
