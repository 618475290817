import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppLoops extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Loops</title>
                    <meta name="description" content = "Looping statements provides a way to repeat a set of statements at a given place." />
                    <meta name="keywords" content="C++ Loops,  C++ Loops Tutorial, Loops , Loops in c++, for loop, while loop, do while loop, nested loop, for loop c++" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Loops " />
                    <meta property="og:description" content="Looping statements provides a way to repeat a set of statements at a given place." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-loops" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Loops</h2>
                    <br/>
                    <p>Looping statements provides a way to repeat a set of statements at a given place.</p>
                    <p>Types of looping statements –</p>
                <p>1. for loop</p>
                <p>2. while loop</p>
                <p>3. do while loop</p>
                <br/><br/>
                <p><strong>1.	for statement</strong> - The for statement provides a compact way to iterate over a range of values.</p>
                <p>For loop is used to repeatedly loops until a particular condition is satisfied</p>
                <pre>
                for(initialization; termination; increment)<br/>
                &#123;<br/>
                    statement(s)<br/>
                &#125;
                </pre>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()&#123;<br/>
                    for(int i=1; i&lt;=5; i++)&#123;<br/>
                        cout &lt;&lt; "Value of variable i is: " &lt;&lt; i &lt;&lt; endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Value of variable i is: 1<br/>
                Value of variable i is: 2<br/>
                Value of variable i is: 3<br/>
                Value of variable i is: 4<br/>
                Value of variable i is: 5
                </pre>
                </div>
                <br/><br/>
                <p><strong>2.	while statement</strong> – The while statement continually executes a block of statements while a particular condition is true. Its syntax can be expressed as -</p>
                <pre>
                while(expression)&#123;<br/>
                    statement(s)<br/>
                &#125;
                </pre>
                <br/>
                <p>The while statement evaluates expression, which must return a boolean value, If the expression evaluates to true, the while statement executes the statement(s) in the while block.</p>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    float number, sum = 0;<br/>
                    <br/>
                    do &#123;<br/>
                        cout &lt;&lt; "Enter a number: ";<br/>
                        cin &gt;&gt; number;<br/>
                        sum += number;<br/>
                    &#125;<br/>
                    while(number != 0);<br/>
                    <br/>
                    cout &lt;&lt; "Total sum = " &lt;&lt; sum;<br/>
                    <br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Enter a number: 43<br/>
                Enter a number: 2<br/>
                Enter a number: 5<br/>
                Enter a number: 10<br/>
                Enter a number: 0<br/>
                Total sum = 60
                </pre>
                </div>
                <br/><br/>
                <p><strong>3.	do - while statement</strong> – do - while evaluates its expressions at the bottom of the loop instead of the top. The statements within the doblock are always executed at least once.</p>
                <pre>
                do&#123;<br/>
                    statement(s)<br/>
                &#125;while(expression);
                </pre>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main() &#123;<br/>
                    int x = 5;<br/>
                    do &#123;<br/>
                        cout &lt;&lt; "X is: " &lt;&lt; x &lt;&lt; endl;<br/>
                        x = x + 1;<br/>
                    &#125; while (x &lt; 10);<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                X is: 5<br/>
                X is: 6<br/>
                X is: 7<br/>
                X is: 8<br/>
                X is: 9
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-control-statements">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-storage-classes">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}