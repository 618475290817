import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaStrings extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Strings</title>
                    <meta name="description" content="Once a object of string is created then it will never be changed that’s why Strings are immutable in nature." />
                    <meta name="keywords" content="java tutorial, core java tutorial, Java String, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Strings" />
                    <meta property="og:description" content="Once a object of string is created then it will never be changed that’s why Strings are immutable in nature." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-string" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span className="tutorial-header-name">Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <hr/>
                <strong style={{fontSize:`25px`}}>Java Strings</strong>
                <p>String is a sequence of characters. It is used to store text.</p>
                <p>Once a object of string is created then it will never be changed that’s why Strings are immutable in nature.</p>
                <p>String can be created easily. For Example: String str=”Hello all of you”;</p>
                <p>You can create string in two ways:</p>
                <p>1.	By string literal</p>
                <p>2.	By new keyword</p>
                <p>In string literal, string is created by using double quotes.</p>
                <p>String str = ”Hello world”;</p>
                <p>You can use the new operator followed by a space and then class name and then in parentheses you can include values used to initialize the fields of the object.</p>
                <p>String str=new String(“Hello World”);</p>
                <br/>
                <strong>Methods of String:</strong>

                <p><strong>1.	int length()</strong> – Returns the no characters in the string.</p>
                <p><strong>Syntax:</strong> str.length();</p>
                <p><strong>For Example:</strong> “tutsfinder”.length();		//retursn 10</p>
                <p><strong>2.	chartAt(int i)</strong> – Returns the character at ith index</p>
                <p><strong>Syntax:</strong> str.charAt(i);</p>
                <p><strong>For example:</strong> “tutsfinder”.charAt(4);		//returns f</p>
                <p><strong>3.	substring(int i)</strong> – Returns the substring from the ith index to the end of the string.</p>
                <p><strong>Syntax:</strong> str.substring(i);</p>
                <p><strong>Example:</strong> “tutsfinder”.substring(4);		// returns finder</p>
                <p><strong>4.	substring(int i,int j)</strong> – Returns the string from ith index to j-1 index.</p>
                <p><strong>Syntax:</strong> str.substring(i,j);</p>
                <p><strong>For example:</strong> “tutsfinder”.substring(4,7)		//returns find </p>
                <p><strong>5.	concat(str)</strong> – Concatenates specified string to the end of the string.</p>
                <p><strong>For Example:</strong></p>
                <p>String s1=”tuts”;</p>
                <p>String s2=”finder”;</p>
                <p>S1.concat(s2);						//returns tutsfinder</p>
                <p><strong>6.	indexOf(String str)</strong> – Returns the index within the string of the first occurrence of the specified string, starting at the specified string.</p>
                <p><strong>For example:</strong> </p>
                <p>String s=new String(“Java Tutorials”);</p>
                <p>s.indexOf(“Tutorials”);			//returns 5</p>
                <p><strong>7.	toLowerCase()</strong> – Coverts all the characters in the string to Lower case.</p>
                <p><strong>Syntax:</strong> str.toLowerCase();</p>
                <p><strong>8.	toUpperCase()</strong> – Converts all the characters in the string to Upper case.</p>
                <p><strong>Syntax:</strong> str.toUpperCase();</p>
                <p><strong>9.	trim()</strong> – It removes the whitespaces from the string and returns the copy of that string. It does not white spaces in the middle.</p>
                <p><strong>Syntax:</strong> str.trim();</p>
                <p><strong>10.	replace(old char, new char)</strong> -  Replaces the old character with new character and returns the new string.</p>
                <p><strong>Syntax:</strong> </p>
                <p>String str=”Java”</p>
                <p>str.replace(‘a’,’x’);&nbsp;				//returns Jxvx</p>

                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-array">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-interface">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}