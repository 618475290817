import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpSyntaxAndComments extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Syntax And Comments</title>
                    <meta name="description" content = "PHP code contains html code and some php scripting files." />
                    <meta name="keywords" content="php syntax, php comments, how to write code in php, php starting, php syntax and comments" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Syntax And Comments" />
                    <meta property="og:description" content="PHP code contains html code and some php scripting files." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-syntax-and-comments" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Syntax</strong>
                    <br/><br/>
                    <p>PHP script starts from &lt;?php and ends with ?&gt;</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                        (//code)<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <p>.php is the file extension for php files.</p>
                    <p>PHP code contains html code and some php scripting files.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php
                        echo "Hello World !";
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <p>Every PHP statement ends with a semi colon(;) – this tells PHP engine that this is the end of our current statement.</p>
                    <br/>
                    <h1>HTML code in PHP –</h1>
                    <p>Inside PHP file we can write HTML code, PHP files are plain text files.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; A Simple PHP File&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; &lt;?php echo "Hello, world!"; ?&gt; &lt;/h1&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <p>So, from this way we can embedded our html code into PHP file with .php extension.</p>
                    <br/>
                    <hr/>
                    <br/>
                    <strong style={{fontSize:`35px`}}>PHP Comments</strong>
                    <p>A PHP comment is always ignored by the PHP engine. Comments are used only for reference purposes.</p>
                    <p>Main purpose of comment is to make the code more understandable and readable to the users. It helps other developers to understand what you have did in your code.</p>
                    <p>PHP supports single line comments as well as multiple line comments.</p>
                    <br/>
                    <p>For single line comments, we can use double slash // or we can either use hash #.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example for single line comment</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Single line comment)<br/>
                    # Single line comment<br/>
                    echo "Hello, world!";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>

                    <p>For multiple line comments we can use slash and asterisk.</p>
                    <p>Multi line comment start with slash followed by an asterisk and ends with asterisk followed by an slash.</p>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example for multi line comment</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (/* This is a multiple line comment<br/>
                    That covers multiple line */)<br/>
                    echo "Hello, world!";
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="getting-started-with-php">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-variables-and-constants">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}