import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssLists extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Lists</title>
                    <meta name="description" content="There are two types of lists in HTML - Ordered list, Unordered list" />
                    <meta name="keywords" content="css list styles templates, css list indent, list style type, html list, css list horizontal, css list style color, list style image, list style position, css style, image css, css value, css name, css characters, a href css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Lists" />
                    <meta property="og:description" content="There are two types of lists in HTML - Ordered list, Unordered list" />
                    <meta property="og:site_name" content="https://www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-lists" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Lists</h2>
                    <br/><br/>

                    <h4>Lists</h4>
                    <p>There are two types of lists in HTML</p>
                    <p>Ordered list</p>
                    <p>Unordered list</p>
                    <br/>
                    <p>We can style these lists with the help of CCS list properties.</p>
                    <p>Following are the properties that we can use to style our lists – </p>
                    <p><strong>1.	List-style-type</strong> – It defines the the type of the list where we can use list-marker like disc, square, circle, lower roman, upper-alpha, none.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    ul&#123;<br/>
                        list-style-type:square;<br/>
                    &#125;<br/>
                    <br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;<br/>
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <ul id="squareList"><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    </ul><br/>
                    <br/>
                    <ul><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    </ul><br/>
                    <br/>
                    <ul><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    <li> Item </li><br/>
                    </ul>
                    <br/><br/>

                    <p><strong>2.	List-style-position</strong> – It specefies the position of the list marker an dit can have only two values that is inside or outside.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    body &#123;<br/>
                    padding-left: 50px;<br/>
                    &#125;<br/>
                    <br/>
                    ul:nth-of-type(1) &#123;<br/>
                    list-style-position: inside;<br/>
                    padding: 0;<br/>
                    border-left: solid 2px red;<br/>
                    &#125;<br/>
                    <br/>
                    ul:nth-of-type(2) &#123;<br/>
                    list-style-position: outside;<br/>
                    padding: 0;<br/>
                    border-left: solid 2px red;<br/>
                    &#125;<br/>
                    <br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>

                    <p><strong>3.	List-style-image</strong> – It specefies that you can also used images for list-marker.</p>
                    <p><strong>4.	List-style</strong> – lt is a shorthand property where we can define multiple properties in on declaration.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    ul &#123;<br/>
                    list-style: square inside;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;ul&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;li&gt; Item &lt;/li&gt;<br/>
                    &lt;/ul&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-table">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-border">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}