import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpInclude extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Include</title>
                    <meta name="description" content = "include () or require() is used to use the content of one file into another php file. We can also use require() for this. include() or require() works same as copying the script from any file and pasted into the location where it is need." />
                    <meta name="keywords" content="include, php include, php include tutorials, php include tutsfinder, require, php include method, php require method" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Include" />
                    <meta property="og:description" content="include () or require() is used to use the content of one file into another php file. We can also use require() for this. include() or require() works same as copying the script from any file and pasted into the location where it is need." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-include" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                    </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP include</strong>
                    <br/><br/>
                    <p>include () or require() is used to use the content of one file into another php file. We can also use require() for this. include() or require() works same as copying the script from any file and pasted into the location where it is need.</p>
                    <p><strong>Syntax</strong></p>
                    <p>include("path/to/filename"); -Or- include "path/to/filename";</p>
                    <p>require("path/to/filename"); -Or- require "path/to/filename";</p>
                    <h2>Example</h2>
                    <p>First of all we create a PHP file. Suppose that we created a file called demo.php, which is later called by another PHP file.</p>

                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Demo.php </h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;a href="http://www.tutsfinder.in/"&gt;Home&lt;/a&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <p>Now we have created a new file called include.php and now we will call the above php file in include.php file.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Include.php </h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;html&gt;<br/>
                    &lt;body bgcolor="pink"&gt;<br/>
                    &lt;?php<br/>
                    include("demo.php");<br/>
                    ?&gt;<br/>
                    &lt;h2&gt;&lt;b&gt;Welcome to TutsFinder&lt;/b&gt;&lt;/h2&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-form">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}