import React from 'react';
// import axios from 'axios';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Footer extends React.Component {
    state = {
        email: ''
    }
    handleChange = event => {
        this.setState({ email: event.target.value });
        console.log("key presses", this.state.email);
    }
    subscribe() {
        const data = {
            email: this.state.email
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({data})
        };
        fetch('http://localhost:4000/subscribe', requestOptions)
            .then(response => response.json())
            // .then(data => this.setState({ postId: data.id }));
    };
    render() {
        return(
            <footer className="footer">
                <div className="footer_top">
                    <div className="container">
                        <div className="newsLetter_wrap">
                            <div className="row justify-content-between">
                                <div className="col-md-7">
                                    <div className="footer_widget">
                                        <h3 className="footer_title">
                                            Stay Updated
                                        </h3>
                                        <form className="newsletter_form">
                                            <input type="email" name="email" placeholder="Email Address" onChange={this.handleChange} required/>
                                            <button type="submit" name="submit" onClick={() => this.subscribe()}>Subscribe Now</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-5">
                                    <div className="footer_widget">
                                        <h3 className="footer_title">
                                            Stay Updated
                                        </h3>
                                        <div className="socail_links">
                                            <ul>
                                                <li>
                                                    <a href="https://www.facebook.com/tutsfinder/">
                                                        <FontAwesomeIcon icon={['fab', 'facebook']} size="lg"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/tutsfinder/">
                                                        <FontAwesomeIcon icon={['fab', 'instagram']} size="lg"/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/tutsfinder">
                                                        <FontAwesomeIcon icon={['fab', 'linkedin']} size="lg"/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" align="center">
                            <div className="col-xl-4 col-md-6 col-lg-4">
                                <div className="footer_widget">
                                    <h3 className="footer_title">
                                        <a href="https://tutsfinder.com/homepage"><img src="../img/1-removebg.png" alt="logo" width="300px"/> </a>
                                    </h3>
                                </div>
                        </div>
                            <div className="col-xl-4 col-md-6 col-lg-4">
                                <div className="footer_widget">
                                    <h3 className="footer_title">
                                        Services
                                    </h3>
                                    <ul>
                                        <li>Website Development</li>
                                        <li>Contact <a href="mailto:info@tutsfinder.com">info@tutsfinder.com</a></li>
                                        <li >
                                            <div className="fb-like" data-href="https://facebook.com/tutsfinder" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-lg-4" >
                                <div className="footer_widget">
                                    <h3 className="footer_title">
                                        Support
                                    </h3>
                                    <ul>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        <li><Link to="/feedback">Site feedback</Link></li>
                                        <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right_text">
                    <div className="container">
                        <div className="footer_border"></div>
                        <div className="row">
                            <div className="col-xl-12">
                                <p className="copy_right text-center">
                                    <span className="text-center">Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved</span>
                                    <span className="text-center"><a href="privacy_policy.html" style={{color: `white`}}> Privacy Policy</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}