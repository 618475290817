import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaPolymorphism extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Networking</title>
                    <meta name="description" content="Java programming language have java.net package for networking programs. This package contains all classes and interfaces that are used in to share resources via network." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Networking" />
                    <meta property="og:description" content="Java programming language have java.net package for networking programs. This package contains all classes and interfaces that are used in to share resources via network." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-networking" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Networking</strong>
                    <br/><br/>
                <p>Transmission of data from one system to another that connected via network.</p>
                <p>Java programming language have java.net package for networking programs. This package contains all classes and interfaces that are used in to share resources via network.</p>
                <p>These two protocols are used in networking – </p>
                <p>1.	TCP</p>
                <p>2.	UDP</p>
                <h4>TCP – Transmission Control Protocol</h4>
                <p>This is an Internet Protocol that provides for the reliable delivery of data from one host to another.</p>
                <p>It is based on IP.</p>
                <h4>UDP – User Datagram Protocol</h4>
                <p>It is a connection-less protocol, that enables packets of data to be transmitted between application.</p>
                <p>Some classes of java.net package –</p>
                <p>Inet address- This class represents an Internet Protocol address as two fields: hostname and address.</p>
                <p><strong>URL-</strong> It is an acronym of Uniform Resource Locator .A standard for writing a text refrence to an arbitrary piece of data in www. A URL looks like 1 ”protocol://host/localinfo”.</p>
                <p>Where protocol specifies a set of rules for communication.</p>
                <p><strong>For Example:</strong> TCP, FTP, Telnet, SMTP, POP etc.</p>
                <p><strong>URL Connection-</strong> This class represents the communication between the application and a URL.</p>
                <p><strong>Socket-</strong> java.net class implements socket .Socket is one endpoint between two way communications between two programs running on the internet.</p>
                <p><strong>Server Socket-</strong> This class allows to act like a server for connection oriented connections. </p>
                <p><strong>Datagram Packet –</strong> It is a connectionless socket that is used to implement a connection less delivery service.</p>
                <p><strong>Datagram Socket –</strong> This class represents socket for sending and receiving datagram packets.</p>
                <strong>Serialization </strong> <p>In short , serialization is converting a file into byte stream. The objects in the file is converted to the bytes for security purposes.
                We need to import java.io.serialization.</p>
                <p><strong>Deserialization</strong> is vice versa of serialization in which bytes stream is converted into files.</p>
                <p>ObjectOutputStream.writeObject  Serialize the object and write the serialized object to a file.</p>
                <p>ObjectInputStream.readObject  Read the file and deserializes the object.</p>
                Note :- We can skip the serialization for the variables in the file by using a Transient keyword.
                <br/><br/>
                <strong>Now , what is Transient variable?</strong>
                <p>These are not the part of serialization. During deserialization the transient variables values are set to default values. It is not used with static variables.
                </p>
                <strong>Program by extending thread class</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                class ThreadExtend extends Thread&#123;<br/>
                    public void run()&#123;<br/>
                        System.out.println("Thread Created...");<br/>
                    &#125;<br/>
                    public static void main(String[] args)&#123;<br/>
                    ThreadExtend obj=new ThreadExtend();<br/>
                    obj.start();<br/>
                    &#125;<br/>
                &#125;
                    </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                Thread Created...
                </pre>
                </div>
                <br/><br/>
                <strong>Program by implementing runnable interface.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                class ThreadImplement implements Runnable&#123;<br/>
                    public void run()&#123;<br/>
                        System.out.println("Thread Created...");<br/>
                    &#125;<br/>
                    public static void main(String[] args)&#123;<br/>
                    Thread obj = new Thread(new ThreadImplement());<br/>
                obj.start();<br/>
                    &#125;<br/>
                &#125;
                    </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                Thread Created...
                </pre>
                </div>
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-multithreading">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-input-output">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}