import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssShadowProperty extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Shadow Property</title>
                    <meta name="description" content=">With this property we can able to add show around text and images." />
                    <meta name="keywords" content="shadow property, css shadow property, shadow property in css, shadow property tutsfinder, tutsfinder, text shadow, box shadow, shadow propertyw3schools, shadow property javatpoint, shadow property tutorials" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Shadow Property" />
                    <meta property="og:description" content=">With this property we can able to add show around text and images." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-shadow-property" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2>CSS Shadow Property</h2>
                    <br/><br/>

                    <p>With this property we can able to add show around text and images.</p>
                    <p>Text-shadow</p>
                    <p>Box-shadow</p>
                    <br/><br/>

                    <p><strong>Text-shadow</strong> property is used to add shadow on text.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;
                    &lt;html&gt;
                    &lt;head&gt;
                    &lt;style&gt;
                    h1 &#123;
                    color: yellow;
                    text-shadow: 2px 2px black;
                    &#125;
                    &lt;/style&gt;
                    &lt;/head&gt;
                    &lt;body&gt;

                    &lt;h1&gt; TutsFinder - Elementary way to find &lt;/h1&gt;

                    &lt;/body&gt;
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/text-shadow.png" alt="display" style={{width:`100%`}} />
                    <br/><br/>
                    <p><strong>Box-shadow</strong> can be applied on elements such as &lt;div&gt; and &lt;section&gt;.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;
                    &lt;html&gt;
                    &lt;head&gt;
                    &lt;style&gt;
                    div &#123;
                    width: 200px;
                    height: 100px;
                    background-color: black;
                    box-shadow: 10px 10px grey;
                    &#125;
                    &lt;/style&gt;
                    &lt;/head&gt;
                    &lt;body&gt;

                    &lt;h1&gt;box-shadow Property&lt;/h1&gt;

                    &lt;div&gt; &lt;/div&gt;

                    &lt;/body&gt;
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/box-shadow.png" alt="display" style={{width:`70%`}} />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-z-index">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-pseudo-classes">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}