import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppFirstProgram extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - How to write first program in C++</title>
                    <meta name="description" content="Now that you have know about tools, it is time to program your first C++ program." />
                    <meta name="keywords" content="How to write first program in C++, C++ first program, hello world program in c++, C++ first program Tutorial, first program in c++" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - How to write first program in C++" />
                    <meta property="og:description" content="Now that you have know about tools, it is time to program your first C++ program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-first-program" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>First Program in C++</h2>
                    <br/>
                    <p>Now that you have know about tools, it is time to program your first C++ program.</p>
                <br/>
                <p>Writing a program is first step towards creating an executable. The basic steps in creating applications in C++, If you are on windows, you can follow these steps – </p>
                <br/>
                <p>1.	Open any text editor and write your code.</p>
                <p>2.	Save it with any name with .cpp extension.</p>
                <p>3.	If you are using Turbo C++ press , Ctrl+F9 to run the program.</p>
                <p>But we are using Blocks to run C++ code.</p>
                <br/>
                <h4>Program to print Hello World – </h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main() &#123;<br/>
                    cout &lt;&lt; " Hello World ! ";<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                <img src="../img/c++/first-1.png" alt="Output" style={{width:`100%`}} />
                <br/>
                <br/>
                <h4>Output:</h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Hello World !
                </pre>
                </div>
                <br/><br/>
                <img src="../img/c++/first-2.png" alt="Output" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row" >
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-environment-setup">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-variables">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}