import React from 'react';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import GoogleAd from '../Components/GoogleAd';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Programming Tutorials</title>
                    <meta name="keywords" content="tutsfinder programming tutorials,  programming Tutorials, c, c++, java, c language" />

                    <meta property="og:title" content="TutsFinder - Programming Tutorials" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/web-development" />
                </MetaTags>
                <GoogleAd/>
                <div className="popular_program_area section__padding" style={{paddingTop:`0px`, paddingBottom:`0px`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Programming Languages</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent" style={{paddingTop:`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/c/c-language-introduction"><img src="img/c.jpg" alt="C" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>C Language</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/c++/cpp-introduction"><img src="img/c++.jpg" alt="C++" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>C++</h4>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/java/java-overview"><img src="img/java.jfif" alt="" /></Link>
                                            </div>
                                            <div className="program__content" style={{padding:`10px 10px`}}>
                                                <span></span>
                                                <h4>Core Java</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br/><br/><br/>
                </div>
            </>
        )
    }
}