import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ZIndex extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Z Index</title>
                    <meta name="description" content="Z-index property defines the stack level of an element. Stack level refers to the position of the element on the z-index." />
                    <meta name="keywords" content="Z-index, Z-index css, z index property, Z-index property, tutsfinder, max z index, z index not working, z index w3schools, z index bootstrap 4, z index inside z index, z index always on top, bring div to front without z index, z index mdn" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Z Index" />
                    <meta property="og:description" content="Z-index property defines the stack level of an element. Stack level refers to the position of the element on the z-index." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-z-index" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Z-Index</h2>
                    <br/><br/>

                    <p>Z-index property defines the stack level of an element. Stack level refers to the position of the element on the z-index.</p>
                    <p>The element who have more stack level is always in front of another element who have less stack level.</p>
                    <br/>
                    <h4>CSS Syntax for Z-index</h4>
                    <br/>
                    z-index: auto|number|initial|inherit;


                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    .div1&#123;<br/>
                        width: 800px;<br/>
                    height: 800px;<br/>
                    background: red;<br/>
                    position: absolute;<br/>
                    z-index: 9999;<br/>
                    &#125;<br/>
                    .div2&#123;<br/>
                        width: 600px;<br/>
                    height: 600px;<br/>
                    background: blue;<br/>
                    position: absolute;<br/>
                    z-index: 9999;<br/>
                    &#125;<br/>
                    .div3&#123;<br/>
                        width: 200px;<br/>
                    height: 200px;<br/>
                    background: green;<br/>
                    position: absolute;<br/>
                    z-index: 9999;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                        &lt;body&gt;<br/>
                            &lt;div className="div1"&gt;<br/>
                            &lt;/div&gt;<br/>
                            &lt;div className="div2"&gt;<br/>
                            &lt;/div&gt;<br/>
                            &lt;div className="div3"&gt;<br/>
                            &lt;/div&gt;<br/>
                        &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/z-index.png" alt="display" style={{width:`50%`}} />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-icons">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-shadow-property">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}