import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Introduction</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Introduction" />
                    <meta property="og:description" content="" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Comments</h2>
                    <br/><br/>
                    <p>CSS comments are same as HTML comments.  Comments are used to add explanatory notes about the code that makes code easy to understand for others. </p>
                    <p>It is a good habbit for coders if they use comments.</p>
                    <p>Comments can be used as single lines and as mutiple lines</p>
                    <p>Comments can be placed in between /* and */.</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt; /* Style start from here. This is a single line comment.*/<br/>
                    h1&#123;<br/>
                    color:blue;<br/>
                    text-align:center;<br/>
                    &#125;<br/>
                    p &#123;<br/>
                    color: red;<br/>
                    text-align: center;<br/>
                    font-size:40px;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    (/*Multiple line comments<br/>
                    Body tag start from here<br/>
                    */)<br/>
                    &lt;h1&gt;Hello World!&lt;/h1&gt;<br/>
                    &lt;p&gt;Welcome to TutsFinder&lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/comments.JPG" alt="CSS Comments" />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="how-to-add-css">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-units">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}