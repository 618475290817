import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlForm extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Forms </title>
                    <meta name="description" content="An HTML form is used when you want to collect the input from users. The user input then sent to a server for processing." />
                    <meta name="keywords" content="student form, html student form, login form, html login form, Html forms, Html forms Tutorial, forms in html, html forms tutsfinder, html form , html student form, how to create form in html" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Forms" />
                    <meta property="og:description" content="An HTML form is used when you want to collect the input from users. The user input then sent to a server for processing." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-forms" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span className="tutorial-header-name">HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Forms</h2>
                    <br/><br/>
                <p>An HTML form is used when you want to collect the input from users.</p>
                <p>The user input then sent to a server for processing.</p>
                <br/>
                <p>Let us take an simple example to understand the use of HTML forms:</p>
                <p>During user registration you would like to collect information such as name, email address, password etc.</p>
                <br/>
                <p>When the registration is completed entering the required fields the data is posted to a back end application such as CGI, ASP script, PHP Script etc. The back end application will perform required processing on the passed data based on defined business logic inside the application.</p>
                <br/>
                Syntax for HTML form:
                <br/>
                <pre>
                &lt;form action = “Script URL” method = “GET|POST”&gt;
                Form elements like radio button, input etc.
                &lt;/form&gt;
                </pre>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;HTML Forms&lt;/h1&gt;<br/>
                &lt;form action="/action_page.php"&gt;<br/>
                &lt;label for="fname"&gt; First name: &lt;/label&gt;<br/>
                &lt;br&gt;<br/>
                &lt;input type="text" id="fname" name="fname" value="John"&gt;<br/>
                &lt;br&gt;<br/>
                &lt;label for="lname"&gt;Last name:&lt;/label&gt;<br/>
                &lt;br&gt;<br/>
                &lt;input type="text" id="lname" name="lname" value="Doe"&gt;<br/>
                &lt;br&gt;&lt;br&gt;<br/>
                &lt;input type="submit" value="Submit"&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;p&gt;If you click the "Submit" button, the form-data will be sent to a page called "/action_page.php".&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>Form Attributes</h4>
                <br/>
                <p>Some attributes of form:</p>
                <p><strong>1.	action</strong> – backend script ready to process your passed data.</p>
                <p><strong>2.	Method</strong> – method to be used to upload data, most commonly method that are used GET and POST.</p>
                <p><strong>3.	Target</strong> – specify the target window or frame where the result of the script will be displayed.</p>
                <p><strong>4.	enctype</strong> – this attribute is used to specify how the browser encodes the data before it sends it to the browser.</p>
                <br/>
                <h4>HTML Form Controls</h4>
                <h5>•	Text Input Controls</h5>
                <p>There are three types of text input controls used in forms:</p>
                <p><strong>1.	Single line text input controls</strong>- this control is used for items that require only one line of user input such as names, they are created using HTML &lt;input&gt; tag.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Text input control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                First name: &lt;input type = “text” name = “first_name” /&gt;<br/>
                &lt;br&gt;&lt;br&gt;<br/>
                Last name: &lt;input type = “text” name = “last_name” /&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form1.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h5>Attributes of input tag:</h5>
                <br/><br/>
                <img src="../img/html/input.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>2.	Password input Controls</h4>
                <p>This control is used to secure ur text, which makes the text itno characters as soon as user enter it, they are also created using &lt;input&gt; tag but type attribute is set to password.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Password Input control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                User Id : &lt;input type = “text” name = “user_id”/&gt;<br/>
                &lt;br&gt;&lt;br&gt;<br/>
                Password &lt;input type = “password” name = "password"&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form2.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h5>Attributes of password tag:</h5>
                <br/><br/>
                <img src="../img/html/password.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>3.	Multiple-line text input Controls</h4>
                <p>This control is used when user is required to give the details in more than a single sentence. It is created using &lt;textarea&gt; tag.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                Description:<br/>
                &lt;br&gt;<br/>
                &lt;textarea rows="4" cols="50" name="description"&gt;<br/>
                Describe here..<br/>
                &lt;/textarea&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form3.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attributes of textarea tag:
                <br/><br/>
                <img src="../img/html/textarea.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>4.	Checkbox Control</h4>
                <p>This control is used when more than one option is required to be selected.</p>
                <p>They are created using &lt;input&gt; tag but type attribute is set to checkbox.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Checkbox Control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                &lt;input type = "checkbox" name = "English" value = "on"&gt; English<br/>
                &lt;input type = "checkbox" name = "Business Studies" value = "on"&gt; Business Studies<br/>
                &lt;/form&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form4.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attributes of Checkbox control:
                <br/><br/>
                <img src="../img/html/checkbox.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>5.	Radio Button Control</h4>
                <p>Radio buttons are used when user wants to select only one option out of many options.</p>
                <p>They are also created using &lt;input&gt; tag and type attribute is set to radio.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Radiobox Control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                &lt;input type = "radio" name = "subject" value = "English"&gt; English<br/>
                &lt;input type = "radio" name = "Subject" value = "Business Studies"&gt; Business Studies<br/>
                &lt;/form&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form5.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attributes of Radio box Control:
                <br/><br/>
                <img src="../img/html/radio-box.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>6.	Select Box Control</h4>
                <p>Select box or drop down box provides option to list down various options in the form of drop down list, from where user can select one or more options.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Select Box Control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                &lt;select name = “dropdown”&gt;<br/>
                &lt;option value = “Engineer” selected&gt; Engineer &lt;/option&gt;<br/>
                &lt;option value = “Doctor” selected&gt; Doctor &lt;/option&gt;<br/>
                &lt;/select&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form6.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attributes of Select and option Tag:
                <br/><br/>
                <img src="../img/html/select-option.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>7.	Button Controls</h4>
                <p>These are use to create clickable buttons, also be created using &lt;input&gt; tag by setting type attribute to button.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Button Control &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                &lt;input type = "submit" name = "submit" value = "Submit"  /&gt;<br/>
                &lt;input type = "reset" name = "reset" value = "Reset" //&gt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form7.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attribute of Button Control:
                <br/><br/>
                <img src="../img/html/button.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                <h4>8.	File Upload Box</h4>
                <p>It is used when you want to allow a user to upload a file to your web site.</p>
                <p>This is also created using &lt;input&gt; tag and setting the type attribute to file.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Upload Box &lt;/title&gt;<br/>
                &lt;head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;form&gt;<br/>
                &lt;input type = "file" name = "fileupload" accept = “"image/*" /&lt;<br/>
                &lt;/form&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <img src="../img/html/form8.png" alt="Form" style={{width:`100%`}} />
                <br/><br/>
                Attributes of file upload box:
                <br/><br/>
                <img src="../img/html/upload-box.png" alt="Form" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-marquees">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-iframes">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}