import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssUnits extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Units</title>
                    <meta name="description" content="CSS Units are used to specify length and measurements units in your style such as “padding:10px”. There are two type of Units in CSS – 1" />
                    <meta name="keywords" content="units, css units, units in css, css units tutsfinder, tutstfinder, absolute length, facebook, relative length, instagram, whatsapp" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Units" />
                    <meta property="og:description" content="CSS Units are used to specify length and measurements units in your style such as “padding:10px”. There are two type of Units in CSS – 1" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-units" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Units</h2>
                    <br/><br/>
                    <p>CSS Units are used to specify length and measurements units in your style such as “padding:10px”. There are two type of Units in CSS – 1</p>
                    <p>•	Absolute length</p>
                    <p>•	Relative length</p>
                    <br/>
                    <h4>Absolute length</h4>
                    <p>Absolute length are fixed, It appears according to size of the device. It is not good for use on screen because screen size vary acoording to device display.</p>
                    <br/>
                    <h4>Relative length</h4>
                    <p>Relative length is good for different redering screens because it can make size of the text relative to everything else in the page.</p>
                    <br/>
                    <div className="container1" >
                    <table className="cont">
                    <tbody>
                    <tr>
                    <th scope="col">Unit</th>
                    <th scope="col">Name</th>
                    <th scope="col">Equivalent to</th>
                    </tr>

                    <tr>
                    <td><code>cm</code></td>
                    <td>Centimeters</td>
                    <td>1cm = 96px/2.54</td>
                    </tr>
                    <tr>
                    <td><code>mm</code></td>
                    <td>Millimeters</td>
                    <td>1mm = 1/10th of 1cm</td>
                    </tr>
                    <tr>
                    <td><code>Q</code></td>
                    <td>Quarter-millimeters</td>
                    <td>1Q = 1/40th of 1cm</td>
                    </tr>
                    <tr>
                    <td><code>in</code></td>
                    <td>Inches</td>
                    <td>1in = 2.54cm = 96px</td>
                    </tr>
                    <tr>
                    <td><code>pc</code></td>
                    <td>Picas</td>
                    <td>1pc = 1/6th of 1in</td>
                    </tr>
                    <tr>
                    <td><code>pt</code></td>
                    <td>Points</td>
                    <td>1pt = 1/72th of 1in</td>
                    </tr>
                    <tr>
                    <td><code>px</code></td>
                    <td>Pixels</td>
                    <td>1px = 1/96th of 1in</td>
                    </tr>
                    </tbody>
                    </table>
                    </div>

                    <br/><br/>

                    <table className="standard-table cont">
                    <tbody>
                    <tr>
                    <th scope="col">Unit</th>
                    <th scope="col">Relative to</th>
                    </tr>
                    <tr>
                    <td><code>em</code></td>
                    <td>Font size of the parent, in the case of typographical properties like <code><a href="/en-US/docs/Web/CSS/font-size">font-size</a></code>, and font size of the element itself, in the case of other properties like <code><a href="/en-US/docs/Web/CSS/width">width</a></code>.</td>
                    </tr>
                    <tr>
                    <td><code>ex</code></td>
                    <td>x-height of the element's font.</td>
                    </tr>
                    <tr>
                    <td><code>ch</code></td>
                    <td>The advance measure (width) of the glyph "0" of the element's font.</td>
                    </tr>
                    <tr>
                    <td><code>rem</code></td>
                    <td>Font size of the root element.</td>
                    </tr>
                    <tr>
                    <td><code>lh</code></td>
                    <td>Line height of the element.</td>
                    </tr>
                    <tr>
                    <td><code>vw</code></td>
                    <td>1% of the viewport's width.</td>
                    </tr>
                    <tr>
                    <td><code>vh</code></td>
                    <td>1% of the viewport's height.</td>
                    </tr>
                    <tr>
                    <td><code>vmin</code></td>
                    <td>1% of the viewport's smaller dimension.</td>
                    </tr>
                    <tr>
                    <td><code>vmax</code></td>
                    <td>1% of the viewport's larger dimension.</td>
                    </tr>
                    </tbody>
                    </table>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-comments">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-background">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}