import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaExceptionHandling extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Exception Handling</title>
                    <meta name="description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Exception Handling" />
                    <meta property="og:description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-exception-handling" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Exception Handling</strong>
                    <br/><br/>
                    <h4>What is Exception?</h4>
                <p>Exception is an event that occurs during an execution of a program that disrupts the normal flow the program.</p>
                <p>To handle these exceptions, there be a concept in java that is Exception handling; it maintains the normal flow of the program.</p>
                <br/>
                <p>Main advantage of exception handling is that it’s maintain the normal flow of the program and Separate error handling code form normal code.</p>
                <p>Exception can be occurred due to wrong input data e.g. divide by zero.</p>
                <br/>
                <p>Types of Exceptions —</p>
                <p>•	Checked exceptions — All exceptions except runtime exceptions are checked exceptions.</p>
                <p>•	Unchecked exceptions — All runtime exceptions are unchecked exceptions.</p>
                <p>•	Errors — Errors are not recoverable.</p>
                <br/>
                <strong>Try / catch block </strong>– <p>Error prone code is placed in try block and catch block is used to handle that error, try block should be always followed by catch or finally block.</p>
                <br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                <strong>Syntax:</strong>
                try{
                //error prone code
                }
                Catch(Exception name xyz){
                //catch block
                }
                </pre>
                </div>

                <br/><br/>

                <strong>Finally</strong> – <p>In this block, we placed important code that should be executed whether the exception is handled or not.</p>
                <br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                        <strong>Syntax:</strong>
                        try{
                        //error prone code
                        }
                        Finally{
                        }
                    </pre>
                </div>
                <br/><br/>
                <strong>Throw</strong> – <p>Throw keyword that allows the user to throw an exception or any class that implements “throwable” exception.</p>
                <strong>Throws</strong> – <p>A java keyword used in method declarations that specify which exceptions are not handled within the method but rather passed through the next higher level of the program.</p>
                <strong>A basic Program to show how try/catch block works.</strong>
                <p>In this example, we have divided the no. by zero and it gives an error.</p>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                public class TryCatchExample &#123;<br/>
                    public static void main(String[] args) &#123;<br/>
                        try<br/>
                        &#123;<br/>
                        int number=10/0;<br/>
                        &#125;<br/>
                        <br/>
                        catch(Exception e)<br/>
                        &#123;<br/>

                            System.out.println("Number can't divided by zero.");<br/>
                            &#125;<br/>
                        &#125;<br/>

                    &#125;
                    </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                    Number can't divided by zero.
                </pre>
                </div>
                <br/><br/>
                <strong>Program to show how finally block executes.</strong>
                <p>Here in try block we placed a error prone code and handle in catch block and in finally block we placed our rest code.</p>
                <br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                    public class FinallyBlock&#123;<br/>
                    public static void main(String args[])&#123;<br/>
                    try&#123;<br/>
                    int number=10/0;<br/>
                    System.out.println(number);<br/>
                    &#125;<br/>
                    catch(ArithmeticException e)&#123;<br/>
                    System.out.println(e);<br/>
                    &#125;<br/>
                    finally&#123;<br/>
                    System.out.println("Finally Block is always executed either error is caught or not.");<br/>
                    &#125;<br/>
                    System.out.println(" Output is: " + 10/10);<br/>
                    &#125;<br/>
                    &#125;<br/>
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                java.lang.ArithmeticException: / by zero<br/>
                Finally Block is always executed either error is caught or not.<br/>
                Output is: 1
                </pre>
                </div>
                    <br/><br/>
                    <strong>Program to show how throw keyword works.</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                class JavaThrow<br/>
                &#123;<br/>
                    public static void main(String args[])<br/>
                    &#123;<br/>
                        try<br/>
                        &#123;<br/>
                            throw new NullPointerException("Example");<br/>
                        &#125;<br/>
                        catch(NullPointerException e)<br/>
                        &#123;<br/>
                            System.out.println("Exception in main caught.");<br/>
                        &#125;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                Exception in main caught.
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row" >
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-inner-class">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-regular-expression">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}