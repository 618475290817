import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ProfitAndLoss extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Profit & Loss</title>
                    <meta name="keywords" content="profit & loss, profit, loss, profit and loss tutsfinder, aptitude questions for interview, aptitude question answer tutsfinder, online aptitude questions and answers, 100 aptitude questions and answers, logical reasoning aptitude questions, aptitude questions pdf, mathematics aptitude test questions and answers pdf, aptitude questions on average, aptitude questions meaning, train aptitude questions, job question, pass question, talent question, quantitative aptitude for competitive, quantitative aptitude, rapid quantitative aptitude, how to prepare for quantitative, quantitative aptitude and reas, course in mental ability and" />

                    <meta property="og:title" content="TutsFinder - Profit & Loss" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/quantitative/profit-and-loss" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Profit & Loss</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>Quantitative Ability Questions</h2>
                    <br/><br/><br/><br/>
                    <img src="../img/quant/profit-loss/1.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/2.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/3.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/4.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/5.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/6.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/7.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/8.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/9.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/10.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/11.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/12.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/13.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/14.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/15.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/16.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/17.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/18.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/19.JPG" width="100%" alt="profit and loss" />
                    <img src="../img/quant/profit-loss/20.JPG" width="100%" alt="profit and loss" />
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="speed-time-distance">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="si-and-ci">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}