import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptErrorHandling extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    myFunction() {
        var str = "TutsFinder - Elementary way to find";
        try {
            alert(str);
        }
        catch (e) {
            alert("Error: " + e.description );
        }
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Error Handling</title>
                    <meta name="description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta name="keywords" content="javascript error handling best practices, javascript error message, throw error javascript, try catch javascript, javascript catch specific error, javascript throw error with status code, try catch finally javascript, type error in javascript" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Error Handling" />
                    <meta property="og:description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-error-handling" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>Error Handling</h2>
                    <br/><br/>
                    <p>Exception is an event that occurs during an execution of a program that disrupts the normal flow the program.</p>
                    <strong>Try / catch block </strong>– <p>Error prone code is placed in try block and catch block is used to handle that error, try block should be always followed by catch or finally block.</p>
                    <br/>
                    <div className="container" id="java-program">
                    <strong>Syntax:</strong>
                    <pre>
                    {`
                    try{
                    //error prone code
                    }
                    Catch(Exception name xyz){
                    //catch block
                    }
                    `}
                    </pre>
                    </div>

                    <br/><br/>

                    <strong>Finally</strong> – <p>In this block, we placed important code that should be executed whether the exception is handled or not.</p>
                    <br/>
                    <div className="container" id="java-program">
                    <strong>Syntax:</strong>
                    <pre>
                    {`
                    try{
                    //error prone code
                    }
                    Finally{
                    }
                    `}
                    </pre>
                    </div>
                    <br/><br/>

                    <strong>Throw</strong> – <p>Throw keyword that allows the user to throw an exception or any class that implements “throwable” exception.</p>
                    <strong>Throws</strong> – <p>A keyword used in method declarations that specify which exceptions are not handled within the method but rather passed through the next higher level of the program.</p>
                    <h3>Syntax:</h3>
                    <pre>
                    {`
                    &lt;script&gt; type = "text/javascript"&gt;
                        try {
                            // Code to run
                            [break;]
                        }

                        catch ( e ) {
                            // Code to run if an exception occurs
                            [break;]
                        }

                        [ finally {
                            // Code that is always executed regardless of
                            // an exception occurring
                        }]
                    &lt;/script&gt;
                    `}
                    </pre>


                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;script type = "text/javascript"&gt;<br/>
                                function myFunction() &#123;<br/>
                                var str = "TutsFinder - Elementary way to find";<br/>
                                try &#123;<br/>
                                    alert(str);<br/>
                                &#125;<br/>
                                catch ( e ) &#123;<br/>
                                    alert("Error: " + e.description );<br/>
                                &#125;<br/>
                                &#125;<br/>
                        &lt;/script&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;form&gt;<br/>
                            &lt;input type = "button" value = "Click Me" onClick = "myFunction()" /&gt;<br/>
                        &lt;/form&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                        <form>
                            <input type = "button" value = "Click Me" onClick = {this.myFunction} />
                        </form>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-ajax">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-regular-expression">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}