import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HrQuesAns1 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Speed, Time & Distance</title>
                    <meta name="keywords" content="Speed, Time, Distance, Speed, Time & Distance tutsfinder, Speed, Time and Distance, aptitude questions for interview, aptitude question answer tutsfinder, online aptitude questions and answers, 100 aptitude questions and answers, logical reasoning aptitude questions, aptitude questions pdf, mathematics aptitude test questions and answers pdf, aptitude questions on average, aptitude questions meaning, train aptitude questions, job question, pass question, talent question, quantitative aptitude for competitive, quantitative aptitude, rapid quantitative aptitude, how to prepare for quantitative, quantitative aptitude and reas, course in mental ability and" />

                    <meta property="og:title" content="TutsFinder - Speed, Time & Distance" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/quantitative/speed-time-distance" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Speed, Time & Distance</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>Quantitative Ability Questions</h2>
                    <br/><br/><br/><br/>
                    <img src="../img/quant/std/1.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/2.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/3.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/4.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/5.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/6.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/7.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/8.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/9.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/10.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/11.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/12.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/13.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/14.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/15.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/16.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/17.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/18.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/19.JPG" width="100%" alt="Speed, time and distance" />
                    <img src="../img/quant/std/20.JPG" width="100%" alt="Speed, time and distance" />
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="simplification">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="profit-and-loss">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}