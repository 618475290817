import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Gradient</title>
                    <meta name="description" content="When we use two colors together for background, gradient provides a smooth transition between colors." />
                    <meta name="keywords" content="Gradient, css Gradient, css, tutsfinder, Gradient tutorials, Gradient in css, what is Gradient, what is Gradient in css, youtube, Gradient, Gradient, google" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Gradient" />
                    <meta property="og:description" content="When we use two colors together for background, gradient provides a smooth transition between colors." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-Gradient" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Gradient</h2>
                    <br/><br/>

                    <p>When we use two colors together for background, gradient provides a smooth transition between colors.</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show simple gradient:</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    #simpleGradient &#123;<br/>
                    height: 300px;<br/>
                    background-image: linear-gradient(#16E2F9, #B0F905);<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt;This gradient have two colors blue and green.&lt;/p&gt;<br/>
                    &lt;div id="simpleGradient"&gt;&lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/gradient1.jpg" alt="Gradient" style={{width:`100%`}} />
                    <br/><br/>
                    <p>•	Linear Gradients </p>
                    <p>•	Radial Gradients</p>
                    <p><strong>Linear gradients</strong> –In Linear gradients, user can also give direction in which transition goes.</p>
                    <p>Direction can be up, down, left, right and diagonally.</p>
                    <p><strong>Syntax</strong> </p>
                    background-image: linear-gradient(direction, color, color, .....);
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show linear gradient:</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    #linearGradient &#123;<br/>
                    height: 300px;<br/>
                    background-image: linear-gradient(to right, #16E2F9, #B0F905);<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt;This gradient have two colors blue and green.&lt;/p&gt;<br/>
                    &lt;div id="linearGradient"&gt;&lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/gradient2.jpg" alt="Gradient" style={{width:`100%`}} />
                    <br/><br/>

                    <p>Radial Gradient - In Radial gradient user can use centre for transition.</p>
                    Syntax - background-image: radial-gradient(shape or size , color, color);
                    Example to show radial gradient:
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show radial gradient:</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    #radialGradient &#123;<br/>
                    height: 300px;<br/>
                    background-image: radial-gradient( #16E2F9, #B0F905);<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt;This gradient have two colors blue and green.&lt;/p&gt;<br/>
                    &lt;div id="radialGradient"&gt;&lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/gradient3.jpg" alt="Gradient" style={{width:`100%`}} />
                    <br/><br/>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Another Example in which user can use shape:</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                    #radialGradient &#123;<br/>
                    height: 300px;<br/>
                    background-image: radial-gradient(circle, #16E2F9, #B0F905, yellow);<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;p&gt;This gradient have three colors blue, yellow and green.&lt;/p&gt;<br/>
                    &lt;div id="radialGradient"&gt;&lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/gradient4.jpg" alt="Gradient" style={{width:`100%`}} />
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-animation">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-transition">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>

            </>
        )
    }
}