import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CArrays extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Arrays</title>
                    <meta name="description" content = "Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values." />
                    <meta name="keywords" content="C Arrays,  C Arrays Tutorial, Arrays in C, Arrays ," />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Arrays " />
                    <meta property="og:description" content="Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-array" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Array in C</h2>
                    <br/>
                    <p>Array is a collection of data of similar data type. Consider an example – If we say there is an array of Integer this means that we have a collection of data that contains only integer values.</p>
                <p>Array uses static memory allocation. To access element from array you must have to mention its index value corresponding to the position of the element. We can easily access data in array.</p>
                <p>The index of array always starts from 0.</p>
                <p>But there is a disadvantage in array that we can store only fixed amount of elements. We cannot change size of an array at runtime.</p>
                <h3>Array Declaration and Initialization</h3>
                <p>If you want use array in your program then first declare variable and initialize the value to that variable.</p>
                <p><strong>Syntax:</strong> dataType array_name[]=&#123; &#125;;</p>
                <p><strong>Example:</strong> int myarray [] = &#123; 7,8,4,21&#125;;</p>
                <br/>
                <p>There are two types of arrays –</p>
                <p>1. One Dimensional</p>
                <p>2. Multi Dimensional</p>
                <br/>
                <br/>
                Program to print to elements of an array
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    int arr[]=&#123;1,2,3,4,5&#125;;<br/>
                    for(int i=0;i&lt;5;i++)<br/>
                    &#123;<br/>
                        printf("%d",&arr[i]);<br/>
                        i++;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                <h4>Two Dimensional Array</h4>
                <p>2D Arrays are collection of rows and column</p>
                <p><strong>Syntax:</strong> dataType array_name[]=[row][column];</p>
                <br/><br/>
                <p>Program to print matrices</p>
                <div className="container" id="java-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                <br/>
                #define maxrow 5<br/>
                #define maxcol 5<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    int matrix[maxrow][maxcol];<br/>
                    int i,j,row,col;<br/>
                    <br/>
                    printf("Enter number of Rows and Columns : ");<br/>
                    scanf("%d%d",&row,&col);<br/>
                    <br/>
                    for(i=0;i &gt; row;i++)<br/>
                    &#123;<br/>
                        for(j=0;j &lt; col;j++)<br/>
                        &#123;<br/>
                            printf("Enter element [%d,%d] : ",i+1,j+1);<br/>
                            scanf("%d",&matrix[i][j]);<br/>
                        &#125;<br/>
                    &#125;<br/>
                    <br/>
                    printf("\nMatrix is :\n");<br/>
                    for(i=0;i &lt; row;i++)<br/>
                    &#123;<br/>
                        for(j=0;j &lt; col;j++)<br/>
                        &#123;<br/>
                            printf("%d\t",matrix[i][j]);<br/>
                        &#125;<br/>
                        printf("\n");<br/>
                    &#125;<br/>
                    return 0<br/>
                &#125;
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="functions-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="strings-in-c">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}