import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaConstructor extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Constructors</title>
                    <meta name="description" content="A pseudo-method that creates an object. In the Java programming language, constructors are instance methods with the same name as their class." />
                    <meta name="keywords" content="java constructor, core java tutorial constructor, constructor in java programming, Constructors in java, default constructor, parameterized constructor in java" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Constructors" />
                    <meta property="og:description" content="A pseudo-method that creates an object. In the Java programming language, constructors are instance methods with the same name as their class." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-constructor" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Constructor</strong>
                    <br/>
                    <br/>
                    <p>Constructors are special functions that are used to initialize the member of any class.</p>
                <p>A pseudo-method that creates an object. In the Java programming language, constructors are instance methods with the same name as their class.</p>
                <p>Constructors are invoked using the new keyword.</p>
                <p>It is called when the instance of the class is created.</p>
                <p>Rules-
                <br/>
                •	Class name must be same as constructor name.
                <br/>
                •	There is not any explicit return type.
                </p>
                <br/><br/>
                <h3>Defining a Constructor in Java</h3>
                <p>A simple Java constructor declaration example. In which a class with single constructor -</p>
                <div className="container" id="java-program">
                    public class TutsFinder &#123;
                <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; public TutsFinder() &#123;
                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &#125;
                <br/><br/>
                &#125;
                </div>
                <br/>
                <br/>
                <h3>Types of Constructors-</h3>
                <br/><p><strong>Default constructor</strong> - When constructor does not have any parameter then it is called as default parameter.</p>
                <p>Example to define Default Constructor -</p>
                <div className="container" id="java-program">
                class Tuts &#123;
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;   public Tuts()
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; &#123;
                <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; System.out.println("Java Tutorials");
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; public static void main (String[] args)
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123;
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     Tuts obj = new Tuts();
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;
                <br/>
                &#125;
                </div>
                <br/>
                <br/>
                <strong>Output: </strong>
                <br/><br/>
                <div className="container">
                Java Tutorials
                </div>
                <br/>
                <br/><p><strong>Parameterized constructor</strong> - A constructor with arguments is known as parameterized constructor.</p>
                <br/>
                <p>Example to define Parameterized Constructor -</p>
                <div className="container" id="java-program">
                <p>import java.io.*;
                    <br/>
                class Tuts<br/>
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;&#123;

                <br/>&nbsp;&nbsp;&nbsp;&nbsp;String name;
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;int id;
                    <br/>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Tuts(String name, int id) <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#123; <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;this.name = name; <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; this.id = id; <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125; <br/>
                <br/>
                <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;public static void main (String[] args)                     &nbsp;&nbsp;&nbsp;&nbsp;&#123; <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tuts obj = new Tuts("Java Tutorials", 1); <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Tutorials Name :" + obj.name + " and Tutorials Id :" + obj.id); <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#125;
                <br/>
                &#125;
                </p>
                </div>
                <br/>
                <br/>
                <strong>Output: </strong>
                <br/><br/>
                <div className="container">
                Tutorials Name: Java Tutorials and Tutorials Id: 1
                </div>
                    <br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-keywords">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-class-and-object">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}