import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlStructure extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Structure </title>
                    <meta name="description" content="As a beginner to HTML, one should use the Notepad to create HTML page, as HTML file is a text file we can use any text editors." />
                    <meta name="keywords" content="Html structure tutorial, structure of html program, html structure, structure in html" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Structure" />
                    <meta property="og:description" content="As a beginner to HTML, one should use the Notepad to create HTML page, as HTML file is a text file we can use any text editors." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-structure" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Structure</h2>
                    <br/><br/>
                <h3>Choosing your HTML editor (HTML Structure)</h3>
                <br/>
                <p>•	As a beginner to HTML, one should use the Notepad to create HTML page, as HTML file is a text file we can use any text editors.</p>
                <p>•	After learning the basics, you can easily switch to some other professional text editors.</p>
                <p>•	A good HTML editor will keep your code clean and organized, it will also detect when you open a new tag and automatically close it to avoid the buggy code.</p>
                <br/>
                <p>There are many free and paid HTML editors, below are the some top options you can choose:</p>
                <p>•	HTML-Kit</p>
                <p>•	CoffeeCup</p>
                <p>•	KompoZer</p>
                <p>•	Notepad++</p>
                <p>•	Codelobster</p>
                <br/>
                <p>Steps for creating your first web page using Notepad:</p>
                <br/>
                <p><strong>Step 1</strong>: Open Notepad on PC</p>
                <br/>
                <p>Open the start screen on the left bottom type Notepad on search bar.</p>
                <p><strong>Step 2</strong>: Write some HTML code in notepad</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example: </h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt; My first heading &lt;/h1&gt;<br/>
                &lt;p&gt; My first paragraph &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <p><strong>Step 3</strong>: Saving your HTML page</p>
                <br/>
                <p>Save the html file which you have created in your computer choosing the path, select file Save as in the notepad menu or you can also save by clicking CTRL+S.</p>
                <p>Name the file and save it with extension .htm or .html.</p>
                <p><strong>Step 4</strong>: Viewing the HTML page in your browser</p>
                <p>Open the saved HTML file in your browser (Internet explorer or mozilafirefox), double click on the file, or choose the browser by clicking right click and open with.</p>
                <br/>
                Output:
                <br/><br/>
                <img src="../img/html/program.png" alt="program" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-tags">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-elements">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}