import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppClassAndObjects extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Class And Object</title>
                    <meta name="description" content="Class is a type that defines the implementation of a particular kind of object." />
                    <meta name="keywords" content="C++ class, C++ objects, c++ class and objects,  C++ class and object Tutorial, c++ class javatpoint, c++ object javatpoint" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Class And Object" />
                    <meta property="og:description" content="Class is a type that defines the implementation of a particular kind of object." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-class-and-objects" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <h2>Class and Objects</h2>
                    <br/>
                    <h3>Class</h3>
                    <br/>
                <p>Class is a type that defines the implementation of a particular kind of object.</p>
                <p>A class is a blue print of the object. A class describes all the attributes and methods of object.</p>
                <p>Class keyword is used to declare the new class.</p>
                <p>•	Data(variables) and methods defined within the class.</p>
                <p>•	 Variables and methods are called members of class.</p>
                <p>•	 Data are data members or instance variables of the class.</p>
                <p>•	Methods are called member functions of the class.</p>
                <p><strong>Example</strong> – A class that models a car looks like the following </p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                class Car<br/>
                &#123;<br/>
                    (//attributes)<br/>
                    String brand;<br/>
                    String color;<br/>
                    String model;<br/>
                    (//methods)<br/>
                    void gearChange();<br/>
                    void gateOpen();<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h3>Object</h3>
                <br/>
                <p>An object is an instance of class. An object is an real world entity that has some attributes, behavior and identity. Attributes and behavior of an object are defined by the class definition.</p>
                <p>A class is like  a blueprint , and declaring a class alone has no effect on the execution of a program.</p>
                <p>Objects have states and behaviours -</p>
                <p>• State defines the characteristic of the object e.g. Car brand is BMW, color Black etc.</p>
                <p>• Behaviour defines the functionality of the object e.g. gear change, sun roof open etc.</p>
                <br/>
                Example: In this example we have created a Car class which has one method printInfo() . We have creating a object of Car class and print it.
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Car<br/>
                &#123;<br/>
                    (// Access specifier)<br/>
                    public:<br/>
                    (// Data Members)<br/>
                    string brand;<br/>
                    string model;<br/>
                    string color;<br/>
                    (// Member Functions())<br/>
                    void printInfo()<br/>
                    &#123;<br/>
                        cout &lt;&lt; "Details about Car : " &lt;&lt; brand+" "+model+" "+color &lt;&lt; endl;<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main() &#123;<br/>
                    (// Declare an object of class geeks)<br/>
                    Car obj;<br/>
                    (// accessing data member)<br/>
                    obj.brand = "BMW";<br/>
                    obj.model = "X5";<br/>
                    obj.color = "Black";<br/>
                    (// accessing member function)<br/>
                    obj.printInfo();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Details about Car : BMW X5 Black
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-namespaces">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-abstraction">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}