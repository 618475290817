import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpDateAndTime extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Date and Time</title>
                    <meta name="description" content = "PHP date() function is used to format a date in php. This function is used to make date and time more readable that means it formats a timestamp in more readable form." />
                    <meta name="keywords" content="php date time, date time in php, date time function in php, jobs, react js, tutsfinder, date and time in php" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Date and Time" />
                    <meta property="og:description" content="PHP date() function is used to format a date in php. This function is used to make date and time more readable that means it formats a timestamp in more readable form." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-date-and-time" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Date and Time</strong>
                    <br/><br/>
                    <h1>PHP Date() function</h1>
                    <p>PHP date() function is used to format a date in php.</p>
                    <p>This function is used to make date and time more readable that means it formats a timestamp in more readable form.</p>
                    <p><strong>For example</strong> – mm-dd-yyyy, mm/dd/yy etc</p>
                    <p><strong>Syntax</strong></p>
                    <p>date(format,timestamp)</p>
                    <p><strong>Format</strong> – In which format you want to show you date and time.</p>
                    <p><strong>Timestamp</strong> – Specify a timestamp.</p>
                    <br/>
                    <h1>For Date</h1>
                    <p><strong>In order to get a date we have to used some special characters that are </strong></p>
                    <p>•	m – It is used to represents month (01 to 12)</p>
                    <p>•	d – It represents the day of the month (01 to 31)</p>
                    <p>•	y – Represents the year.</p>

                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example to show date in different formats: </h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo "Today is " . date("Y/m/d") . "&lt;br&gt;";<br/>
                    echo "Today is " . date("Y.m.d") . "&lt;br&gt;";<br/>
                    echo "Today is " . date("Y-m-d") . "&lt;br&gt;";<br/>
                    echo "Today is " . date("l");<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>For Time</h1>
                    <p><strong>Some characters that are used in to get a time </strong></p>
                    <p>•	H - 24-hour format of an hour (00 to 23)</p>
                    <p>•	h - 12-hour format of an hour with leading zeros (01 to 12)</p>
                    <p>•	i - Minutes with leading zeros (00 to 59)</p>
                    <p>•	s - Seconds with leading zeros (00 to 59)</p>
                    <p>•	a - Lowercase Ante meridiem and Post meridiem (am or pm)</p>

                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-form">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}