import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpOverview extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Overview</title>
                    <meta name="description" content = "PHP is Hypertext Preprocessor, initially it was known as Personal Home Page. PHP is a most popular server side scripting language that is used to create dynamic web pages" />
                    <meta name="keywords" content="php, php tutsfinder, php tutorials tutsfinder, what is php, php definition, php w3schools, php full form, php stands for, php operator, php latest version, php download, php tutorial" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Overview" />
                    <meta property="og:description" content="PHP is Hypertext Preprocessor, initially it was known as Personal Home Page. PHP is a most popular server side scripting language that is used to create dynamic web pages." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-overview" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/php/php.png" style={{width:`100%`, height:`auto`, border: `groove`}} alt="php" />
                    </div>
                    <br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>Introduction</strong>
                    <br/><br/>
                    <p>PHP is Hypertext Preprocessor, initially it was known as Personal Home Page.</p>
                    <p>PHP is a most popular server side scripting language that is used to create dynamic web pages.</p>
                    <p>PHP is a open source and widely used scripting language was originally created by Rasmus Lerdorf in 1994.</p>
                    <p>PHP code is executed on server side and displays the result in the browser in html form. PHP can be integrated with any popular databases such as mysql, Oracle, Microsoft SQL Server and so on.</p>
                    <h1>Prerequisites</h1>
                    <p>You must know at least the basics of these three languages –</p>
                    <p>1.	HTML</p>
                    <p>2.	CSS</p>
                    <p>3.	Javascript</p>

                    <h1>What should PHP file contain?</h1>
                    <p>Contain some text, html, css, javascript and php code.</p>
                    <p>With .php extension.</p>

                    <h1>What we can do with PHP?</h1>
                    <p>PHP can be used to create interactive and dynamic web pages.</p>
                    <p>PHP can be used to perform CRUD operations.</p>
                    <p>PHP can add, delete, and update data in databases.</p>
                    <p>PHP can be used to send receive cookies.</p>
                    <h1>Advantages of PHP </h1>
                    <p><strong>Simple</strong> – PHP is the easiest scripting language. PHP is easy to learn and use, for beginners who just started out with web development php is best choice for them.</p>
                    <p><strong>Open-source</strong> – PHP is very popular and widely used open source language and maintained by a large community who makes it fast, secure and up to date.</p>
                    <p><strong>Flexibility</strong> – PHP is very flexible, the best thing in PHP is after completion of project PHP has ability to make changes and this saves lots of time.</p>
                    <p><strong>Efficiency</strong> – Depending on the web developers choice, PHP is best for small as well as for large projects.</p>
                    <hr/>
                    <p>In next chapter we will discuss about how we can start web development with php and later on dig out some new concepts in php.</p>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="getting-started-with-php">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}