import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ThreeDTransform extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - 3D Transform</title>
                    <meta name="description" content="Using 3D Transform we can transform or move elements along x, y and z-axis." />
                    <meta name="keywords" content="3D transform, 3D transform property, 3D transform property Tutorials, tutsfinder, css 3D transform, 3D transform property css" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - 3D Transform" />
                    <meta property="og:description" content="Using 3D Transform we can transform or move elements along x, y and z-axis." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-3d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>3D Transform</h2>
                    <br/><br/><br/>
                    <p>CSS also supports 3D Transformation of elements.</p>
                    <p>Using 3D Transform we can transform or move elements along x, y and z-axis.</p>
                    <h4>CSS 3D Transform method</h4>
                    <br/><br/>
                    <p><strong>matrix3d(n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n)</strong> – It is used to transforms the element by using 4X4 into 16 values of matrix.</p>
                    <p><strong>translate3d(x,y,z)</strong> – It is used to transforms the element by using x-axis,y-axis and z-axis.</p>
                    <p><strong>translateX(x)</strong> - Used x-axis to transform the elements.</p>
                    <p><strong>translateY(y)</strong> - Used y-axis to transform the elements.</p>
                    <p><strong>translateZ(z)</strong> - Used z-axis to transform the elements.</p>
                    <p><strong>scale3d(x,y,z)</strong> – It is used to increase or decrease the size alonh x, y and z-axis.</p>
                    <p><strong>scaleX(x)</strong> - Used to scale transforms the element by using x-axis.</p>
                    <p><strong>scaleY(y</strong>) - Used to scale transforms the element by using y-axis.</p>
                    <p><strong>scaleZ(z)</strong> - Used to scale transforms the element by using z-axis.</p>
                    <p><strong>rotate3d(x,y,z,angle)</strong> – It is used to rotate element along x, y and z-axis.</p>
                    <p><strong>rotateX(angle)</strong> - Used to rotate the element by using x-axis.</p>
                    <p><strong>rotateY(angle)</strong> - Used to rotate the element by using y-axis.</p>
                    <p><strong>rotateZ(angle)</strong> - Used to rotate the element by using z-axis.</p>
                    <br/>
                    <h5>Rotate along X-axis</h5>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                            div &#123;<br/>
                                width: 200px;<br/>
                                height: 100px;<br/>
                                background-color: yellow;<br/>
                                border: 1px solid black;<br/>
                            &#125;<br/>
                            div#myDiv &#123;<br/>
                                -webkit-transform: rotateX(150deg);<br/>
                                (/* Safari */)<br/>
                                transform: rotateX(150deg);<br/>
                                (/* Standard syntax */)<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;div&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                        &lt;p&gt;Along X-axis&lt;/p&gt;<br/>
                        &lt;div id = "myDiv"&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/3D-RotateX.png" alt="3DRotate" />
                    <br/><br/>

                    <h5>Rotate along Y-axis</h5>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                            div &#123;<br/>
                                width: 200px;<br/>
                                height: 100px;<br/>
                                background-color: yellow;<br/>
                                border: 1px solid black;<br/>
                            &#125;<br/>
                            div#myDiv &#123;<br/>
                                -webkit-transform: rotateY(150deg);<br/>
                                (/* Safari */)<br/>
                                transform: rotateY(150deg);<br/>
                                (/* Standard syntax */)<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;div&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                        &lt;p&gt;Along Y-axis&lt;/p&gt;<br/>
                        &lt;div id = "myDiv"&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/3D-RotateY.png" alt="3DRotate" />
                    <br/><br/>

                    <h5>Rotate along Z-axis</h5>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;style&gt;<br/>
                            div &#123;<br/>
                                width: 200px;<br/>
                                height: 100px;<br/>
                                background-color: yellow;<br/>
                                border: 1px solid black;<br/>
                            &#125;<br/>
                            div#myDiv &#123;<br/>
                                -webkit-transform: rotateZ(150deg);<br/>
                                (/* Safari */)<br/>
                                transform: rotateZ(150deg);<br/>
                                (/* Standard syntax */)<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;div&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                        &lt;p&gt;Along Z-axis&lt;/p&gt;<br/>
                        &lt;div id = "myDiv"&gt;<br/>
                            TutsFinder - Elementary way to find<br/>
                        &lt;/div&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/3D-RotateZ.png" alt="3DRotate" />
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-2d-transform">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}