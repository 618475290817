import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpFileHandling extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - File Handling</title>
                    <meta name="description" content = "File handling allows a system to create a new file, write a file, read data from file, append file, delete file and close file." />
                    <meta name="keywords" content="file handling, php file handling, File Handling in php, php tutorials, manage File Handling in php, File Handling tutsfinder" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - File Handling" />
                    <meta property="og:description" content="File handling allows a system to create a new file, write a file, read data from file, append file, delete file and close file." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-file-handling" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP File Handling</strong>
                    <br/><br/>
                    <p>File handling allows a system to create a new file, write a file, read data from file, append file, delete file and close file.</p>
                    <p>In php, there are many functions to perform operations on file –</p>
                    <p>fopen()</p>
                    <p>fread()</p>
                    <p>fwrite()</p>
                    <p>fclose()</p>
                    <p>fdelete()</p>
                    <br/>
                    <h2>1.	PHP open file</h2>
                    <p>PHP fopen() function is used to open the file. </p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $file = fopen(“demo.txt”,'w');<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>
                    <p>First parameter is used for filename and second parameter is tells about mode in which files need to be open.</p>
                    <br/>
                    <p>Modes in which file can be opened –</p>
                    <p><strong>•	“w”</strong> – Opens a file for write only. If file not exist then new file is created and if file already exists then contents of file is erased.</p>
                    <p><strong>•	“r”</strong> – File is opened for read only.</p>
                    <p><strong>•	“a”</strong> – File is opened for write only. File pointer points to end of file. Existing data in file is preserved.</p>
                    <p><strong>•	“w+”</strong> – Opens file for read and write. If file not exist then new file is created and if file already exists then contents of file is erased.</p>
                    <p><strong>•	“r+”</strong> – File is opened for read/write.</p>
                    <p><strong>•	“a+”</strong> – File is opened for write/read. File pointer points to end of file. Existing data in file is preserved. If file is not there then new file is created.</p>
                    <p><strong>•	“x”</strong> – New file is created for write only.</p>
                    <br/>

                    <h2>2.	PHP read file</h2>
                    <p>PHP fread() function is used to read the content of the open file. It accepts only two arguments: file name and file size.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $filename = “demo.txt";<br/>
                    $handle = fopen($filename, "r");//open file in read mode<br/>
                    <br/>
                    $contents = fread($handle, filesize($filename));//read file<br/>
                    <br/>
                    echo $contents;//printing data of file<br/>
                    fclose($handle);//close file<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h2>3.	 PHP write file</h2>
                    <p>PHP fwrite() function is used to write the content in the existing file or it will be create new file after that write the content in it.</p>
                    <p>It accepts two arguments: file name and text that needs to be enter in the file and there is another optional one that is length of the text.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $file = fopen("demo.txt", 'w');<br/>
                    $text = "Hello world\n";<br/>
                    fwrite($file, $text);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h2>4.	PHP close function</h2>
                    <p>PHP fclose() function is used to close the file.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $file = fopen("demo.txt", 'r');<br/>
                    (//some code to be executed)<br/>
                    fclose($file);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h2>5.	PHP delete function </h2>
                    <p>PHP fdelete() function is used to delete the file.</p>
                    <p>To delete a file we have used unlink() function.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    unlink('data.txt');<br/>
                    echo "File deleted successfully";<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-form">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}