import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ConrolStatements extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Control Statements</title>
                    <meta name="description" content="Control Statements controls the flow of program execution." />
                    <meta name="keywords" content="C++ tutorial,  C++ comments Tutorial, website , tutorials, beginners, professionals, introduction to C++, concepts, example, C++, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Control Statements" />
                    <meta property="og:description" content="Control Statements controls the flow of program execution." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-control-statements" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Control Statements</h2>
                    <br/>
                    <p>Control Statements controls the flow of program execution.</p>
                    <p>These are keywords that are used for making a decision. In this, we will discuss about conditional statements and unconditional statements. These statements are used to control the flow of statements.</p>
                <h4>Conditional Statements</h4>
                <p>Conditional Statements are used when the execution of statements depends on one or more conditions.</p>
                Decision making statements :
                <p>•	If</p>
                <p>•	If-else</p>
                <p>•	if-else-if</p>
                <p>Example of if statement –</p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    int num;<br/>
                    cout &lt;&lt; "Enter an integer: ";<br/>
                    cin &gt;&gt; num;<br/>
                    (// checks if the number is positive)<br/>
                    if ( num &gt; 0)<br/>
                    &#123;<br/>
                        cout &lt;&lt; "You entered a positive integer: " &lt;&lt; num &lt;&lt; endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/><br/>
                <div className="container" id="cpp-program" >
                <pre>
                Enter an integer: 5<br/>
                You entered a positive integer: 5
                </pre>
                </div>
                <br/><br/>
                <h4>Syntax of if-else statement -</h4>
                <pre>
                if(condition)<br/>
                &#123;<br/>
                (//statement if condition is true)<br/>
                &#125;<br/>
                else<br/>
                &#123;<br/>
                (//statement if condition is false)<br/>
                &#125;
                </pre>
                <br/>
                <strong>Program to show how to use if else statement</strong>
                <br/><br/>
                <div className="container" id="cpp-program" >
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    int num;<br/>
                    cout &lt;&lt; "Enter an integer: ";<br/>
                    cin &gt;&gt; num;<br/>
                    if ( num &gt;= 0)<br/>
                    &#123;<br/>
                        cout &lt;&lt; "You entered a positive integer: " &lt;&lt; num &lt; endl;<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        cout &lt;&lt; "You entered a negative integer: " &lt;&lt; num &lt;&lt; endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <div className="container" id="cpp-program" >
                <pre>
                Enter an integer: -23<br/>
                You entered a negative integer: -23
                </pre>
                </div>
                <br/><br/>
                <strong>Program of if else if statement –</strong>
                <br/><br/>
                <div className="container" id="cpp-program" >
                <pre>
                #include&lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()&#123;<br/>
                    int num;<br/>
                    cout &lt;&lt; "Enter an integer number between 1 & 9999: ";<br/>
                    cin &gt;&gt; num;<br/>
                    if(num &lt; 10 && num &lt; =9)&#123;<br/>
                        cout &lt;&lt; "It's a one digit number";<br/>
                    &#125;<br/>
                    else if(num &lt; 100 && num &gt; =1) &#123;<br/>
                        cout &lt;&lt; "Its a two digit number";<br/>
                    &#125;<br/>
                    else if(num &lt; 1000 && num &gt; =100) &#123;<br/>
                        cout &lt;&lt; "Its a three digit number";<br/>
                    &#125;<br/>
                    else if(num &lt; 10000 && num &gt; =1000) &#123;<br/>
                        cout &lt;&lt; "Its a four digit number";<br/>
                    &#125;<br/>
                    else &#123;<br/>
                        cout &lt;&lt; "number is not between 1 & 9999";<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <div className="container" id="cpp-program" >
                <pre>
                Enter an integer number between 1 & 9999: 15<br/>
                Its a two digit number
                </pre>
                </div>
                <br/><br/>
                <h2>Uncoditional Statements</h2>
                <p>Uncoditional Statements are not used to satisfy any conditions, they immediately move the control of program to another part</p>
                <p><strong>goto</strong> - Goto statement is used to take control of the program to any part of the program.</p>
                <p><strong>For Example </strong>- </p>
                <pre>
                statement1;<br/>
                goto label;<br/>
                statement2;<br/>
                statement3;<br/>
                label:<br/>
                statement4;
                </pre>
                <br/>
                <p>In above example,when goto statement is encountered , it takes control to the place where label is defined in the program.</p>
                <p><strong>break</strong> - This statement is used to change the normal flow of the program. It is used to terminate the loop means break th ecurrnet flow of the program.</p>
                <p><strong>continue</strong> - Where continue statement encountered inside a loop ,control directly jumps to the begining of the loop of next iteration. </p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-data-types">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-loops">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}