import React from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Header extends React.Component {
    render() {
        return(
            <header>
            <div className="header-area">
            <div className="header-top_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header_top_wrap d-flex justify-content-between align-items-center">
                                <div className="text_wrap">
                                    <p><span></span> <span>info@tutsfinder.com</span></p>
                                </div>
                                <div className="google-cse">
                                    <form method = "get" title = "Search Form" action="https://cse.google.com/cse/publicurl">
                                        <div>
                                            <input type="text" id="q" name="q" title="Search this site" alt="Search Text" maxLength="256" />
                                            <input type="hidden" id="cx" name="cx" value="000872874670878430221:opdbax8grbc" />
                                            <input type="submit" id="searchSubmit" name="submit" value="Search" alt="Go" title="Submit Search Query" />
                                        </div>
                                    </form>
                                </div>
                                <div className="text_wrap">
                                    <p>
                                        <a href="https://www.facebook.com/tutsfinder"> <FontAwesomeIcon icon={['fab', 'facebook']} style={{fontSize: `20px`}} /></a>
                                        <a href="mailto:info@tutsfinder.com"><FontAwesomeIcon icon="envelope" style={{fontSize: `20px`}} /></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sticky-header" className="main-header-area" style={{position:`inherit`}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header_wrap d-flex justify-content-between align-items-center">
                                <div className="header_left">
                                    <div className="">
                                        <Link to="/Homepage">
                                            <img className="logo-text" src="../img/logo-text.png" alt="Tutsfinder Logo"width="300px"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="header_right d-flex align-items-center">
                                    <div className="main-menu d-none d-lg-block">
                                        <nav>
                                            <ul id="navigation">
                                                <li><Link to="/Homepage"><FontAwesomeIcon icon="home" style={{marginRight: `5px`}}/>Home</Link></li>
                                                <li><Link to="/Courses">Courses</Link>
                                                    <ul className="submenu">
                                                       <li><Link to="/programming">Programming Languages</Link></li>
                                                        <li><Link to="/web-development">Web Development</Link></li>
                                                        <li><Link to="/quantitative/quantitative-set-1">Aptitude</Link></li>
                                                        <li><a href="logical.html">Logical</a></li>
                                                        <li><Link to="hr-interview-questions/hr-interview-questions-set-1">HR Questions</Link></li>
													</ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mobile_menu d-block d-lg-none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>
        )
    }
}