import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlJavascript extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    showTime() {
        document.getElementById('demo').innerHTML = Date();
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Javascript </title>
                    <meta name="description" content="By using HTML Javascript user can make the pages more dynamic and interactive." />
                    <meta name="keywords" content="javascript, javascript tutsfinder, javascript in html, how to use javascript in html, Html javascript" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Javascript" />
                    <meta property="og:description" content="By using HTML Javascript user can make the pages more dynamic and interactive." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-javascript" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Javascript</h2>
                    <br/><br/>
                <p>By using HTML Javascript user can make the pages more dynamic and interactive.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1&gt;My First JavaScript&lt;/h1&gt;<br/>
                &lt;button type="button"<br/>
                onclick="document.getElementById('demo').innerHTML = Date()"&gt;<br/>
                Click me to display Date and Time.&lt;/button&gt;<br/>
                &lt;p id="demo"&gt;&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <button type="button" onClick={this.showTime}>Click me to display Date and Time.</button>
                <p id="demo"></p>
                <br/><br/>
                <h4>HTML Script Tag</h4>
                <p>The &lt;script&gt; tag is used to define a client-side script.</p>
                <p>The &lt;script&gt; element contains script statements, or it points to an external script file through the src attribute.</p>
                <p>Common uses for JavaScript are image manipulation, form validation, and dynamic changes of content.</p>
                <br/>
                Example:
                <br/>
                <pre>
                &lt;script&gt;
                document.getElementById(“demo”).innerHTML = “Hello JavaScript”;
                &lt;/script&gt;
                </pre>
                <h4>Some Functions which illustrate the use of Javascript:</h4>
                <br/><br/>
                <h5>Javascript can change HTML content</h5>
                <br/>
                <pre>document.getElementById(“demo”).innerHTML = “Hello JavaScript”;</pre>
                <br/><br/>
                <h5>Javascript can change HTML styles</h5>
                <br/>
                <pre>
                document.getElementById(“demo”).style.fontsize = “25px”;
                document.getElementById(“demo”).style.color = “red”;
                document.getElementById(“demo”).style.backgroundColor = “red”;
                </pre>
                <h5>JavaScript can change HTML attributes</h5>
                <br/>
                <pre>
                document.getElementById(“image”).src = “picture.gif”;
                </pre>
                <br/><br/>
                <h4>The HTML &lt;noscript&gt; Tag</h4>
                <br/>
                <p>The &lt;noscript&gt; tag is used to provide an alternate content for users that have disabled scripts in their browser or have a browser that doesn't support client-side scripts.</p>
                <br/>
                Example:
                <br/>
                <pre>
                &lt;script&gt;<br/>
                document.getElementById(“demo”).innerHTML = “Hello JavaScript”;<br/>
                &lt;/script&gt;<br/>
                &lt;noscript&gt; Sorry, your browser does not support Javascript! &lt;/noscript&gt;
                </pre>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-iframes">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-canvas">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}