import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppFunctions extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Functions</title>
                    <meta name="description" content="Function in C++ are same as function in C. Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta name="keywords" content="C++ Functions,  C++ Functions Tutorial, Functions in c++, c++ functions tutsfinder, c++ Functions tutorialspoint, geeks, geeksforgeeks" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Functions" />
                    <meta property="og:description" content="Function in C++ are same as function in C. Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-functions" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Functions</h2>
                    <br/>
                    <p>Function in C++ are same as function in C. Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it.</p>
                <p><strong>Example-</strong></p>
                <pre>
                void xyz()&#123;<br/>
                (//code)<br/>
                &#125;
                </pre>
                <p>Every program has at least one function, that is main() function. main() function tells the compiler that program execution will be start from here.</p>
                <p>To define a function these parts play an role –</p>
                <p><strong>Return Type</strong> – Every function should have any return type either it is void or any other primitive data type.</p>
                <p><strong>Function Name</strong> – Function name must be unique. This is the name of the function that we have to execute.</p>
                <p><strong>Parameters</strong> – Parameters are optional, if you need to pass the parameter then you can. It declares the type and no. of arguments.</p>
                <h3>Define a function – </h3>
                <pre>
                data_type function_name(arguments if any)<br/>
                &#123;<br/>
                (//code)<br/>
                &#125;
                </pre>
                <p><strong>Return values</strong> – void function does not return any value, if you wants to return value then you have to used any data type (int, string etc.) and use the return keywords inside the function.</p>
                <p><strong>For Example –</strong></p>
                <pre>
                int main()&#123;<br/>
                (//code)<br/>
                return 0;<br/>
                &#125;
                </pre>
                <p>There are two types of function – </p>
                <p>1.	Library functions</p>
                <p>2.	Used define functions</p>
                <p><strong>Library functions</strong> - These are pre-define functions or built in functions used in C++ programming.</p>
                <p>We can used directly by invoking function directly.</p>
                <p><strong>For Example</strong> –  toUpper(c), toLower(c), sqrt(x), isdigit(c), sin(x) , cos(x)</p>
                <p><strong>Users define functions</strong> – These functions can be created by users, these are user defined functions. It allows user to provide extra functionality in a program besides built functions.</p>
                Program to show user define functions –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int area (int x);   //declaring function<br/>
                int main()<br/>
                &#123;<br/>
                    int a = 5;<br/>
                    int b = area(a);   //calling function<br/>
                    cout &lt;&lt; b;<br/>
                &#125;<br/>
                int area (int x)   //defining function<br/>
                &#123;<br/>
                    return (x*x);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <p>There are two ways to calling a function –</p>
                <p>1. Call by Value</p>
                <p>2. Call by Reference</p>
                <p><strong>1.	Call by Value</strong> – In call by value the value is passed in function as an argument.</p>
                <p>Both the actual and formal parameters refer to the same location. So, if you make any changes inside the function, then they are not reflected back in actual parameters.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int add(int a,int b);<br/>
                int main()<br/>
                &#123;<br/>
                int a = 5;<br/>
                int b = 10;<br/>
                int c;<br/>
                c=add(a,b);<br/>
                cout &lt;&lt; "Addition of sum is : " &lt;&lt; c &lt;&lt; endl;<br/>
                return 0;<br/>
                &#125;<br/>
                int add(int a, int b)<br/>
                &#123;<br/>
                return a+b;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Addition of Sum is : 15
                </pre>
                </div>
                <br/><br/>
                <p><strong>2. Call by reference</strong> – In call by reference we can pass the address of the variable as an argument.</p>
                <p>This method copies the reference of actual parameters into the formal parameters. And both parameters stored in different memory location.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                (// Function prototype)<br/>
                void swap(int*, int*);<br/>
                int main()<br/>
                &#123;<br/>
                    int a = 7, b = 8;<br/>
                    cout &lt;&lt; "a & b Before swapping" &lt;&lt; endl;<br/>
                    cout &lt;&lt; "a = " &lt;&lt; a &lt;&lt; endl;<br/>
                    cout &lt;&lt; "b = " &lt;&lt; b &lt;&lt; endl;<br/>
                    swap(&a, &b);<br/>
                    cout &lt;&lt; "\na & b After swapping" &lt;&lt; endl;<br/>
                    cout &lt;&lt; "a = " &lt;&lt; a &lt;&lt; endl;<br/>
                    cout &lt;&lt; "b = " &lt;&lt; b &lt;&lt; endl;<br/>
                    return 0;<br/>
                &#125;<br/>
                void swap(int* n1, int* n2) &#123;<br/>
                    int temp;<br/>
                    temp = *n1;<br/>
                    *n1 = *n2;<br/>
                    *n2 = temp;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                a & b Before swapping<br/>
                a = 7<br/>
                b = 8<br/>
                a & b After swapping<br/>
                a = 8<br/>
                b = 7<br/>
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" >
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-modifiers">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6" >
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-arrays">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}