import React from 'react';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class QuantSet1 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Quantitative Abilty - Set 1</title>
                    <meta name="keywords" content="aptitude questions for interview, aptitude question answer tutsfinder, online aptitude questions and answers, 100 aptitude questions and answers, logical reasoning aptitude questions, aptitude questions pdf, mathematics aptitude test questions and answers pdf, aptitude questions on average, aptitude questions meaning, train aptitude questions, job question, pass question, talent question, quantitative aptitude for competitive, quantitative aptitude, rapid quantitative aptitude, how to prepare for quantitative, quantitative aptitude and reas, course in mental ability and" />

                    <meta property="og:title" content="Quantitative Abilty - Set 1" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://tutsfinder.com/quantitative/quantitative-set-1" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Set 1</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <h2 style={{textAlign:`center`}}>Quantitative Ability Questions</h2><br/><br/>
                    <br/>
                    <br/>
                    <h4>1)	A man bought 13 articles at Rs.70 each, 15 at Rs. 60 each and 12 at Rs. 65 each. The average price per article is?</h4>
                <pre>
                a) 60.25   b) 64.75  c) 65.75  d) 62.25
                </pre>
                    <img src="../img/quant/set-1/1.png" width="100%" alt="Quant" />
                    <br/>
                    <h4>2)	A man purchased 7 bags of rice at the rate of Rs. 800 each, 8  bags of rice at Rs. 1000 each and 5 bags of rice at the rate of Rs. 1200 each. What is the average cost of 1 bag? 
                </h4>
                    <pre>
                a) 1010  b) 980  c) 1120  d) 1050
                    </pre>
                    <img src="../img/quant/set-1/2.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>3)	What sum of money given at simple interest for 6 months at 4% p.a. to earn Rs. 150 interest?</h4>
                    <pre>
                a) 5050  b) 9000 c)7500  c) 15000
                    </pre>
                    <img src="../img/quant/set-1/3.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>4)	The SI on a certain sum at 5% p.a. for 3 years and 4 years differs by Rs. 42. Find the principal.</h4>
                    <pre>
                a)	810  b) 820  c) 850  d) 840
                    </pre>
                    <img src="../img/quant/set-1/4.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>5)	A certain sum of money yields Rs. 1261 as compound interest for 3 years at 5% p.a. Find the sum.</h4>
                    <pre>
                a)	9000  b) 8400  c) 7500  d) 8000
                    </pre>
                    <img src="../img/quant/set-1/5.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>6)	A certain sum invested at 4% p.a. compounded half yearly ,amounts to Rs.7803 at the end of 1 year .The sum is.
                a)	7200  b) 7000  c) 7700  d) 7500
                </h4>
                    <pre>
                a)	7200  b) 7000  c) 7700  d) 7500
                    </pre>
                    <img src="../img/quant/set-1/6.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>7)	Find the value of (0.346̅7̅ + 0.133̅3̅) :
                </h4>
                    <pre>
                    a)	0.48  b) 0.480̅1̅  c) 0.4̅8̅  d) 0.48̅  
                    </pre>
                    <img src="../img/quant/set-1/7.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>     8)  1+ 1/2 + 1/4 +  1/7 +1/14+  1/28  is equal to
                </h4>
                    <pre>
                a) 2   b) 2.5   c) 3   d) 3.5
                    </pre>
                    <img src="../img/quant/set-1/8.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>9) ((√72-√18) ÷√12)) is equal to:
                </h4>
                    <pre>
                a) √6    b) √3/2    c) √2/3    d) √6/2
                    </pre>
                    <img src="../img/quant/set-1/9.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>10)  Find the value of (0.008*0.01*0.072) ÷ (0.12*0.004)
                        
                </h4>
                    <pre>
                a) 1.2   b) 0.12   c) 0.012   d) 1.02
                    </pre>
                    <img src="../img/quant/set-1/10.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>11) The HCF of two numbers is 15 and their LCM is 300. If one of the no. is 60. Find the other no.
                
                </h4>
                    <pre>
                a)75   b) 50   c) 65    d) 100
                    </pre>
                    <img src="../img/quant/set-1/11.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>12) The least no. which when divided by 4, 6,8,12 and 16 leaves a remainder of 2 in each case:
                
                </h4>
                    <pre>
                a) 47   b)48   c) 49   d) 50 
                    </pre>
                    <img src="../img/quant/set-1/12.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>13)  If basis points are defined so that 1 percent equal to 100 basis points, then by how many basis points is 82.5 percent greater than 62.5 percent.
                
                </h4>
                    <pre>
                a) 20   b) 200   c) 2000   d) 20000
                    </pre>
                    <img src="../img/quant/set-1/13.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>14) The time duration of 1 hour 45 minute is what percent of a day?
                
                </h4>
                    <pre>
                a) 7.218    b) 7.291    c) 8.31     d) 8.24
                    </pre>
                    <img src="../img/quant/set-1/14.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>15) The cost price of a cloth a Rs. 50/ meter. After 2 successive discounts of 15% and 20% .what is the net selling price. 
                
                </h4>
                    <pre>
                a) 32     b) 33    c) 34    d) 40
                    </pre>
                    <img src="../img/quant/set-1/15.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>16) If the loss percent on a article is 15%, then the ratio of cost price and selling price is 
                
                </h4>
                    <pre>
                a) 17 : 20     b) 20 : 17     c) 20 :25     d) 15 : 23
                    </pre>
                    <img src="../img/quant/set-1/16.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4> 17) A can do a work in 15 days and B can do the same work in 20 days . if they work together for 4 days then the fraction of work which is left. 
                
                </h4>
                    <pre>
                a)5/15     b) 6/15     c) 7/15     d) 8/15  
                    </pre>
                    <img src="../img/quant/set-1/17.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>18) A and B can do a piece of work in 12 days , B and C in 15 days , C and A in 20 days. In how many days will they complete the work together?
                
                </h4>
                    <pre>
                a)5     b) 8   c) 9     d) 10
                    </pre>
                    <img src="../img/quant/set-1/18.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>19)  A train passes two bridges of length 500m and 250 m in 100 seconds and 60 sec. respectively .find the length of the train.</h4>
                    <pre>
                a) 125   b) 250   c) 150   d) 160
                    </pre>
                    <img src="../img/quant/set-1/19.PNG" width="100%" alt="Quant" />
                    <br/>
                    <h4>20) A boat goes 40 km upstream in 8 hours and 36 km downstream in 6 hours. Find the speed of boat in still water.
                </h4>
                    <pre>
                a) 5.5km/hr   b) 6km/hr   c) 6.5km/hr   d) 5km/hr
                    </pre>
                    <img src="../img/quant/set-1/20.PNG" width="100%" alt="Quant" />
                    <br/>
                    <div className="line"></div>
                </div>
            </>
        )
    }
}