import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlSTyles extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Style </title>
                    <meta name="description" content="Stlye is an attribute pf HTML which is used to add styles to an element such as color, font, size, etc." />
                    <meta name="keywords" content="style, style tutsfinder, Html style, Html style Tutorial" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Style" />
                    <meta property="og:description" content="Stlye is an attribute pf HTML which is used to add styles to an element such as color, font, size, etc." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-style" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Styles</h2>
                    <br/><br/>
                <p>Stlye is an attribute pf HTML which is used to add styles to an element such as color, font, size, etc.</p>
                <p>It provides easy and effective alternatives to specify various attributes for the HTML tags.</p>
                <br/>
                Syntax:
                <br/>
                &lt;tagname style = “property:value;”&gt;
                <br/>
                <p>The property is a CSS property and the value is CSS value, you will learn more about CSS in CSS tutorial.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Below example showing background color of a page:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;p&gt;I am normal&lt;/p&gt;<br/>
                &lt;p style="color:yellow;"&gt;I am yellow&lt;/p&gt;<br/>
                &lt;p style="color:red;"&gt;I am red&lt;/p&gt;<br/>
                &lt;p style="font-size:20px;"&gt;I am small&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h3>The HTML Style Attribute</h3>
                <h4>Text Color</h4>
                <p>It defines the text color of an element by using property color.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Below example showing background color of a page:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1 style="color:green;"&gt;This is a heading &lt;/h1&gt;<br/>
                &lt;p style="color:yellow;"&gt;This is a paragraph. &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Background Color</h4>
                <p>It defines the background color of an element by using background-color property.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Below example showing background color of a page:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body style="background-color:blue;"&gt;<br/>
                &lt;h1&gt; This is a heading&lt;/h1&gt;<br/>
                &lt;p&gt; This is a paragraph.&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>HTML Fonts</h4>
                <p>It defines which font is to be used by using font-family property.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1 style="font-family:Calibri;"&gt;This is a heading&lt;/h1&gt;<br/>
                &lt;p style="font-family:courier;"&gt;This is a paragraph.&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Text Alignment</h4>
                <p>It defines the text alignment by using the text-align property.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1 style="text-align:left;"&gt; Heading on left&lt;/h1&gt;<br/>
                &lt;p style="text-align:center;"&gt;Centered paragraph.&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h4>Text Size</h4>
                <p>It defines the text size of an element by using font-size property.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;body&gt;<br/>
                &lt;h1 style="font-size:200%;"&gt;This is a heading&lt;/h1&gt;<br/>
                &lt;p style="font-size:150%;"&gt;This is a paragraph.&lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-links">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-tables">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}