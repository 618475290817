import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptStrings extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Strings</title>
                    <meta name="description" content="String is a special case of an array of characters. String is a sequence of characters that are used to store text." />
                    <meta name="keywords" content="javascript string concatenation, javascript string array, javascript string format, javascript string interpolation, javascript string variable, javascript string split, javascript string contains, javascript number to string" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Strings" />
                    <meta property="og:description" content="String is a special case of an array of characters. String is a sequence of characters that are used to store text." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-strings" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Strings</h2>
                    <br/><br/>
                    <p>String is a special case of an array of characters. String is a sequence of characters that are used to store text.</p>
                    <p>There are 2 ways to construct string in JavaScript:</p>
                    <p>•	By Using String literal.</p>
                    <p>•	By Using the new keyword .</p>
                    <br/><br/>
                    <h3>Bt usng literals</h3>
                    <p><strong>Syntax:</strong> var string_name="string value";</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; JavaScript String &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var str="TutsFinder – Elementary way to find";<br/>
                    document.write(str);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    TutsFinder – Elementary way to find
                    <br/><br/>
                    <h3>By using new keyword</h3>
                    <p><strong>Syntax: </strong>var string_name = new String("string literal");</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; JavaScript String New Keyword &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var stringname = new String("TutsFinder – Elementary way to find");<br/>
                    document.write(stringname);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    TutsFinder – Elementary way to find
                    <br/><br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-arrays">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-functions">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}