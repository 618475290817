import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppInheritance extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Inheritance</title>
                    <meta name="description" content="In method overriding, redefining inherit methods in subclasses. In this, declaration should be identical, code may be different. It is like having another version of the same product." />
                    <meta name="keywords" content="C++ Inheritance,  C++ Inheritance Tutorial, Inheritance in c++, C++ Inheritance tutsfinder, tutsfinder, Inheritance javatpoint, Inheritance" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Inheritance" />
                    <meta property="og:description" content="In method overriding, redefining inherit methods in subclasses. In this, declaration should be identical, code may be different. It is like having another version of the same product." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-inheritance" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Inheritance</h2>
                <br/><br/><br/>
                <p>Inheritance is one of the features of OOP where one class acquires the properties of another class.</p>
                <p>Inheritance represents IS-A relationship and used for reusability of code.</p>
                <p>•	<strong>Superclass</strong> is the class from which class is derived. Super class is also called parent class or base class.</p>
                <p>•	A class is derived from the superclass is called as the <strong>child class or derived class</strong>.</p>
                <p>•	Methods and variables must be in public or protected except private.</p>
                <p>•	<strong>extends</strong> keyword is used to inherit the class.</p>
                <p>
                <strong>Types of Inheritance:</strong></p>
                <p><strong>1. Single Inheritance</strong> - When a class inherited by another class is known as single inheritance.</p>
                <p><strong>For Example -</strong> car class inherits vehicle class.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/single-inheritance.png" alt="single inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>2. Multi-level Inheritance - </strong>There is a chain of classes where class A inherits class B and class B is also inherited by another class C.</p>
                <p><strong>For Example </strong>- BMW inherits the car class and car inherits the vehicle class.
                </p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/multilevel-inheritance.png" alt="multilevel inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>3. Mutiple Inheritance</strong> - When properties of more than one parent is inherited by another class then this is known as multiple inheritance.</p>
                <p>But java does not support multiple inheritance because it creates ambiguity problem.</p>
                <p>To overcome this problem, there is a concept in java i.e. <strong>Interface</strong>. Later we will learn about it .</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/multiple-inheritance.png" alt="multiple inheritance" width="100%" />
                </div>
                <p><strong>4. Hierarchical Inheritance</strong>- When more than one class acquires the property of a single class then is called as hierarchical inheritance.</p>
                <p><strong>For Example </strong>- Car and bike class inherits the vehicle class.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/hierarchical-inheritance.png" alt="Hierarchical inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>5. Hybrid Inheritance</strong> - It is a comination of more than one type of inheritance.</p>
                <p>When class A is inherited by class B and C, and class C is inherited by class D.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/hybrid-inheritance.png" alt="Hybrid inheritance" width="100%" />
                </div>
                <br/>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                void area(int);<br/>
                void area(int,float);<br/>
                <br/>
                int main() &#123;<br/>
                <br/>
                    int a = 5;<br/>
                    float b = 10.5;<br/>
                    <br/>
                    area(a);<br/>
                    area(a,b);<br/>
                    <br/>
                    return 0;<br/>
                &#125;<br/>
                <br/>
                void area(int var) &#123;<br/>
                    cout &lt;&lt; "Area of square : " &lt;&lt; var*var &lt;&lt; endl;<br/>
                &#125;<br/>
                <br/>
                void area(int var1,float var2) &#123;<br/>
                    cout &lt;&lt; "Area of rectangle  : " &lt;&lt; var1*var2 &lt;&lt; endl;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Area of square : 25<br/>
                Area of rectangle  : 52.5
                </pre>
                </div>
                <br/><br/>
                <h3>Method Overriding</h3>
                <br/>
                <p>In method overriding, redefining inherit methods in subclasses. In this, declaration should be identical, code may be different. It is like having another version of the same product.</p>
                <p>• It can be used to add more functionality to a method.</p>
                <p>• It is also called as run time polymorphism.</p>
                <p>• In overriding, a method has same name, same no. of arguments and same data type.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class BaseClass &#123;<br/>
                public:<br/>
                    void display()&#123;<br/>
                        cout &lt;&lt; "Parent Class";<br/>
                    &#125;<br/>
                &#125;;<br/>
                class DerivedClass: public BaseClass&#123;<br/>
                public:<br/>
                    void display() &#123;<br/>
                        cout &lt;&lt; "Child Class";<br/>
                    &#125;<br/>
                &#125;;<br/>
                int main() &#123;<br/>
                    DerivedClass obj = DerivedClass();<br/>
                    obj.display();<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Child Class
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-polymorphism">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-interface">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}