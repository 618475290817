import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class AJAX extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - AJAX</title>
                    <meta name="description" content="Ajax is abbreviated as Asynchronous Javascript and XML. It is used to create faster and interactive web pages." />
                    <meta name="keywords" content="ajax, ajax javascript, ajax amsterdam, ajax vs, ajax stock, ajax js, ajax php, ajax tutsfinder, ajax w3schools" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - AJAX" />
                    <meta property="og:description" content="Ajax is abbreviated as Asynchronous Javascript and XML. It is used to create faster and interactive web pages." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-ajax" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/><br/>
                    <h2>AJAX</h2>
                    <br/><br/>
                    <p>Ajax is abbreviated as Asynchronous Javascript and XML.</p>
                    <p>It is used to create faster and interactive web pages.</p>
                    <p>AJAX uses asynchronous data transfers between web server and browser.</p>
                    <h3>Advantages of AJAX</h3>
                    <p><strong>Saves bandwidth</strong> – It refresh the page without reloading overallpage.</p>
                    <p>Intercative </p>
                    <p>Faster</p>
                    <p><strong>The technologies used by AJAX</strong></p>
                    <p>•	JavaScript</p>
                    <p>•	XMLHttpRequest</p>
                    <p>•	Document Object Model (DOM)</p>
                    <p>•	Extensible HTML (XHTML)</p>
                    <p>•	Cascading Style Sheets (CSS)</p>

                    <h3>Difference between AJAX and Javascript</h3>
                    <br/>
                    <table className="cont">
                    <tbody>
                        <tr>
                        <th>AJAX</th><th>Javascript</th>
                        </tr>
                        <tr>
                        <td>AJAX is used to send the requests to gthe serve.</td><td>JavaScript is used only to make request to the server.</td>
                        </tr>
                        <tr>
                        <td>It refreshes the page without reloading the whole page.</td><td>First Javascript download the page then manage and control the webpage.</td>
                        </tr>
                        <tr>
                        <td>While using AJAX scripts need to update only once</td><td>JavaScript posts a request that updates the script every time</td>
                        </tr>
                    </tbody>
                    </table>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                        &lt;title&gt;<br/>
                            jQuery ajax() Method<br/>
                        &lt;/title&gt;<br/>
                        <br/>
                        &lt;script src= "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"&gt; &lt;/script&gt;<br/>
                        <br/>
                    &lt;/head&gt;<br/>
                    &lt;body style="text-align:center" &gt;<br/>
                        &lt;h1 style="color:blue"&gt;<br/>
                            TutsFinder<br/>
                        &lt;/h1&gt;<br/>
                        &lt;h2&gt;<br/>
                            Elementary way to find<br/>
                        &lt;/h2&gt;<br/>
                        &lt;p id="demo"&gt;&lt;/p&gt;<br/>
                        &lt;button&gt;Click&lt;/button&gt;<br/>
                        &lt;script&gt;<br/>
                            $(document).ready(function() &#123;<br/>
                                $("button").click(function() &#123;<br/>
                                    $.ajax(&#123;url: "file.txt",<br/>
                                            success: function(result) &#123;<br/>
                                        $("#demo").html(result);<br/>
                                    &#125;&#125;);<br/>
                                &#125;);<br/>
                            &#125;);<br/>
                        &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                        <h1 style={{color:`blue`, textAlign:`center`}}>
                            TutsFinder
                        </h1>
                        <h2 style={{textAlign:`center`}}>
                            Elementary way to find
                        </h2>
                        <p id="demo" style={{textAlign:`center`}}></p>
                        <button>Click</button>
                        <script>
                            $(document).ready(function() &#123;
                                $("button").click(function() &#123;
                                    $.ajax(&#123;url: "file.txt",
                                            success: function(result) &#123;
                                        $("#demo").html(result);
                                    &#125;&#125;);
                                &#125;);
                            &#125;);
                        </script>
                        <br/>
                        <br/>
                        <div className="row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-json">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-error-handling">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}