import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpDataTypes extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Data Types</title>
                    <meta name="description" content = "Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta name="keywords" content="php data type, data type in php, data types, data type tutsfinder, php data type tutsfinder, facebook, flipkart" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Data Types" />
                    <meta property="og:description" content="Data type defines the type of variable that which type of variable you are using in you program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-data-types" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Data Types</strong>
                    <br/><br/>
                    <p>Data type defines the type of variable that which type of variable you are using in you program.</p>
                    <p>Data types are used to store data of different data types.</p>
                    <p>$ is used to declare variables and var_dump(x); function is used to return the data type and value.</p>
                    <h2>PHP has pre defined set of data types –</h2>
                    <p>•	Integer</p>
                    <p>•	String</p>
                    <p>•	Float </p>
                    <p>•	Boolean</p>
                    <p>•	Array</p>
                    <p>•	Object</p>
                    <p>•	NULL</p>
                    <p>•	Resource</p>
                    <br/>

                    <h1>Integer</h1>
                    <p>Integers are whole numbers without decimal point that value lies between -2,147,483,648 and 2,147,483,647.0</p>
                    <p>Integers have at least 1 integer.</p>
                    <p>Integer does not have decimal point between them.</p>
                    <p>Integer can be positive or negative.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $a = 123;<br/>
                    (// decimal number)<br/>
                    var_dump($a);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Strings</h1>
                    <p>String is a sequence of characters. Strings are used to store text.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $x = "TutsFinder - ";<br/>
                    $y = 'Elementary way to find';<br/>
                    <br/>
                    echo $x;<br/>
                    echo "<br/>";<br/>
                    echo $y;<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Float</h1>
                    <p>Float is a number that have decimal between them or in exponential form.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $x = 7.87;<br/>
                    var_dump($x);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Boolean</h1>
                    <p>Boolean is used as switch case that returns only two values either True or False.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Assign the value TRUE to a variable)<br/>
                    $show_error = true;<br/>
                    var_dump($show_error);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Arrays</h1>
                    <p>Arrays enable you to store elements of same data type in the memory, in a sequential order.</p>
                    <p>• An array is a collection of elements.</p>
                    <p>• All elements contained in an array are of same data type.</p>
                    <p>• This collection forms a complete set.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $city = array("Pune", "Delhi", "Ghaziabad");<br/>
                    var_dump($city);<br/>
                    echo "<br/>";<br/>
                    $city_codes = array( "Pune" =&gt; "411047", "Delhi" =&gt; "110001", "Ghaziabad" =&gt; "201204" );<br/>
                    var_dump($city_codes);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Object</h1>
                    <p>Object is an instance of class. It is basic unit of system. An object is an real world entity that has some attributes, behavior and identity. Attributes and behavior of an object are defined by the class definition.</p>
                    <p>For declaring an object you must declare a class first.</p>
                    <p>An object is a data type that is used to store data and information.</p>
                    <p>New keyword is used to create the object of the class.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Class definition)<br/>
                    class greeting&#123;<br/>
                    (// properties)<br/>
                    public $str = "Hello World!";<br/>
                    (// methods)<br/>
                    function show_greeting()&#123;<br/>
                    return $this-&gt;str;<br/>
                    &#125;<br/>
                    &#125;<br/>
                    (// Create object from class)<br/>
                    $message = new greeting;<br/>
                    var_dump($message);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Null </h1>
                    <p>Null is a special value that is used to represent empty variables.</p>
                    <p>A null variable is empty variable that does not have any data.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $a = NULL;<br/>
                    var_dump($a);<br/>
                    echo "<br/>";<br/>
                    $b = "Hello World!";<br/>
                    $b = NULL;<br/>
                    var_dump($b);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Resources </h1>
                    <p>It is used to storing the reference to functions and resource to an external source.</p>
                    <p>Resource data type is used to handle special cases like database connections and file handling.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Open a file for reading)<br/>
                    $handle = fopen("note.txt", "r");<br/>
                    var_dump($handle); echo "<br/>";<br/>
                    (// Connect to MySQL database server with default setting)<br/>
                    $link = mysql_connect("localhost", "root", "");<br/>
                    var_dump($link);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/><br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-echo-print">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}