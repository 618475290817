import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CPointers extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Pointers</title>
                    <meta name="description" content = "Every variable has its own address; to store the address of that variable pointers are used." />
                    <meta name="keywords" content="C Pointers,  C Pointers Tutorial, Pointers , Pointers in C, store address in Pointers, tutsfinders, udemy, naukri, javatpoint" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Pointers " />
                    <meta property="og:description" content="Every variable has its own address; to store the address of that variable pointers are used." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-pointers" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <h2>C Pointers</h2>
                    <br/>
                    <p>Every variable has its own address; to store the address of that variable pointers are used.</p>
                <p>Pointer is a special variable that store the address of the variable.</p>
                <br/>
                <p>Syntax to declare a pointer : </p>
                <p>int *p1;</p>
                
                <p>char *p2;</p>
                <br/>
                <h4>Program to print the address of variable  </h4>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                <br/>
                void main () &#123;<br/>
                <br/>
                    int  a;<br/>
                    char b[5];<br/>
                    <br/>
                    printf("Address of a : %x\n", &a  );<br/>
                    printf("Address of b : %x\n", &b  );<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Address of a : 62fe4c<br/>
                Address of b : 62fe40
                </pre>
                </div>
                <br/><br/>
                <h3>How can we use pointers in our program ?</h3>
                <br/>
                <p>Pointer program to show value of pointer variable and address of pointer variable.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                void main()&#123;<br/>
                    int num=8;<br/>
                    int *a;<br/>
                    a=&num;<br/>
                    printf("Address of a variable is %x \n",a);<br/>
                    printf("Value of a variable is %d \n",*a);<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Address of a variable is 62fe44<br/>
                Value of a variable is 8
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="strings-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="file-input-output-in-c">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}