import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class StringsInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Strings</title>
                    <meta name="description" content = "String is a sequence of characters. Strings are used to store text."/>
                    <meta name="keywords" content="C Strings,  C Strings Tutorial, Strings , Strings in C, string methods, string create methods, create strings by using literals, create string by using char" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Strings" />
                    <meta property="og:description" content="String is a sequence of characters. Strings are used to store text." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/strings-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Strings in C</h2>
                <br/>
                <p>String is a sequence of characters. Strings are used to store text.</p>
                <p>String uses one dimensional array to store the characters terminated by null character “\0”.</p>
                <p>Strings are same as array but there is a difference between them that string is terminated with null character.</p>
                <br/>
                <p>We can create strings in two ways in C language –</p>
                <p>1.	By char array</p>
                <p>2.	By string literal </p>
                <br/>
                <h5>•	String by char array</h5>
                <p>char ch[11]=&#123;'t’,’u’,’t’,’s’,’f’,’I’,’n’,’d’,’e’,’r’,’\0'&#125;;</p>
                <br/>
                <h5>•	String by string literal</h5>
                <p>char ch[]="tutsfinder";</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Program to create string –<br/><br/>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;conio.h&gt;<br/>
                void main ()<br/>
                &#123;<br/>
                char ch1[11]=&#123;'t', 'u', 't', 's', 'f', 'i', 'n', 'd', 'e', 'r', 't' ,’\0'&#125;;<br/>
                char ch2[]="TutsFinder";<br/>
                printf("String by Char arrray : %s\n", ch1);<br/>
                printf("String by Literals : %s\n", ch2);<br/>
                getch();<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <h4>Strings functions that are used by C</h4>
                <br/><br/>
                <p><strong>1.	strcpy(s1, s2);</strong></p>
                <p>Copies the content if string s2 into string s1.</p>
                <p><strong>2.	strcat(s1, s2);</strong></p>
                <p>Concatenates or join string s2 onto the end of string s1 and relut will be defined in string s1.</p>
                <p><strong>3.	strlen(s1);</strong></p>
                <p>Returns the length of string s1.</p>
                <p><strong>4.	strcmp(s1, s2);</strong></p>
                <p>Compare the string s1 with strings s2.</p>
                <p>Returns 0 if s1 and s2 are the same; </p>
                <p><strong>5.	strchr(s1, ch);</strong></p>
                <p>Returns a pointer to the first occurrence of character ch in string s1.</p>
                <p><strong>6.	strstr(s1, s2);</strong></p>
                <p>Returns a pointer to the first occurrence of string s2 in string s1.</p>
                <br/>
                <p>Program to show C Strings function</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;string.h&gt;<br/>
                <br/>
                void main () &#123;<br/>
                <br/>
                    char s1[10] = "Hello";<br/>
                    char s2[10] = "World";<br/>
                    char s3[10];<br/>
                    int  len ;<br/>
                    <br/>
                    (/* copy strings */)<br/>
                    strcpy(str3, str1);<br/>
                    printf("String will be :  %s\n", str3 );<br/>
                    <br/>
                    (/* concatenates strings */)<br/>
                    strcat( str1, str2);<br/>
                    printf("After concatenation :   %s\n", str1 );<br/>
                    <br/>
                    (/* returns lenghth of string */)<br/>
                    len = strlen(str1);<br/>
                    printf("Length of string is :  %d\n", len );<br/>
                    <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                String will be:  Hello<br/>
                After concatenation : HelloWorld<br/>
                Length of string is:  10
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-array">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-pointers">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}