import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CFunctions extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Functions </title>
                    <meta name="description" content = "Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta name="keywords" content="C Functions,  C Functions Tutorial, Functions , Functions in C, function name, Functions tutsfinder, call by value in c, tutorialspoint, returntype,call by refrence, call by value javatpoint Functions, call by refrence in c, Functions tutorialspoint" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Functions " />
                    <meta property="og:description" content="Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/functions-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <h2>Functions</h2>
                    <br/>
                <p>Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it.</p>
                <p>Every program has at least one function, that is main() function. main() function tells the compiler that program execution will be start from here.</p>
                <p>To define a function these parts play an role –</p>
                <p><strong>Return Type</strong> – Every function should have any return type either it is void or any other primitive data type.</p>
                <p><strong>Function Name</strong> – Function name must be unique. This is the name of the function that we have to execute.</p>
                <p><strong>Parameters</strong> – Parameters are optional, if you need to pass the parameter then you can. It declares the type and no. of arguments.</p>
                <h3>Function Declaration</h3>
                <p><strong>Syntax</strong> :</p>
                <p>ReturnType FunctionName(Parameters)</p>
                <p>Example to show declare a function </p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                    #include&lt;stdio.h&gt;<br/>
                    void DemoFunction();<br/>
                    void main ()<br/>
                    &#123;<br/>
                        printf("Hello World ");<br/>
                        DemoFunction();<br/>
                    &#125;<br/>
                    void DemoFunction()<br/>
                    &#123;<br/>
                        printf("DemoFunction called…");<br/>
                    &#125;
                </pre>
                </div>
                <br/><br/>
                <p>Types of function –</p>
                <p>There are two types of functions in C Language.</p>
                <p><strong>1.	Predefined</strong> – These are already defined by the C language. Eg. printf(), scanf()</p>
                <p><strong>2.	User Defined</strong> – These functions are defined by the user at the time of writing code.</p>
                <p><strong>Calling a Function</strong> – When we call a function, control of the program gets transferred to functions.</p>
                <p>There are two ways to calling a function – </p>
                <p>1.	Call by Value</p>
                <p>2.	Call by Reference</p>
                <p><strong>1.	Call by Value</strong> – In call by value the value is passed in function as an argument.</p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                int add(int,int);<br/>
                void main()<br/>
                &#123;<br/>
                    int a,b,c;<br/>
                    printf("main function ..\n");<br/>
                    printf("Enter values of a and b ");<br/>
                    scanf("%d%d",&a,&b);<br/>
                    c=add(a,b);<br/>
                    printf("Sum is %d ",&c);<br/>
                &#125;<br/>
                int add(int a, int b)<br/>
                &#123;<br/>
                    return a+b;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <br/>
                <p><strong>2.	Call by reference</strong> – In call by reference we can pass the address of the variable as an argument.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                void add(int *sum);<br/>
                void main()<br/>
                &#123;<br/>
                    int c;<br/>
                    add(&c);<br/>
                    printf("Sum is %d ",c);<br/>
                &#125;<br/>
                void add(int *sum)<br/>
                &#123;<br/>
                    *sum=5+10;<br/>
                &#125;
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="loops-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-array">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}