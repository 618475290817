import React from 'react';
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ContactUs extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>TutsFinder - Contact Us</title>
                    <meta name="keywords" content="tutsfinder contact details, tutsfinder address, tutsfinder contact form, tutsfinder contact no., tutsfinder details" />

                    <meta property="og:title" content="TutsFinder - Contact Us" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/contact-us" />
                </MetaTags>
                <div className="bradcam_area breadcam_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bradcam_text">
                                    <h3 >Contact</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="contact-section">
                    <div className="container">
                        <div className="d-none d-sm-block mb-5 pb-4">
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="contact-title">Get in Touch</h2>
                            </div>
                            <div className="col-lg-8" style={{fontFamily: `Poppins, sans-serif`}}>
                                <form className="form-contact contact_form" name="form" action="mail.php" method="post" id="contactForm">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter Message'" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="name" id="name" type="text" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter your name'" placeholder="Enter your name"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="email" id="email" type="email" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter email address'" placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input className="form-control" name="subject" id="subject" type="text" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject"/>
                                            </div>
                                        </div>
                                        </div>
                                    <div className="form-group mt-3">
                                        <button type="submit" name="submit" className="button button-contactForm boxed-btn">Send</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                    {/* <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-home"></i></span>
                                        <div className="media-body">
                                            <h3>Buttonwood, California.</h3>
                                            <p>Rosemead, CA 91770</p>
                                        </div>
                                    </div>
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                        <div className="media-body">
                                            <h3>+1 253 565 2365</h3>
                                            <p>Mon to Fri 9am to 6pm</p>
                                        </div>
                                    </div> */}
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><FontAwesomeIcon icon="envelope" style={{fontSize: `30px`}} /></span>
                                        <div className="media-body">
                                            <h3>info@tutsfinder.com</h3>
                                            <p>Send us your query anytime!</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}