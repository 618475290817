import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class MemoryManagementInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Memory Management</title>
                    <meta name="description" content = "Make the efficient use of available memory. Memory is nothing but a storage area where your program get execute ad can be store." />
                    <meta name="keywords" content="C Memory Management,  C Memory Management Tutorial, Memory Management , Memory Management in C, malloc, reallocc, calloc, free" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Memory Management" />
                    <meta property="og:description" content="Make the efficient use of available memory. Memory is nothing but a storage area where your program get execute ad can be store." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/memory-management-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Memory Management</h2>
                    <br/>
                    <p>Make the efficient use of available memory. Memory is nothing but a storage area where your program get execute ad can be store.</p>
                <p>First, when you run a program, it loads into your computer's memory and begins to execute by sending and receiving instructions from the computer's processor.</p>
                <br/>
                <p>Memory divided into 4 segments – </p>
                <p><strong>1.	Code Segment</strong> – It contains only binary which has to be executed.</p>
                <p><strong>2.	Data Segment</strong> – Data segment can be divided into two types –</p>
                <p><strong>•	Initialized data segment</strong> – The data which is already declared.</p>
                <p><strong>•	Uninitialized data segment</strong> – The data which is not initialized.</p>
                <p><strong>3.	Heap</strong> – Heap is used to allocate memory to the program at run time.</p>
                <p><strong>4.	Stack</strong> – Stack is used to store variables that can be used to pass the arguments to the function.</p>
                <p>C supports dynamic memory allocation which has ability to free the memory after execution is done.</p>
                <p>Following are the key functions in standard C library – </p>
                <br/>
                <p><strong>•	Malloc</strong> -  It is used to reserve a block of memory of a given size.</p>
                        <p>It allocates the specified number of bytes, it takes only one arguments i.e amount of memory.</p>
                <p><strong>•	Calloc</strong> – It is also used to allocate memory and initialize with zero.</p>
                <p>It needs two arguments, no of variables and size in bytes.</p>
                <p><strong>•	Realloc</strong> – It is used to resize the block of memory where you can increase or decrease the size of the memory.</p>
                <p><strong>•	Free</strong> – When you not need the memory anymore then you can release it with this function.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                #include &lt;stdlib.h&gt;<br/>
                #include &lt;string.h&gt;<br/>
                <br/>
                int main() &#123;<br/>
                <br/>
                    char name[100];<br/>
                    char *tuts;<br/>
                    <br/>
                    strcpy(name, "Trishant");<br/>
                    <br/>
                    tuts = malloc( 30 * sizeof(char) );<br/>
                    <br/>
                    if( tuts == NULL ) &#123;<br/>
                        fprintf(stderr, "Error - unable to allocate required memory\n");<br/>
                    &#125; else &#123;<br/>
                        strcpy( tuts, "Elementary way to find.\n");<br/>
                    &#125;<br/>
                    <br/>
                    tuts = realloc( tuts, 100 * sizeof(char) );<br/>
                    <br/>
                    if( tuts == NULL ) &#123;<br/>
                        fprintf(stderr, "Error - unable to allocate required memory\n");<br/>
                    &#125; else &#123;<br/>
                        strcat( tuts, "C Programming tutorials");<br/>
                    &#125;<br/>
                    <br/>
                    printf("Name = %s\n", name );<br/>
                    printf("tuts: %s\n", tuts );<br/>
                    <br/>
                    (/* release memory */)<br/>
                    free(tuts);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                Name = Trishant<br/>
                tuts: Elementary way to find.<br/>
                C Programming tutorials
                </pre>
                </div>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="error-handling-in-c">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-command-line-argument">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}