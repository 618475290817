import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaInheritance extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Inheritance</title>
                    <meta name="description" content="Inheritance is one of the features of OOP where one class acquires the properties of another class." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Inheritance" />
                    <meta property="og:description" content="Inheritance is one of the features of OOP where one class acquires the properties of another class." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-inheritance" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Inheritance</strong>
                    <br/><br/>
                <p>Inheritance is one of the features of OOP where one class acquires the properties of another class.</p>
                <p>Inheritance represents IS-A relationship and used for reusability of code.</p>
                <p>•	<strong>Superclass</strong> is the class from which class is derived. Super class is also called parent class or base class.</p>
                <p>•	A class is derived from the superclass is called as the <strong>child class or derived class</strong>.</p>
                <p>•	Methods and variables must be in public or protected except private.</p>
                <p>•	<strong>extends</strong> keyword is used to inherit the class.</p>
                <p>
                <strong>Types of Inheritance:</strong></p>
                <p><strong>1. Single Inheritance</strong> - When a class inherited by another class is known as single inheritance.</p>
                <p><strong>For Example -</strong> car class inherits vehicle class.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/single-inheritance.png" alt="single inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>2. Multi-level Inheritance - </strong>There is a chain of classes where class A inherits class B and class B is also inherited by another class C.</p>
                <p><strong>For Example </strong>- BMW inherits the car class and car inherits the vehicle class.
                </p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/multilevel-inheritance.png" alt="multilevel inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>3. Mutiple Inheritance</strong> - When properties of more than one parent is inherited by another class then this is known as multiple inheritance.</p>
                <p>But java does not support multiple inheritance because it creates ambiguity problem.</p>
                <p>To overcome this problem, there is a concept in java i.e. <strong>Interface</strong>. Later we will learn about it .</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/multiple-inheritance.png" alt="multiple inheritance" width="100%" />
                </div>
                <p><strong>4. Hierarchical Inheritance</strong>- When more than one class acquires the property of a single class then is called as hierarchical inheritance.</p>
                <p><strong>For Example </strong>- Car and bike class inherits the vehicle class.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/hierarchical-inheritance.png" alt="Hierarchical inheritance" width="100%" />
                </div>
                <br/>
                <p><strong>5. Hybrid Inheritance</strong> - It is a comination of more than one type of inheritance.</p>
                <p>When class A is inherited by class B and C, and class C is inherited by class D.</p>
                <br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/hybrid-inheritance.png" alt="Hybrid inheritance" width="100%" />
                </div>
                <br/>
                <br/>
                <p>Example to show class can be extended.</p>

                <div className="container" id="java-program">
                <p>
                class Vehicle<br/>
                &#123;<br/>
                    (// member declarations)<br/>
                &#125;<br/>
                class Car extends Vehicle<br/>
                &#123;<br/>
                    (// inherit accessible members from Vehicle)<br/>
                    (// own member declarations)<br/>
                &#125;
                </p>
                <br/>
                </div>

                <br/><br/>
                <p>Program to show how inheritance works in java.
                </p>
                <div className="container" id="java-program">
                class Vehicle&#123;<br/>
                <br/>
                void run()&#123;<br/>
                System.out.println("Vehicle is running");<br/>
                &#125;<br/>
                &#125;<br/>
                class Car extends Vehicle&#123;<br/>
                void stop()&#123;<br/>
                    System.out.println("Car is stop");<br/>
                    &#125;<br/>
                    &#123;<br/>
                class Main&#123;<br/>
                public static void main(String[] args)&#123;<br/>
                Car obj=new Car();<br/>
                obj.run();<br/>
                obj.stop();<br/>
                &#125;<br/>
                &#125;
                </div>
                <br/>
                <br/>
                <p>This program is also a example of Single Inheritance where Vehicle is parent class and  Car is child class  </p>
                    <br/>
                    <br/>

                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-abstraction-encapsulation">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-polymorphism">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}