import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaInputOutput extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials -Input / Output</title>
                    <meta name="description" content="Java Input-Output package is introduced to perform all input and output operations." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java InputOutput, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials -Input / Output" />
                    <meta property="og:description" content="Java Input-Output package is introduced to perform all input and output operations." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-input-output" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>Java Input/Output</strong>
                    <br/><br/>
                <p>Java Input-Output package is introduced to perform all input and output operations.</p>
                <p>I/O stream has some classes in java.io package and some classes in java.nio.file.</p>
                <p>I/O Stream:</p>
                <p>•	Byte Streams</p>
                <p>•	Character Streams </p>
                <p>•	 Buffered Streams</p>
                <p>Java provides these two popular streams that are used in to perform I/O operations.</p>
                <br/>
                <h4>System.out(System output stream)</h4>
                <p>It is used to provide output of data. System.out is a outputstream through which you can write characters.</p>
                <p>Functions that are used with system.out in java.</p>
                <p>System.out.print- It is used to print the text on screen and moves the cursor to end of the line. And next text prints starts from here.</p>
                <p>System.out.println- It is also used tp print the text on the screen and moves the cursor to the next line. And next text starts print from here.</p>
                <br/>
                <h4>System.in(System input stream)</h4>
                <p>It is used to provide input of data. It reads data from the source (keyboard).</p>
                <p>When we type any data from keyboard than it will be read by system.in.</p>
                <br/>
                <h4>Charcter Stream-</h4>
                <p>This stream is used to perform read- write operations on character type.</p>
                <p>Classes that are used to to read character data-</p>
                <p>•	Reader: To read character streams.</p>
                <p>•	InputStreamReader: It is used to read byte stream and converts it to character stream.</p>
                <p>•	FileReader: It is used to read characters from a file.</p>
                <p>•	BufferedReader: It reduces the I/O operations in the file system because in one read() call method more date can be read from the file.</p>
                <p>Classes that are used to write character data-</p>
                <p>•	Writer: To write the character streams.</p>
                <p>•	OutputStreamWriter: It is used to write character streams and also convert it to byte streams.</p>
                <p>•	FileWriter: It is used to write characters to the file.</p>
                <p>•	BufferedWriter: It can also reduces the I/O operations in the file system because in one write() call method more date can be write in the file.</p>
                <br/>
                <h4>Byte Stream-</h4>
                <p>This stream is used to perform read-write operations on byte data. In byte stream, we have to work with raw data e.g.  characters, image, Unicode data.</p>
                <p>Classes that are used to read the byte data:</p>
                <p>•	InputStream: To read the byte streams.</p>
                <p>•	FileInputStream: It is used to read bytes from a file.</p>
                <p>•	BufferedInputStream: As we saw in the character streams, this is a more efficient method than FileInputStream.</p>
                <p>Classes that are used to write the byte data:</p>
                <p>•	OutputStream: To write byte streams.</p>
                <p>•	FileOutputStream: It is used to write raw bytes to the file.</p>
                <p>•	ByteOutputStream: As we saw in the character streams, this is a more efficient method than FileOutputStream.</p>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    Note:- All these classes are defined under java.io package.
                    </pre>
                </div>

                <br/><br/>
                <strong>Program to create a new file</strong>
                <p>In this program, we are creating a new file, If same name of file is already exist then it will give us error otherwise it will successfully created.</p>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                import java.io.File;<br/>
                import java.io.IOException;<br/>
                public class CreateFile<br/>
                &#123;<br/>
                    public static void main( String[] args )<br/>
                    &#123;<br/>
                        try &#123;<br/>
                        File file = new File("C:\\JavaPrograms\\tutsfinder.txt");<br/>
                            boolean check = file.createNewFile();<br/>
                        if (check)&#123;<br/>
                                System.out.println("File has been created successfully");<br/>
                        &#125;<br/>
                        else&#123;<br/>
                                System.out.println("File already exist !");<br/>
                        &#125;<br/>
                        &#125; catch (IOException e) &#123;<br/>
                            System.out.println("Exception Occured");<br/>
                            e.printStackTrace();<br/>
                        &#125;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                File has been created successfully
                </pre>
                </div>
                <br/><br/>
                <strong>Program to copy a file into new file.</strong>
                <p>In this program, we are copy the already existing file into another file, it it does not exist it will shoe an exception otherwise it will create successfully.</p>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                import java.io.File;<br/>
                import java.io.FileInputStream;<br/>
                import java.io.FileOutputStream;<br/>
                import java.io.IOException;<br/>
                public class CopyFile<br/>
                &#123;<br/>
                    public static void main(String[] args)<br/>
                    &#123;<br/>
                        try&#123;<br/>
                            File infile =new File("C:\\JavaPrograms\\Tutsfinder.txt");<br/>
                            File outfile =new File("C:\\JavaPrograms\\CopyTustfinder.txt");<br/>
                            FileInputStream fis = new FileInputStream(infile);<br/>
                            FileOutputStream fos = new FileOutputStream(outfile);<br/>
                            byte[] buffer = new byte[1024];<br/>
                            int length;<br/>
                            while ((length = fis.read(buffer)) &lt; 0)&#123;<br/>
                                fos.write(buffer, 0, length);<br/>
                            &#125;<br/>
                            fis.close();<br/>
                            fos.close();<br/>
                            System.out.println("File successfully copied !");<br/>
                        &#125;catch(IOException ioe)&#123;<br/>
                            System.out.println(ioe);<br/>
                        &#125;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                    File successfully copied !
                    </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-networking">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-collection">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}