import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpStrings extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - String</title>
                    <meta name="description" content = "String is a sequence of characters. It is used to store text. String can be created as enclosed string literals in double quotes." />
                    <meta name="keywords" content="string, php string, string in php, string php, what is string in php, php string tuttsfinder, tutsfinder, google, reactjs" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - String" />
                    <meta property="og:description" content="String is a sequence of characters. It is used to store text. String can be created as enclosed string literals in double quotes." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-strings" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Strings</strong>
                    <br/><br/>
                    <p>String is a sequence of characters. It is used to store text.</p>
                    <h3>$str = 'TutsFinder';</h3>
                    <p>String can be created as enclosed string literals in double quotes.</p>

                    <h2>How to manipulate PHP Strings –</h2>
                    <br/>
                    <h1>Length of a String</h1>
                    <p>It is used to calculate the no. of letters in the string.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    $str = 'TutsFinder – Elementary way to find';<br/>
                    echo strlen($str);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>PHP words count </h1>
                    <p>It is used to count the no. of words in the string.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo str_word_count("TutsFinder – Elementary way to find");<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Reverse a string</h1>
                    <p>It is used to reverse a string.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo strrev("Hello world!"); // outputs !dlrow olleH<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Search text in string </h1>
                    <p>It is used to search for a specific text within a string. It returns the index of the string.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo strpos("Hello world!", "world"); // outputs 6<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Replace a string </h1>
                    <p>It replces the characters in the string with the other characters.</p>
                    <br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    echo str_replace("world", "Universe", "Hello world!");<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-array">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-functions">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}