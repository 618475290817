import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class LoopsInC extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Loops</title>
                    <meta name="description" content = "Looping statements provides a way to repeat a set of statements at a given place. Types of looping statements – " />
                    <meta name="keywords" content="Loops, Loops in C, C Loops,  C Loops Tutorial, for loop, while loop, do while loop, programiz, guru99" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Loops" />
                    <meta property="og:description" content="Looping statements provides a way to repeat a set of statements at a given place. Types of looping statements – " />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/loops-in-c" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Loops Statements</h2>
                <br/>
                <p>Looping statements provides a way to repeat a set of statements at a given place. Types of looping statements – </p>
                <p>1.	for loop</p>
                <p>2.	while loop</p>
                <p>3.	do while loop</p>
                <br/>
                <p>1.	for loop –Provides a way to iterate over a range of values. It repeadtedly loops until a particular condition is satisfied.</p>
                <p><strong>Syntax : </strong></p>
                <pre>
                For(initialization;termination;increment)<br/>
                &#123;<br/>
                    Statement(s)<br/>
                &#125;
                </pre>
                <br/>
                Program :
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main()<br/>
                &#123;<br/>
                    for(int i=0;i&lt;=10;i++)<br/>
                    &#123;<br/>
                        printf("Count is : "+i);<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <p>While loop  –  while loop continuously executes a block of statements while a particular condition is true.</p>
                <p><strong>Syntax:</strong></p>
                <br/>
                <pre>
                while(expression)<br/>
                    Statement(s)<br/>
                &#125;
                </pre>
                <br/>
                Program :
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main<br/>
                &#123;<br/>
                    int count=1;<br/>
                    while(count&lt;=10)<br/>
                    &#123;<br/>
                        printf("%d",+count);<br/>
                        count++;<br/>
                    &#125;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <p><strong>do-while loop</strong> -  Statement with in the do-while block executes atleast once.</p>
                <p><strong>Syntax:</strong></p>
                <br/>
                <pre>
                do<br/>
                &#123;<br/>
                statement(s)<br/>
                &#125;while(expression);
                </pre>
                <br/>
                <p><strong>Example :</strong></p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;stdio.h&gt;<br/>
                void main()<br/>
                &#123;<br/>
                    int count=1;<br/>
                    do<br/>
                    &#123;<br/>
                        printf("%d",&count);<br/>
                        count++;<br/>
                    &#125;<br/>
                    while(count&lt;=0);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-control-statements">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="functions-in-c">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}