import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssMargin extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Margin</title>
                    <meta name="description" content="Margin specify the space from all four sides of the element. It is a shorthand property." />
                    <meta name="keywords" content="css margin bottom, css margin vs padding, css margin order, css padding, css margin padding, html margin, css margin start, css margin collapse" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Margin" />
                    <meta property="og:description" content="Margin specify the space from all four sides of the element. It is a shorthand property." />
                    <meta property="og:site_name" content="https://www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-margin" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Margin</h2>
                    <br/><br/>

                    <p>Margin specify the space from all four sides of the element. It is a shorthand property.</p>
                    <p><em>margin</em> is used to set all the sides in one declaration.</p>
                    <p>For each side of the element, we have used these margin properties – </p>
                    <p>•	margin-top</p>
                    <p>•	margin-bottom</p>
                    <p>•	margin-left</p>
                    <p>•	margin-right</p>
                    <br/>
                    <p>CSS properties that have Margin values</p>
                    <p>•	auto – browser calculates margin automatically</p>
                    <p>•	length - a margin can be in px, pt, cm, etc.</p>
                    <p>•	% - specifies a margin in % of the width of the containing element</p>
                    <p>•	inherit - specifies that the margin should be inherited from the parent element</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                    h1 &#123;<br/>
                        margin-top: 50px;<br/>
                        margin-bottom: 50px;<br/>
                        margin-right: 100px;<br/>
                        margin-left: 100px;<br/>
                    &#125;<br/>
                    &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                    &lt;h1&gt; TutsFinder- Elementary way to find &lt;/h1&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/margin.png" style={{width:'100%'}} alt="Css Margin" />
                    <p>Margin is used to provide space around the elements outside of any defined border. Margin is a shorthand property where we can use all four properties in a single declaration.</p>
                    <p><strong>For example</strong> – margin: 20px 20px 20px20px;</p>
                    <p>We can define margin in length(cm, px, pt) and in %.</p>
                    <pre>
                    body<br/>
                    &#123;<br/>
                        margin: size;<br/>
                    &#125;
                    </pre>
                    <br/>
                    <h5>1.	margin property with 4 values</h5>
                    <p>margin: 20px 50px 60px 40px;</p>
                    <p>top = 20px</p>
                    <p>right = 50px</p>
                    <p>bottom = 60px</p>
                    <p>left = 40px</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            margin:60px 80px 40px 60px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;  TutsFinder &lt;/h1&gt;<br/>
                        &lt;p&gt; Elementary way to find &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/margin1.png" style={{width:'100%'}} alt="css margin"/>
                    <br/><br/>
                    <h5>2.	margin with 3 values</h5>
                    <p>margin: 50px 80px 100px;</p>
                    <p>top = 50px</p>
                    <p>right and left = 80px</p>
                    <p>bottom = 100px</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            margin:40px 30px 50px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;  TutsFinder &lt;/h1&gt;<br/>
                        &lt;p&gt; Elementary way to find &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/margin2.png" style={{width:'100%'}} alt="css margin" />
                    <br/><br/>
                    <h5>3.	margin property with 2 values</h5>
                    <p>margin: 50px 70px;</p>
                    <p>top and bottom = 50px;</p>
                    <p>left and right = 70px;</p>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            margin: 50px 80px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;  TutsFinder &lt;/h1&gt;<br/>
                        &lt;p&gt; Elementary way to find &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/margin3.png" style={{width:'100%'}} alt="css margin" />
                    <br/><br/>
                    <h5>4.	margin property with 1 value</h5>
                    <p>margin: 100px;</p>
                    <p>top, right, bottom and left = 100px</p>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example </h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            margin:100px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    <br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;  TutsFinder &lt;/h1&gt;<br/>
                        &lt;p&gt; Elementary way to find &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/margin4.png" style={{width:'100%'}} alt="css margin"/>
                    <br/><br/>

                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-border">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-position">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}