import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppStorageClasses extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Storage Classes</title>
                    <meta name="description" content = "Storage class defines the scope, life-span and initial value of the variable and functions, it also describes the features of variables and functions." />
                    <meta name="keywords" content="C++ Storage Classes,  C++ Storage Classes Tutorial, Storage Classes, tutsfinder, Trishant Kumar, auto, register, extern, static, mutable, google.com, google" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Storage Classes " />
                    <meta property="og:description" content="Storage class defines the scope, life-span and initial value of the variable and functions, it also describes the features of variables and functions." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-storage-classes" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Storage Class</h2>
                    <br/>
                    <p>Storage class defines the scope, life-span and initial value of the variable and functions, it also describes the features of variables and functions.</p>
                <p><strong>Syntax to specify storage class:</strong></p>
                <div className="container" id="cpp-program">
                <pre>
                storage_class data_type var_name;
                </pre>
                </div>
                <br/><br/>
                <p>There are 5 storage classes in C++ </p>
                <p>1.	auto</p>
                <p>2.	register</p>
                <p>3.	extern</p>
                <p>4.	static</p>
                <p>5.	mutable</p>
                <br/>
                <img src="../img/c++/storage-class.png" style={{width:`100%`}} alt="Storage Class in C++" />
                <br/><br/>
                <p><strong>1.	Automatic</strong> - auto storage class lets you explicitly declare a variable with automatic storage.</p>
                <p>It is default for variables that are described inside the block i.e. local variables.</p>
                <pre>&#123;<br/>
                    auto a=20;<br/>
                &#125;
                </pre>
                <p><strong>2.	Register</strong> – It indicates to the compiler that the object should be stored in machine register.</p>
                <p>An object having register storage class must be defined within a block or declared as a parameter to a function.</p>
                <pre>
                &#123;<br/>
                    register int  month;<br/>
                &#125;
                </pre>
                <p><strong>3.	Extern</strong> – extern storage class objects can be used by several source files.</p>
                <p>An extern declaration can define outside a function or at the beginning of a block.</p>
                <pre>int i = 42; // declaration and definition<br/>
                extern int i;  // declaration only. </pre>
                <p><strong>4.	Static</strong> – This storage class have static storage duration which means memory for these objects allocated when program begins run and is free when program terminates.</p>
                <pre>Static int x=5</pre>
                <p><strong>5.	Mutable</strong> – This keyword is used to modify the value of  particular data member of const object.</p>
                <p>You cannot use the mutable keyword with names declared as static or const.</p>
                <pre>mutable int x;
                </pre>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6" >
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-loops">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6" >
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-modifiers">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}