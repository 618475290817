import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppEnvironmentSetup extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - How to setup envirnmental variables in C++</title>
                    <meta name="description" content="For running a C++ program, all you need to have a notepad and a compiler. There are various compiler in today’s market GCC, MinGW and you can also use IDE’s such Visual Studio, Blocks." />
                    <meta name="keywords" content="how to setup environment variables in c++, C++ environment setup,  C++ environment setup Tutorial, naukri.com, setup environment variables" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - How to setup envirnmental variables in C++" />
                    <meta property="og:description" content="For running a C++ program, all you need to have a notepad and a compiler. There are various compiler in today’s market GCC, MinGW and you can also use IDE’s such Visual Studio, Blocks." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-environment-setup" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Environment Setup</h2>
                    <br/>
                    <p>For running a C++ program, all you need to have a notepad and a compiler. There are various compiler in today’s market GCC, MinGW and you can also use IDE’s such Visual Studio, Blocks.</p>
                <p>Text Editor – Text editor is used to edit a plain text, we can write our C++ code in text editor,the file in a text editor is our source file that can be executed by the compiler to convert it into machine language. Some text editors are notepad++, sublime text.</p>
                <p>Compiler – C++ compiler is used to convert our source file into machine language code.</p>
                <p>Original C++ program is source code and the resulting code will be object file.</p>
                <p>Compiler is itself a computer program . Examples of compiler are – GCC, MingGW.</p>
                <br/>
                <h4>How to install in Linux –</h4>
                <br/>
                <p>To install GNC C/C++ compiler in linux you have to follow this command -</p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                sudo apt install g++
                sudo apt install build-essential
                </pre>
                </div>
                <br/>
                <h4>How to install in windows –</h4>
                <br/>
                <p>To install compiler on windows, first go to official website of MinGW and click on download button.</p>
                <p>After successful installation check the version of your compiler with the following command –</p>
                <p><i>gcc --version</i></p>
                <p>you will get this output</p>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                gcc (tdm64-1) 5.1.0
                Copyright (C) 2015 Free Software Foundation, Inc.
                This is free software; see the source for copying conditions.  There is NO
                warranty; not even for MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-introduction">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-first-program">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}