import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Association</title>
                    <meta name="description" content="It represents a relationship between two or more objects where all objects have their own life cycle and there is no owner that means no one is depend on another." />
                    <meta name="keywords" content="java abstraction, java abstraction encapsulation, encapsulation, Abstraction in Java tutorials, beginners, Encapsulation in Java, example, java, abstraction, encapsulation basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Association" />
                    <meta property="og:description" content="It represents a relationship between two or more objects where all objects have their own life cycle and there is no owner that means no one is depend on another." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/Java/java-association" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <hr/>
                <strong style={{fontSize:`25px`}}>Association</strong>
                <p>It represents a relationship between two or more objects where all objects have their own life cycle and there is no owner that means no one is depend on another.</p>
                <p>Association can be one to one, one to many, many to one, many to many.</p>
                <p>The objects that are part of association can be created and destroyed independently.</p>
                <p><strong>For Example:</strong> Relationship between doctor and patient. A doctor can be associated with multiple patients and at the same time, patient can visit multiple doctors for treatment. In this, each object doctor and patient has its own life cycle.</p>
                <p>There are two forms of association:</p>
                <p>1.	Aggregation</p>
                <p>2.	Composition</p>
                <br/><br/>
                <div style={{textAlign:`center`}}>
                    <img src="../img/java/association.png" alt="Association" width="100%" />
                </div>	
                <br/>
                <br/>
                <strong>Aggregation</strong>
                <p>It represents Has-A relationship.</p>
                <p>Aggregation is a kind of association where both entities survives independently which means ending one entity will not affect the other entity.</p>
                <p>It is a weak relationship.</p>
                <p>It is a unidirectional association i.e. one way relationship.</p>
                <p><strong>For Example:</strong> Here two entities Department and Teacher. A Teacher may belong to multiple Departments. Hence Teacher is a part of multiple departments. But if we delete a department, Teacher entity will not destroy.</p>
                <br/>
                <br/>
                <strong>Composition</strong>
                <p>It represents a one to many relationship.</p>
                <p>It is restricted form of aggregation where entities are highly dependent on each other. On entity cannot be exist without other.</p>
                <p>It is a strong relationship.</p>
                <p><strong>For Example:</strong> College consists of several departments whenever college entity destroys automatically all the department entities will be destroyed that is without existing college entity there is no chance of existing dependent entity.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-polymorphism">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-array">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}