import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class FirstJavaProgram extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - First Java Program</title>
                    <meta name="description" content="Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries" />
                    <meta name="keywords" content="how to write program in java, first program in java, core java tutorial example, java programming example, tutorials, example in java, java program" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - First Java Program" />
                    <meta property="og:description" content="Java is a high level programming language created by James Gosling in 1991 from Sun Microsystems. The first public available version of Java (Java 1.0) was released in 1995 before that it was named as Oak. Over time several version of java were released which enhanced the language and its libraries" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/first-java-program" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                        <br/><br/>
                        <hr/>
                        <p>In this Segment, you will be understood how java program works and what are the things that user has to focus on.
                        </p>
                        <p>User needs Java Development Kit, To download jdk <a href="https://www.oracle.com/in/java/technologies/javase-downloads.html"><span style={{color:`green`, fontSize:`15px`}}><strong>install jdk</strong></span></a></p>
                        <p>We can create Java program using Notepad -</p>
                        <p>•	First user has to open notepad</p>
                        <p>•	Then type the following code
                        </p>
                        <br/>
                        <p><strong>There are some important parts of the program that perform dominant role to create program.</strong></p>
                        <p><strong>Class : </strong>A class is a blue print of the object. A class describes all the attributes and methods of object.</p>
                        <p><strong>Object : </strong>Object is an instance of class. It is basic unit of system. An object is an real world entity that has some attributes, behavior and identity. Attributes and behavior of an object are defined by the class definition.</p>
                        <p><strong>Methods : </strong>Method is a place where actual logics are written and actions are excuted. It is a block of code which runs only when it is called.</p>
                        <p><strong>Variables : </strong>Each variable has its data type. It is a piece of memory that contain some value.</p>
                        <br/>
                        <br/>
                        <div className="container" id="java-program" style={{width:`80%`, height:`auto`, border: `2px solid black`, background:`#E3CDC2`, color:`black`}}>
                        <p>
                            public class FirstProgram
                            <br/>
                            &#123;
                                <br/>
                                public static void main(String[] args)&#123;
                                <br/>
                                System.out.print(“This is my First Program”);
                                <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;
                                <br/>
                            &#125;
                        </p>
                        </div><br/><br/>
                    <p>•	Save the file as <strong>FirstProgram.java</strong>. </p>
                    <div className="container" style={{width:`100%`, height:`auto`, border: `2px solid green`, color:`black`}}>
                    Note: File name is always same as class name and it is case sensitive.
                    </div>
                    <br/><br/>
                    <p>•	Now open your cmd and set the directory path where your java program is saved and run the following commands:
                    <br/>
                    <i>&nbsp;&nbsp;&nbsp;&nbsp;javac FirstProgram.java</i>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;<i>java FirstProgram</i>
                    <br/><br/>
                    Output:
                    <br/>
                    This is my First Program
                    </p>
                    <br/>
                    <img src="../img/java/first-program.png" style={{maxWidth:`100%`}} alt="first-program-in-java" />
                    <br/><br/>
                    <p><strong style={{fontSize: '25px'}}>Program Explained</strong></p>
                    <p><strong style={{fontSize: '18px'}}>main() method</strong></p>
                    <p>Java main() is composed of these terms:</p>
                    <p><strong style={{fontSize: '18px'}}>public</strong></p>
                    <p>It is an access specefier. It only defines that it can be accessed publicly.</p>
                    <p><strong style={{fontSize: '18px'}}>static</strong></p>
                    <p>It means that no object is required to access this method</p>
                    <p><strong style={{fontSize: '18px'}}>void</strong></p>
                    <p>Java main function is void so it does not return any value.</p>
                    <p><strong style={{fontSize: '18px'}}>main</strong></p>
                    <p>It tells to java program that our program starts from here. This is the name of java main method</p>
                    <p><strong style={{fontSize: '18px'}}>String[] args</strong></p>
                    <p>It defines that java program can accept a single argument of type string array. It is also know java command line arguments.</p>
                    <br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="program-execution-in-java">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="jdk-jre-jvm">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}