import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptRegularExpression extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Regular Expression</title>
                    <meta name="description" content="Regular Expression is an API that is used to defining String patterns that we want to match to strings." />
                    <meta name="keywords" content="javascript regular expression match, javascript regular expression tester, javascript regular expression example, javascript regex test, javascript regex match example, regular expression examples, regular expression java, regular expression tutorial" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Regular Expression" />
                    <meta property="og:description" content="Regular Expression is an API that is used to defining String patterns that we want to match to strings." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-regular-expression" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>Error Handling</h2>
                    <br/><br/>
                    <p>Regular Expression is an API that is used to defining String patterns that we want to match to strings.</p>
                    <p>Regular expression is used to search fro a particular pattern, it is based on pattern-matching.</p>
                    <pre><strong>
                    Syntax:
                    var pattern = /pattern/attributes
                    Or
                    var pattern = new RegExp(pattern, attributes);
                    </strong></pre>
                    <p>•	pattern −.the pattern you want to search</p>
                    <p>•	attributes – </p>
                    methods for regex
                    <table className="cont">
                    <tbody>
                        <tr><th>Method</th><th>Description</th></tr>
                        <tr><td>exec()</td><td>Executes a search for a match in its string parameter.</td></tr>
                        <tr><td>test()</td><td>Tests for a match in its string parameter.</td></tr>
                        <tr><td>toSource()</td><td>Returns an object literal representing the specified object; you can use this value to create a new object.</td></tr>
                        <tr><td>toString()</td><td>Returns a string representing the specified object.</td></tr>
                    </tbody>
                    </table>
                    <br/><br/>
                    <h2>Matching Symbols</h2>
                    <p>These are used to match the characters in the specefied string.</p>
                    <br/><br/>
                    <div style={{textAlign:`center`}}>
                    <img src="../img/java/regular-expression.png" alt="Regular Expression" style={{width:`100%`}} />
                    </div>
                    <br/><br/>

                    <h2>Meta Character</h2>
                    <p>Meta characters have predefined meanings. They works as short code.</p>
                    <br/><br/>
                    <div style={{textAlign:`center`}}>
                    <img src="../img/java/meta-character.png" alt="Regular Expression" style={{width:`100%`}} />
                    </div>
                    <br/><br/>
                    <h2>Quantifiers</h2>
                    <p>Quantifiers define how many times an element can occur.</p>
                    <br/><br/>
                    <div style={{textAlign:`center`}}>
                    <img src="../img/java/quantifier.png" alt="Regular Expression" style={{maxWidth:`80%`}} />
                    </div>
                    <br/><br/><br/>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt;Creating a Regular Expression in JavaScript&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;script&gt;<br/>
                        var str = "TutsFinder";<br/>
                        <br/>
                        (// Literal syntax)<br/>
                        var regex1 = /^Tu/;v<br/>
                        <br/>
                        if(regex1.test(str)) &#123;<br/>
                            document.write("Match found!");<br/>
                        &#125; else &#123;<br/>
                            document.write("Match not found.");<br/>
                        &#125;<br/>
                        <br/>
                        &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre><br/>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    Match found!
                <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-objects">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-dom">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}