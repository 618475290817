import React from 'react';
import {Link} from "react-router-dom";
import GoogleAd from '../Components/GoogleAd';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <div className="popular_program_area section__padding" style={{paddingTop:`0px`,paddingBottom:`0px`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Programming Languages</h3>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content" id="nav-tabContent" style={{paddingTop:`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="c/c-language-introduction"><img src="img/c.jpg" alt="C" /></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>C Language</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/c++/cpp-introduction"><img src="img/c++.jpg" alt="C++"/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>C++</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/java/java-overview"><img src="img/java.jfif" alt="Java" /></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>Core Java</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Web Development</h3>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content" id="nav-tabContent" style={{'padding':`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/html/html-introduction"><img src="img/html.png" alt="HTML"/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>HTML </h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/css/css-introduction"><img src="img/css.png" alt=""/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>CSS</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="/javascript/javascript-introduction"><img src="img/Javascript.png" alt="Javascript"/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>Javascript</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoogleAd />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>Quant and Apti Questions</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent" style={{'paddintTop':`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="quantitative/quantitative-set-1"><img src="img/quant.jpg" alt=""/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>Quantitative Ability </h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <img src="img/logical.png" alt=""/>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>Logical Reasoning</h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text-center">
                                    <h3>HR Interview Questions</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent" style={{'paddintTop':`30px`}}>
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">

                                    <div className="col-lg-4 col-md-6">
                                        <div className="single__program">
                                            <div className="program_thumb">
                                                <Link to="hr-interview-questions/hr-interview-questions-set-1"><img src="img/HR.png" alt="HR"/></Link>
                                            </div>
                                            <div className="program__content" style={{'padding':`10px 10px`}}>
                                                <span></span>
                                                <h4>HR </h4>
                                                <p></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div><br/><br/><br/>

            </>
        )
    }
}