import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptControlFlow extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Control Flow</title>
                    <meta name="description" content="Control Statements controls the flow of program execution. These are keywords that are used for making a decision." />
                    <meta name="keywords" content="javascript control flow w3schools, javascript control flow exercises, javascript control structures pdf, control statements in javascript, what is control flow in java, javascript control structures answers, control statements in javascript geeksforgeeks, control statement in javascript w3schools" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Control Flow" />
                    <meta property="og:description" content="Control Statements controls the flow of program execution. These are keywords that are used for making a decision." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-control-flow" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Control Flow</h2>
                    <br/><br/>
                    <p>Control Statements controls the flow of program execution.</p>
                    <p>These are keywords that are used for making a decision. In this, we will discuss about conditional statements and unconditional statements. These statements are used to control the flow of statements.</p>
                    <h3>Conditional Statements</h3>
                    <p>Conditional Statements are used when the execution of statements depends on one or more conditions.</p>
                    <h4>Decision making statements :</h4>



                    <h3>• if statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    {
                    //if condition is true then only code to be executed;
                    }
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en"&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var x = 100;<br/>
                    if(x &lt; 10)&#123;<br/>
                    document.write("Value of x is greater than 10");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>Value of x is greater than 10 </h1>
                    <br/><br/>

                    <h3>• if-else statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    {
                    //code to be executed if condition is true;
                    }
                    else {
                    //code to be executed if condition is false;
                    }
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if-else statement&lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a=20;<br/>
                    if(a%2==0)&#123;<br/>
                    document.write("Even number");<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    document.write("Odd number");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> Even number</h1>
                    <br/><br/>


                    <h3>• if-else-if statement</h3>
                    Syntax:
                    <pre>
                    if (condition)
                    {
                    //code to be executed if condition is true;
                    }
                    elseif (condition) {
                    //code to be executed if condition is true;
                    } else {
                    //code to be executed if all conditions are false;
                    }
                    </pre>

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript if-else-if statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var a=7;<br/>
                    if(a==8)&#123;<br/>
                    document.write("Number is equal to 8");<br/>
                    &#125;<br/>
                    else if(a==15)&#123;<br/>
                    document.write("Number is equal to 15");<br/>
                    &#125;<br/>
                    else if(a==7)&#123;<br/>
                    document.write("Number is equal to 7");<br/>
                    &#125;<br/>
                    else&#123;<br/>
                    document.write("Number is not equal to 8, 15 or 7");<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;<br/>
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1>Number is equal to 7  </h1>
                    <br/><br/>

                    <h3>switch statement</h3>
                    Syntax:
                    <pre>
                    Switch case
                    switch(expression)
                    &#123;
                    case value1:
                    (//code to be executed)
                    break;
                    case value2:
                    (//code to be executed)
                    break;
                    ......
                    default:
                    code to be executed if all cases are not matched;
                    &#125;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html lang="en&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;meta charset="UTF-8"&gt;<br/>
                    &lt;title&gt; JavaScript Switch Statement &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    var favcolor='red';<br/>
                    var result;<br/>
                    switch (favcolor) &#123;<br/>
                    case "red":<br/>
                    result="Your favorite color is red!";<br/>
                    break;<br/>
                    case "blue":<br/>
                    result="Your favorite color is blue!";<br/>
                    break;<br/>
                    case "green":<br/>
                    result="Your favorite color is green!";<br/>
                    break;<br/>
                    default:<br/>
                    result="Your favorite color is neither red, blue, nor green!";<br/>
                    &#125;<br/>
                    document.write(result);<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h1> Your favorite color is red!</h1>
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-data-types">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-events">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}