import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class SetupEnvironmentalVariables extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Setup Environment Variables</title>
                    <meta name="description" content="In this segment, we will discuss about how we can set up environment variables to establish runtime environment." />
                    <meta name="keywords" content="how to setup environment variables in java, setup environement variabes, how to set path in java, environament setup java tutsfinder, tutsfinder environment setup" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Setup Environment Variables" />
                    <meta property="og:description" content="In this segment, we will discuss about how we can set up environment variables to establish runtime environment." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/setup-environmental-variables-in-java" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <hr/>
                    <p>In this segment, we will discuss about how we can set up environment variables to establish runtime environment.</p>
                    <hr/>
                        <strong style={{fontSize:`25px`}}>Set-up Environment Variables</strong>
                        <br/>
                        <br/>
                        <p>To set the path for java programming language, follow these steps –</p>
                        <p>1.	Right click on  <i>ThisPC</i> and open <strong>Properties</strong>. </p>
                        <img src="../img/java/setup-environment-1.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>
                        <p>2.	Click on <strong>Advance System Settings</strong>.</p>
                        <img src="../img/java/setup-environment-2.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>
                        <p>3.	Then Click on <strong>Environment Variables</strong>.</p>
                        <img src="../img/java/setup-environment-3.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>
                        <p>4.	Click on <strong>New</strong> Button of <i>User Variables</i>.</p>
                        <img src="../img/java/setup-environment-4.png" style={{maxWidth:`100%`}} alt="" />
                        <br/><br/>
                        <p>5.	Set Variable name as <i>“path”</i> and copy the path of your <strong>Java JDK bin folder</strong>(C:\Program Files\Java\jdk1.8.0_231\bin)  in <i>Variable value</i>.</p>
                        <img src="../img/java/setup-environment-5.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>
                        <p>6.	Click on <strong>OK</strong> button.</p>
                        <img src="../img/java/setup-environment-6.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>
                        <p>7.   Click again on <strong>OK</strong> button and save that settings.</p>
                        <img src="../img/java/setup-environment-7.png" style={{maxWidth:`100%`}} alt=""/>
                        <br/><br/>

                    <br/>
                    <br/>
                    <br/>
                    <p>Now, you will be able to run and execute your Java program.</p>
                    <p>If you want to check that java is successfully installed in your system, then go to Command Prompt and type <i>“java –version”</i> and <i>“javac –version”</i>.</p>
                    <img src="../img/java/setup-environment-9.png" style={{maxWidth:`100%`}} alt="" />
                    <br/>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6" id="next-pev-button-div">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-overview">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6" id="next-pev-button-div">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="program-execution-in-java">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}