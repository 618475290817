import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Marquee from "react-fast-marquee";
export default class HtmlMarquees extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Marquees </title>
                    <meta name="description" content="An HTML Marquee tag is used to display a text or image scrolling horizontally or vertically." />
                    <meta name="keywords" content="html marquee tag, marquee tag, Html marquees, Html marquees Tutorial, marquees, marquee, marquee tutsfinder, javatpoint, marqiee w3schools" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Marquees" />
                    <meta property="og:description" content="An HTML Marquee tag is used to display a text or image scrolling horizontally or vertically." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-marquees" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Marquees</h2>
                    <br/><br/>
                <p>An HTML Marquee tag is used to display a text or image scrolling horizontally or vertically.</p>
                <p>You can use Marquee tag if you want your text or image to be moved up, down, left or right automatically.</p>
                <p><strong>NOTE</strong>: Marquees can use in Javascript and CSS, as it is deprecated in HTML5.</p>
                <br/>
                Syntax:
                <br/>
                <pre>
                &lt;marquee attribute name = “attribute_value”. . . more attributes&gt;
                Lines or Images to be displayed
                &lt;/marquee&gt;
                </pre>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example:</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt; Marquee Tag &lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;marquee direction = “right”&gt;TutsFinder - Elementary way to find&lt;/marquee&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <Marquee direction="right">TutsFinder - Elementary way to find</Marquee>
                <br/><br/>
                <p>Above example shows to move the text from left to right, similarly you can enter the values as left, up, down.</p>
                <br/><br/>
                <h4>Table illustrating Marquee Tag Attributes</h4>
                <br/><br/>
                <img src="../img/html/marquees.png" alt="Marquees" style={{width:`100%`}} />
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-lists">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-forms">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}