import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlLinks extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Links </title>
                    <meta name="description" content="Links are the content which are found on all web pages, and is used to click from one page to another page." />
                    <meta name="keywords" content="links, links i html, Html links, Html links Tutorial, what is links in html, coursera" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Links" />
                    <meta property="og:description" content="Links are the content which are found on all web pages, and is used to click from one page to another page." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-links" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Links</h2>
                    <br/><br/>
                <p>Links are the content which are found on all web pages, and is used to click from one page to another page.</p>
                <p>HTML links are also called as hyperlinks, when a user clicks on a hyperlink It redirects to another document.</p>
                <p>Identification for a hyperlink is when a user moves a mouse on hyperlink the arrow will turn into a little hand.</p>
                <br/>
                Syntax:
                <br/>
                <p>Hyperlinks are defined with the HTML &lt;a&gt; tag.</p>
                &lt;a href = “url”&gt; link text &lt;/a&gt;
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;<br/>
                &lt;title&gt;&lt;/title&gt;<br/>
                &lt;/head&gt;<br/>
                &lt;body&gt;<br/>
                &lt;p&gt; Click on &lt;a href= http://www.tutsfinder.in/ target=”_blank”&gt; this-
                Link&lt;/a&gt; to go to home page of tutfinder. &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <h5>Target Attribute of HTML Link</h5>
                <p>It specifies where to open the linked document.</p>
                <p>Values of target attribute:</p>
                <p>•	_blank- open the linked document in a new window or tab.</p>
                <p>•	_self- opens the linked document in the same window or tab.</p>
                <p>•	_parent- opens the linked document in the parent frame.</p>
                <p>•	_top- opens the linked document in the full body of the window.</p>
                <br/>
                <h5>HTML links can have the following:</h5>
                <br/>
                <h4>Button as a Link</h4>
                <p>To use an HTML button as a link, you have to add some JavaScript code.JavaScript allows you to specify what happens at certain events, such as a click of a button.</p>
                <p><strong>Example:</strong></p>
                &lt;button onclick = “document.location = ‘default.asp’ “&gt; HTML tutorial &lt;/button&gt;
                <br/><br/>
                <h4>Image as a Link</h4>
                <p>Links can be commonly used as images.</p>
                <p><strong>Example:</strong></p>
                &lt;a href = “default.asp”&gt;
                &lt;img src = “smiley.gif” alt= “HTML tutorial” style = “width: 40px; height : 40px;border:0;”&gt;
                &lt;/a&gt;
                <br/><br/>
                <h4>Link Titles</h4>
                <p>The title attribute specifies extra information about an element. The information is most often shown as a tooltip text when the mouse moves over the element.</p>
                <p><strong>Example:</strong></p>
                &lt; a href = “http://www.tutsfinder.in/” title=”Go to Tutsfinder HTML tutorial”&gt; Visit our HTML Tutorial &lt;/a&gt;
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-anchor-tag">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-styles">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}