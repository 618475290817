import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Padding</title>
                    <meta name="description" content="Padding is used to provide space around the elements inside of any defined border. Padding is a shorthand property where we can use all four properties in a single declaration." />
                    <meta name="keywords" content="css padding shorthand, css padding order, css padding vs margin, css border, css margin, css padding color, html padding left" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Padding" />
                    <meta property="og:description" content="Padding is used to provide space around the elements inside of any defined border. Padding is a shorthand property where we can use all four properties in a single declaration." />
                    <meta property="og:site_name" content="https://www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-padding" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>CSS Padding</h2>
                    <br/><br/>

                    <p>Padding is used to provide space around the elements inside of any defined border. Padding is a shorthand property where we can use all four properties in a single declaration.</p>
                    <p><strong>For example</strong> – padding: 20px 20px 20px20px;</p>
                    <p>We can define padding in length(cm, px, pt) and in %.</p>
                    <pre>
                    body<br/>
                    &#123;<br/>
                        Padding: size;<br/>
                    &#125;
                    </pre>
                    <br/>
                    <h5>1.	padding property with 4 values</h5>
                    <pre>
                    padding: 20px 50px 60px 40px;<br/>
                    top = 20px<br/>
                    right = 50px<br/>
                    bottom = 60px<br/>
                    left = 40px
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                        &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            padding:60px 80px 40px 60px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;<br/>
                            TutsFinder<br/>
                        &lt;/h1&gt;<br/>
                        &lt;p&gt;<br/>
                            Elementary way to find<br/>
                        &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/padding1.png" alt="Css Padding" />
                    <br/><br/>
                    <h5>2.	padding with 3 values</h5>
                    <pre>
                    padding: 50px 80px 100px;<br/>
                    top = 50px<br/>
                    right and left = 80px<br/>
                    bottom = 100px<br/>
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                        &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            padding:40px 30px 50px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;<br/>
                            TutsFinder<br/>
                        &lt;/h1&gt;<br/>
                        &lt;p&gt;<br/>
                            Elementary way to find<br/>
                        &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/padding2.png" alt="CSS Padding" />
                    <br/><br/>
                    <h5>3.	padding property with 2 values</h5>
                    <pre>
                    padding: 50px 70px;<br/>
                    top and bottom = 50px;<br/>
                    left and right = 70px;
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                        &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            padding: 50px 80px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;<br/>
                            TutsFinder<br/>
                        &lt;/h1&gt;<br/>
                        &lt;p&gt;<br/>
                            Elementary way to find<br/>
                        &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/padding3.png" alt="CSS Padding" />
                    <br/><br/>
                    <h5>4.	padding property with 1 value</h5>
                    <pre>
                    padding: 100px;<br/>
                    top, right, bottom and left = 100px
                    </pre>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;/head&gt;<br/>
                        &lt;style&gt;<br/>
                            p<br/>
                            &#123;<br/>
                            padding:100px;<br/>
                            &#125;<br/>
                        &lt;/style&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                        &lt;h1&gt;<br/>
                            TutsFinder<br/>
                        &lt;/h1&gt;<br/>
                        &lt;p&gt;<br/>
                            Elementary way to find<br/>
                        &lt;/p&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/padding4.png" alt="CSS Padding" />
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-position">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-display">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}