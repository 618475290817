import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    totalElements(){
        var allcity = document.getElementsByName("city");
        alert("Total Cities:"+allcity.length);
    }
    count(){
        var total = document.getElementsByTagName("h4");
        alert("Total heading tags : "+total.length);
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Data Object Model</title>
                    <meta name="description" content="A document object is created when html page get loaded in the browser which can be used to add dynamic content to html web pages." />
                    <meta name="keywords" content="document object model in javascript pdf, document object in javascript with example, document object model in javascript ppt, document object model in javascript in hindi, javascript dom methods, javascript dom examples, document object model in xml, properties of document object model" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Data Object Model" />
                    <meta property="og:description" content="A document object is created when html page get loaded in the browser which can be used to add dynamic content to html web pages." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-data-object-model" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>Data Object Model</h2>
                    <br/><br/>
                    <p>DOM or Data Object Model is used to access the documents.</p>
                    <p>A document object is created when html page get loaded in the browser which can be used to add dynamic content to html web pages.</p>
                    <p>The object model defines all the html elements as objects, that defines properties and methods of objects for all the html elements.</p>
                    <br/>
                    <h3>Methods of DOM</h3>
                    <br/>
                    <table className="cont">
                    <tbody>
                    <tr>
                    <th>Methods</th>
                    <th>Description</th>
                    </tr>
                    <tr>
                    <td>write("sometext")</td><td>Used to display text</td>
                    </tr>
                    <tr>
                    <td>writeln("sometext")</td><td>Display text from newline</td>
                    </tr>
                    <tr>
                    <td>getElementByID()</td><td>Returns the element by given id.</td>
                    </tr>
                    <tr>
                    <td>getElementByName()</td><td>Return elements by specified name.</td>
                    </tr>
                    <tr>
                    <td>getElementsByTagName()</td><td>Return elements by specific tag name.</td>
                    </tr>
                    <tr>
                    <td>getElementByClassName()</td><td>Return elements by specific class name.</td>
                    </tr>
                    </tbody>
                    </table>
                    <br/><br/>
                    <h3>Get element by id</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;h2&gt; Demo Page &lt;/h2&gt;<br/>
                    <br/>
                    &lt;p id="demo"&gt;&lt;/p&gt;<br/>
                    <br/>
                    &lt;script&gt;<br/>
                    document.getElementById("demo").innerHTML = "Hello World!";<br/>
                    &lt;/script&gt;<br/>
                    <br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h2> Demo Page </h2>
                    <p>Hello World!</p>
                    <br/><br/>

                    <h3>Get element by name</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script type="text/javascript"&gt;<br/>
                    function totalelements()<br/>
                    &#123;<br/>
                    var allcity=document.getElementsByName("city");<br/>
                    alert("Total Cities:"+allcity.length);<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;form&gt;<br/>
                    Pune:&lt;input type="radio" name="city" value="pune"&gt;<br/>
                    Mumbai:&lt;input type="radio" name="city" value="mumbai"&gt;<br/>
                    Delhi:&lt;input type="radio" name="city" value="delhi"&gt;<br/>
                    &lt;input type="button" onclick="totalelements()" value="Total Genders"&gt;<br/>
                    &lt;/form&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <form>
                    <input type="text" name="city" value="pune" />
                    <input type="text" name="city" value="mumbai" />
                    <input type="text" name="city" value="delhi" />
                    <input type="button" onClick={this.totalElements} value="Total Cities" />
                    </form>
                    <br/><br/>

                    <h3>Get element by tag name</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script type="text/javascript"&gt;<br/>
                    function count()&#123;<br/>
                    var total=document.getElementsByTagName("h4");<br/>
                    alert("total tags are: "+total.length);<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;h4&gt;First Heading&lt;/h4&gt;<br/>
                    &lt;h4&gt;Second Heading&lt;/h4&gt;<br/>
                    &lt;p&gt;Let's see the simple example&lt;/p&gt;<br/>
                    &lt;button onclick="count()"&gt;Click to get count&lt;/button&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <h4>First Heading</h4>
                    <h4>Second Heading</h4>
                    <p>Let's see the simple example</p>
                    <button onClick={this.count}>Click to get count</button>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-forms">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-browser-object-model">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}