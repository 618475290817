import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CIntroduction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Introduction</title>
                    <meta name="description" content = "C is a general purpose, middle level, procedure oriented programming language that supports structured programming." />
                    <meta name="keywords" content="C programming, c langugae, C tutorial,  C programming Tutorial, introduction to C, introduction, c programming tutorial pdf, c programming tutorial w3schools, w3schools, best c language tutorial on youtube, c programming software, c language basics, c programming download, c programming examples with output, c programming examples ,head first c, c programm absolute b, c the complete reference, expert c programm deep c, mingww64, mingw, borland turbo c, gnu compiler collection, intel c compiler, tiny c compiler, c crash course a fastpace, the c programm language, c primer, effective modern c, c without fear a beginners, c templates the comp" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Introduction " />
                    <meta property="og:description" content="C is a general purpose, middle level, procedure oriented programming language that supports structured programming." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-introduction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                    </nav>
                    <br/><br/>
                    <div>
                        <img src="../img/c/c.jpg" style={{width:`100%`, height:`auto`, border: `groove`}} alt="C Programming" />
                    </div>
                    <br/>
                <h2>C Introduction</h2>
                    <br/>
                    <p>C is a general purpose, middle level, procedure oriented programming language that supports structured programming.</p>
                <p>C language is developed by Dennis Ritchie at Bell Labs between 1972 to 1973 for UNIX operating systems. But latterly, it has become the most widely used programming language and in 1989 C has been standardized by the ANSI and by the International Organization of Standardizations (ISO).
                </p>
                    <br/>
                    <h4>Why C Programming ?</h4>
                <p>C is mother of all the languages. So if you want become a software engineer than it is must for students and working professionals to learn C language. Few key points that makes C language popular – </p>
                <p>•	Easy to learn</p>
                <p>•	Structured Language</p>
                <p>•	Middle Level</p>
                <p>•	Mother of all languages</p>
                <p>•	Efficient programs</p>
                <br/>
                <h4>History of C</h4>
                <p>C language is developed by Dennis Ritchie at AT& T(American Telephone & Telegraph) Bell Labs between 1972 to 1973.</p>
                <p>It is developed to be used in utilities of UNIX operating system.</p>
                <p>It was introduced to overcome the problems of B and BCPL languages.</p>
                <br/>
                <h4>Features of C language </h4>
                <p><strong>1.	Easy to learn</strong> – C programs are very easy to understand because it primitives that are easy and break the problem into parts i.e. functions.</p>
                <p><strong>2.	Efficient and Fast</strong> – Programs written in C language are efficient and fast because it has variety of data types and operators. Compilation and execution of C language is fast because it has lesser functions.</p>
                <p><strong>3.	Portable</strong> – A program written on one machine can be run on another machine with little or no modification.</p>
                <p><strong>4.	Middle Level</strong> – C is introduced only for lower level programming but it has also a features for high level programming language.</p>
                <p><strong>5.	Structured Language</strong> - In structured programming we can break the program into pieces in the form of functions and then solve it. So, it makes programming easy.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="first-program-in-c">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}