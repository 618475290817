import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppExceptionHandling extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Exception Handling</title>
                    <meta name="description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta name="keywords" content="exception handling, exception handling in c++, C++ exception handling,  C++ exception handling Tutorial, exception handling in c++ javatpoint, google" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Exception Handling" />
                    <meta property="og:description" content="Exception is an event that occurs during an execution of a program that disrupts the normal flow the program." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-exception-handling" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Exception Handling</h2>
                    <br/>
                <p>Exception is an event that occurs during an execution of a program that disrupts the normal flow the program.</p>
                <p>To handle these exceptions, there be a concept in java that is Exception handling; it maintains the normal flow of the program.</p>
                <p>Main advantage of exception handling is that it’s maintain the normal flow of the program and Separate error handling code form normal code.</p>
                <p>Exception can be occurred due to wrong input data e.g. divide by zero.</p>
                <p>There are three keywords in C++ associated with exception handling</p>
                <p><strong>Try</strong> – To make statements exception safe, enclose them within a try block and handle the exceptions that emerge out of try block in the catch block.</p>
                <pre>
                void func()<br/>
                &#123;<br/>
                    try&#123;<br/>
                        int* p= new int;<br/>
                        *p = 999;<br/>
                        Delate p;<br/>
                &#125;<br/>
                    catch(Exception e)&#123;<br/>
                        cout &lt;&lt; e &lt;&lt; endl;<br/>
                &#125;<br/>
                &#125;
                </pre>
                <br/>
                <p><strong>Catch</strong> – catch block is used to catch the exception when try block throws an exception.</p>
                <pre>
                catch
                &#123;
                    (//methods to control error;)
                &#125;
                </pre>
                <p><strong>Throw</strong> - Throw keyword allows the user to throw an exception or any class .</p>
                <br/>
                Program to show how try/catch block works
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                float divide(int x, int y) &#123;<br/>
                    if( y == 0 ) &#123;<br/>
                        throw "Exception occured!";<br/>
                    &#125;<br/>
                    return (x/y);<br/>
                &#125;<br/>
                int main () &#123;<br/>
                    int i = 5;<br/>
                    int j = 0;<br/>
                    float k = 0;<br/>
                    try &#123;<br/>
                        k = divide(i, j);<br/>
                        cout &lt;&lt; k &lt;&lt; endl;<br/>
                    &#125;catch (const char* e) &#123;<br/>
                        cerr &lt;&lt; e &lt;&lt; endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Exception occured!
                </pre>
                </div>
                <br/><br/>
                Exception handling using try/cath throw keyword
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    int a=5, b=0, c;<br/>
                    try<br/>
                    &#123;<br/>
                        if(b == 0)<br/>
                        &#123;<br/>
                            throw "exception occured  ";<br/>
                            c = a/b;<br/>
                        &#125;<br/>
                    &#125;<br/>
                    catch(char* ex)<br/>
                    &#123;<br/>
                        cout &lt;&lt; ex;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Exception occured
                </pre>
                </div>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    fstream fs;<br/>
                    fs.open("C:\xyz.txt",ios::out);  // Creating new file<br/>
                    if(!fs)<br/>
                    &#123;<br/>
                        cout &lt;&lt; "File creation failed";<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        cout &lt;&lt; "New file created";<br/>
                        fs.close();<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                New file created
                </pre>
                </div>
                <br/>
                <h3>Reading from a file –</h3>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                using namespace std;<br/>
                int main () &#123;<br/>
                    string str;<br/>
                    ifstream filestream("xyz.txt");<br/>
                    if (filestream.is_open())<br/>
                    &#123;<br/>
                    while ( getline (filestream,str) )<br/>
                    &#123;<br/>
                        cout &lt;&lt; str &lt;&lt; endl;<br/>
                    &#125;<br/>
                    filestream.close();<br/>
                    &#125;<br/>
                    else &#123;<br/>
                        cout &lt;&lt; "File opening is fail !" &lt;&lt; endl;<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/>
                <h3>Writing to a file – </h3>
                <br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                #include &lt;fstream&gt;<br/>
                using namespace std;<br/>
                int main()<br/>
                &#123;<br/>
                    fstream fs;<br/>
                    fs.open("C:\xyz.txt",ios::out);<br/>
                    if(!fs)<br/>
                    &#123;<br/>
                        cout &lt;&lt; "File creation failed";<br/>
                    &#125;<br/>
                    else<br/>
                    &#123;<br/>
                        cout &lt;&lt; "New file created successfully";<br/>
                        fs &lt;&lt; "TutsFinder";<br/>
                        fs.close();<br/>
                    &#125;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>

                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-file-and-streams">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </>
        )
    }
}