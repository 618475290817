import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CssIcons extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>CSS Tutorials - Icons</title>
                    <meta name="description" content="Icons can be defined as symbols or picture representation of an element." />
                    <meta name="keywords" content="icons, css icons, icons in css, css icons tutsfinder, tutsfinder, trishant, font awesome, fa fa icon, google icons, bootstrap icons, fa fa, fa fa-facebook" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="CSS Tutorials - Icons" />
                    <meta property="og:description" content="Icons can be defined as symbols or picture representation of an element." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/css/css-2d-transform" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>CSS</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <h2>Icons</h2>
                    <br/><br/>

                    <p>Icons can be defined as symbols or picture representation of an element.</p>
                    <p>To use CCS icons in you html page you just simply import libraries such as font awesome, google icons, bootstrap icons etc.</p>
                    <p>Between &lt;i&gt; &lt;/i&gt; placed the icon name.</p>
                    <br/>
                    For example
                    &lt;i className="fa fa-address-book" aria-hidden="true"&gt;&lt;/i&gt;
                    <br/><br/>
                    <h4>How to use CSS icons in an HTML page – </h4>
                    First you have to import script of that particular icon libray, for example If you are using font awesome icons then you have to imort &lt;script src="https://use.fontawesome.com/86c9103e36.js"&gt;&lt;/script&gt; in &lt;head&gt; section.

                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example -</h5>
                    <div className="css-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;script src="https://use.fontawesome.com/86c9103e36.js" &lt;/script&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    <br/>
                    &lt;i className="fa fa-address-book" aria-hidden="true"&gt;&lt;/i&gt;<br/>
                    &lt;br&gt;<br/>
                    &lt;i className="fa fa-envelope-open" aria-hidden="true"&gt;&lt;/i&gt;<br/>
                    &lt;br&gt;<br/>
                    &lt;i className="fa fa-phone" aria-hidden="true"&gt;&lt;/i&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <img src="../img/css/icons.png" alt="display" style={{width:`100%`}} />
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                            <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="css-opacity">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="css-z-index">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}