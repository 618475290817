import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavascriptFunctions extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    area(){
        alert("Area is: "+4*5);
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Javascript Tutorials - Functions</title>
                    <meta name="description" content="Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta name="keywords" content="javascript function onclick, javascript function object, how do you create a function in javascript, javascript functions list, types of functions in javascript, javascript function declaration, javascript functions for beginners, javascript function arguments" />
                    <meta name="theme-color" content="#E9D40A" />
                    <meta name="msapplication-navbutton-color" content="#E9D40A" />

                    <meta property="og:title" content="Javascript Tutorials - Functions" />
                    <meta property="og:description" content="Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/javascript/javascript-functions" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>JavaScript</span>
                            </button>
                        </div>
                        </nav>
                    <br/><br/><br/>
                    <h2>JavaScript Functions</h2>
                    <br/><br/>
                    <p>Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it.</p>
                    <p><strong>Syntax for declaring a function</strong></p>
                    <pre>
                    function functionName() {
                        // Code to be executed
                    }
                    </pre>
                    <h3>Function to print hello message</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; Function define and call &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                        function myFunction() &#123;<br/>
                            document.write("Hello, welcome to this TutsFinder!");<br/>
                        &#125;<br/>
                        myFunction();<br/>
                    &lt;/script&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    Hello, welcome to this TutsFinder!
                    <br/><br/>
                    <h3>Passing arguments in function</h3>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div className="javascript-program">
                    <pre style={{padding:`10px`}}>
                    &lt;!DOCTYPE html&gt;<br/>
                    &lt;head&gt;<br/>
                    &lt;title&gt; JavaScript Functions &lt;/title&gt;<br/>
                    &lt;/head&gt;<br/>
                    &lt;body&gt;<br/>
                    &lt;script&gt;<br/>
                    function area(l,b)&#123;<br/>
                    alert("Area is: "+l*b);<br/>
                    &#125;<br/>
                    &lt;/script&gt;<br/>
                    &lt;form&gt;<br/>
                    &lt;input type="button" value="click" onClick="area(4,5)"/&gt;<br/>
                    &lt;/form&gt;<br/>
                    &lt;/body&gt;<br/>
                    &lt;/html&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    Output:
                    <br/><br/>
                    <form>
                    <input type="button" value="click" onClick={this.area}/>
                    </form>
                    <br/><br/>
                        <br/>
                        <br/>
                        <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="javascript-strings">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="javascript-objects">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}