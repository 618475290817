import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CEnumeration extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C Programming - Enumeration</title>
                    <meta name="description" content = "Enum is a user defined data type that is used to store constant values that makes a program readable and simple." />
                    <meta name="keywords" content="C Enumeration,  C Enumeration Tutorial, Enumeration , enum data type, Enumeration in C, enum, enum keyword, " />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="C Programming - Enumeration " />
                    <meta property="og:description" content="Enum is a user defined data type that is used to store constant values that makes a program readable and simple." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c/c-enumeration" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C Programming</span>
                            </button>
                        </div>
                    </nav>
                <br/><br/>
                <h2>Enumeration</h2>
                <br/>
                <p>Enum is a user defined data type that is used to store constant values that makes a program readable and simple.</p>
                <p>Keyword ‘enum’ is used to decalare a enumeration.</p>
                <p><strong>Synatx</strong> :</p>
                <p>enum enum_name&#123;constant1, constant2,  ....... &#125;;</p>
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                <br/>
                enum week&#123;Sun,Mon, Tue, Wed, Thur, Fri, Sat&#125;;<br/>
                <br/>
                void main()<br/>
                &#123;<br/>
                    int i;<br/>
                    for (i=Sun; i &lt; =Sat; i++)<br/>
                        printf("%d ", i);<br/>
                        <br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/><br/>
                0 1 2 3 4 5 6
                <br/><br/>
                <p>In the above program we declare one enum that is week outside the main().</p>
                <br/><br/>
                <br/><br/>
                <div className="container" id="java-program">
                <pre>
                #include&lt;stdio.h&gt;<br/>
                enum language&#123;Hindi, English, Marathi, telugu&#125;;<br/>
                void main()<br/>
                &#123;<br/>
                    enum language l;<br/>
                    l=Hindi;<br/>
                    printf("%d\n",l);<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/><br/>
                <br/>
                <br/>
                    <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="c-type-casting">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="c-pre-processors">Next</Link>
                        </button>
                    </div>
                </div>
                </div>
            </>
        )
    }
}