import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaApplet extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Applet</title>
                    <meta name="description" content="An Applets are used to create GUI that can be embedded in to web pages. It resides on client side and run in browser to give dynamic functionality." />
                    <meta name="keywords" content="java applet program, java applet tutorial, introduction java Applet, core, concepts, example, java applet, language, applet basic, applet" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Applet" />
                    <meta property="og:description" content="An Applets are used to create GUI that can be embedded in to web pages. It resides on client side and run in browser to give dynamic functionality." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-applet" />
                </MetaTags>
            <div id="content">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn  btn-info">
                            <FontAwesomeIcon icon="align-left"/>
                            <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                        </button>
                    </div>
                    </nav>
            <br/><br/>
            <hr/>
            <strong style={{fontSize:`25px`}}>Java Applet</strong>
            <br/><br/>
            <p>An Applets are used to create GUI that can be embedded in to web pages. It resides on client side and run in browser to give dynamic functionality.</p>
            <p>An applet in embedded in HTML page using applet tag &lt;applet&gt;. Applets are used to provide interactive features that HTML can’t be provided.</p>
            <p>Applet is a small java program, It can’t run alone but rather to be embedded in another application.</p>
            <br/>
            <h3>Applet life cycle-</h3>
            <br/>
            <p>The life cycle of applet starts with init() and ends with destroy() method. In its life cycle an applet is goes through many phases –</p>
            <p>1.	Init()</p>
            <p>2.	Start()</p>
            <p>3.	Paint()</p>
            <p>4.	Stop()</p>
            <p>5.	Destroy()</p>
            <p><strong>init()</strong>- This is the first state of applet life cycle. In this method, variable declaration and initialization operations are performed.</p>
            <p>An applet is created by calling init() method. It is called only one time in applet life cycle, when applet is first loaded.</p>
            <p><strong>Start()</strong>- The start() method executes immediately after init() method. When start() method called, then applet is comes in running state.</p>
            <p>This method is called at least once in life span of an applet.</p>
            <p>This method is called when applet is start or restart.</p>
            <p><strong>Stop()</strong>- stop() method stops the execution of applet.</p>
            <p>It is called when we move from one page to another or minimizes the applet.</p>
            <p>It is called atleast one and can be called any no. of times.</p>
            <p><strong>Destroy()</strong>- It is called after stop() method. It is execute when applet window is closed.</p>
            <p>In this state applet is completely remove from the memory.</p>
            <p>It occurs only once in its overall life cycle. </p>
            <p><strong>Paint()</strong>- This method is called when applet is only in running state. It can be called any no. of times.</p>
            <p>Applet comes in display state whenever it has to perform output operations on the screen.</p>
            <p>Graphics class provides painting feature in applet.</p>

            <br/><br/>
            <strong>First Applet Program</strong>
            <p>Applet program can be run in two ways-</p>
            <p>By HTML</p>
            <p>By AppletViewer Tool</p>
            <br/><br/>
            <p style={{textAlign: 'center'}}>JavaAppletExp.java</p>
            <div className="container" id="java-program">
            <pre className="pre-tag-program">
            import java.applet.Applet;<br/>
            import java.awt.Graphics;<br/>
            public class JavaAppletExp extends Applet &#123;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;public void paint(Graphics g) &#123;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g.drawString("TutsFinder", 50, 25);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#125;<br/>
            &#125;
            </pre>
            </div>
            <br/><br/>
            <br/><br/>
            <p style={{textAlign: 'center'}}>JavaAppletShow.html</p>
            <div className="container" id="java-program">
            <pre className="pre-tag-program">
            &lt;html&gt;<br/>
            &lt;head&gt;<br/>
            &lt;TITLE&gt; Applet Program &lt;/TITLE&gt;<br/>
            &lt;/head&gt;<br/>
            &lt;body&gt;<br/>
            &lt;APPLET CODE="JavaAppletExp.class" WIDTH=150 HEIGHT=25&gt;<br/>
            &lt;/APPLET&gt;<br/>
            &lt;/body&gt;<br/>
            &lt;/html&gt;
            </pre>
            </div>
            <br/><br/>
            <div className="container" id="java-program">
            <pre className="pre-tag-program">
            NOTE: To run program through AppletViewer follow these commands:<br/>
            javac JavaAppletShow.java<br/>
            appletviewer javac JavaAppletExp.java
            </pre>
            </div>
            <br/><br/>
                <br/>
                <br/>
                <div className="row next-prev-button-row">
                    <div className="col-md-6">
                        <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-awt">Previous</Link>
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-swing">Next</Link>
                        </button>
                    </div>
                </div>
            </div>
            </>
        )
    }
}