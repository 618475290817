import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class HtmlComments extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>HTML Tutorials - Comments </title>
                    <meta name="description" content="Comments is a piece of code which increases the code readability and help the user to understand you code easily. Comment is not visible when the code is run on web browser." />
                    <meta name="keywords" content="comments in html, Html comments, Html comments Tutorial, website , tutorials, beginners, professionals, introduction to Html, concepts, example, Html, language, basic, introduction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="HTML Tutorials - Comments" />
                    <meta property="og:description" content="Comments is a piece of code which increases the code readability and help the user to understand you code easily. Comment is not visible when the code is run on web browser." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/html/html-comments" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>HTML</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>HTML Comments</h2>
                    <br/><br/>
                <p>Comments is a piece of code which increases the code readability and help the user to understand you code easily. Comment is not visible when the code is run on web browser.</p>
                <p>It is always a good practice to mention the comments in your code which also indicates the section of every document, specially if the code is complex then it make easier to read the code.</p>
                <br/>
                Syntax for Comment:
                <br/>
                &lt;!--..--&gt;
                <br/>
                <p>Any content place in between the &lt;!--..--&gt; indicates the comment and ignored by the browser.</p>
                <br/><br/>
                <div style={{backgroundColor:`#D5CFCF`}}>
                <h5 style={{padding:`10px`}}>Example</h5>
                <div className="html-program">
                <pre style={{padding:`10px`}}>
                &lt;!DOCTYPE html&gt;<br/>
                &lt;html&gt;<br/>
                &lt;head&gt;&lt;!—Heading of document-- &gt;<br/>
                &lt;/head&gt;&lt;!—Heading of document ends--&gt;<br/>
                &lt;body&gt;<br/>
                &lt;p&gt; Document content &lt;/p&gt;<br/>
                &lt;/body&gt;<br/>
                &lt;/html&gt;
                </pre>
                </div>
                </div>
                <br/><br/>
                <p>Types of Comments:</p>
                <p>•	Valid and Invalid Comments</p>
                <p>•	Multiline Comments- we can have multiple line of comments in our HTML document.</p>
                <p>•	Conditional Comments</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="html-meta-tag">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="html-anchor-tag">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}