import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>Java Tutorials - Inner Class</title>
                    <meta name="description" content="Inner class also known as nested classes which are define inside another class. It helps in to grouping of classes that are related to each other." />
                    <meta name="keywords" content="java tutorial, core java tutorial, java programming, Java InnerClass , Java Constructor, tutorials, beginners, professionals, java Collection, core, concepts, example, java, language, basic, " />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials -Inner Class" />
                    <meta property="og:description" content="Inner class also known as nested classes which are define inside another class. It helps in to grouping of classes that are related to each other." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-inner-class" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>

                <br/><br/>
                <hr/>
                <strong style={{fontSize:`25px`}}>Java Inner Class</strong>
                <p>Inner class also known as nested classes which are define inside another class.</p>
                <p>Inner classes are non-static nested classes.</p>
                <p>Inner classes are able to access the data of outer class including private data members and methods.</p>
                <p>It helps in to grouping of classes that are related to each other.</p>
                <p>Increases readability and maintainability of code.</p>
                <p>Types of Inner class – </p>
                <p>1.	Nested Inner Class</p>
                <p>2.	Local Inner Class</p>
                <p>3.	Anonymous Inner Class</p>
                <br/>
                <p>Nested Inner class can access other instance variables of the outer class, even if they are declared as private. </p>
                <p>Class created inside a method is known as local inner class. If you want to invoke the method of local inner class, you must instantiate this class inside the method.</p>
                <p>A class that have no name is known as Anonymous Inner class. It is created in two ways –</p>
                <p>1.	Class</p>
                <p>2.	interface
                </p>
                <br/>
                <h6>Program to show how we can acces the data of inner class.  </h6>
                <br/><br/>
                <div className="container" id="java-program">
                    <pre className="pre-tag-program">
                class Outer &#123;<br/>
                    int num;<br/>
                    private class Inner &#123;		// inner class<br/>
                        public void show() &#123;<br/>
                        System.out.println("This is an inner class");<br/>
                        &#125;<br/>
                    &#125;<br/>
                    void showInner() &#123;		//access inner class method from method in outer class<br/>
                        Inner in = new Inner();<br/>
                        in.show();<br/>
                    &#125;<br/>
                &#125;<br/>
                <br/>
                public class InnerClassDemo &#123;<br/>

                    public static void main(String[] args) &#123;<br/>

                        Outer out = new Outer();<br/>

                        out.showInner();<br/>
                    &#125;<br/>
                &#125;
                    </pre>
                </div>
                <br/><br/>
                <p>Here, Outer is an outer class and Inner is an inner class and show is the method inside inner class.</p>
                <strong>Output:</strong>
                <br/><br/>
                <div className="container" id="java-program">
                <pre className="pre-tag-program">
                    This is an inner class
                </pre>
                </div>
                <br/><br/>
                    <br/>
                    <br/>

                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="java-interface">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-exception-handling">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}