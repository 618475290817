import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppPointers extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Pointers</title>
                    <meta name="description" content = "In C++, a pointer is a variable that contains the address of another variable rather than store the value directly. Just the same way as a variable of type int is used to contain an integer value, A pointer varibale is one that is used to contain a memory address." />
                    <meta name="keywords" content="Pointers in c++, Pointers, C++ Pointers,  C++ Pointers Tutorial, instagram pointers, Pointers, tutsfinder, udemy, coursera" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Pointers" />
                    <meta property="og:description" content="In C++, a pointer is a variable that contains the address of another variable rather than store the value directly. Just the same way as a variable of type int is used to contain an integer value, A pointer varibale is one that is used to contain a memory address." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-pointers" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Pointers</h2>
                    <br/>
                    <p>In C++, a pointer is a variable that contains the address of another variable rather than store the value directly. Just the same way as a variable of type int is used to contain an integer value, A pointer varibale is one that is used to contain a memory address.</p>
                <p><strong>Syntax :</strong> </p>
                <p>data_type *pointer_name;</p>
                <br/>
                <h3>Declaring a Pointer  </h3>
                <br/>
                <p>Type *pointer_variable_name = null;			//initializing value</p>
                <h3>Declaring a pointer to an integer would be:</h3>
                <p>Int *a=null; </p>
                <br/>
                Program to determining the addresses of an int and a double
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    int age=20;<br/>
                    const double Pi = 3.1416;<br/>
                    <br/>
                    cout &lt;&lt; "Integer value address  : " &lt;&lt; &age &lt;&lt; endl;<br/>
                    cout &lt;&lt; "Double value address : " &lt;&lt; &Pi;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/> <br/>
                <div className="container" id="cpp-program">
                <pre>
                Integer value address  : 0x7ffda263f844<br/>
                Double value address : 0x7ffda263f848
                </pre>
                </div>
                <br/><br/>
                <h3>Using poiters to stor addresses  </h3>
                <p>Assume a variabe declaration of the types you already know  :</p>
                <pre>
                (//declaraing a variable)<br/>
                Type variable_name = initial_value;
                </pre>
                <p>To store the address of a variable in a pointer, you would declare a pointer to the same type and initialized the pointer to the variable’s address using the refrence operator (&):</p>
                <pre>
                (//declaring a pointer to the same type and iniatializing to address:)<br/>
                Type* pointer = &variable;
                </pre>
                <br/>
                Program to demnostrate the declaration and initialization of a pointer
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    int age=20;<br/>
                    int* a = &age;<br/>
                    <br/>
                    cout &lt;&lt; "Integer value address  : " &lt;&lt; a &lt;&lt; endl;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output :
                <br/> <br/>'<div className="container" id="cpp-program">
                <pre>
                Integer value address  : 0x7fffe3ce4414
                </pre>
                </div>
                <br/><br/>
                Program that demostrate pointer reassignment to another variable
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    int age=20;<br/>
                    int* a = &age;<br/>
                    <br/>
                    cout &lt;&lt; "First Age is : " &lt;&lt; endl;<br/>
                    cout &lt;&lt; "a is  : " &lt;&lt; a &lt;&lt; endl;<br/>
                    <br/>
                    int myAge =23;<br/>
                    a = &myAge;<br/>
                    cout &lt;&lt; "My age : " &lt;&lt; endl;<br/>
                    cout &lt;&lt; "a is : " &lt;&lt; a;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                First Age is :<br/>
                a is  : 0x7fff188e05c0<br/>
                My age :<br/>
                a is : 0x7fff188e05c4
                </pre>
                </div>
                <br/><br/>
                <h3>What is the sizeOf() of a Pointer  ?</h3>
                <br/>
                <p>It returns the no. of bytes required to store, The sizeOf() is a pointer is hence dependent on the compiler and the operating system the program has been compiled for.</p>
                <br/>
                Program to demonstrate that pointers to different types have the same sizes –
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                <br/>
                int main()<br/>
                &#123;<br/>
                    int age = 20;<br/>
                    double pi = 3.1416;<br/>
                    char sayYes = 'y';<br/>
                    <br/>
                    int* a = &age;<br/>
                    double* p = &pi;<br/>
                    char* s = &sayYes;<br/>
                    <br/>
                    cout &lt;&lt; "sizeof(int) = " &lt;&lt; sizeof(int) &lt;&lt; endl;<br/>
                    cout &lt;&lt; "sizeof(double) = " &lt;&lt; sizeof(double) &lt;&lt; endl;<br/>
                    cout &lt;&lt; "sizeof(char) = " &lt;&lt; sizeof(char) &lt;&lt; endl;<br/>
                    <br/>
                    cout &lt;&lt; "sizeof(a) = " &lt;&lt; sizeof(a) &lt;&lt; endl;<br/>
                    cout &lt;&lt; "sizeof(p) = " &lt;&lt; sizeof(p) &lt;&lt; endl;<br/>
                    cout &lt;&lt; "sizeof(s) = " &lt;&lt; sizeof(s) &lt;&lt; endl;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                sizeof(int) = 4<br/>
                sizeof(double) = 8<br/>
                sizeof(char) = 1<br/>
                sizeof(a) = 8<br/>
                sizeof(p) = 8<br/>
                sizeof(s) = 8
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-strings">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-namespaces">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}