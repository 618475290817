import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class CppAbstraction extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>C++ Tutorials - Abstraction</title>
                    <meta name="description" content="Data abstraction is the most essential feature of Object Orientd programmin C++." />
                    <meta name="keywords" content="C++ abstraction,  C++ abstraction Tutorial, c++ abstraction javatpoint, c++ abstraction tutsfinder, abtraction" />
                    <meta name="theme-color" content="#D00777" />
                    <meta name="msapplication-navbutton-color" content="#D00777" />

                    <meta property="og:title" content="C++ Tutorials - Abstraction" />
                    <meta property="og:description" content="Data abstraction is the most essential feature of Object Orientd programmin C++." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/c++/cpp-abstraction" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>C++ Programming</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/>
                <h2>Abstraction</h2>
                <br/>
                <p>Abstraction is a process to hide the implementation details from the users and showing only the functionality. It displays only essential things.</p>
                <p>Data abstraction is the most essential feature of Object Orientd programmin C++.</p>
                <p>For example: Like ATM Machine, we can see only functionality but don’t know internal details that how it works.</p>
                <h4>Advantages:</h4>
                <br/>
                <p>• Increases reusability of code by minimizes code redundancy.</p>
                <p>• Separates program into code and implementation.</p>
                <p>• Increases code readability.</p>
                <p>We can achieve abstraction by using these two ways in C++</p>
                <br/>
                <p><strong>By using Classes:</strong>  In class we can group data members and member functions using access specefiers.A class can allow which data members will be visible the class and which is not.</p>
                <p><strong>By using Header files:</strong> One more type of abstraction in C++ can be header files. We can use many method which is already defined in header files of C++. For example – sqrt(), whenever we need to calculate the square root of any number, we just need to implement math.h class.</p>
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                #include &lt;iostream&gt;<br/>
                using namespace std;<br/>
                class Multiplication &#123;<br/>
                        (// constructor)<br/>
                        public:<br/>
                        Multiplication(int i=1) &#123;<br/>
                        Ans = i;<br/>
                        &#125;<br/>
                        void mul(int num) &#123;<br/>
                        Ans *= num;<br/>
                        &#125;<br/>
                        int getMult() &#123;<br/>
                        return Ans;<br/>
                        &#125;;<br/>
                    private:<br/>
                        (// hidden data from outside world)<br/>
                        int Ans;<br/>
                &#125;;<br/>
                int main() &#123;<br/>
                    Multiplication obj;<br/>
                    obj.mul(5);<br/>
                    obj.mul(2);<br/>
                    obj.mul(3);<br/>
                    cout &lt;&lt; "Ans is : " &lt;&lt; obj.getMult() &lt;&lt; endl;<br/>
                    return 0;<br/>
                &#125;
                </pre>
                </div>
                <br/><br/>
                Output:
                <br/><br/>
                <div className="container" id="cpp-program">
                <pre>
                Ans is : 30
                </pre>
                </div>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="cpp-class-and-objects">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="cpp-encapsulation">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}