import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class JavaKeywords extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                  <title>Java Tutorials - Keywords</title>
                    <meta name="description" content="Java keywords are reserved words that have predefined meaning in java. They are total 51 keywords in java, but I have mentioned only few-" />
                    <meta name="keywords" content="what is keywords in java, java keywords tutsfinder, Java keywords, keywords in java" />
                    <meta name="theme-color" content="#08F271" />
                    <meta name="msapplication-navbutton-color" content="#08F271" />

                    <meta property="og:title" content="Java Tutorials - Keywords" />
                    <meta property="og:description" content="Java keywords are reserved words that have predefined meaning in java. They are total 51 keywords in java, but I have mentioned only few-" />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/java/java-keywords" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>Java</span>
                            </button>
                        </div>
                        </nav>
                <br/><br/><hr/>
                    <strong style={{fontSize:`35px`}}>List of Java Keywords</strong>
                    <br/>
                    <br/>
                    <p>Java keywords are reserved words that have predefined meaning in java. They are total 51 keywords in java, but I have mentioned only few- </p>
                <p>Here is a list of keywords in the java programming language.
                </p>
                    <br/>
                    <img src="../img/java/java-keywords.png" alt="Java Jeywords" style={{maxWidth:`100%`}} />
                    <br/><br/>
                    <h3>Modifiers</h3>
                    <p>Modifiers can be categorized in two categories, access modifiers and non-access modifiers:</p>
                    <p>1.	Access modifiers
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Public
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Protected
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Default
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Private
                </p>
                    <br/>
                    <img src="../img/java/access-specefiers.png" alt="Access Specefiers" style={{maxWidth:`100%`}} />
                    <br/><br/>
                    <p>2.	Non-access modifiers
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Static
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Abstract
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	Final
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	synchronized
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	New
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	This
                <br/>&nbsp;&nbsp;&nbsp;&nbsp;•	super
                </p>
                <br/>
                <h3>Static keyword</h3>
                <p>Static keyword is used with variables and methods; it is mainly used to make your programs memory efficient.</p>
                <p>When we declare static variable, then classes maintain one copy of class variables regardless of how many instances exist of that class.</p>
                <p>Static can also be used to define a method as class method. Class methods are invoked by the class instead of a specific instance, and can only operate on class variable.</p>
                <p>Static method does not require object to call the method.</p>
                <p><strong>Static field</strong> - Another name of class variable.</p>
                <p><strong>Static method</strong> - Another name of class method.</p>
                <br/>
                <h3>Abstract</h3>
                <p>A keyword used in class definition to specify that a class is not be instantiated, but rather be inherited by other classes.</p>
                <p>An abstract class can have abstract and non abstract methods that are not implemented in the abstract class, but in subclass.</p>
                <p><i>Abstract</i> methods are undefined methods i.e. methods without body, there is no implementation details of abstract methods.</p>
                <p><i>Non-abstract</i> methods are defined methods i.e. methods with body.</p>
                <br/>
                <h3>Final</h3>
                <p>Final keyword is used to restrict the functionality of the variable, class and method.</p>
                <p><strong>Final variable</strong>- If we declare any variable as final, then it will not be change i.e. constant.</p>
                <p><strong>Final method</strong>- I we declare any method as final, then it will not be override.</p>
                <p><strong>Final class</strong>-If any class will be declaring as final class than that class can’t be inherited.</p> 
                <br/>
                <h3>Synchronized</h3>
                <p>A keyword in Java programming language that, when applied to a method or code block, guarantees that at most one thread at a time executes the code.</p>
                <br/>
                <h3>new Keyword</h3>
                <p>new keyword in java is used to dynamic allocates memory for an object returns a reference to object and binds the reference with the object. Here reference to object means address of object. So it is essential in java that all the objects must be dynamically allocated.</p>
                <br/>
                <h3>this Keyword </h3>
                <p>A java keyword that can be used to represent an instance of the class in which it appears(i.e. current class). this can be used to access class variables and methods.</p>
                <br/>
                <h3>Super Keyword</h3>
                <p>A java keyword used to access members of a class inherited by the class in which it appears.</p>
                    <br/>
                    <br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="jdk-jre-jvm">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="java-constructor">Next</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}