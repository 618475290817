import React from 'react';
import $ from 'jquery';
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class PhpFunctions extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        $(document).ready(function () {
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
            });
        });
    }
    render() {
        return (
            <>
                <MetaTags>
                    <title>PHP Tutorials - Function</title>
                    <meta name="description" content = "Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta name="keywords" content="function, php function, functionin php, php function tutsfinder" />
                    <meta name="theme-color" content="#08D4F2" />
                    <meta name="msapplication-navbutton-color" content="#08D4F2" />

                    <meta property="og:title" content="PHP Tutorials - Function" />
                    <meta property="og:description" content="Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it." />
                    <meta property="og:site_name" content="www.tutsfinder.com" />
                    <meta property="og:url" content="https://www.tutsfinder.com/php/php-function" />
                </MetaTags>
                <div id="content">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button type="button" id="sidebarCollapse" className="btn  btn-info">
                                <FontAwesomeIcon icon="align-left"/>
                                <span style={{fontFamily: `Poppins, sans-serif`, marginLeft: `5px`}}>PHP</span>
                            </button>
                        </div>
                        </nav>

                    <br/><br/>
                    <hr/>
                    <strong style={{fontSize:`35px`}}>PHP Functions</strong>
                    <br/><br/>
                    <p>Function is a block of code where we can implement our data to execute it. It is design to perform specific task. Functions have unique name so that other functions can call it or execute it.</p>

                    <h1>How to create and invoke a function</h1>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    function functionName(){<br/>
                        // Code to be executed<br/>
                    }
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php

                    (//define a function that displays hello function)

                    function hello()&#123;
                        echo “Hello World”;
                    &#125;
                    hello();

                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Function with parameters </h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    (// Defining function)<br/>
                    function getSum($num1, $num2)&#123;<br/>
                    $sum = $num1 + $num2;<br/>
                    echo "Sum of the two numbers $num1 and $num2 is : $sum";<br/>
                    &#125;<br/>
                    (// Calling function)<br/>
                    getSum(10, 20);<br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/>

                    <h1>Advantages of using functions </h1>
                    <p><strong>Reduction in code</strong> – Same function can be used anywhere in the program. Users just need to copy paste the block of code when there is need.</p>
                    <p><strong>Code can be maintain easily</strong> – Functions makes code maintainable, if user change in function than it will be automatically changed without affecting any other files.</p>
                    <p><strong>Reusability</strong> – Functions makes code reusable because same code can be used many times.</p>
                    <p><strong>Reduction in the no. of errors</strong> – When code is divided into chunks in the form of functions then it is easy to exactly know here error is occur.</p>

                    <p>PHP has its own built-in functions and also user can define own functions i.e. user defined functions.</p>
                    <br/>
                    <h1>PHP built in functions</h1>
                    <p>PHP comes with many standard built in functions, user just directly call the functions in order to use.</p>
                    <p><strong>Example</strong> – sqrt(), ettype(), print_r(), var_dump.</p>
                    <br/>
                    <h1>PHP user defined functions</h1>
                    <p>These are special functions that are declare by the users, these are users own defined functions.</p>
                    <br/>
                    <h2>Rules to create user defined functions</h2>
                    <p>•	Function names must start with a letter or an underscore but not a number.</p>
                    <p>•	Function name must be unique.</p>
                    <p>•	Function name must not contain spaces.</p>
                    <div style={{backgroundColor:`#D5CFCF`}}>
                    <h5 style={{padding:`10px`}}>Example</h5>
                    <div style={{backgroundColor:`#EFF4F2`, fontSize:`20px`}}>
                    <pre style={{padding:`1rem`}}>
                    &lt;?php<br/>
                    <br/>
                    (//define a function that displays hello function)<br/>
                    <br/>
                    function sum()&#123;<br/>
                        echo 1 + 2;<br/>
                    &#125;<br/>
                    sum();<br/>
                    <br/>
                    ?&gt;
                    </pre>
                    </div>
                    </div>
                    <br/><br/>
                    <div className="row next-prev-button-row">
                        <div className="col-md-6">
                            <button id="prev-button" className="btn float-left btn-success btn-lg"><Link to="php-string">Previous</Link>
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button id="next-button" className="btn float-right btn-success btn-lg"><Link to="php-control-statements">Next</Link>
                            </button>
                        </div>
                    </div>
                    </div>
            </>
        )
    }
}