import React, { Component  } from 'react'

class GoogleAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
        <ins className = "adsbygoogle"
                style = { {display:"inline-block",width:"728px",height:"90px"} }
                data-ad-client = "ca-pub-9313522705855841"
                data-ad-slot = ""></ins>
        </div>)
    }
}

export default GoogleAd;